<template>
    <b-modal id="changePasswordModal" hide-footer title="Change Password" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex" >
                <span style="font-weight: bold; margin-left: 2px">Change Password</span>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('changePasswordModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        <div>
                <b-overlay :show="is_loading">
                <div class="m-1" >Password</div>
                <b-form>
                    <div class="d-flex">
                        <b-form-input class="password" type="password" size="sm" :state="formState1()" v-model.lazy="password" autocomplete="false" placeholder="Enter Password"></b-form-input>
                        
                        <b-button size="sm" variant="warning" @click="password = ''" class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                    </div>
                    <p class="m-0 p-0 error_styling" v-show="password_minLength">This field must be at least 6 characters.</p>
                    <p class="m-0 p-0 error_styling"  v-show="password_character_check">Password requires at least 1 special character such as (!#$&).</p>
                    <div class="m-1 mt-3">Confirm Password</div>
                    <div class="d-flex">
                        <b-form-input class="password" type="password" size="sm" :state="formState2()" autocomplete="false" v-model.lazy="confirm_password" placeholder="Enter Password" aria-describedby="input-2-live-feedback"></b-form-input>
                        <b-button size="sm" variant="warning" @click="confirm_password = ''" class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                    </div>
                    <p class="m-0 p-0 error_styling" v-show="confirm_password_minLength">This field must be at least 6 characters.</p>
                    <p class="m-0 p-0 error_styling" v-show="confirm_password_character_check">Password requires at least 1 special character such as (!#$&).</p>
                    <p class="m-0 p-0 error_styling" v-show="confirm_password_match">Passwords do not match.</p>
                </b-form>
                <span class="mt-2" style="font-size: 10px; display:inline-flex; align-items: center;" v-if="password.length != 0 || confirm_password.length != 0"><input class="m-1 cursor-pointer" type="checkbox" @click="showPassword()"><span class="">Show Password</span></span>
                <b-button @click="updatePasswordDetails()" class='ms-auto mt-3' size="md" style="float: right" variant='primary'>Update Password</b-button>
                </b-overlay>
            </div>
    </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
//mport draggable from 'vuedraggable';
import { levelComputed, peopleComputed, peopleMethods, } from '@/state/helpers';
//import _ from 'lodash'
export default {
mixins: [dateMixin],
components: {
    //VueTypeaheadBootstrap
},
data:() => ({
    is_loading: false,
    password: '',
    confirm_password: '',
    password_minLength: false,
    password_character_check: false,
    confirm_password_minLength: false,
    confirm_password_character_check: false,
    confirm_password_match: false
}),
props:{
    payload: {
        required: true
    }
},
watch:{
},
computed:{
    ...levelComputed,
    ...peopleComputed,
},
methods:{
    ...peopleMethods,
    formState1() {
        
        if(this.password.length == 0) {
            this.password_minLength = false
            this.password_character_check = false
            return null
        }
        if(this.password.length < 6) {
            this.password_minLength = true
            return false
        } else {
            this.password_minLength = false
        }
        if(!this.validateNewPassword(this.password)) {
            this.password_character_check = true
            return false
        } else {
            this.password_character_check = false
        }
        //if(validateNewPassword())
        if(this.validateNewPassword(this.password) && this.password.length > 6) {
            this.password_minLength = false
            this.password_character_check = false
            return true
        }
        return null
    },
    formState2() {
        if(this.confirm_password.length == 0) {
            this.confirm_password_match = false
            this.confirm_password_minLength = false
            this.confirm_password_character_check = false
            return null
        }
        if(this.confirm_password.length < 6) {
            this.confirm_password_minLength = true
            return false
        } else {
            this.confirm_password_minLength = false
        }
        if(!this.validateNewPassword(this.confirm_password)) {
            this.confirm_password_character_check = true
            return false
        } else {
            this.confirm_password_character_check = false
        }
        if(this.confirm_password != this.password) {
            this.confirm_password_match = true
            return false
        } else {
            this.confirm_password_match = false
        }
        if(this.confirm_password == this.password) {
            this.confirm_password_match = false
            this.confirm_password_minLength = false
            this.confirm_password_character_check = false
            return true
        } else {
            this.confirm_password_match = true
        }
        return null
    },
    showPassword() {
        let x = document.getElementsByClassName("password");
        console.log(x)
        if(x.length > 1) {
            Array.from(x).forEach(item => {
                if(item.type == "password") {
                    item.type = "text";
                } else {
                    item.type = "password";
                }
            })
        }
    },
    validateNewPassword(password) {
        if(/(?=.*[!@#$%^&*])/.test(password)) {
            return true
        } else {
            return false
        }
    },
    updatePasswordDetails(){
        if(this.confirm_password != this.password) {
            this.$swal.fire({
                title: 'The passwords do not match',
                icon: "warning",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "ok!",
            })
            return
        }
        if(!this.validateNewPassword(this.password)) {
            this.$swal.fire({
                title: 'Please enter a valid password of at least 6 characters and one special character (!#$&).',
                icon: "warning",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "ok!",
            })
            return
        }
        this.$swal.fire({
            title: "Are you sure you want to update this employee's password?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, update it!",
        })
        .then( result =>{
            if(result.value){
                console.log(JSON.parse(JSON.stringify(this.payload)))
                let updatedUser = this.payload
                if(updatedUser.active == undefined){
                    //* include new 'active' flag
                    this.updatedUser['active'] = true;
                }
                updatedUser.password = this.confirm_password;
                this.updateEmployee(updatedUser)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.$bvModal.hide('changePasswordModal')

                    })
                })
            }
        })
        
    },
},
beforeDestroy() {
    this.password = ''
    this.confirm_password = ''
},
mounted(){
    this.password = ''
    this.confirm_password = ''
},
}
</script>
<style>
.error_styling {
    font-size: 10px;
    color: red;
}
</style>