<template>
    <div>
        <!-- display default alert with task progress and info -->
        <div v-if="status != 100">
            <b-alert show>
                    <h6>Task Progress</h6>
                <div class="d-flex flex-row">
                    <label>Step: {{step}}</label>
                    <label>Responsible User: {{userId}}</label>
                    <label>Completion Date: {{completionDate}}</label>
                    <label>Procedure: {{notes}}</label>
                </div>

                <div class="d-flex flex-column">
                     <b-form-select class="w-25 rounded" v-model="status" :options="options">{{status}}</b-form-select>
                     <b-progress :value='status' class="w-100 mt-2" show progress animated></b-progress>
                </div>
            </b-alert>
        </div>

        <!-- display success alert with task info and completion message -->
        <div v-else>
            <b-alert variant="success" show>
                <h6>Task Progress</h6>
                <div class="d-flex flex-row">
                    <label>Step Number: {{step}}</label>
                    <label>User Name: {{userId}}</label>
                    <label>Completion Date: {{completionDate}}</label>
                    <label>Procedure: {{notes}}</label>
                </div>
                <h6>Task Completed<b-icon class="m-1" icon="check-circle-fill" variant="success"></b-icon></h6>
            </b-alert>
        </div>
    </div>
</template>

<script>
    import { peopleMethods } from '@/state/helpers'
    //receives props from ./onboarding & ./offboarding
    export default{
        props:{
            step: Number,
            userId: String,
            //completionDate: Date,
            notes: String,
            check_id: Number,
        },
        data(){
            return{
                //progress milestones for tasks
                options: [0, 25, 50, 75, 100],
                status: this.status,
                completionDate: new Date().toLocaleDateString('en-US')
            }
        },
        watch:{
            status(){
               this.saveItem();
            }
        },
        methods:{
            ...peopleMethods,
            //* add a check_id prop that takes the item id of the step (id)
            saveItem(){
                // * create a new object with the values we want to save
                const check_save_params = {
                    check_id: this.check_id,
                    completion_date: this.completionDate,
                    status: this.status,
                    notes: '', // can leave this as an empty string for now
                };

                const save_params = {
                    //* active user from the top candidates table
                    user_id: this.activeCandidateId,
                    //* This endpoint accepts an array of checks that can be saved
                    //* but we are only saving 1 item here
                    tracking: [check_save_params]
                }

                //* fire the save endpoint from the people store
                this.saveOnboardingTracking(save_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                })
            }
        }
    }
</script>

<style scoped>
    label{
        padding-right: 1rem;
    }
</style>