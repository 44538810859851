<template>
    <div class="border border-dark">
        <apexchart
        v-if="hasMounted"
        type="bar"
        :options="options"
        :series="series">
        </apexchart>
    </div>
</template>

<script>
export default {
    props:{
        graph_data: {
            required: true
        }
    },
    data:() => ({
        hasMounted: false,
    }),
    computed:{
        options(){
            return {
                chart:{
                    id: window.webix.uid(),
                    toolbar:{
                        show: false,
                    }
                },
                plotOptions:{
                    bar: {
                        distributed: true
                    }
                },
                xaxis:{
                    categories:this.graph_data.names,
                }
            }
        },
        series(){
            return [{data: this.graph_data.series}]
        }
    },
    methods:{},
    mounted(){
        this.$nextTick(()=>{
            this.hasMounted = true;
        })
    },
}
</script>

<style>

</style>