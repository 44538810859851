<template>
    <div>
        <b-card class="">
            <Table
                v-if="has_mounted && !show_kanban"
                :columns="columns"
                :simple_columns="simple_columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="activities"
                :is_loading="is_loading"
                :context_menu_items="['View', 'Edit', 'Delete']"
                only_editable_columns
                :default_values="default_values"
                :ignore_edit_only_rules="['level_id', 'milestone_objective_id']"
                :button_methods="button_methods"
                re_order
                enable_pretty_mode
                :inner_card_component_name="'keyActionTile'"
                has_header_filters
                :validation="table_validation_rules"
                :required_permissions="['action-plans','action-plans-manager']"
                :disable_action_buttons="areMultipleObjectivesSelected"
                :variable_watchers="variable_watchers"
                @selectedRowEvent="selectedRowEvent"
                @updateViewMode="updateViewMode"
                @saveTable="saveTable"
                @updatedOrderEvent="onRowIndexUpdate"
                @deleteRows="deleteRow">
                <template #title>
                    <span v-if="key_action_type == 'personal'">Personal </span>Action Plan
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span>  
                        <span>
                            <span class="text-primary">{{returnSelectedObjectiveName}}</span>
                            &nbsp;&nbsp;>&nbsp;&nbsp;
                            <span class="text-success">{{returnCurrentUserName}}</span>
                        </span>
                        <span v-if="false" class="text-danger">
                            No Objective Selected
                        </span>
                    </span>
                </template>
                <template #buttons>
                    <b-button v-show="key_action_type != 'personal'" title="Show Kanban" @click="setShowKanban(true)" size="sm" variant="primary"  class="me-2" style="display: inline-block;">
                        <i class="fas fa-grip-horizontal"></i>
                    </b-button>
                    
                </template>
            </Table>
            <kanbanChart v-if="show_kanban && company_milestones.length != 0" />
            <b-alert v-show="company_milestones.length == 0 && key_action_type != 'personal'" show variant="info" >
                Add a Milestone to view kanban
            </b-alert>
        </b-card>
        <ActivityLinkingModal v-if="show_activity_linking_modal" :payload="modal_payload" :activities="activities" @selectedNewID="handleNewActivityParent"/>
        <HierarchyModal v-if="show_level_linking_modal" :payload="modal_payload"/>
        <ObjectiveModal v-if="show_objective_linking_modal" :payload="modal_payload"/>
        <!--- rating test -->
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue'
import kanbanChart from '@/components/kanban/kanbanView';
import _ from 'lodash'
import {
    activityRatingMethods,
    activityMethods, activityComputed,
    levelComputed, levelMethods,
    adminComputed, webixTableState,
    performanceComputed, performanceMethods,
    peopleComputed, peopleMethods
} from "@/state/helpers.js";
import { format, parseISO } from 'date-fns';

import ActivityLinkingModal from '../misc/activityModals/linkedActivityModal.vue'
import HierarchyModal from '../misc/hierarchyModal.vue'
import ObjectiveModal from '../misc/objectiveModals/linkedObjectiveModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
export default {
    mixins: [ dateMixin ],
    props: {
        key_action_type:{
            default: 'task',
        }
    },
    components:{
        Table,
        ActivityLinkingModal,
        ObjectiveModal,
        HierarchyModal,
        kanbanChart
    },
    data:() => ({
        //* table vars
        table_uid: 'activityList',
        table_view_mode: 'view',
        is_loading: false,
		delete_array: [],
        changes_made_flag: false,
        //* -------------

        flip: true,
        isShow: {
            isCancel: false,
            isView: true,
            isEditingNew: false,
            isEditingExisting: false,
            isGuideline: false,
        },
        priority_options:[
            { id: 'low', value: 'Low'},
            { id: 'medium', value: 'Medium'},
            { id: 'high', value: 'High'},
        ],

        status_options:[
            { id: '0', value: '0%'},
            { id: '25', value: '25%'},
            { id: '50', value: '50%'},
            { id: '75', value: '75%'},
            { id: '100', value: '100%'}
        ],
        //* booleans to show modals
        show_activity_linking_modal: false,
        show_objective_linking_modal: false,
        show_level_linking_modal: false,
        modal_payload: null,

        has_mounted: false,
    }),
    watch:{
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
        selected_personal_objective() {
            this.is_loading = true
            this.$nextTick(() => {
                this.is_loading = false
            })
        }
    },
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...webixTableState,
        ...performanceComputed,
        ...peopleComputed,
        is_component_disabled(){
            let is_invalid = this.selected_goal_objective == -1;
            return is_invalid;
        },
        canShowHeaderFilters(){
            if(this.table_states[this.table_uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        canShowRatingComponent() { return !!this.selected_user_id && this.selected_objective_details != null },
        activity_inbox(){
            //TODO VERIFY ACTIVITY_INBOX
            let data = [];
            //if(this.selected_objective_details != null){
            //    data = this.selected_objective_details.plan_data.inbox;
            //}
            return data;
        },
        activities(){
            let data = [];
            let temp_data = [];
            if(this.key_action_type == 'personal') {
                temp_data = _.cloneDeep(this.employeeDetails.assigned_plans)
                if(this.selected_personal_objective == -1) {
                    console.log(1)
                    console.log(temp_data)
                    data = temp_data
                } else {
                    console.log(2)
                    //filter all assigned plans
                    let data1 = temp_data.filter(item => {
                        return item.milestone_objective_id == this.selected_personal_objective
                    })
                    data = data1
                }
            } else {
                console.log("SHOULD NOT GET HERE")
                temp_data = _.cloneDeep(this.level_plans)
                if(this.level_plans != null){
                if(this.current_objective_details && this.current_objective_details.plan_data){
                    let temp_data = [];
                    temp_data = _.cloneDeep(this.current_objective_details.plan_data)
                    temp_data.forEach( item => {
                        //TODO - remove after calvin adds column in backend
                        if(item.linked_key_result == undefined){
                            item.linked_key_result = null;
                        } 
                        if(item.status != 'Sent'){
                            item.activity_type = item.type;
                            item.type = 'folder';
                            data.push(item);
                        }
                    })
                 }
                }
            }
            
            //data = _.uniqBy(data, 'id');
            //data = _.orderBy(data, ['item_order']);
            // if(this.level_plans != null){
            //     let temp_data = [];
            //     temp_data = _.cloneDeep(this.level_plans)
            //     temp_data.forEach( item => {
            //         //TODO - remove after calvin adds column in backend
            //         if(item.linked_key_result == undefined){
            //             item.linked_key_result = null;
            //         } 
            //         if(item.status != 'Sent'){
            //             item.activity_type = item.type;
            //             item.type = 'folder';
            //             data.push(item);
            //         }
            //     })
            // }
            console.log(data)
            data = _.uniqBy(data, 'id');
            return data;
        },
        availableLinkingActivities(){
            let options = [{ id: 'null', value: 'Not Linked'}];
            if(this.selected_objective_details != null){
                this.selected_objective_details.plan_data.forEach( plan => {
                    options.push({
                        id: plan.id,
                        value: plan.name
                    })
                })
            }
            return options;
        },
        activeUserId(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        areMultipleObjectivesSelected(){
            return this.selected_objectives.length > 0
        },
        returnSelectedObjectiveName(){
            if(this.key_action_type == 'personal') {
                //return the name of the selected personal objective
                if(this.selected_personal_objective != null && this.employeeDetails) {
                    let objective = this.employeeDetails.personal_objectives.find(item => {
                        return item.id == this.selected_personal_objective
                    })
                    if(objective) {
                        return objective.name
                    } else {
                        return 'No Personal Objective Selected'
                    }
                    
                } else {
                    return 'No Personal Objective Selected'
                }
            } else {
                if(this.areMultipleObjectivesSelected){
                return 'Multiple Objectives Selected'
                }
                else if(this.selected_objective != null){
                    return this.selected_objective.name;
                }
                else{
                    return 'No Level Objective Selected';
                }
            }
            
        },
        
        returnCurrentUserName(){
            let name = '';
            if(this.activeUserId == -1){
                name = 'All users';
            }
            else{
                let index = _.findIndex(this.userList, {id: Number(this.activeUserId)})
                if(index != -1){
                    name = this.userList[index].value;
                }
                else{
                    name = '#error#';
                }
            }
            return name;
        },
        variable_watchers(){
            var el = this;
            return {
                //eslint-disable-next-line
                milestone_objective_id(newVal, oldVal, id, is_new_row){
                    if(newVal != null){
                        let item = window.webix.$$(el.table_uid).getItem(id);
                        item.linked_key_result = null;
                        window.webix.$$(el.table_uid).updateItem(id, item);
                    }
                },
                //eslint-disable-next-line
                linked_key_result(newVal, oldVal, id, is_new_row){
                    console.log('Look at me', newVal, oldVal)
                    if(newVal != null){
                        let item = window.webix.$$(el.table_uid).getItem(id);
                        item.milestone_objective_id = null;
                        window.webix.$$(el.table_uid).updateItem(id, item);
                    }
                }
                // start_date(newVal, oldVal, id, is_new_row){
                //     let item = window.webix.$$(el.table_uid).getItem(id);
                //     if(is_new_row == false){
                //         //update flags for table save
                //         item['must_update_target'] = true;
                //         window.webix.$$(el.table_uid).updateItem(id, item);

                //         let new_date = parseISO(newVal);
                //         let old_date = parseISO(oldVal);
                //         if(isBefore(new_date, old_date)){
                //             el.$swal.fire('New values from the adjusted start date will have to be updated in this graphs dataset view.')
                //         }
                //         else if(isAfter(new_date, old_date)){
                //             el.$swal.fire('Some data will be lost due to new starting date');
                //         }
                //     }
                //     else{
                //         //* for a new row, set deadline to start date
                //         item.deadline = newVal;
                //         window.webix.$$(el.table_uid).updateItem(id, item);
                //     }
                // },
            }
        },
        simple_columns(){
            let columns = [
                {   
                    id: "progress",
                    tooltip: '#progress_value#%',
                    header: "Progress",
                    width: '100', compact: true,
                    sort: 'string',
                    format: (value) => {
                        if(value != undefined){
                            return `<div class="bar mt-2"><div class="bar-inner" style="width: ${value}%"></div></div>`
                        }
                        else{
                            return `<div class="bar mt-2"><div class="bar-inner" style="width: 0%"></div></div>${value}`;
                        }
                    },
                },
                    //* NB! type is used by webix so just remember activity_type == type
                    {
                        id: "name", header: this.canShowHeaderFilters ? ["Action", {content: "textFilter"}] : "Action", tooltip: false,
                        fillspace: 2,
                        template: (obj, common, value) => {
                            if(value != ''){
                                return common.treetable(obj, common) + "<span>"+value+"</span>";
                            }
                            else{
                                return '';
                            }
                        },
                        exportAsTree: true,
                    },
                    //{ id: "activity_type", header: "Type", minWidth: 150, editor: 'combo', options:['Programme', 'Project', 'Task']},
                    { id: "end_date", header: "End Date",tooltip: '',
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                    },
                    { id: "priority", header: "Priority", minWidth: 120, editor: 'combo', options: this.priority_options, tooltip: '',}, 
                    { id: "status", header: "Status", minWidth: 120, editor: 'combo', options: this.status_options, tooltip: '',},
                    { id: "responsible_user_id", header: this.canShowHeaderFilters ? ["Responsible User", { content:"richSelectFilter" }] : "Responsible User",
                        options: this.userList,
                        minWidth: 150,
                        tooltip: '',
                        suggest:{
                            view:"suggest",
                            filter:function(item, value){
                                let text = value.toLowerCase();
                                let name = item.value.toLowerCase();
                                let email = item.email.toLowerCase();
                                if(name.includes(text) || email.includes(text)){
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            },
                            body:{
                                view:"list",
                                data:this.userList,
                                template:"#value# - #email#",
                                yCount:10
                            }
                        },
                    },
                    {
                        id: 'notes',
                        header: "Notes",
                        width: 60,
                        template:function(obj){
                            if(obj.is_new || obj.is_empty){
                                return '';
                            }
                            if(obj.notes == null || obj.notes == ''){
                                return '';
                            }
                            return `
                            <div style='text-align: center' class="notes_button">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                            </div>
                            `
                        }
                    }
                ];
                return columns;
        },
        //* table props
        columns(){
            //let el = this;
            return [
                { id: "progress", header: "Progress", width: '100'},
                //* NB! type is used by webix so just remember activity_type == type
                {
                    id: "name", header: "Action", fillspace:true, editor: 'text',
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + "<span>"+value+"</span>";
                        }
                        else{
                            return '';
                        }
                    },
                    exportAsTree: true,
                    tooltip:(obj)=>{
                        if(obj.is_empty){ return '' }
                        else{
                            return obj.name;
                        }
                    },
                },
                //{ id: "activity_type", header: "Type", width: 150, editor: 'combo', options:['Programme', 'Project', 'Task']},
                //{ id: "name", header: "Name", fillspace: 0.8, editor: 'text'},
                //{ id: "description", header: "Description", fillspace: 1, editor: 'text'},
                { id: "responsible_user_id",
                    header: this.canShowHeaderFilters ? ["Responsible User", { content:"richSelectFilter" }] : "Responsible User",
                    editor: "combo", options: this.userList,
                    width: 150,
                    suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                },
                { id: "owner_user_name", header: "From", width: 150,},
                { id: "priority", header: "Priority", width: 120, editor: 'combo', options: this.priority_options},
                { id: "status", header: "Status", width: 120, editor: 'combo', options: this.status_options},
                { id: "start_date", header: "Start Date", width: 120, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "end_date", header: "End Date",width: 120, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                /*{
                    id: 'parent_id', header: 'Linked Activity', editor: "combo", fillspace: 0.9,
                    options: this.availableLinkingActivities,
                },*/
                // {
                //     id: "milestone_objective_id", //* _name
                //     header: 'Linked Objective',
                //     width: 230,
                //     template:function(obj){
                //         console.log(obj)
                //         if(obj.is_empty){
                //             return '';
                //         }
                //         return `<span class="show_hierarchy_text">
                //                             <i class="me-1 show_objective_modal fas fa-pen"></i>
                //                             ${obj.milestone_objective_id}
                //                         </span>`
                //     }

                // },
                // { 
                //     id: 'parent_id',
                //     header: 'Linked Activity',
                //     width: 230,
                //     template:function(obj){
                //         if(obj.parent_id != undefined){
                //             let index = _.findIndex(el.selected_objective_details.plan_data.plans, {id: Number(obj.parent_id)})
                //             let name = 'Error' 
                //             if(index != -1){
                //                 name = el.selected_objective_details.plan_data.plans[index].name;
                //             }
                //             if(el.table_view_mode === 'edit'){
                //                 return `
                //                     <span class="show_hierarchy_text">
                //                         <i class="me-1 show_activity_modal fas fa-pen"></i>
                //                         ${name}
                //                     </span>`
                //             }
                //             else{
                //                 return `
                //                     <span>
                //                         ${name}
                //                     </span>`
                //             }
                //         }
                //         else if(obj.parent_id === null){
                //             if(el.table_view_mode === 'edit'){
                //                 return `
                //                     <span class="show_hierarchy_text">
                //                         <i class="me-1 show_activity_modal fas fa-pen"></i>
                //                         Not Linked
                //                     </span>`
                //             }
                //             else{
                //                 return `
                //                     <span>
                //                         Not Linked
                //                     </span>`
                //             }
                //         }
                //         else{
                //             return '';
                //         }
                //     }
                // },
                // {
                //     id: "level_id",
                //     //editor: "combo",
                //     header: "Structure",
                //     width: 150,
                //     template:function(obj){
                //         if(obj.level_id != undefined){
                //             let index = _.findIndex(el.levels, {id: obj.level_id });
                //             let level_name = '';
                //             if(index != -1){
                //                 level_name = el.levels[index].name;
                //             }
                //             if(el.table_view_mode === 'edit'){
                //                 //* we only need to show the pencil icon in edit mode
                //                 return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                //             }
                //             else{
                //                 return `<span>${level_name}</span>`
                //             }
                //         }
                //         else{
                //             return ''
                //         }
                //     },
                // },
                // {
                //     id: "linked_key_result",
                //     //editor: "combo",
                //     header: "Linked KR",
                //     width: 230,
                //     editor: 'combo',
                //     //eslint-disable-next-line
                //     options: this.getAvailableKeyResults,
                // },
                {
                    id: 'notes', header: 'Notes', editor: 'text'
                }
            ];
        },
        default_values(){
            let el = this;
            return [    
                { id : "level_id", value: el.selected_level.id },
                { id : 'responsible_user_id', value: el.employeeDetails ? el.employeeDetails.id : null},
                //{ id : "level_milestone_id", value: null },
                //{ id : "parent_objective_id", value: null },
                { id: "milestone_objective_id", value: el.selected_goal_objective != -1 ? el.selected_goal_objective : -1}, //! old selected_objective_id
                { id : "status", value: '0'},
                /*{ id : "parent_id", value: null },*/
                { id : "start_date", value: format(new Date(), 'yyyy-MM-dd')},
                { id : "priority", value: 'low'},
                //{ id : "linked_key_result", value: null } // if kr is selected, use it as default link
                //{ id : "deadline", value: format(new Date(), 'yyyy-MM-dd')}
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_activity_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showActivityModal(item);
                },
                //eslint-disable-next-line
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){

                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                //eslint-disable-next-line
                'notes_button':function(e, id){
                    let item = this.getItem(id)
                    let note_data = {
                        item: item,
                        can_edit: false,
                        table: 'actionPlan'
                    }
                    //el.notes_text = item.notes;
                    el.$eventHub.$emit('showNotesModal', note_data);
                },
            }
            return onClick;
        },
        table_validation_rules(){
            let el = this;
            return{
                "name":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "milestone_objective_id": (item, obj) => {
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if((item === '' || item == undefined) && obj.linked_key_result == null && el.key_action_type != 'personal'){
                            console.log('validation', item, obj.linked_key_result)
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                // "activity_type":function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "responsible_user_id":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "status":function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "start_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == {} || item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.end_date){
                                return el.startDateCheck(obj.start_date, obj.end_date)
                            }
                            return true;
                        }
                    }
                },
                "end_date": function(item, obj){
                    if(item == {} || obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.end_date){
                                return el.endDateCheck(obj.end_date, obj.start_date)
                            }
                            return true;
                        }
                    }
                },
            }
        },

        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid;
        },
        dropdownFix(){
            if(this.changes_made_flag || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
        getAvailableKeyResults(){
            let items = [
                {id: 'null', value: "Not Linked"}
            ];
            if(this.selected_objective_details && this.selected_objective_details.key_results){
                this.selected_objective_details.key_results.forEach( item => {
                    items.push({
                        id: item.id,
                        value: item.name,
                    })
                })
            }
            return items;
        },
        getAvailableObjectives(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            if(this.activity_data == null){
                return names;
            }
            
            
            if(this.activity_data.parent_objectives != undefined){
                this.activity_data.parent_objectives.forEach( item =>{
                    names.push({
                        id: item.id, 
                        value: item.name,
                    });
                })
                //users.push({id: value.id, value: value.name, email: value.email});
            }
            this.activity_data.objectives.forEach( item =>{
                names.push({
                    id: item.id, 
                    value: item.name,
                });
            })
            
            return names;
        },
    },
    methods:{
        ...activityRatingMethods,
        ...activityMethods,
        ...levelMethods,
        ...performanceMethods,
        ...peopleMethods,
        //* table methods
        selectedRowEvent(row){
            if(this.key_action_type == 'personal') {
                if(!row){
                this.selectPersonalActivity(null);
                return;
                }
                //* Event returns the selected row
                let index = _.findIndex(this.employeeDetails.assigned_plans, {id: row.id})
                if(index != -1){
                    this.selectPersonalActivity(this.employeeDetails.assigned_plans[index]);
                }
            } else {
                if(!row){
                this.selectActivity(null);
                return;
                }
                //* Event returns the selected row
                let index = _.findIndex(this.activities, {id: row.id})
                if(index != -1){
                    this.selectActivity(this.activities[index]);
                }
            }
           
            
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        saveTable(data){
            let objective_id = null
            if(this.key_action_type == 'personal') {
                if(this.selected_personal_objective != -1) {
                    console.log("1")
                    let data = this.employeeDetails.personal_objectives
                    let objective = data.find(item => {
                        let id = this.selected_personal_objective
                        return item.id == id
                    })
                    objective_id = objective.id
                } else {
                    console.log("2")
                    objective_id = null
                }
                console.log(objective_id)
            } else {
                console.log("3")
                if(this.selected_goal_objective != -1 ) {
                    objective_id = this.selected_goal_objective
                }
            }
            let rows_to_save = [];
                    data.forEach( row => {
                        if(!row.is_empty){
                            let owner = row.owner_id
                            if(this.key_action_type == 'personal') {
                                owner = this.employeeDetails.id
                            }
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : row.id,
                                parent_id: null,
                                level_id:  this.selected_level.id,
                                objective_id: objective_id,
                            //key_result_id: row.linked_key_result,
                                name: row.name,
                                start_date: this.getFormattedDate(row.start_date),
                                end_date: this.getFormattedDate(row.end_date),
                                type: this.key_action_type == 'personal' ? 'personal' : 'task',
                                description: 'Empty',
                                body: 'Empty',
                                notes: row.notes == null ? '' : row.notes,
                                status: row.status,
                                priority: row.priority,
                                owner_id: owner != undefined ? owner: this.core.id,
                                responsible_user_id: Number(row.responsible_user_id),// row.owner_user_id//this.findLevelUserId(row.owner_name)?.id,
                            };
                            if(params.key_result_id){
                                params.objective_id = null;
                            }
                            rows_to_save.push(params);
                        }
                    });
                    //let objective_id = this.selected_objective.id;
                    let activity_params = {
                        plans: rows_to_save
                    }
                    this.is_loading = true;
                    //this.is_loading = true;
                    this.saveActivityInbox(activity_params)
                    .then(()=>{
                        if(this.key_action_type == 'personal') {
                            this.refreshPersonalObjectiveData()
                        } else {
                            this.refreshData();
                        }
                    })
                    .catch(()=>{
                        this.is_loading = false;
                    })
                    // .then(()=>{
                    //     this.loadPerfromanceData();
                    // })
        },
        refreshPersonalObjectiveData() {
            let params = {
               level_id: this.current_level_id, milestone_data: 1
            }
            this.loadPerformanceScreenData({
                type: 'level',
                params: params,
            })
            .then(()=>{
                this.loadEmployeeData(this.selected_user_id)
                this.delete_array = [];
                this.changes_made_flag = false;
                this.$refs[this.table_uid].table_mode = 'view';
                this.$refs[this.table_uid].showCardView();
                this.is_loading = false;   
            })
        },
        refreshData(){
            let params = {
                level_id: this.current_level_id,
                objective_id: this.current_objective_details.id,
                objective_data: 1,
                //graph_data: 1
            }
            this.loadPerformanceScreenData({
                type: 'objective',
                params: params,
            })
            .then(()=>{
                this.delete_array = [];
                this.changes_made_flag = false;
                this.$refs[this.table_uid].table_mode = 'view';
                this.$refs[this.table_uid].showCardView();
                this.is_loading = false;   
            })
        },

        saveTable2(){
            //eslint-disable-next-line
            if('1' == true){
                return;
            }
            else
            return new Promise(( resolve, reject) => {
                // eslint-disable-next-line
                let table = $$(this.table_uid);
                let is_valid = table.validate();

                if(is_valid == false){
                    reject();
                    Swal.fire("Invalid rows found.", "", "warning")
                }

                if(is_valid){
                    const all_data = table.serialize(null, true);
                    let objective_id = null
                    if(this.key_action_type == 'personal') {
                        if(this.selected_personal_objective != null) {
                            let objective = this.employeeDetails.personal_objectives.find(item => {
                                let id = this.selected_personal_objective
                                return item.id = id
                            })
                            objective_id = objective.id
                        }
                        console.log(objective_id)
                    } else {
                        if(this.selected_goal_objective != -1 ) {
                            objective_id = this.selected_goal_objective
                        }
                    }
                    
                    
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        if(!row.is_empty){
                            let owner = row.owner_id
                            if(this.key_action_type == 'personal') {
                                owner = this.employeeDetails.id
                            }
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : row.id,
                                parent_id: null,
                                level_id:  this.selected_level.id,
                                objective_id: objective_id,
                            //key_result_id: row.linked_key_result,
                                name: row.name,
                                start_date: this.getFormattedDate(row.start_date),
                                end_date: this.getFormattedDate(row.end_date),
                                type: this.key_action_type == 'personal' ? 'personal' : 'task',
                                description: 'Empty',
                                body: 'Empty',
                                notes: row.notes == null ? '' : row.notes,
                                status: row.status,
                                priority: row.priority,
                                owner_id: owner != undefined ? owner : this.core.id,
                                responsible_user_id: Number(row.responsible_user_id),// row.owner_user_id//this.findLevelUserId(row.owner_name)?.id,
                            };
                            if(params.key_result_id){
                                params.objective_id = null;
                            }
                            rows_to_save.push(params);
                        }
                    });
                    //let objective_id = this.selected_objective.id;
                    let activity_params = {
                        //level_id: this.selected_level.id,
                        //objective_id: objective_id,
                        plans: rows_to_save
                    }
                    this.is_loading = true;
                    this.saveActivityInbox(activity_params)
                    .then(()=>{
                        this.loadEmployeeData(this.selected_user_id)
                        this.$refs[this.table_uid].table_mode = 'view';
                        // let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                        // this.loadObjectiveDetailAPI(params)
                        // .then(()=>{
                        //     this.delete_array = [];
                        //     this.changes_made_flag = false;
                        //     console.log('Action Plan table ref',this.$refs[this.table_uid])
                        //     this.$refs[this.table_uid].table_mode = 'view';
                        //     this.is_loading = false;
                        // })
                        this.loadPerfromanceData();
                    })
                }
            })
        },
        getQueryParams(){
            let params = {};
            if(this.selected_milestone){
                params = {
                    level_id: this.current_level_id,
                    graph_data: 1,
                    //user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                    milestone_id: this.selected_milestone.id
                }
            }
            else{
                params = {
                    level_id: this.current_level_id,
                    //user_id: this.selected_user_id ? this.selected_user_id : 0,
                    milestone_data: 1,
                }
            }
            if(this.selected_user_id){
                params['user_id'] =  this.selected_user_id
            }
            return params;
            
        },
        loadPerfromanceData: _.debounce( function (){
            let params = this.getQueryParams();
            this.loadPerformanceScreenData({
                type: 'level',
                params: params,
            })
            .then(()=>{
                this.delete_array = [];
                this.changes_made_flag = false;
                this.$refs[this.table_uid].table_mode = 'view';
                this.is_loading = false;
                this.$swal.fire({
                    icon:'success',
                    title:'Success',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        }, 1),
        reloadGoalObjectveData2(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    user_id: this.selected_user_id ? this.selected_user_id : 0,
                    objective_id: this.selected_goal_objective,
                    objective_data: 1,
                    //graph_data: 1
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                })
                .catch(()=>{
                    reject();
                })
            })
        },
        getFormattedDate(value){
            let newValue = ''
            if(typeof value === 'string'){
                newValue = format(parseISO(value), 'yyyy-MM-dd');
            }
            else{
                newValue = format(value, 'yyyy-MM-dd');
            }
            return newValue;
        },
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            let params = {
                type: 'activity',
                data: sortData,
            }
            this.updateItemOrder(params)
            .then(()=>{
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected activities?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteObjectiveTask({key_action_id: item.id}));
            })
            this.is_loading = false;
            Promise.all(promises)
            .then(()=>{
				// let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                // this.loadObjectiveDetailAPI(params)
                // .then(()=>{
                //     this.$refs[this.table_uid].table_mode = 'view';
                //     //this.toggleViewMode();
                //     this.is_loading = false;
                //     let objective = _.find(this.activity_data.objectives, {id: objective_id})
                //     this.setSelectedObjective(null);
                //     this.$nextTick(()=>{
                //         this.setSelectedObjective(objective);
                //     })
                // })
                if(this.key_action_type == 'personal') {
                    this.refreshPersonalObjectiveData()
                } else {
                    this.refreshData();
                }
			})
        },
        showObjectiveModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_objective_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedObjectiveModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_objective_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        showActivityModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_activity_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedActivityModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_activity_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        showLevelModal(payload){
            if(this.table_view_mode === 'edit'){
                this.modal_payload = _.cloneDeep(payload);
                this.modal_payload['modal_type'] = 'Activity'
                this.show_activity_linking_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkedActivityModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedActivityModal") {
                            this.show_activity_linking_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            }
        },
        //* -------------


        selectActivity(item){
            this.setSelectedActivity(item);
        },
        selectPersonalActivity(item){
            this.setSelectedPersonalActivity(item);
        },
        


        //* webix misc
        initColumnConfig(){
            // eslint-disable-next-line
            /*let table = $$("activityList");
            if (!this.ownerDropdown || !table) {
                setTimeout(() => {
                    this.initColumnConfig();
                }, 100);
                return;
            }

            table.getColumnConfig("owner_name").suggest = this.ownerDropdown.map((item) => {
                return {
                    id: item,
                    value: item,
                };
            });*/
        },

        /* modal methods */
        showHierarchyModal(payload){
            if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }
            else{
                if(this.table_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'Objective'
                    this.show_level_linking_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('hierarchyModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                                this.show_level_linking_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            }
        },
        handleNewActivityParent(newID){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            //* update the parent id fields (the dollar property mignt be unused)
            item['parent_id'] = Number(newID);
            item['$parent'] = Number(newID);

            let table_state = table.getState(); //* get open nodes :)
            this.$bvModal.hide('linkedActivityModal') //* hide modal
            this.saveTable()
            .then(()=>{
                this.$nextTick(()=>{
                    table = window.webix.$$(this.table_uid);
                    table_state.open.forEach( id => {
                        if(table.exists(id)){
                            table.open(id);
                        }
                    })
                    if(item.parent_id != null && table.exists(item.parent_id)){
                        table.open(item.parent_id);
                    }
                    table.select(item.id);
                    let index = _.findIndex(this.all_activities, { id: item.id });
                    if(index != -1){
                        let activity = _.cloneDeep(this.all_activities[index]);
                        this.$eventHub.$emit('selectedObjective', activity);
                        this.setSelectedActivity(activity);
                    }
                })
                //this.loadObjectiveDetailAPI(this.selected_objective.id);
            })

        }
    },
    mounted(){
        this.has_mounted = true;
        this.setShowKanban(true);
    },
}
</script>

<style lang="scss" scoped>
::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}
</style>