<template>
    <div>
        <div class="card-title">
            Skills Summary
        </div>
        <div v-if="skills.length > 0">
            <b-row v-if="hasCandidates == false">
                <b-alert show >No candidate data available</b-alert>
            </b-row>
            <b-row v-else>
                <b-col xl="3" lg="4" md="6" sm="6" v-for="graph in skills" :key="graph.id">
                    <h5>{{graph.name}}</h5>
                    <Graph :graph_data="graph" />
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-alert show variant="warning">No Skills Added</b-alert>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Graph from './ratingGraph.vue'
export default {
    components:{
        //eslint-disable-next-line
        Graph,
    },
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed, 
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
        /*skills(){
            let candidates = [];
            if(this.active_job_data != null){
                candidates = this.active_job_data.candidates;
                candidates.forEach( person => {
                    let skills = person.skills;
                    skills.forEach( item => {
                        let index = _.findIndex(this.job_skills, {id: item.skill_id});  
                        if(index != -1){
                            console.log(this.job_skills[index])
                            item['skill_name'] = this.job_skills[index].skill_name;
                            item['skill_description'] = this.job_skills[index].skill_description;
                        }
                    })
                })
            }



            if(this.active_candidate_data != null){
                //data = this.active_candidate_data.skills
                data = this.active_candidate_data.skills;
                data.forEach( item => {
                    let index = _.findIndex(this.job_skills, {id: item.skill_id});  
                    if(index != -1){
                        console.log(this.job_skills[index])
                        item['skill_name'] = this.job_skills[index].skill_name;
                        item['skill_description'] = this.job_skills[index].skill_description;
                    }
                })
            }
            return data;
        },*/
        /*summary_data(){
            let data = [];
            if(this.active_job_data != null){
                this.active_job_data.candidates.forEach( person => {
                    
                })
            }
        },*/
        skills(){
            let data = [];
            if(this.active_job_data != null){
                let job_skills = _.cloneDeep(this.active_job_data.skills);
                job_skills.forEach( skill => {
                    let data_object = {
                        id: skill.id,
                        name: skill.skill_name,
                        names:[],
                        series:[],
                    };
                    this.active_job_data.candidates.forEach( person => {
                        data_object.names.push(person.first_name);
                        
                        let idx = _.findIndex(person.skills, {skill_id: skill.id})
                        if(idx > -1){
                            data_object.series.push(person.skills[idx].proficiency_achieved);
                        }
                        else{
                            data_object.series.push(0);
                        }
                    })
                    data.push(data_object);
                    //this.active_job_data.candidates.forEach( person => {
                        /*this.active_candidate_data.skills.forEach( skill => {
                            if(item.id == skill.skill_id){
                                item['proficiency_achieved'] = skill.proficiency_achieved;
                                item['interviewer_user_id'] = skill.interviewer_user_id;
                                item['save_id'] = skill.id;
                            }
                        })
                        if(item.proficiency_achieved == undefined){
                            item['proficiency_achieved'] = 0;
                        }*/
                    //})
                    /*if(item.proficiency_achieved == undefined){
                        item['proficiency_achieved'] = 0
                    }*/
                })
            }

            return data;
        },
        summary_data(){
            let data = [];
            if(this.active_job_data != null){
                this.active_job_data.candidates.forEach( person => {
                    let person_data = {
                        id: person.id,
                        name: person.first_name,
                        values:{
                            experience: 0,
                            skill_level: 0,
                            team_fit: 0,
                            total: 0,
                        }
                    }
                    person.interviews.forEach( interview => {
                        person_data.values.experience += interview.experience;
                        person_data.values.skill_level += interview.skill_level;
                        person_data.values.team_fit += interview.team_fit;
                    })
                    if(person.interviews.length > 0){
                        person_data.values.experience = (Math.round((person_data.values.experience / person.interviews.length) * 10) / 10).toFixed(1);
                        person_data.values.skill_level = (Math.round((person_data.values.skill_level / person.interviews.length) * 10) / 10).toFixed(1);
                        person_data.values.team_fit = (Math.round((person_data.values.team_fit / person.interviews.length) * 10) / 10).toFixed(1);
                        
                        let a = Number(person_data.values.experience);
                        let b = Number(person_data.values.skill_level);
                        let c = Number(person_data.values.team_fit)
                        person_data.values.total = a + b + c;
                    }
                    data.push(person_data);
                })
            }
            return data;
        },
        hasCandidates(){
            let valid = false;
            if(this.active_job_data != null){
                if(this.active_job_data.candidates.length > 0){
                    valid = true;
                }
            }
            return valid;
        },
        graph_dataa(){
            let names = [];
            if(this.active_job_data != null){
                this.active_job_data.candidates.forEach( person => {
                    console.log('Person', person)
                    names.push(person.first_name);
                })
            }
            let data = [];
            this.job_skills.forEach( skill => {
                data.push({
                    id: skill.id,
                    series:[],
                    names:names,
                })
            });

            if(this.active_job_data != null){
                data.forEach( item => {
                    console.log('Looping', item.id)
                    this.active_job_data.candidates.forEach( person => {
                        console.log('candidate', person.first_name)
                        person.skills.forEach( skill => {
                            console.log('Skill', skill.id)
                            if(item.id == skill.skill_id){
                                console.log('SKILL ==', skill.id)
                            }
                            //let index = _.findIndex(data, {id: skill.skill_id});
                            //if(index != -1){
                            //    data[index].series.push(Number(skill.proficiency_achieved))
                            //}
                        })
                    })
                })
            }

            

            return data;
        },
        graph_data(){
            let data = {
                experience:{
                    series:[],
                    names:[],
                },
                skill_level:{
                    series:[],
                    names:[],
                },
                team_fit:{
                    series:[],
                    names:[],
                },
                total:{
                    series:[],
                    names:[],
                },
            }
            this.summary_data.forEach( item => {
                data.experience.series.push(item.values.experience);
                data.experience.names.push(item.name);

                data.skill_level.series.push(item.values.skill_level);
                data.skill_level.names.push(item.name);

                data.team_fit.series.push(item.values.team_fit);
                data.team_fit.names.push(item.name);

                data.total.series.push(item.values.total);
                data.total.names.push(item.name);

            })
            return data;
        },
        job_skills(){
            let data = [];
            if(this.active_job_data != null){
                //data = this.active_candidate_data.skills
                data = this.active_job_data.skills;
                //let index = _.findIndex
            }
            return data;
        },
        interviews(){
            let data = [];
            if(this.active_candidate_data != null){
                data = _.cloneDeep(this.active_candidate_data.interviews);
                data.forEach( item => {
                    item['total'] = item.experience + item.skill_level + item.team_fit;
                });
            }
            return data;
        },
    },
    methods:{
        ...peopleMethods,
    },
    mounted(){},
}
</script>

<style>

</style>