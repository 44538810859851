<template>
    <!--<div class="graph-toolbar">
        <span class="graph-toolbar-text card-title p-0 m-0 text-truncate">
            <slot name="text"></slot>
        </span>
        <span class="graph-toolbar-buttons">
            <slot name="buttons"></slot>
        </span>
    </div>-->
    <div>
        <div class="d-flex flex-1">
            <span style="padding-top: 0.15rem;">
                <slot name="badges"></slot>
            </span>
            
            <span class="ms-auto">
                <slot class="" name="buttons"></slot>
            </span>
        </div>
        <div class="card-title p-0 m-0">
            <slot name="text"></slot>
        </div>
    </div>

</template>

<script>
export default {
    props:{
        noHeader:{
            type: Boolean,
            default: false,
        }
    },
    data:() => ({}),
    computed:{},
    methods:{},
}
</script>

<style>
/*.graph-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.graph-toolbar-text{
    flex-basis: 50%;
    text-align: left;
}
.graph-toolbar-buttons{
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
    .graph-toolbar-buttons {
        justify-content: flex-start; 
    }
}*/
</style>