<template>
    <b-card no-header class="bg-light bg-soft">
        <div v-if="!this.employeeDetails.current_job.skills || this.employeeDetails.current_job.skills.length < 3">
            <b-alert show>
                Please ensure the linked job has required skills
            </b-alert>
        </div> 
        <compentencyRating />
        <!-- <userRating /> -->
    </b-card>
</template>

<script>

import { levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    peopleComputed,
    peopleMethods,
    performanceComputed,
    performanceMethods,
    ganttComputed,
    ganttMethods } from "@/state/helpers.js";
//import complianceRating from "./scorecard/complianceRating.vue";
import compentencyRating from "./skills/compentencyRating.vue";
//import userRating from "./skills/userRating.vue";

export default {
    name: 'skills',
    data:() => ({
        name:null
    }),
    components:{
        compentencyRating,
       //userRating
    },
    computed:{
        ...ganttComputed,
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        ...peopleComputed
    },
    methods:{
        ...ganttMethods,
        ...levelMethods,
        ...activityMethods,
        ...performanceMethods,
        ...peopleMethods
    },
    beforeDestroy(){
    },
    mounted(){
    },
}
</script>

<style>

</style>