<template>
    <div style="height: 100%; width: 100%;">
        <b-overlay :show="this.gantt_kanban_loading">
            <b-alert class="mb-1" show v-if="this.gantt_kanban_loading == false && this.gantt_tasks.length == 0" >
                <span style="font-weight: bold;">
                    {{ getNoDataMessage }}
                </span>
            </b-alert>
            <div v-show="!hide_gantt">
                <CardHeader>
                    <!-- <template #title-buttons>
                        
                    </template> -->
                    <template #title>
                        Gantt View
                        <span v-if="selected_milestone_goal == -1">
                            &nbsp;>&nbsp;
                        </span>
                        <span v-if="selected_milestone_goal == -1" class="text-primary">{{ getMilestoneName }}</span>
                        <span v-if="selected_milestone_goal != -1">
                            &nbsp;>&nbsp;
                        </span>
                        <span v-if="selected_milestone_goal != -1" class="text-primary">{{ getMilestoneGoalName }}</span>
                        <span v-if="gantt_selected_objective_id !== -1">
                            &nbsp;>&nbsp;
                        </span>
                        <span class="text-primary">{{ getSelectedName }}</span>
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button title="Return to Objectives" class="m-0" size="sm" variant="primary" @click="setShowGantt(false)">
                                <font-awesome-icon :icon="['fas', 'table']" />
                            </b-button>
                            <b-dropdown variant="success" size="sm" class="" no-caret>
                                <template #button-content>
                                    <span class="me-2">{{ selectedScale }}</span>
                                    <font-awesome-icon icon="fa-caret-down"/>
                                </template>
                                <b-dropdown-item v-for="value in scales" :key="value.unit" @click="selectScale(value.unit)">
                                    {{value.unit}}
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-button size="sm" @click="hide_columns = !hide_columns" class="" variant="primary">
                                <span v-show="hide_columns">
                                    Show Details
                                </span>
                                <span v-show="!hide_columns">
                                    Hide Details
                                </span>
                            </b-button>
                        </span>
                        <!-- <b-button class="ms-1" size="sm" variant="success" @click="collapsed_gantt_chart = !collapsed_gantt_chart">
                            {{ collapseGanttText }}
                        </b-button> -->
                    </template>
                </CardHeader>
                <transition name="fade">
                        <webix-ui v-if="show_chart" :config="ui" />
                </transition>

            </div>
        </b-overlay>
    </div>
</template>

<script>
// eslint-disable-next-line
import { isBefore, parseISO } from 'date-fns'
import { activityComputed, activityMethods, ganttMethods, ganttComputed, levelComputed, settingsComputed, performanceComputed, performanceMethods } from '@/state/helpers';
// eslint-disable-next-line
import CardHeader from '@/components/widgets/cardHeader.vue';
// import ObjectiveEditModal from '../scheduler/objectiveEditModal.vue';
import _ from 'lodash';
import Utils from '@/mixins/utils'
export default {
    mixins:[ Utils],
    components: { CardHeader },
    data:() => ({
        collapsed_gantt_chart: false,
        gantt_state: null,
        show_chart: false,
        hidden_gantt: false,
        hide_columns: true,
        is_loading: false,
        window_resize_event: null,
        objective_ids: [],
        filter_input: '',
        selectedScale: 'Monthly',
        //selectedScale: 'Selected Scale',
        scales: [ { unit: "Yearly" },
                { unit: "Quarterly"},
                { unit: "Monthly"},
                { unit: "Weekly"} ],
        rows: [],
        ui:{
            css:'objectivesGantt',
            id: 'myGantt',
            view:"gantt",
            scroll: true,
            select: 'row',
            markers: [
                { css: "webix_gantt_today_marker", now: true }
            ],
            readonly: false,
            //criticalPath: true,
            edit: false,
            //resources: true, // must be enabled!
            // show resources view
            //display: "resources",
            //width: 0,
            minHeight: 300,
            scales: [
                {   unit: "month", step: 1, format: "%M"},
            ],
        },
        gantt_selected_objective: '',
        show_objectives_edit_modal: false,
    }),
    
    computed:{
        ...activityComputed,
        ...ganttComputed,
        ...levelComputed,
        ...settingsComputed,
        ...performanceComputed,
        getNoDataMessage(){
            //* if user is selected, retuen name.
            //* if no user selected, return level name
            if(this.selected_user_id != null){
                let user = this.all_users.find( person => { return person.id == this.selected_user_id; })
                return `No Gantt Data Available for ${user.name}`;
            }
            else{
                return `No Gantt Data Available for ${this.selected_level.name}`;
            }
        },
        getMilestoneGoalName() {
            let item = this.goal_objectives.find( task => {
                return task.id == this.selected_milestone_goal
            });
            if(!item){
                return ''
            }
            return item.name
        },
        getMilestoneName() {
            if(this.pinned_milestone_id != -1){
                let name = '';
                if(this.selected_milestone.name != null) {
                    name = `${this.selected_milestone.year} - ${this.selected_milestone.name}`;
                } else {
                    name = `${this.selected_milestone.year}`
                }
                return `${name} (${this.goal_objectives.length})`
            }
            else{
                return 'No Milestone Selected';
            }
            //return this.selected_milestone ? this.selected_milestone.name : "All Milestones";
        }, 
        getSelectedName() {
            if(this.gantt_selected_objective_id !== -1 && this.gantt_tasks) {
                let item = this.gantt_tasks.find( task => {
                    let task_type = 'objective'
                    if(task.item_type == 'key_result'){
                        task_type = 'kr'
                    }
                    if(task.item_type == 'milestone') {
                        task_type = 'milestone'
                    }
                    if(task.plain_id == this.gantt_selected_objective_id && this.gantt_selected_item_type == task_type) {
                        return task.plain_id == this.gantt_selected_objective_id
                    }    
                });
                if(!item){
                    return ''
                }
               return item.text
            } else {
                return null
            }
        },
        collapseGanttText(){
            let text = this.collapsed_gantt_chart ? 'Expand' : 'Collapse';
            return `${text} Gantt Chart`;
        },
        getQuarterText(){
            if(this.objective_viewing_quarter == 0){
                return 'All Quarters'
            }
            else{
                return `Quarter ${this.objective_viewing_quarter}`;
            }
        }
    },
    methods:{
        ...activityMethods,
        ...ganttMethods,
        ...performanceMethods,
        // doPinnedMilestone(){
        //     //* get id by combining type and id -> objective-112 or kr-318
        //     let item_id = `${this.gantt_selected_item_type}-${this.gantt_selected_objective_id}`

        //     //* get parent node (not returned with serialize)
        //     let parent = _.clone(window.webix.$$('myGanttTree').getItem(item_id));
        //     parent.parent = 0; //*gant needs root node to have parent = 0
            
        //     //* only run the serialize method if the current branch has children
        //     let has_children = window.webix.$$('myGanttTree').data.getBranch(item_id).length > 0;
        //     let children = has_children
        //         ? window.webix.$$('myGanttTree').data.serialize(item_id)
        //         : []
            
        //     children.push(parent);
        //     this.setPinnedGanttItems(children)
        // },
        fixGanttHeight(){
            let table_header = document.querySelector('[view_id="myGanttTree"]').children[0].clientHeight;
            let table_footer = 15;
            let tree = window.webix.$$('myGanttTree');

            let tree_height = document.querySelector('[view_id="myGanttTree"]').children[1].children[1].children[0].clientHeight;

            window.webix.$$('myGantt').config.height = tree_height + (table_header+table_footer);
            //tree.showItem(`${this.gantt_selected_item_type}-${this.gantt_selected_objective_id}`);
            tree.resize();

        },
        async initDebugGantt(){
            let gantt_backend = await import('./js/myBackend');
            let gantt_tree = await import('./js/customTree');
            let extended_classes = await import('./js/ExtendClasses')
            this.ui['override'] = new Map([
                [window.gantt.services.Backend, gantt_backend.default],
                [window.gantt.views.tree, gantt_tree.default],
                [window.gantt.views["chart/bars"], extended_classes.CustomBarsView],
                [window.gantt.views.top, extended_classes.CustomTopView],
                [window.gantt.views["task/info"], extended_classes.CustomInfo],
                [window.gantt.views["task/form"], extended_classes.CustomForm],
                [window.gantt.views["chart"], extended_classes.CustomBarsChart] 

            ])
            this.show_chart = true;
        },
        selectScale(value) {
            this.selectedScale = value;
            
        },
        openEditModal(){
                this.modal_payload = _.cloneDeep(this.gantt_selected_objective_id);
                this.show_objectives_edit_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('objectiveEditModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "objectiveEditModal") {
                            this.show_objectives_edit_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
        },
        updateGanttScale(scale_unit){
            let width = 60;
            switch(scale_unit){
                case 'Weekly':{
                    width = 100;
                    break;
                }
                // case 'Yearly':{
                //     //TODO check if we can get the gantt bars webix view instance instead of query selector
                //     let bars_element = document.getElementsByClassName('webix_gantt_bars')[0];
                //     let bars_element_width = bars_element.clientWidth;
                //     let header_tags_row = document.getElementsByClassName('webix_gantt_scale_row')[0];
                //     let tag_count = header_tags_row.children.length;
                //     width = bars_element_width/tag_count;
                //     break;
                // }
                default : {
                    break;
                }
            }

            let array = this.getScales(scale_unit);
            const local = window.webix.$$('myGantt').getService("local");
            const scales = local.getScales(); 
            local.setScales(
                scales.start,
                scales.end,
                true,
                width,//width
                24,//height (24 = 1 row height)
                array// array of scales (getScales(new_value) )
            );
        },
     
        getScales(minScale) { 
            const yearScale = { unit: "year", format: "%Y" };
            const quarterScale = {
                
                unit: "quarter",
                format: function(start) {
                    const parser = window.webix.Date.dateToStr("%M");
                    const qstart = quarterStart(start);
                    const qend = window.webix.Date.add(
                        window.webix.Date.add(qstart, 3, "month", true),
                        -1,
                        "day",
                        true
                    );
                    function quarterStart(date) {
                            date = window.webix.Date.copy(date);
                            date.setMonth(Math.floor(date.getMonth() / 3) * 3);
                            date.setDate(1);
                            return date;
                    }
                    return parser(qstart) + " - " + parser(qend);
                },
            };
            const monthScale = { unit: "month", format: "%M %y" };
            const weekScale = {
                unit: "week",
                format: function(start) {
                    const parser = window.webix.Date.dateToStr("%d %M");
                    const wstart = window.webix.Date.weekStart(start);
                    const wend = window.webix.Date.add(
                        window.webix.Date.add(wstart, 1, "week", true),
                        -1,
                        "day",
                        true
                    );
                    return parser(wstart) + " - " + parser(wend);
                },
            };
            const scales = [];
            switch (minScale) {
            case "Yearly":
                scales.push(yearScale);
                break;
            case "Quarterly":
                scales.push(yearScale, quarterScale);
                break;
            case "Monthly":
                scales.push(yearScale, monthScale);
                break;
            case "Weekly":
                scales.push(quarterScale, monthScale, weekScale);
                break;
            }
            return scales;
        },
        fetchGanttData: _.debounce(function(should_clear = false){
            if(this.gantt_pinned_id != -1){
                this.clearPinnedGanttItems();
            }
            //remove ol gantt/kanban data
            if(should_clear){
                this.$nextTick(()=>{
                    this.resetGanttKanban();
                })
                
            }
            let params = null
            if(this.selected_milestone_goal != -1) {
                params = {
                    objective_id: this.selected_milestone_goal,
                    user_id: this.selected_user_id != null ? this.selected_user_id : -1
                };
            } else if(this.pinned_milestone_id != -1) {
                params = {
                    milestone_id: this.pinned_milestone_id,
                    level_id: this.selected_level.id,
                    user_id: this.selected_user_id != null ? this.selected_user_id : -1
                };
            } else {
                params = {
                    level_id: this.selected_level.id,
                    user_id: this.selected_user_id != null ? this.selected_user_id : -1
                };
            }

            this.loadGanttData(params)
            .then(()=>{
                if(this.selected_objective == null){
                    return;
                }
            })
        },50),
        refreshGanttData: _.debounce(function(){
            const gant_context = window.webix.$$('myGantt');
            if(gant_context){
                gant_context.reload();
                // check if valid scale was set
                if(this.selectedScale){
                    this.updateGanttScale(this.selectedScale)
                }
            }
        },55),
        setViewingQuarter(quarter){
            this.setObjectiveQuarter(quarter)
            this.$refs.quarterDropdownGantt.hide();
            this.refreshGanttData();
            //* using set timeout because this is a heavy method and is currently hidden with a v-show
            setTimeout(()=>{
                this.filterObjectivesByQuarter();
            }, 400)
            
        }
    },
    watch:{
        is_left_menu_collapsed(){

            window.webix.$$('myGantt').adjust();
        },
        // watch gant state to set component height on collapse
        collapsed_gantt_chart(is_collapsed){
            let table_header = 0;
            let table_footer = 0;

            table_header = document.querySelector(`[view_id=myGanttTree]`).firstChild.clientHeight;
            table_footer = Number(document.querySelector(`[view_id=myGanttTree]`).children.item(2).style.height.split('px')[0]) ;

            if(is_collapsed){
                let tree = window.webix.$$('myGanttTree');
                //let tree_height = window.webix.$$('myGanttTree').count();
                let tree_height = 24;
                
                window.webix.$$('myGantt').config.height = tree_height + (table_header+table_footer);
                tree.resize();
                    //! NOTE - does not work without the repitition  :(  sorry
                    this.$nextTick(()=>{
                        tree.showItem(`${this.gantt_selected_item_type}-${this.gantt_selected_objective_id}`);
                        this.$nextTick(()=>{
                            tree.showItem(`${this.gantt_selected_item_type}-${this.gantt_selected_objective_id}`);
                        })
                    })
                //}

                
            }
            else{
                let tree = window.webix.$$('myGanttTree');
                let tree_height = window.webix.$$('myGanttTree').count();
                tree_height = tree_height*24;

                window.webix.$$('myGantt').config.height = tree_height + (table_header+table_footer);
                tree.showItem(`${this.gantt_selected_item_type}-${this.gantt_selected_objective_id}`);
                tree.resize();
            }
        },
        selected_level(){
            //this.fetchGanttData(true);
        },
        objective_viewing_quarter(){
            this.$nextTick(() => {
                this.fetchGanttData(true);
            })
        },
        pinned_milestone_id(){
            this.$nextTick(() => {
                this.fetchGanttData(true);
            })
        },
        selected_user_id(){
            this.$nextTick(() => {
                this.fetchGanttData(true);
            })
            //setShowGantt
        },
        selected_milestone_goal() {
            this.$nextTick(() => {
                this.fetchGanttData(true);
            })
        },
        //reload if data source updates for normal and pinned items
        pinned_gantt_tasks(){
            this.refreshGanttData();
        },
        gantt_tasks(){
            this.refreshGanttData();
        },
        gantt_height: {
            handler() {
                let setHeight = document.getElementsByClassName('objectivesGantt');
                setHeight[0].style.height = this.gantt_height + 'px'
                window.webix.$$('myGanttTree').height = setHeight 
                window.webix.$$('myGantt').height = setHeight 
                window.webix.$$('myGantt').adjust();
                window.webix.$$('myGanttTree').refresh()
            },
        },
        hide_columns() {
            let tree = window.webix.$$('myGanttTree');
                if(this.hide_columns) {
                    tree.hideColumn('start_date')
                    tree.hideColumn('end_date')
                    tree.hideColumn('progress')
                } else {
                    tree.showColumn('start_date')
                    tree.showColumn('end_date')
                    tree.showColumn('progress')
                }
        },
        filter_input(newValue){
            let tree = window.webix.$$('myGanttTree');
            if(tree == undefined){ return; }
            if(newValue == ''){
                //* clear filter if text is empty
                tree.filter();
                tree.refresh();
            }
            else{   
                tree.filter("#text#", newValue)
                let open = tree.getOpenItems();
                open.forEach( id => {
                    tree.close(id);
                    tree.open(id);
                })
            }

        },
        selectedScale(scale_unit){
            this.updateGanttScale(scale_unit);
        },
    },
    beforeDestroy(){
        //* check if new objective was selected, then select it

        //! temp disabled
        // if(this.gantt_selected_objective_id != this.selected_objective_id){
        //     let objectives_table = window.webix.$$('objectivesTable');
        //     if(objectives_table && objectives_table.exists(this.gantt_selected_objective_id)){
        //         objectives_table.select(this.gantt_selected_objective_id);
        //     }
        // } else if (this.saved_key_action) {
        //     let params = {
        //     id: this.selected_objective.id,
        //     user_id: this.activeUserID,
        //     }
        //     this.loadObjectiveDetailAPI(params)
        // }

        window.webix.eventRemove(this.window_resize_event);
        window.gantt_context = undefined;
        //* clears vuex, hotfix for kanban not loading when showing the component twice
        //this.resetGanttKanban();
        //this.setGanttHeight();
    },
    mounted(){
        //this.is_loading = true
        window.gantt_context = this;
        this.hideGantt(false);
        this.savedKeyAction(false);
        this.fetchGanttData();
        this.initDebugGantt();
        this.window_resize_event = window.webix.event(window, "resize", _.debounce(function(){
            let table = window.webix.$$('myGantt');
            if(table){
                window.gantt_context.fixGanttHeight();
                table.adjust();
            }
        }, 100));
       
    }
   
}
</script>

<style>
.objectivesGantt .webix_gantt_link{
    display: none !important;
    /* width: 14px;
    height: 14px;
    top: 2px; */
}
.objectivesGantt .webix_gantt_task{
    cursor: pointer !important; 
}
.objectivesGantt .webix_gantt_progress_drag {
    display: none !important;
}

.scaleDropdown {
    margin: 0 auto;
}

.objectivesGantt .webix_ss_body .webix_cell{
    height: 24px !important;
    line-height: 24px !important;
}
.objectivesGantt .webix_gantt_content {
    line-height: 12px !important;
    
}

div.webix_template>.webix_gantt_task_title{
    font-size: 13px;
    font-weight: 500;
}

.pinned-gantt-item{
    color: rgb(197, 197, 197);
}
.pinned-gantt-item:hover{
    cursor: pointer;
    color: rgb(44, 44, 44);
}
.selected-pinned-gantt-item{
    color: var(--bs-green);
}
.selected-pinned-gantt-item:hover{
    cursor: pointer;
    color: var(--bs-green);
}
</style>