<template>
<div id="wrapper" class="card-hover d-flex border rounded" style="width: 300px; height: 100px;">
    <div class="me-3">
        <div class="m-0 p-0 ms-2 pt-3 " style="width: 65px; height: 65px;">
            <b-img
                fluid
                :src="user.avatar_src"
                alt
                onerror="src='/img/user.png'"
                class=" smaller-image-height avatar-md rounded-circle img-thumbnail header-profile-user-view"
                id="image"
                >
            </b-img>
        </div>
        </div>
        <div class="media-body align-self-center">
            <div class="text-muted">
                <h6 class="mb-1">{{ name }}</h6>               
                <div class="d-flex flex-column">
                    <label class="me-2 mb-0" :title="getAllUserLevelName" style="font-size: 10px;">
                        <strong>Structure: </strong>    
                        {{ getUserLevelName }}
                    </label>
                    <label class="me-2 mb-0" style="font-size: 10px;">
                        <strong>Job: </strong>
                        {{ getJobByID }}
                    </label>
                    <label class="me-2 mb-0" :title="user.email" style="font-size: 10px;">
                        <strong>Email: </strong>
                        {{ getEmails }}
                    </label>
                </div>                
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash';
    import { levelComputed, peopleComputed, } from '@/state/helpers'
    export default{
        props:{
            user: Object,
        },
        data(){
            return{
                name: this.user.name,
                level: this.user.pivot.level_id,
                ID: this.user.id,
                Email: this.user.email
            }
        },
        computed:{
            ...levelComputed,
            ...peopleComputed,

            getLevelName(){
                let index = _.findIndex(this.levels, {id: this.level});
                if( index != -1){
                    return  this.levels[index].name;
                }
                return 'Not valid'
            },
            getAllUserLevelName(){
                let names = [];
                let name = "No Level Available"
                //if(this.user.level_id){
                //    let index = _.findIndex(this.levels, {id: this.user.level_id})
                //    if(index != -1){
                //        name =  this.levels[index].name;
                //    }
                //}
                this.user.levels.forEach( (level_object) =>{
                    names.push(level_object.name);
                })
                names.sort();
                names.forEach( (level_name, index) => {
                    if(index == 0){
                        name = `${level_name}`;
                    }
                    else{
                        //include comma for subsequent items
                        name +=`, ${level_name}`;
                    }
                })
                return name;
            },
            getUserLevelName(){
                let names = [];
                let name = "No Level Available"
                //let moreThan = false
                //if(this.user.level_id){
                //    let index = _.findIndex(this.levels, {id: this.user.level_id})
                //    if(index != -1){
                //        name =  this.levels[index].name;
                //    }
                //}
                this.user.levels.forEach( (level_object, index) =>{
                    if(index < 3) {
                        names.push(level_object.name);
                    } else {
                        //moreThan = true
                    }
                })
                names.sort();
                names.forEach( (level_name, index) => {
                    if(index == 0){
                        name = `${level_name}`;
                    }
                    else{
                        //include comma for subsequent items
                        if(index == names.length -1){
                            name +=`, ${level_name}...`;
                        //last element of array
                        } else {
                            name +=`, ${level_name}`;
                        }
                        
                    }
                })
                return name;
            },
            getJobByID(){
                let job_name = 'Invalid Job';
                if(this.user.current_job != null){
                    let index = _.findIndex(this.companyJobs, {id: this.user.current_job.id});
                    if(index != -1){
                        job_name = this.companyJobs[index].title;
                    }
                }
                else{
                    job_name = 'No Job'
                }
                return job_name;
            },
            getEmails(){
                if(this.user.email != null){
                    if(this.user.email.length > 25) {
                        return this.user.email.slice(0, 25) + '...'
                    } else {
                        return this.user.email
                    }
                  
                } else {
                  return "No Email"
                }
            }
        }
    }
</script>

<style scoped>
    #wrapper{
        margin: 1rem;
        cursor: pointer;
    }
    .avatar-container{
        padding-top: 5.5px;
    }
    .smaller-image-height{
        height: 65px;
        width: 65px;
    }
</style>