<template>
    <div>
        <hr>
        <b-card>
            <CardHeader>
                <template #title>
                    <div class="d-flex">
                        <span>
                            Objective Details
                            &nbsp;&nbsp;>&nbsp;&nbsp;
                            <span class="text-primary" v-if="current_objective_details">
                                {{current_objective_details.name}}
                            </span>
                        </span>
                    </div>
                </template>
                <template #buttons>

                </template>
            </CardHeader>
            <b-collapse id="objective-details-collapse" class="mt-2 pb-2 " v-model="isDetailsExpanded">
                <b-tabs class="details-tabs" content-class="mt-3" nav-class="slim-bootstrap-tab" v-model="details_tab_index">
                    <b-tab title="Key Results">
                        <b-row>
                            <b-col cols="12">
                                <b-overlay :show="is_updating">
                                    <transition name="fade">
                                        <KeyResultBootstrapTable
                                            v-show="table_view"
                                            :rows="table_data"
                                            @handleKeyResultSelect="handleKeyResultSelect"
                                            @handleSaveRefresh="processStatusValues"
                                        >
                                        </KeyResultBootstrapTable>
                                    </transition>
                                    <b-collapse v-model="show_key_results">
                                        <KeyResultsDetails @savedEvent="handleSaveEvent()"  />
                                    </b-collapse>
                                
                                </b-overlay>
                            </b-col>
                            <b-col cols="12" v-if="false">
                                <ActivityList />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Objective Plan">
                        <b-row>
                            <b-col cols="12">
                                <label class="d-flex" for="">
                                    Objective Plan
                                    <svg @click="edit_objective_plan = !edit_objective_plan" :class="edit_objective_plan ? 'text-primary':''" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="ms-auto cursor-pointer fa-fw me-1 svg-inline--fa fa-pen"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" class=""></path></svg>
                                </label>
                                <b-overlay :show="is_saving_plan">
                                    <div v-show="edit_objective_plan == false">
                                        <b-form-textarea v-model="objective_plan_data" readonly rows="5"></b-form-textarea>
                                    </div>
                                    <div v-show="edit_objective_plan">
                                        <b-form-textarea v-model="objective_plan_data" rows="5">
                                        </b-form-textarea>
                                        <div v-if="isOwner" class="d-flex pt-1">
                                            <b-button @click="savePlan" size="sm" variant="success" class="ms-auto">
                                                Save
                                            </b-button>
                                            <b-button @click="resetPlan" size="sm" variant="secondary" class="ms-1">
                                                Cancel
                                            </b-button>
                                        </div>
                                    </div>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Wrap Up / Chat">
                        <b-row>
                            <b-col cols="12">
                                <label class="d-flex" for="">
                                    Check-in / Wrap-up Chat
                                    <svg @click="edit_wrap_up = !edit_wrap_up" :class="edit_wrap_up ? 'text-primary':''" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="ms-auto cursor-pointer fa-fw me-1 svg-inline--fa fa-pen"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" class=""></path></svg>
                                </label>
                                <b-overlay :show="is_saving_wrapup">
                                    <div v-show="edit_wrap_up == false">
                                        <b-form-textarea v-model="wrap_up_data" readonly rows="5"></b-form-textarea>
                                    </div>
                                    <div v-show="edit_wrap_up">
                                        <b-form-textarea v-model="wrap_up_data" rows="5">
                                        </b-form-textarea>
                                        <div class="d-flex pt-1">
                                            <b-button @click="saveWrapUp()" size="sm" variant="success" class="ms-auto">
                                                Save
                                            </b-button>
                                            <b-button @click="edit_wrap_up == false" size="sm" variant="secondary" class="ms-1">
                                                Cancel
                                            </b-button>
                                        </div>
                                    </div>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-collapse>
            
        </b-card>
        <KeyResultsModal v-if="show_kr_modal"
            is_parent
            :edit_id="edit_id"
            :available_key_results="table_data"
            @savedEvent="handleSaveEvent()"
        />
    </div>
</template>

<script>

import KeyResultBootstrapTable from './bootstrapTables/krBootstrapTable.vue'
//eslint-disable-next-line
import { parse } from 'date-fns';
import CardHeader from "@/components/widgets/cardHeader.vue";
import { performanceComputed, performanceMethods, levelComputed } from '@/state/helpers';
import KeyResultsDetails from "./KeyResultsDetails.vue";
import KeyResultsModal from '../misc/objectiveModals/keyResultModal.vue';
//import ProgressBar from "../misc/KeyResultGanttProgressBar.vue";
import ActivityList from "@/components/objectives/components/cards/action-plans.vue";
import { cloneDeep } from 'lodash';

export default {
    props:{
        view_type:{

        }
    },
    data:() => ({
        table_data:[],
        is_updating: false,
        progress_status: 0,

        isDetailsExpanded: false,
        show_key_results: false,

        table_view: true,

        show_kr_modal: false,
        edit_id: -1,
        edit_objective_plan: false,
        edit_wrap_up: false,
        wrap_up_data: '',
        objective_plan_data: '',

        is_saving_wrapup: false,
        is_saving_plan: false,
    }),
    components:{
        CardHeader,
        KeyResultsDetails,
        KeyResultsModal,
//        ProgressBar,
        ActivityList,
        KeyResultBootstrapTable,
    },
    computed:{
        ...performanceComputed,
        ...levelComputed,
        isOwner(){
            return true;
        },
        details_tab_index:{
            get(){
                return this.objective_details_selected_tab_index
            },
            set(index){
                this.setObjectiveDetailsTabIndex(index);
            }
        },
        getSelectedObjective(){
            return this.current_objective_details;
        },
        //getSelectedKeyResult(){
        //    let kr = this.table_data.find( item => {
        //        return item.id == this.selected_kr_id;
        //    });
        //    return kr;
        //},
        selected_objective_id(){
            //if(this.view_type == 'COMPANY'){
                return this.selected_goal_objective;
            //}
        }
        //table_data(){
        //    return this.current_objective_details
        //    ? cloneDeep(this.current_objective_details.key_results)
        //    : [];
        //},
    },
    watch:{
        selected_kr_id(id){
            if(id == -1){
                this.show_key_results = false;
            }
            else{
                this.show_key_results = true;
            }
        },
        'selected_goal_objective':{
            immediate: true,
            handler(){
                //hide details if no goal objective is selected
                if(this.selected_goal_objective != -1 || this.selected_personal_objective != -1) {
                    this.isDetailsExpanded = true
                }
                //this.isDetailsExpanded = this.selected_goal_objective != -1;
            }
        },
        'selected_personal_objective':{
            immediate: true,
            handler(){
                //hide details if no goal objective is selected
                if(this.selected_goal_objective != -1 || this.selected_personal_objective != -1) {
                    this.isDetailsExpanded = true
                }
                //this.isDetailsExpanded = this.selected_personal_objective != -1;
            }
        },
        
        'current_objective_details'(newVal, oldVal){
            let old_id = oldVal ? oldVal.id : null;
            let new_id = newVal ? newVal.id : null;

            let has_previous_ids = old_id && new_id
            if(!has_previous_ids || old_id != new_id){
                this.setSelectedKR(-1);
            }
            
            const has_valid_objective = this.current_objective_details != null;
            //const has_key_results = this.current_objective_details.key_results != [];

            if(has_valid_objective == false){
                this.table_data = [];
                this.progress_status = 0;
                return;
            }
            this.processStatusValues();
        },
    },
    methods:{
        ...performanceMethods,
        processStatusValues(){
            let filtered_array = this.current_objective_details.key_results.filter( (item) => {
                return item.parent_id == null;
            })
            this.table_data = filtered_array.map( (item) => ({
                ...item,
                status_value: item.status,
            }))
            this.progress_status = this.current_objective_details.progress;

            this.resetPlan();
            this.resetWrapUp();
        },
        saveWrapUp(){
            let objective = this.getStrippedObjective();
            objective.wrap_up_notes = this.wrap_up_data;
            //objective['objective_plan'] = objective.objective_plan;
            objective.owner_id = objective.owner_user_id;
            objective.type = objective.objective_type;
            objective.milestone_id = objective.level_milestone_id;
            objective.parent_id = objective.parent_objective_id;

            this.is_saving_wrapup = true;
            this.saveObjectives({objectives: [objective]})
            .then(() => {
                this.is_saving_wrapup = false;
                this.edit_wrap_up = false;
            })
        },
        resetWrapUp(){
            this.wrap_up_data = cloneDeep(this.current_objective_details.wrap_up_notes);

        },
        savePlan(){
            let objective = this.getStrippedObjective();
            //objective['objective_plan'] = this.objective_plan;
            //objective['wrap_up_notes'] = objective.wrap_up_notes;
            objective.owner_id = objective.owner_user_id;
            objective.type = objective.objective_type;
            objective.milestone_id = objective.level_milestone_id;
            objective.parent_id = objective.parent_objective_id;
            this.is_saving_wrapup = true;
            this.saveObjectives({objectives: [objective]})
            .then(() => {
                this.is_saving_wrapup = false;
                this.edit_objective_plan = false;
            })

        },
        resetPlan(){
            this.objective_plan_data = cloneDeep(this.current_objective_details.objective_plan);
        },
        getStrippedObjective(){
            let item = cloneDeep(this.current_objective_details);
            delete item.level;
            delete item.level_user_ratings;
            return item;
        },
        getUserNameFromID(id){
            let user = this.all_users.find( user => user.id == id);
            if(user){
                return user.name;
            }
            return '';
        },
        handleSaveEvent(){
            this.reloadGoalObjectveData().then(() => {
                this.$bvModal.hide('keyResultModal');
            })
        },
        handleKeyResultSelect(id){
            if(id == this.selected_kr_id){
                //* reset selection if selecting same item
                this.setSelectedKR(-1);
            }
            else{
                this.setSelectedKR(id);
            }
        },
        
        toggleTableChat(){
            //TODO add support for unsaved changes in chat
            this.table_view = !this.table_view;
        },
        showModal(id){
            this.edit_id = id;
            this.show_kr_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('keyResultModal')
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "keyResultModal") {
                        this.show_kr_modal = false;
                        this.edit_id = -1;
                    }
                });
            })
        },
        showDeletePrompt(name,id){
            this.$swal.fire({
                title: `Delete "${name}" ?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteKpi(id)
                    .then(()=>{
                        this.reloadGoalObjectveData();
                    })
                }
            });
        },
        notifyChange(event, item_id, property){
            console.log(event, item_id, property);
            this.is_updating = true;
            setTimeout(()=>{
                this.is_updating = false;
            }, 500)
        },
        updateStatus(index, new_value){
            this.table_data[index].status_value = new_value;
            let key_results = [ this.table_data[index] ]
            this.saveKeyResultData({key_results: key_results}).then(()=>{
                this.reloadGoalObjectveData();
            })
        },
        reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = null
                if(this.selected_goal_objective != -1) {
                    params = {
                        level_id: this.current_level_id,
                        user_id: this.selected_user_id ? this.selected_user_id : 0,
                        objective_id: this.selected_goal_objective,
                        objective_data: 1,
                        //graph_data: 1
                    }
                }
                if(this.selected_personal_objective != -1) {
                    params = {
                        level_id: this.current_level_id,
                        user_id: this.selected_user_id ? this.selected_user_id : 0,
                        objective_id: this.selected_personal_objective,
                        objective_data: 1,
                        //graph_data: 1
                    }
                }   
                console.log(params)
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                })
                .catch(()=>{
                    reject();
                })
            })

        }
    },
    mounted(){},
}
</script>

<style scoped>
    .success-progress{
        background-color: rgba(52,195,143, 1) !important;
    }

    
    
</style>
<style>
.nav-tabs{
    border-bottom: 1px solid rgb(163, 163, 163) !important;
    padding-bottom: 0px !important;
    margin-bottom: 5px !important;
}

.slim-bootstrap-tab > li > a{
    padding: 4px 8px 4px 8px;
    font-size: 0.65rem;
}
</style>