<template>
    <div v-if="uid != -1">
        <div>
            <div v-if="isItemWithinRange" :id="'popover-'+uid" class="gantt-progress-bar cursor-pointer">
                <div class="range">
                    <div class="item-range" :style="getRangeStyle"></div>
                    <div class="current-date-line" :style="getCurrentDateLineStyle"></div>
                    <!-- <div class="current-date-line-bar" :style="getCurrentDateBarStyle"></div> -->
                </div>
            </div>
            <div v-else style="padding-top: 0.3rem; font-size: 0.62rem;">
                Date range outside of linked objecive
            </div>
        </div>
        
        <b-popover :target="'popover-'+uid" triggers="hover" placement="left" customClass='gantt-popover-class'>
            <div>Item Duration:  </div>
            <div>
                <strong class="text-primary">
                    {{getFormattedDate(item_start_date)}}
                    <!-- {{getFormattedDate(clampedItemDates.start)}} -->
                </strong>
                <span class="ps-1 pe-1">to</span>
                <strong class="text-primary">
                    {{getFormattedDate(item_end_date)}}
                    <!-- {{getFormattedDate(clampedItemDates.end)}} -->
                </strong>
            </div>
            <hr class="mt-1 mb-1">
            <div>Range:  </div>
            <div>
                <strong class="text-primary">
                    {{getFormattedDate(start_date)}}
                </strong>
                <span class="ps-1 pe-1">to</span>
                <strong class="text-primary">
                    {{getFormattedDate(end_date)}}
                </strong>
            </div>
        </b-popover>
    </div>
</template>

<script>
//eslint-disable-next-line
    import { format, parseISO, parse, min, max, isWithinInterval  } from "date-fns";
    export default {
        data: () => ({
            uid: -1,
        }),
        props: {
            start_date: Date,
            end_date: Date,
            item_start_date: Date,
            item_end_date: Date,
        },
        methods:{
            getFormattedDate(date_value){
                return format(date_value, 'dd-MMM-yyyy') 
            }
        },
        computed: {
            isItemWithinRange() {
                const { start, end } = this.clampedItemDates
                return isWithinInterval(start, { start: this.start_date, end: this.end_date }) &&
                        isWithinInterval(end, { start: this.start_date, end: this.end_date })
            },
            clampedItemDates() {
                //* clamp start date
                //const s = this.item_start_date < this.end_date
                //let is_valid_start = this.item_start_date >= this.start_date && ;

                const clampedStart = max([this.start_date, this.item_start_date])
                const clampedEnd = min([this.end_date, this.item_end_date])
                return {
                    start: clampedStart,
                    end: clampedEnd
                }
            },
            getCurrentDateBarStyle(){
                const totalDuration = this.end_date - this.start_date;
                const itemDuration = this.current_date - this.item_start_date;
                const itemOffset = (this.item_start_date - this.start_date) / totalDuration * 100;
                const itemWidth = (itemDuration / totalDuration) * 100;
                //return 'tt';
                return {
                    left: itemOffset <= 100
                        ?   `${itemOffset}%`
                        :   `100%`,
                    width: itemWidth <= 100
                        ? `${itemWidth}%`
                        : `100%`
                };
            },
            getRangeStyle() {
                const totalDuration = this.end_date - this.start_date;
                // const itemDuration = this.clampedItemDates.end - this.clampedItemDates.start;
                // const itemOffset = (this.clampedItemDates.start - this.start_date) / totalDuration * 100;
                let clamped_end_date = this.item_end_date > this.end_date ? this.end_date : this.item_end_date;
                const itemDuration = clamped_end_date - this.item_start_date;
                const itemOffset = (this.item_start_date - this.start_date) / totalDuration * 100;
                const itemWidth = (itemDuration / totalDuration) * 100;
                return {
                    left: itemOffset <= 100
                        ?   `${itemOffset}%`
                        :   `100%`,
                    width: itemWidth <= 100
                        ? `${itemWidth}%`
                        : `100%`
                };
            },
            current_date(){
                return new Date();
            },
            getCurrentDateLineStyle() {
                let today_date_line = null;

                if(this.current_date < this.start_date){
                    today_date_line = this.start_date;
                }
                else if(this.current_date > this.end_date){
                    today_date_line = this.end_date;
                }
                else{
                    today_date_line = this.current_date;
                }
                const totalDuration = this.end_date - this.start_date;
                const currentDateOffset = (today_date_line - this.start_date) / totalDuration * 100;
                return {
                    left: `${currentDateOffset}%`,
                };
            },
        },
        mounted(){
            this.uid = window.webix.uid();
        }
    };
</script>

<style scoped>
    .gantt-progress-bar {
        margin-top: 6px;
        width: 100%;
        height: 12px;
        background-color: #e3e3e3;
        position: relative;
    }
    .item-range:hover{
        background-color: #3c92e7;
        transition:all 0.1s linear;
    }
    .range {
        height: 100%;
        background-color: #ffffff00;
    }

    .item-range {
        
        height: 100%;
        background-color: #87d5ff;
        position: absolute;
    }
    .current-date-line {
        margin-top: -12px;
        width: 2px; /* Adjust line width as needed */
        height: 37px;
        background-color: #ffaa00; /* Red color for the line */
        position: absolute;
        top: 0;
    }
    .current-date-line-bar{
        height: 100%;
        background-color: #228dff;
        position: absolute;
    }

    .gantt-popover-class.popover{
        width: 200px;
        border: 1px solid rgb(120, 120, 120);
    }
</style>