<template>
    <b-modal id="updateProjectsModal" hide-footer style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex">
                <h6>Update Linked Projects</h6>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('updateProjectsModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>
            </div>
        </template>
        <projectsTree 
            :payload="linked_projects" 
            :modal="true"
            @selectedNewIDs="updateCard"
        />
    </b-modal>
</template>

<script>
//eslint-disable-next-line

import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, ganttMethods, ganttComputed, performanceComputed, performanceMethods } from '@/state/helpers'
import projectsTree from '../projectsTree.vue'
export default {
components: {
    projectsTree
},
props:{
    payload:{
        required: true,
    },
    item:{
        required:true
    }
},
data:() => ({
    linked_projects: []
}),
watch:{
    
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    ...ganttMethods,
    ...performanceMethods,
    updateCard(ids) {
        console.log(JSON.parse(JSON.stringify(this.item)))
        let form = {
            id: this.item.id,
            parent_id: this.item.parent_id,
            level_id: this.item.level_id,
            key_result_id: this.item.key_result_id,
            name: this.item.name,
            start_date: this.item.start_date,
            end_date: this.item.end_date,
            type:  this.item.type,
            owner_id: this.item.owner_user_id,
            responsible_user_id: this.item.responsible_user_id,
            description: this.item.description,
            priority: this.item.priority,
            body: this.item.body,
            linked_project_ids: ids
        }
        let data = form
        if(this.changesMade && this.selectedType == 'objective') {
            form['objective_id'] = Number(this.selectedPlainId)
            form['target_id'] = null
        }
        if(this.changesMade && this.selectedType == 'target') {
            form['objective_id'] = null
            form['target_id'] = Number(this.selectedPlainId)
        }
        if(this.changesMade && this.selectedType == 'level') {
            form['target_id'] = null
            form['objective_id'] = null
            form['level_id'] = Number(this.selectedId)
        }
        let activity_params = {
            key_actions: [data],
        }
        this.saveActivityInbox(activity_params).then(() => {
            this.$nextTick(() => {
                this.$emit('savedNewDetails', data.id);
                this.filterProjectsData()
                this.$bvModal.hide('updateProjectsModal')
                // this.setProjectsData();
            })
        })
        
    },
    filterProjectsData() {
            this.$nextTick(() => {
                if(this.selected_goal_objective != -1) {
                    console.log("1")
                    let params = {
                        item_id: this.selected_goal_objective,
                        item_type: 'objective',
                        user_id: this.selected_user_id, 
                    }
                    this.getClientProjects(params)
                } else if(this.selected_target != -1) {
                    console.log("2")
                        let params = {
                            item_id: this.selected_target,
                            item_type: 'target',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params)
                } else {
                    console.log("3")
                        let params = {
                            item_id: this.selected_level.id,
                            item_type: 'level',
                            user_id: this.selected_user_id, 
                        }
                        this.getClientProjects(params)
                }     
            })
        },
    
},
mounted(){
    console.log(JSON.parse(JSON.stringify(this.payload)))
    this.linked_projects = this.payload
},
}
</script>
<style>


</style>