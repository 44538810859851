<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-1">
        <TeamMembersTable />
        <b-col cols="8" v-show="!hasMemberSelected" class="mt-1">
          <b-alert style="margin-top: 1.5rem" variant="info" show>
            <strong>Select a team member to view objectives steps.</strong>
          </b-alert>
        </b-col>
      </b-col>
    </b-row>
    <b-row v-show="hasMemberSelected">
      <b-col cols="12" class="mb-1">
        <TeamMemberObjectivesTable />
      </b-col>
    </b-row>
    <b-row ><!-- v-show="hasObjectiveSelected" -->
      <b-col cols="12" class="mb-1">
        <RatingGraph />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TeamMembersTable from "./components/performance/teamMembersTable.vue";
import TeamMemberObjectivesTable from "./components/performance/teamMemberObjectivesTable.vue";
import RatingGraph from './components/performance/ratingGraph.vue'

import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
  components: {
    TeamMembersTable,
    TeamMemberObjectivesTable,
    RatingGraph
  },
  data: () => ({
    //debug
    selectedRatingObjectiveId: -1,
  }),
  computed: {
    ...levelComputed,
    ...peopleComputed,
    hasMemberSelected() {
      return !!this.activeMemberId;
    },
    hasObjectiveSelected(){
      return this.selectedRatingObjectiveId != -1;
    }
  },
  methods: {
    ...peopleMethods,
  },
  mounted() {
    this.getTeamMembersPerformance(this.current_level_id);
  },
};
</script>

<style>
</style>