<template>
    <b-modal hide-header-close :title="modal_title" id='addEmployeeDetailsModal' hide-footer size="lg">
        <b-form >
            <Editor @contentChanged="contentChanged" :content="edit_text" />
        </b-form>
        <!-- footer -->
        <div class='d-flex' >
            <b-button v-show="contentChanged" @click="saveText" variant="success" class="ms-auto mt-2 me-2">Save</b-button>
            <b-button @click="$bvModal.hide('addEmployeeDetailsModal')" class=' mt-2' variant='primary'>Close</b-button>
        </div>
    </b-modal>

</template>

<script>
//import { cloneDeep } from 'lodash';
//import Swal from "sweetalert2";
import Editor from "@/components/widgets/simpleEditorWrapper.vue";
import {
  activityComputed,
  activityMethods,
  peopleComputed,
  peopleMethods,
  levelComputed,
  levelMethods,
  adminComputed,
  adminMethods,
} from "@/state/helpers";
export default {
    components: {
        Editor
    },
    props:{
        payload:{
            required: true,
        },
    },
    data:() => ({
        edit_text:'',
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...peopleComputed,
        modal_title(){
            let data = this.payload
            if(!data.type){
                return 'Invalid item'
            }
            else{
                return data.type; 
            }
        },
        has_valid_note(){
            //returns false if note is null or ''
            return this.employeeDetailsData.note != null || this.employeeDetailsData.note != '';
        },
        setEditClass(){
            if(this.editable){
                //* return nothing for editable
                return '';
            }
            else{
                //return class to prevent mouse events
                return 'block-click'
            }
        }
    },
    methods:{
        ...activityMethods,
        ...levelMethods,
        ...adminMethods,
        ...peopleMethods,
        contentChanged(new_content){
            this.edit_text = new_content;
        },
        saveText() {
            //this.is_loading = true;
            let params = {
                type: this.payload.type, text: this.edit_text,
            }
            console.log(JSON.parse(JSON.stringify(params)))
            this.is_loading = true;
            this.saveEmployeeDetails(params).then(()=>{
                    this.loadEmployeeData(this.selected_user_id)
                    .then(()=>{
                        this.$emit('savedNewDetails')
                        this.$bvModal.hide('addEmployeeDetailsModal');
                    })
            })
        }
    },
    mounted(){
        let data = this.payload
        console.log(data.type)
        this.edit_text = this.payload.data
        //this.title = this.payload.type
        // if(this.has_valid_note){
        //     this.edit_note_text = cloneDeep(this.employeeDetailsData.item.notes)
        // }
    },
}
</script>

<style>

</style>