<template>
    <span class="inputField d-flex">
        <span>
            {{getUserLevelName}}
            <font-awesome-icon class="fa-fw ms-1 mt-0 editIcon" icon="fa-pen" v-show="core.id == selected_user_id || $hasUserRole('Power User')"
            @click="showHierarchyModal()"
            />
            <!-- <span  v-if="isUserManager && assign_user_levels_mode == true" class="ms-2">
                <b-button @click="saveUserLevelsArray()" variant="outline-success" size="sm" class="pt-0 pb-0">
                    Save Level Changes
                </b-button>
                <b-button @click="setLevelAssignFlag(false)" variant="outline-danger" size="sm" class="ms-1 pt-0 pb-0">
                    Cancel
                </b-button>
            </span> -->
        </span>
        <HierarchyModal
            show_all_levels
            v-if="show_hierarchy_modal"
            :current_row="modal_payload"
            @updatedLevels="saveUserLevelsArray"
        />
    </span>
</template>

<script>
import Swal from 'sweetalert2';
import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'
import {
    levelComputed,
    levelMethods,
    peopleComputed,
    peopleMethods,
    adminComputed,
    adminMethods}
from '@/state/helpers'
import _ from 'lodash'
export default {
    props:{
        user:{ required: true,},
        changes_made_flag: { default: false},
    },
    components:{
        HierarchyModal,
    },
    data:() => ({
        show_hierarchy_modal: false,
        company_level_id: null,
        modal_payload: null,
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        isUserManager(){
            let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id) ;
            if(is_editing_as_manager){
                return true;
            }
            return false;
        },
        getUserLevelName(){
            let names = [];
            let name = "No Level Available"
            //if(this.user.level_id){
            //    let index = _.findIndex(this.levels, {id: this.user.level_id})
            //    if(index != -1){
            //        name =  this.levels[index].name;
            //    }
            //}
            this.user.levels.forEach( (level_object) =>{
                let lvl = _.find(this.levels, { id : level_object.id})
                if(lvl){
                    names.push(lvl.name);
                }
                
            })
            names.sort();
            names.forEach( (level_name, index) => {
                if(index == 0){
                    name = `${level_name}`;
                }
                else{
                    //include comma for subsequent items
                    name +=`, ${level_name}`;
                }
            })
            return name;
        }
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        ...adminMethods,
        enableLevelSelection(){
            if(this.changes_made_flag){
                Swal.fire('Please save changes to the employee details form first');
                return;
            }
            if(this.allow_tree_selection){
                //* only one component should be interacting with the org chart
                Swal.fire('The permissions tab is currently using the Organisational Structure to assign level permissions. Please save and try again.');
                return;
            }
            this.$swal.fire('Select the Structures this user is linked to from the Organisational Structure tree menu');
            this.setLevelAssignFlag(true);
        },
        showHierarchyModal(){
            if(this.changes_made_flag == true){
                Swal.fire('Please save changes to the employee details form');
                return;
            }
            let ids = []
            let levels = this.user.levels
            levels.forEach( level => {
                ids.push(level.id)
            })
            console.log(ids)
            this.modal_payload = { level_ids: ids } 
            this.show_hierarchy_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('levelLinkingModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_hierarchy_modal = false;
                        //* reset payload
                        this.modal_payload = null;
                    }
                });
            })
        },
        setCompanyLevelId(){
            return new Promise( resolve => {
                this.$nextTick(()=>{
                    const tree = window.webix.$$('levelsTree');
                    let item = tree.getItem(this.selected_level.id);
                    let top_level_id = -1;
                    if(item.parent_id === null){
                        //* current level is company level
                        top_level_id = item.id;
                    }
                    else{
                        top_level_id = tree.getParentId(this.selected_level.id);
                        let found = false;
                        while(!found){
                            let new_parnet_id = tree.getParentId(top_level_id);
                            if(new_parnet_id === 0){
                                found = true;
                            }
                            else{
                                top_level_id = new_parnet_id;
                            }
                        }
                    }
                    this.company_level_id = top_level_id;
                    resolve();
                })
            })
        },
        handleNewLevelSelection(new_id){
            new_id.forEach(item => {
                this.saveLevel(item)
            })
        },
        
        saveLevel(new_id){
            //let params = {...this.user};
            console.log(new_id
            )
            let params = {
                "id": this.user.id,
                "level_id": Number(new_id),
            }
            //this.updateEmployee(params)
            this.updateEmployeeLevel(params)
            .then(()=>{
                //* close modal and select new level if available
                this.$bvModal.hide('hierarchyModal');
                let is_different_level = Number(this.current_level_id) !== Number(new_id);
                if(is_different_level){
                    let payload = {
                        level_id: Number(new_id),
                        user_id: this.user.id,
                    }
                    this.loadMenuUsers(Number(new_id))
                    .then(()=>{
                        this.loadEmployeesData(Number(new_id))
                            .then(()=>{
                                this.setSelectedUser(null)
                               // alert('loaded employee data')
                                this.$eventHub.$emit('forceSelectNewLevel', payload);
                                this.$emit('setActiveUserData');
                                //this.$nextTick(()=>{
                                    //this.setActiveUser();
                                //})
                                //this.is_loading_job = false;
                        })
                    })
                    
                }
            })
            .catch((e)=>{
                console.log(e, 'Error moving user');
            })
        },
        saveUserLevelsArray(data){
            console.log(data)
            if(data.length == 0) {
               Swal.fire('Employee must be assigned to at least one level');
            } else {
                let payload = {
                user_id: this.user.id,
                levels: data
                }
                console.log(payload)
                this.saveUserLevels(payload)
                .then(()=>{
                    this.setLevelAssignFlag(false);
                    //this.$nextTick(()=>{
                        
                        //this.$eventHub.$emit('updatedUserLevels')
                        this.loadEmployeesData(this.selected_level.id).then(() => {
                            this.show_hierarchy_modal = false
                            this.loadEmployeeData(this.selected_user_id)
                        })
                    //})
                })
            }
            
        },
        handleTreeCheckboxes(){
            this.$nextTick(()=>{
                let tree = window.webix.$$('levelsTree');
                //* only attempt to select checkboxes if webix tree exists in the DOM
                if(tree){
                    this.user.levels.forEach( level_object => {
                        //* prevent webix from selecting an invalid row
                        if(tree.exists(level_object.id)){
                            tree.checkItem(level_object.id);
                        }
                    })
                }
            })
        },
    },
    mounted(){
        //this.setCompanyLevelId();
        this.$eventHub.$on('treeCheckboxesEnabled', this.handleTreeCheckboxes);
    },
    beforeDestroy(){
        this.$eventHub.$off('treeCheckboxesEnabled');
        this.setLevelAssignFlag(false);
    }
}
</script>

<style>

</style>