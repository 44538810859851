<template>
  <div>
    <CardHeader>
      <template #title> Team Members </template>
      <template #buttons>
        <!-- buttons for save and delete -->
        <TableActionButton
          :currentMode="table_view_mode"
          :actions="['view']"
        />
      </template>
    </CardHeader>
    <Table
      :uid="table_uid"
      :is_loading="is_loading"
      :data="level_team_mebers"
      :columns="columns"
      :table_mode="table_view_mode"
      :only_editable_columns="true"
      :context_menu_items="['View']"
      :active_id="activeMemberID"
      :pager_config="8"
      @selectedRowEvent="selectedRowEvent"
    />
  </div>
</template>

<script>
import CardHeader from "@/components/widgets/cardHeader.vue";
import Table from "@/components/widgets/table.vue";
import TableActionButton from "@/components/widgets/tableActionsButton";

import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
  components: {
    CardHeader,
    Table,
    TableActionButton,
  },
  data: () => ({
    //* Variables for the Table Component
    table_uid: "teamMembersTable",
    table_view_mode: "view", //* (view - edit - delete)
    is_loading: false,
  }),
  computed: {
    ...levelComputed,
    ...peopleComputed,
    level_team_mebers() {
      return this.teamMembersPerformance
        ? this.getTableRows(this.teamMembersPerformance)
        : [];
    },
    //* column config for table component
    columns() {
      return [
        {
          id: "member_name",
          header: "Team Member",
          fillspace: 1,
          editor: "text",
        },
        { id: "job_title", header: "Job Title", fillspace: 1, editor: "text" },
        { id: "report_to", header: "Reports To", fillspace: 1, editor: "text" },
        {
          id: "overall_rating",
          header: "Overall Rating",
          fillspace: 1,
          editor: "text",
        },
      ];
    },
    activeMemberID() {
      //* return the selected rows id from vuex store
      //* used to sync table selected row to the row in vuex
      return +this.activeMemberId;
    },
  },
  methods: {
    ...peopleMethods,
    // Get the table row the table needs to for each column
    getTableRows(teamMembersPerformance) {
      return teamMembersPerformance.map((item) => ({
        id: item.member?.id,
        member_name: `${item.member?.name} ${item.member?.last_name}`,
        job_title: `${
          item.member?.employment_tracking?.job?.job_title ?? "N/A"
        }`,
        report_to: `${
          item.member?.employment_tracking?.job?.report_to ?? "N/A"
        }`,
        overall_rating: `${
          item.member?.employment_tracking?.overall_rating ?? "N/A"
        }`,
      }));
    },
    //* Table Component event handlers
    //* table events
    selectedRowEvent(row) {
      //* Event returns the selected row
      if (row.id) {
        this.is_loading = true;
        let id = Number(row.id);
        this.setActiveMemberId(id);
        this.getTeamMemberObjectives(id).then(() => this.is_loading = false );
      }
    }
  },
  mounted() { },
};
</script>

<style>
</style>