<template>
    <div>
        
        <b-row v-if="hasCandidates && has_skills">
            <b-col cols="6">
                <div class="card-title">
                    Results
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-success">{{jobTitle}}</span>
                </div>
                <div>
                    <Table
                    uid="summaryTable"
                    :treetable="true"
                    :columns="columns"
                    :data="summary_data"
                    :table_mode="'view'"
                    />
                </div>
            </b-col>

            <b-col cols="6">
                <div class="card-title">
                    Recommended Candidate
                </div>
                <div>
                        <b-row>
                            <b-col cols="4">
                                <div class="d-flex" style="height: 20rem; align-items: center; justify-content: center;">
                                    <h4 style="text-align: center;">
                                        {{winningCandidate}}
                                    </h4>
                                </div>
                            </b-col>
                            <b-col cols="8">
                                <apexchart
                                type="donut"
                                :options="options.options"
                                :series="options.series">
                                </apexchart>
                            </b-col>
                        </b-row>
                    </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <div class="card-title">
                    Results
                </div>
                <b-alert v-if="has_skills == false" show variant="warning">No Skills Added</b-alert>
                <b-alert v-if="hasCandidates == false" show>No candidate data available</b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash';
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Table from '@/components/widgets/table.vue'

export default {
    components:{
        Table,
    },
    data:() => ({
        winning_user: '',
    }),
    
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        jobTitle(){
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            else{
                return '';
            }
        },
        hasCandidates(){
            let isValid = false;
            if(this.active_job_data != null){
                if(this.active_job_data.candidates.length > 0){
                    isValid = true;
                }
            }
            return isValid;
        },
        winningCandidate(){
            let name = '';
            if(this.summary_data.length > 0){
                let index =_.findIndex(this.summary_data, {name: 'Average'});
                if(index != -1){
                    let top_score = 0;
                    this.summary_data[index].data.forEach( item => {
                        if(item.total > top_score){
                            top_score = item.total;
                            name = item.name;
                        }
                    })
                }
            }
            return name;
        },
        options(){
            let data = {
                options:{
                    chart:{
                        type: 'donut'
                    },
                    labels:[],
                    colors:[],
                },
                series:[],
            }
            if(this.summary_data.length > 0){
                let index =_.findIndex(this.summary_data, {name: 'Average'});
                if(index != -1){
                    let highest_index = -1;
                    let highest = 0;
                    this.summary_data[index].data.forEach( (item, idx) => {
                        data.series.push(item.total);
                        data.options.labels.push(item.name);
                        if(item.total > highest){
                            highest = item.total;
                            highest_index = idx;
                        }
                    })
                    // eslint-disable-next-line
                    this.summary_data[index].data.forEach( (item, idx) => {
                        if(highest_index == idx){
                            data.options.colors.push('#00e397');
                        }
                        else{
                            data.options.colors.push('#cfcfcf');
                        }
                    })
                }
            }
            return data;
        },
        has_skills(){
            let valid = false;
            if(this.active_job_data != null){
                if(this.active_job_data.skills.length > 0){
                    valid = true;
                }
            }
            return valid;
        },
        summary_data(){
            let data = [];
            if(this.active_job_data != null){
                //*build an array of job skills
                this.active_job_data.skills.forEach( job_skill => {
                    let obj = {
                        open: true,
                        name: job_skill.skill_name,
                        id: job_skill.id,
                        people:[]
                    }
                    this.active_job_data.candidates.forEach( person => {
                        obj.people.push(person);
                    })
                    data.push(obj);
                })


                let final = [];
                data.forEach( item => {
                    let row = {
                        name: item.name,
                        id: item.id,
                        data:[]
                    }
                    item.people.forEach( person => {
                        let child_row = {
                            //let index = _.findIndex()
                            name: person.first_name,
                            id: window.webix.uid(),
                            person_id: person.id,
                            experience: 0,
                            skill_level: 0,
                            team_fit: 0,
                            total: 0,
                        }
                        let experience = [];
                        let skill_level = [];
                        let team_fit = [];
                        person.interviews.forEach( interview => {
                            if(row.id == interview.skill_id){
                                experience.push(interview.experience);
                                skill_level.push(interview.skill_level);
                                team_fit.push(interview.team_fit);
                            }
                        })
                        //* get averages
                        if(experience.length > 0){
                            let total = 0;
                            experience.forEach( item => { total += item });
                            child_row.experience = Number(total / experience.length);
                            child_row.experience = +child_row.experience.toFixed(2);
                        }
                        else{ child_row.experience = 0; }
                        if(skill_level.length > 0){
                            let total = 0;
                            skill_level.forEach( item => { total += item });
                            child_row.skill_level = Number(total / skill_level.length);
                            child_row.skill_level = +child_row.skill_level.toFixed(2);
                        }
                        else{ child_row.skill_level = 0; }
                        if(team_fit.length > 0){
                            let total = 0;
                            team_fit.forEach( item => { total += item });
                            child_row.team_fit = Number(total / team_fit.length);
                            child_row.team_fit = +child_row.team_fit.toFixed(2);
                        }
                        else{ child_row.team_fit = 0; }

                        child_row.total = child_row.experience + child_row.skill_level + child_row.team_fit
                        
                        row.data.push(child_row)
                    })
                    final.push(row);
                    
                })
                let average_row = {
                    name: 'Average',
                    id: window.webix.uid(),
                    data:[]
                }
                this.active_job_data.candidates.forEach( person => {
                    let experience = [];
                    let skill_level = [];
                    let team_fit = [];
                    let total = [];
                    final.forEach( item => {
                        if(item.data != undefined){
                            item.data.forEach( data_item => {
                                if(person.id == data_item.person_id){
                                    experience.push(data_item.experience)
                                    team_fit.push(data_item.team_fit)
                                    skill_level.push(data_item.skill_level)
                                    total.push(data_item.total)
                                }
                            })
                        }
                    })
                    let child = {
                        name: person.first_name,
                        id: window.webix.uid(),
                        experience: 0,
                        skill_level: 0,
                        team_fit: 0,
                        total: 0,
                    }
                    experience.forEach( item => { child.experience += item; });
                    if(experience.length > 0){
                        child.experience = Number(child.experience/experience.length);
                        child.experience = +child.experience.toFixed(2);
                    }
                    skill_level.forEach( item => { child.skill_level += item; });
                    if(skill_level.length > 0){
                        child.skill_level = Number(child.skill_level/skill_level.length);
                        child.skill_level = +child.skill_level.toFixed(2);
                    }
                    team_fit.forEach( item => { child.team_fit += item; });
                    if(team_fit.length > 0){
                        child.team_fit = Number(child.team_fit/team_fit.length);
                        child.team_fit = +child.team_fit.toFixed(2);
                    }
                    total.forEach( item => { child.total += item; });
                    if(total.length > 0){
                        child.total = Number(child.total/total.length);
                        child.total = +child.total.toFixed(2);
                    }
                    average_row.data.push(child)
                })
                final.push({})
                final.push(average_row);
                return final;
            }
            return data;
        },
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
        columns(){
            return [
                { id: 'name', header: 'Skill', fillspace: 1.5,
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + value;
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: 'experience', header: 'Experience', fillspace: 0.8,
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },
                },
                { id: 'skill_level', header: 'Skill Level', fillspace: 0.8,
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },
                },
                { id: 'team_fit', header: 'Team Fit', fillspace: 0.8,
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },
                },
                { id: 'total', header: 'Total', fillspace: 0.8,
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/30'
                        }
                    },
                }
            ]
        }
    },
    methods:{
        ...peopleMethods,

    },
    mounted(){},
}
</script>

<style>

</style>