<template>
    <div>
      <b-card class="m-1">
              <div class="d-flex">
                  <div id="chart" style="margin: auto;">
                    <apexchart v-if="has_loaded" type="bar" height="400" width="500" :options="chartOptions" :series="series"></apexchart>
                  </div>
              </div>
      </b-card>
    </div>
  </template>
  
  <script>
  
  import { ganttComputed, ganttMethods, peopleComputed, peopleMethods } from "@/state/helpers.js";
  
  import ApexCharts from "vue-apexcharts";
  export default {
    components: {
      apexchart: ApexCharts,
    },
    data:() => ({
            has_loaded:false,
          // Bar chart
            series: [{
              name: '',
              data: []
            }, {
              name: '',
              data: []
            }],
            chart: {
              type: 'bar',
              height: 250,
              width: 250,
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: '70%',
                dataLabels: {
                  total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900,
                    }
                  }
                }
              },
            },
            chartOptions: {
                chart: {
                    height: 250,
                    width: 250,
                    type: 'bar',
                    stacked: true,
                    stackType: '100%'
                },
                dataLabels: {
                      formatter: function(value, { seriesIndex }) {
                        if(seriesIndex == 0) {
                          return value + '%'
                        } else {
                          return ''
                        }
                        
                      }
                  },
                colors: ['#008FFB', '#00E396', '#4C486A' ], 
                plotOptions: {
                    bar: {
                      columnWidth: '50%',
                      barHeight: '50%',
                        horizontal: true,   
                    },
                },
                title: {
                   text: 'Competence Bar Chart'
                },
                tooltip: {
                    enabled: true,
                    x: {
                        formatter: (value) => value
                    }
                },
                yaxis: {
                  labels: {
                      minWidth: 100,
                      formatter: (value) => {
                        var len = value.length
                        return len > 12 ? value.substr(0, 12) + '...' : value
                      }
                    }
                // labels: {
                //         rotate:-45
                //     }
                },
                stroke: {
                    width: 2,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: [],              
                }, 
                fill: {
                opacity: 0.8
                },  
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }         
            },
      }),
      watch: {
        employeeDetails() {
          this.has_loaded =false
          this.$nextTick(() => {
            this.setActiveJobOfEmployee() 
          })
        }
      },
      computed:{
          ...ganttComputed,
          ...peopleComputed,  
      },
      methods:{
          ...ganttMethods,
          ...peopleMethods,
          setActiveJobOfEmployee() {
            if(this.employeeDetails.current_job.id) {
              this.setActiveJobId(this.employeeDetails.current_job.id).then(() => {
                if(this.employeeDetails.current_job.user_skills) {
                  this.buildChartBase()
                }    
              });
            } else {
              if(this.employeeDetails.current_job.user_skills) {
                this.buildChartBase()
              }
            }
          },
          buildChartBase() {
            if(this.activeJobId == -1 || this.companyJobs.length == 0){
                  return [];
              } else {
                  let names = [];
                  let job_values = [];
                  let user_values = []
                  //let userValues = [8, 5, 3, 4, 10, 8, 7]
                  if(this.employeeDetails.current_job.user_skills.length != 0 && this.employeeDetails.current_job.skills.length != 0) {
                    let job_skills = this.employeeDetails.current_job.skills
                     job_skills.forEach(job_skill => {
                         names.push(job_skill.skill_name)
                         job_values.push(job_skill.competency_required);
                     })
                     let user_skills = this.employeeDetails.current_job.user_skills
                     user_skills.forEach(user_skill => {
                         user_values.push(user_skill.user_competency_rating)
                     })

                  

                    let progress_percentages = [];
                    let percentage_required = []

                    job_values.forEach((value, idx) => {
                        let userValue = user_values[idx]
                        console.log(userValue);
                        console.log(value)
                        let progress = (Number(userValue) / Number(value)) * 100
                        if(progress > 100) {
                            progress = 100
                        }
                        let required = 100 - Number(progress)
                        progress_percentages.push(progress.toFixed(2))
                        percentage_required.push(required.toFixed(2))
                    })
                    // console.log(names)
                    // let data = this.chartOptions.xaxis.categories
                    // data.push(names)
                    this.series[0].name = this.employeeDetails.name + '- Progress'
                    this.series[1].name = this.employeeDetails.current_job.title + "- Required"
                    console.log(progress_percentages)
                    this.series[0].data = progress_percentages
                    this.series[1].data = percentage_required
                    this.chartOptions.xaxis.categories = names
                    this.has_loaded= true
                  } else {
                    return []
                  }
                 
              }
          }  
      },
      beforeDestroy(){
      },
      mounted(){
        this.setActiveJobOfEmployee()
      },
  }
  </script>
  
  <style>
  </style>