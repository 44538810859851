<template>
    <div>
        <div class="d-flex flex-wrap mb-1 align-items-center" style="width: 100%;">
            <div class="me-2">
                <h5 class="card-title mr-2 d-flex align-items-center">
                    <span v-if="edit_checklist === 'edit'" class="text-success">Edit&nbsp;</span>
                    Onboarding Checklist -&nbsp;
                    <span class="text-info">{{  levelName}}</span>
                </h5>
            </div>

            <!--<div class="btn-group ms-auto" role="group">
                <b-button @click="toggleEditView()" :variant="edit_checklist === 'view' ? 'primary' : 'outline-primary'" size="sm">
                    <strong>Candidate Checklist</strong>
                </b-button>
                <b-button  @click="toggleEditView()" :variant="edit_checklist === 'edit' ? 'primary' : 'outline-primary'" size="sm">
                    <strong>Edit Checklist</strong>
                </b-button>
            </div>
            <div class="ms-3 me-3">|</div>-->

            <FilterComponent
            class="ms-auto"
            @searchText="searchText"
            @searchDate="searchDate"
            @resetFilter="resetFilter"/>

            <!-- buttons relating to values emitted from table -->
            <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="me-3 dropdown-menu-end">
                <strong>Save Table Changes</strong>
            </b-button>
            <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="me-3 dropdown-menu-end">
                <strong>Delete Selected Rows</strong>
            </b-button>
            <!-- ------ -->

            <!-- -- dropdown -- -->
            <b-dropdown text="Button text via Prop" variant="white" toggle-tag="a" menu-class="dropdown-menu-end" toggle-class="font-size-16 p-0" right>
                    <template #button-content>
                        <i class="mdi mdi-dots-horizontal"></i>
                    </template>
                    <b-dropdown-item v-if="isViewMode == false" variant="primary" href="#" @click="toggleViewMode()" >
                        View Mode
                    </b-dropdown-item>
                    <b-dropdown-item v-if="isEditMode == false" variant="primary" href="#" @click="toggleEditMode()" >
                        Edit Mode
                    </b-dropdown-item>
                    <b-dropdown-item v-if="isDeleteMode == false" variant="danger" href="#" @click="toggleDeleteMode()" >
                        Delete Mode
                    </b-dropdown-item>
            </b-dropdown>

        </div>

        <div>
            <b-alert :show="onboardingChecksData.length == 0">No Checks Added</b-alert>
            <div v-if="edit_checklist === 'view'">
                <Table
                    uid="checklistTable" 
                    :is_loading="is_loading"
                    :data="onboardingChecksData"
                    :columns="columns_checklist"
                    :table_mode="table_view_mode"
                    :pager_config=8
                    @selectedRowEvent="selectedRowEvent"
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                />
                <hr>

                <!-- print a checklist for evry instance of onboardingChecksData -->
                <boardingChecklist v-for="step in onboardingChecksData" :key="step.id" 
                    :step="step.step_order" 
                    :userId="step.responsible_user_name" 
                    :notes="step.procedure"
                    :check_id="step.id"
                />
                <!--use as prop later - :completionDate="new Date().toLocaleDateString('en-US')"  -->
                
            </div>
        </div>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
import Table from '@/components/widgets/table.vue';
import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import boardingChecklist from '../../boardingChecklist.vue'
import _ from 'lodash';
import { parseISO, isAfter, isBefore, format } from "date-fns";
import Swal from "sweetalert2";
export default {
    name: "Admin",
    components: {
        FilterComponent,
        Table,
        boardingChecklist
    },
    data:() => ({
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'onboardingChecksTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,

        selected_onboarding_check: null,

        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },

        isEditingNew: false,
        edit_checklist: 'view',
        showGuideline: false,
        editableId: -1,
        onboardingChecksData: [],
        addingRow: false,
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelOnboardingData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelOnboardingData[index];
                }
            }
            return data;
        },
        columns_checklist(){
            return [
                { header: 'Step', id: 'step_order', fillspace: 0.5,},
                { header: 'Onboarding Procedure', id: 'procedure', fillspace: 1,},
                { header: 'Responsible Person', id: 'responsible_user_id', fillspace: 1, options: this.userList},
                { id: 'completion_date', header: 'Completion Date', fillspace: 0.5, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'status', header: 'Status', fillspace: 0.5, editor:'combo',
                    options: [
                    { id: '0', value: '0%'},
                    { id: '25', value: '25%'},
                    { id: '50', value: '50%'},
                    { id: '75', value: '75%'},
                    { id: '100', value: '100%'},
                ]},
                { header: 'Notes', id: 'notes', fillspace: 1, editor: "text",},
            ]
        },
        

        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        isViewMode(){
            if(this.table_view_mode != 'view'){ return false; }
            else{ return true; }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){ return false; }
            else{ return true; }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){ return false; }
            else{ return true; }
        },
        storeOnboardingData() {
            return this.levelOnboardingData;
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            return "";
        },
        storeOnboardingChecksData() {
            const el = this;
            const findIndex = _.findIndex(this.storeOnboardingData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                let checks = this.storeOnboardingData[findIndex].onboarding_checks;
                const candidate = this.storeOnboardingData[findIndex].candidates.find((obj) => obj.id == this.activeCandidateId);
                if (candidate) {
                    //alert('Has candidate selected')
                    checks.forEach((obj, index) => {
                        const track = candidate.onboarding_tracking.find((item) => item.onboarding_check_id == obj.id);
                        if(track != undefined){
                            checks[index] = {
                                ...obj,
                                completion_date: track.completion_date,
                                status: track.status,
                                notes: track.notes,
                            }
                        }
                        else{
                            checks[index] = {
                                ...obj,
                                completion_date: obj.created_at,
                                status: 0,
                                notes: '',
                            }
                        }
                    });
                }

                return checks;
            }
            return [];
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
    },
    watch: {
        activeHiringPositionId() {
            this.$nextTick(() => {
                this.mapOnboardingChecksData();
            });
        },
        activeCandidateId() {
            this.$nextTick(() => {
                this.mapOnboardingChecksData();
            });
        },
        storeOnboardingData() {
            this.$nextTick(() => {
                this.mapOnboardingChecksData();
            });
        }
    },
    methods:{
        ...peopleMethods,
        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            this.selected_onboarding_check = row.id;
            return row;
        },
        forceEditMode(){
            this.table_view_mode = 'edit';
        },
        updateViewMode(type){
            this.table_view_mode = type;
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------

        toggleEditView(){
            let newState = ''
            if(this.edit_checklist === 'edit'){
                newState = 'view'
            }
            else{
                newState = 'edit'
            }
            this.edit_checklist = '';
            this.$nextTick(()=>{
                this.edit_checklist = newState;
            })
            
        },


        mapOnboardingChecksData() {
            this.onboardingChecksData = this.storeOnboardingChecksData.map(item => ({...item}));
        },
        setRowActive(data) {
            if (data[0] && data[0].id) {
                this.editableId = data[0].id;
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        saveTable(){
            if(this.edit_checklist === 'view'){
                this.saveCandidateTable();
            }
            else{
                this.saveChecklistTable();
            }
        },
        saveChecklistTable(){
            //* Get all rows that contain valid data
            let table = window.webix.$$(this.table_uid);

            let result = window.webix.$$(this.table_uid).validate();
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            }
            else{
                const all_data = table.serialize(true);

                let onboarding_checks_to_save = [];
                //let onboarding_tracking_to_save = [];

                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;

                        const onboardingChecksParams = {
                            id: newRow ? -1 : row.id,
                            step_order: row.step_order,
                            procedure: row.procedure,
                            responsible_user_id: row.responsible_user_id
                        };
                        onboarding_checks_to_save.push(onboardingChecksParams);

                        /*if (!newRow) {
                            const onboardingTrackingParams = {
                                check_id: row.id,
                                completion_date: row.completion_date,
                                status: row.status,
                                notes: row.notes
                            };
                            onboarding_tracking_to_save.push(onboardingTrackingParams);
                        }*/
                    }
                })

                const onboarding_checks_params = {
                    job_id: this.activeHiringPositionId,
                    checks: onboarding_checks_to_save,
                }

                /*const el = this;
                const findIndex = _.findIndex(this.storeOnboardingData, function(o) { return o.id == el.activeJobId; });
                const user_id = this.storeOnboardingData[findIndex].candidates.find((obj) => obj.id == this.activeCandidateId).user_id
                const onboarding_tracking_params = {
                    user_id: user_id,
                    tracking: onboarding_tracking_to_save,
                }*/

                this.is_loading = true;

                this.saveOnboardingChecks(onboarding_checks_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                })

                /*this.saveOnboardingTracking(onboarding_tracking_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                })*/
            }
        },
        saveCandidateTable(){
            //* Get all rows that contain valid data
            let table = window.webix.$$('checklistTable');

            let result = window.webix.$$('checklistTable').validate();
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            }
            else{
                const all_data = table.serialize(true);
                let tracking = [];

                all_data.forEach( row => {
                    // loop through all the table rows and create a new saving object in the format that the backend expects
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        if (!newRow) {
                            const onboardingTrackingParams = {
                                check_id: row.id,
                                completion_date: row.completion_date,
                                status: row.status,
                                notes: row.notes
                            };
                            tracking.push(onboardingTrackingParams);
                        }
                    }
                })

                const el = this;
                const findIndex = _.findIndex(this.storeOnboardingData, function(o) { return o.id == el.activeHiringPositionId; });
                const user_id = this.storeOnboardingData[findIndex].candidates.find((obj) => obj.id == this.activeCandidateId).user_id

                // final saving payload
                const onboarding_tracking_params = {
                    user_id: user_id, // selected user from the 2nd table
                    tracking: tracking, // array that cntains all the tabl rows as objects
                }

                this.is_loading = true;

                this.saveOnboardingTracking(onboarding_tracking_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                })
            }   
        },
        saveCandidateTable_OLD(){
            //* Get all rows that contain valid data
            let table = window.webix.$$(this.table_uid);

            let result = window.webix.$$(this.table_uid).validate();
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            }
            else{
                const all_data = table.serialize(true);

                let onboarding_checks_to_save = [];
                let onboarding_tracking_to_save = [];

                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;

                        const onboardingChecksParams = {
                            id: newRow ? -1 : row.id,
                            step_order: row.step_order,
                            procedure: row.procedure,
                            responsible_user_id: row.responsible_user_id
                        };
                        onboarding_checks_to_save.push(onboardingChecksParams);

                        if (!newRow) {
                            const onboardingTrackingParams = {
                                check_id: row.id,
                                completion_date: row.completion_date,
                                status: row.status,
                                notes: row.notes
                            };
                            onboarding_tracking_to_save.push(onboardingTrackingParams);
                        }
                    }
                })

                const onboarding_checks_params = {
                    job_id: this.activeHiringPositionId,
                    checks: onboarding_checks_to_save,
                }

                const el = this;
                const findIndex = _.findIndex(this.storeOnboardingData, function(o) { return o.id == el.activeHiringPositionId; });
                const user_id = this.storeOnboardingData[findIndex].candidates.find((obj) => obj.id == this.activeCandidateId).user_id
                const onboarding_tracking_params = {
                    user_id: user_id,
                    tracking: onboarding_tracking_to_save,
                }

                this.is_loading = true;

                this.saveOnboardingChecks(onboarding_checks_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                })

                this.saveOnboardingTracking(onboarding_tracking_params)
                .then(()=>{
                    this.loadOnboardingData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                })
            }

            
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected checks?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(){
            let promises = [];
            this.delete_array.forEach( item => {
                let params = {check_id: item.id};
                promises.push(this.deleteOnboardingCheck(params));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.loadOnboardingData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            })
        },
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
            if(obj.first_name != undefined){
                return (
                obj.first_name
                    .toString()
                    .toLowerCase()
                    .indexOf(text) != -1
                );
            }
            });
        },
        searchDate(value) {
            //* set date filters
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
            let start = parseISO(this.filter.dateStart);
            let end = parseISO(this.filter.dateEnd);

            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.procedure != undefined){
                    let c_d = parseISO(obj.completion_date);
                    return (
                        isAfter(c_d, start) && isBefore(c_d, end)
                    );
                }
            });
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
            window.webix.$$(this.table_uid).filter();
        },
    },
    mounted(){
        this.mapOnboardingChecksData();
    }
}
</script>
