<template>
    <div class="mb-2 reduced-font-size">
        
            <!-- <b-col class="col-12">
            <b-row class="set-grey-border m-0 p-1 mt-1">    
                    <div >
                        <CardHeader >
                        <template #title>
                            My Photos
                        </template>
                        <template #buttons>
                            <span class="table-actions-btn-group btn-group">
                                <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('photos', images)" class="no-focus">
                                    <font-awesome-icon icon="fa-pen" />
                                </b-button>
                            </span>
                            
                        </template>
                        </CardHeader>
                        <blockquote>
                            <p class="normal-text minHeight">
                                {{ images }}
                            </p>
                        </blockquote> 
                        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" style="max-height: 300px">
                            <ol class="carousel-indicators">
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner" role="listbox" style="max-height: 300px;">
                                <div class="carousel-item active">
                                    <img class="d-block img-fluid" src="@/assets/images/small/img-3.jpg" alt="First slide">
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block img-fluid" src="@/assets/images/small/img-2.jpg" alt="Second slide">
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block img-fluid" src="@/assets/images/small/img-1.jpg" alt="Third slide">
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
            </b-row>
            </b-col> -->
                <!-- <b-card no-body class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-1 header-bg-variant="light" header-tag="header" role="tab">
                        <h6 class="m-0">
                            <a class="text-dark" href="javascript: void(0);">
                                <i class="fas me-1" :class="active_collapse_index == 1 ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                Job Details
                            </a>
                        </h6>
                    </b-card-header>
                    
                    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" @show="active_collapse_index = 1" @hidden="active_collapse_index = null">
                        <b-card-body>
                            <b-alert v-show="singleEmployee.current_job.id == null" show variant="info" >
                                Please link a job to edit job details.
                            </b-alert>
                            <editJobDetails :job_object="singleEmployee.current_job" />
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-2 header-bg-variant="light" header-tag="header" role="tab">
                        <h6 class="m-0">
                            <a class="text-dark" href="javascript: void(0);">
                                <i class="fas me-1" :class="active_collapse_index == 2 ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                Personal Details
                            </a>
                        </h6>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel"  @show="active_collapse_index = 2" @hidden="active_collapse_index = null">
                        <b-card-body>
                             
                        </b-card-body>
                    </b-collapse>
                </b-card> -->

                <b-overlay :show="is_loading">
                <b-row class="m-0 p-1 mt-1">
                <b-col class="col-6 set-grey-border p-2 rightSpacing">
                    <CardHeader >
                    <template #title>
                        Personal Interests
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('interests', interests_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div class="minHeight reduced-font-size" v-html="interests_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ interests_text }}
                        </p> -->
                    </blockquote>
                </b-col>
                <b-col class="col-6 set-grey-border p-2 leftSpacing">
                    <CardHeader>
                    <template #title>
                        Personal Achievements
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('achievements', achievements_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div class="minHeight reduced-font-size" v-html="achievements_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ achievements_text }}
                        </p> -->
                    </blockquote>
                </b-col>
            </b-row>
            <b-row class="m-0 p-1 mt-1">
                <b-col class="col-6 set-grey-border p-2 rightSpacing" >
                    <CardHeader >
                    <template #title>
                        My Work Experience
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('work_experience', work_experience_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div class="minHeight reduced-font-size" v-html="work_experience_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ work_experience_text }}
                        </p> -->
                    </blockquote>
                </b-col>
                <b-col class="col-6 set-grey-border p-2 leftSpacing">
                    <CardHeader>
                    <template #title>
                        My Career Plan
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('career_plan', career_plan_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div class="minHeight reduced-font-size" v-html="career_plan_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ career_plan_text }}
                        </p> -->
                    </blockquote>
                </b-col>
            </b-row>
            <b-row class="m-0 p-1 mt-1">
                <b-col class="col-6 set-grey-border p-2 rightSpacing">
                    <CardHeader>
                    <template #title>
                        How to work with me
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('work', work_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div  class="minHeight reduced-font-size" v-html="work_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ work_text }}
                        </p> -->
                    </blockquote>
                </b-col>
                <b-col class="col-6 set-grey-border p-2 leftSpacing">
                    <CardHeader>
                    <template #title>
                        My Personality Profile
                    </template>
                    <template #buttons>
                        <span class="table-actions-btn-group btn-group">
                            <b-button size="sm" :variant="'outline-light'" @click="showEmployeeDetailsModal('profile', personality_text)" class="no-focus" :disabled="core.id !== Number(selected_user_id)">
                                <font-awesome-icon icon="fa-pen" />
                            </b-button>
                        </span>
                        
                    </template>
                    </CardHeader>
                    <blockquote>
                        <div class="minHeight reduced-font-size" v-html="personality_text"></div>
                        <!-- <p class="normal-text minHeight">
                            {{ personality_text }}
                        </p> -->
                    </blockquote>
                </b-col>
            </b-row>
        </b-overlay>
            
           
        <addEmployeeDetailsModal
            v-if="show_employee_details_modal"
            :payload="modal_payload"
            @savedNewDetails="refreshData"
        />
    </div>
</template>

<script>

import addEmployeeDetailsModal from "@/components/people/modals/addEmployeeDetails.vue"
import { levelComputed, peopleComputed, peopleMethods, adminComputed, levelMethods } from '@/state/helpers'
//import editJobDetails from "../editJobDetails.vue"
import CardHeader from "@/components/widgets/cardHeader.vue";
export default {
    components:{
        CardHeader,
        addEmployeeDetailsModal,
        //editJobDetails
    },
    data:() => ({
        active_collapse_index: 1,
        is_loading: false,
        add_images: false,
        enable_interests_edit: false,
        enable_achievements_edit: false,
        enable_work_experience_edit: false,
        enable_career_plan_edit: false,
        enable_personality_edit: false,
        enable_work_edit: false,
        enable_edit: false,
        images: '',
        interests_text: '',
        achievements_text: '',
        work_experience_text: '',
        career_plan_text: '',
        personality_text: '',
        work_text: '',
        show_employee_details_modal: false,
        singleEmployee: null
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed
    },
    watch: {
        selected_user_id: {
            handler() {
                console.log('refreshing the datas');

                this.refreshData()
            }
        }
    },
    methods:{
        ...peopleMethods,
        ...levelMethods,
        contentChanged(new_content){
            console.log(new_content);
        },
        showEmployeeDetailsModal(type, data){
            this.modal_payload = { type: type, data: data };
            console.log("@@@@@@@@@@@@");
            console.log(this.modal_payload)
           
            this.show_employee_details_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addEmployeeDetailsModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addEmployeeDetailsModal") {
                        this.show_employee_details_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        refreshData() {
            //this.$nextTick(() => {
                this.loadEmployeeData(this.selected_user_id).then(() => {
                    this.singleEmployee = this.employeeDetails
                    this.loadEmployeeTextData()
                })
            //})
            
        },
        loadEmployeeTextData() {
            console.log("1")
            if(this.singleEmployee) {
                let textList = this.singleEmployee.profile_text
                console.log("2");
                console.log(textList)
                if(textList.length != 0) {
                   textList.forEach(item => {
                        switch(item.text_type){
                            case 'career_plan':{
                                this.career_plan_text = item.text
                                break;
                            }
                            case 'interests':{
                                this.interests_text = item.text
                                break;
                            }
                            case 'work_experience':{
                                this.work_experience_text = item.text
                                break;
                            }
                            case 'achievements':{
                                this.achievements_text = item.text
                                break;
                            }
                            case 'profile':{
                                this.personality_text = item.text
                                break;
                            }
                            case 'work':{
                                this.work_text = item.text
                                break;
                            }
                        }
                   })
                } else {
                    this.interests_text= ''
                    this.achievements_text= ''
                    this.work_experience_text= ''
                    this.career_plan_text= ''
                    this.personality_text= ''
                    this.work_text= ''
                }
            } 
        }
    },
    mounted(){
        this.is_loading = true
        if(this.selected_user_id != null) {
            this.loadEmployeeData(this.selected_user_id).then(() => {
                this.singleEmployee = this.employeeDetails
                this.loadEmployeeTextData()
                this.is_loading = false
            })
        }
    },
}
</script>

<style>
.minHeight {
    min-height: 30px !important;
}
.leftSpacing {
    width: 49.5%; 
    margin-left: 0.5%
}
.rightSpacing {
    width: 49.5%; 
    margin-right: 0.5%
}
.reduced-font-size {
    font-size: 0.7rem !important;
}
</style>