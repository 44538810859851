<template>
<div>
    <div>
        <b-row>
            <b-col cols="7">
                <OffboardingHiresTable></OffboardingHiresTable>
            </b-col>
            <b-col cols="5" v-if="activeHiringPositionId > -1">
                <OffboardingCandidatesTable ></OffboardingCandidatesTable>
            </b-col>
        </b-row>
        <hr v-if="activeHiringPositionId > -1">
        <b-row v-if="activeHiringPositionId > -1">
            <OffboardingChecklistTable></OffboardingChecklistTable>
        </b-row>
    </div>
</div>
</template>
<script> 
import OffboardingHiresTable from './components/offboarding/offboardingHiresTable.vue'
import OffboardingCandidatesTable from './components/offboarding/offboardingCandidatesTable.vue'
import OffboardingChecklistTable from './components/offboarding/offboardingChecklistTable.vue'
import _ from 'lodash';
import {
    peopleComputed,
    peopleMethods,
    levelComputed,
} from "@/state/helpers";

export default {
    name: "Admin",
    components: {
        OffboardingHiresTable,
        OffboardingCandidatesTable,
        OffboardingChecklistTable,
    },
    computed:{
        ...peopleComputed,
        ...levelComputed,
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelOffboardingData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelOffboardingData[index];
                }
            }
            return data;
        },
        // getUserName(){

        // }
    },
    methods:{
        ...peopleMethods,
        resetOffboarding(){
            //this.activeJobId = -1;
            //this.activeCandidateId = -1;
        }
    },
    mounted() {
        console.log(this.current_level_id)
        this.loadOffboardingData(this.selected_level.id)
    },
    beforeDestroy(){

    }

}

</script>
