<template>
    <div>
        <div class="d-flex flex-wrap mb-3" style="width: 100%;">
            <div class="card-body">
                <div class="d-flex flex-wrap mb-3 align-items-center" style="width: 100%;">
                    <div class="me-2">
                        <h5 class="card-title mr-2 d-flex align-items-center">
                            Employees -&nbsp;
                            <span class="text-info">{{ levelName }}</span>
                            &nbsp; <!--Forced line break-->
                        </h5>
                    </div>
                    <span class="ms-auto me-3" style="float: right;">
                        <b-button v-show="!addMode" @click="toggleAddMode(true)" size="sm" variant="primary">
                            Add Employee
                        </b-button>
                        <b-button v-show="addMode" @click="toggleAddMode(false)" size="sm" variant="primary">
                            Cancel
                        </b-button>
                    </span>
                    <FilterComponent
                        style="float: right;"
                        @searchText="searchText"
                        @searchDate="searchDate"
                        @resetFilter="resetFilter"
                    />
                    <b-button @click="showDeletePrompt" size="sm" style="float: right;" variant="danger" v-if="delete_array.length > 0" right class="me-3 dropdown-menu-end">
                        <strong>Delete Selected Rows</strong>
                    </b-button>
                    <b-dropdown :class="dropdownClassFix" text="Button text via Prop" variant="white" toggle-tag="a" menu-class="dropdown-menu-end" toggle-class="font-size-16 p-0" right>
                        <template #button-content>
                            <i class="mdi mdi-dots-horizontal"></i>
                        </template>

                        <b-dropdown-item v-if="isViewMode == false" variant="primary" href="#" @click="toggleViewMode()" >
                            View Mode
                        </b-dropdown-item>

                        <b-dropdown-item v-if="isDeleteMode == false" variant="danger" href="#" @click="toggleDeleteMode()" >
                            Delete Mode
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-row>
                    <b-col cols="9">
                        <Table
                            :uid="table_uid" 
                            :is_loading="is_loading"
                            :data="employeesData"
                            :columns="columns"
                            :table_mode="table_view_mode"
                            :button_methods="returnOnClickMethods()"
                            :pager_config=8
                            @selectedRowEvent="selectedRowEvent"
                            @onDataUpdate="onChangesMade"
                            @onDeleteArrayUpdate="onDeleteArrayUpdate"
                        />
                    </b-col>
                    <b-col cols="3">
                        <b-card class="border border-dark" style="width: 100%;" title="Profile Picture">
                            <div v-if="hasUserData" style="width:100%; display: flex; justify-content: center;" >
                                <img @click="selectImage" :src="selectedUser.avatar_src" alt height="150px"  />
                                <div v-if="selectedUser.avatar_src">
                                    <b-form-file style="display: none" id="fileUpload" accept="image/*" v-on:change="filesChange"></b-form-file>
                                </div>
                            </div>
                            <div v-else>
                                <b-alert show>No User Selected</b-alert>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </div>
        <b-modal id="cropperModal" title="Crop Image" ok-only ok-title="Save" @ok="saveAvatar">
            <cropper
                ref="cropper"
                class="cropper"
                :src="this.selectedImage"
                :stencil-props="{
                    aspectRatio: 10/10
                }"
                :canvas="{
                    minHeight: 40,
                    minWidth: 40,
                    maxHeight: 40,
                    maxWidth: 40,
                }"
            />
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import {
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Table from '../../../widgets/table.vue'//* reusable table component
//eslint-disable-next-line
import { format, parseISO, isAfter, isBefore } from "date-fns";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    data:() => ({
        //*fix flow
        addMode: false,


        employeesData:[],
        jobsData:[],
        changes_made_flag: false,
        table_uid: 'employeesTable',
        table_view_mode: 'view',
        delete_array: [],
        is_loading: false,
        filter: {
            dateStart: "",
            dateEnd: "",
        },
        selectedUser: {},
        selectedImage: "",
        croppedImage: ""
    }),
    components:{
        Table,
        FilterComponent,
        Cropper
    },
    watch: {
        storeEmployeesData() {
            this.mapEmployeesData();
        }
    },
    computed:{
        //* make vuex stores available
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        hasUserData(){
            if(this.selectedUser.id == undefined){
                return false;
            }
            else{
                return true;
            }
        },
        active_employee_data() {
            let data = null;
            if(this.activeJobId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeJobId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        dropdownClassFix() {
            //* fix buttons position when displaying before the ... dropdown menu
            //changes_made_flag ? '' : 'ms-auto'
            if(this.changes_made_flag == true || this.delete_array.length > 0){
                return '';
            }
            else{
                return ''//'ms-auto'
            }
        },
        //* computed prop for table
        columns(){
            //* array of column items as objects
            return [
                { id: 'name', header: 'Name', fillspace: 1,  editor: "text",},
                { id: 'last_name', header: 'Surname', fillspace: 1,  editor: "text",},
                { id: 'email', header: 'Email Address', fillspace: 1,  editor: "text",},
                { id: "job_id", editor: "combo", header: "Job Title", fillspace: 1, css: { "text-align": "left", "word-wrap": "break-word" }, options: this.levelJobsList,},
                { id: "hierarchy", editor: "combo", header: "Hierarchy", fillspace: 3, css: { "text-align": "left", "word-wrap": "break-word" }, options: this.hierarchy,},
                { id: 'start_date', header: 'Start Date', fillspace: 1,  editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'report_to_user_id', header: 'Reporting To', fillspace: 1, options: this.userList},
            ]
        },
        table_validation_rules(){
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "level_id": function(obj, item){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                }
            };
        },
        storeEmployeesData() {
            let data = _.cloneDeep(this.levelEmployeesData);
            data.forEach(item => {
                item['job_id'] = item.current_job != null ? item.current_job.id : 'No Job';
                item['start_date'] = item.current_job != null ? item.current_job.start_date : new Date();
                item['report_to_user_id'] = item.current_job != null ? item.current_job.report_to_user_id : null;
                item['hierarchy'] = item.pivot.level_id;
            })
            return data; 
        },
        storeLevelJobsData() {
            let data = _.cloneDeep(this.levelJobsData);
            return data; 
        },
        levelName() {
            return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },

        isViewMode(){
            if(this.table_view_mode != 'view'){
                return false;
            }
            else{
                return true
            }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){
                return false;
            }
            else{
                return true
            }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){
                return false;
            }
            else{
                return true
            }
        },
        hierarchy() {
            let levels = [];
            levels = this.levels.map((level) => {
                //* hetHierarchy returns the level name for the given id
                return { id: level.id, value: this.getHierarchy(level.id) };
            });
            return levels;
        },
        levelJobsList() {
            let jobs = [];
            jobs = this.levelJobsData.map((job) => {
                return { id: job.id, value: job.job_title };
            });
            return jobs;
        },
        userList() {
            let users = [];
            _.forEach(this.level_users, function(value) {
                users.push({id: value.id, value: value.name});
            });
            return users;
        },
        currentUser(){
            let user = null;
            let index = _.findIndex(this.level_users, {id: this.current_selected_user_id.row});
            if(index != -1){
                user = this.level_users[index];
            }
            return user;
        }
    },
    methods:{
        ...peopleMethods,
        mapEmployeesData() {
            this.employeesData = this.storeEmployeesData.map(item => ({...item}));
        },
        mapLevelJobsData() {
            this.jobsData = this.storeLevelJobsData.map(item => ({...item}));
            console.log(this.jobsData);
        },
        returnOnClickMethods(){
            let onClick = {
                //eslint-disable-next-line
                'unlink_button':function(e, id){
                    alert(id);
                }
            };
            return onClick;
        },
        toggleAddMode(state){
            this.addMode = state;
            this.$emit('toggleAddMode', state);
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        saveTable(){
            //! BUG WITH SAVING THE HIERARCHY LEVEL
            //TODO -  add rules validation
            //* Get all rows that contain valid data
            //eslint-disable-next-line
            let table = $$(this.table_uid);
            const all_data = table.serialize(true);
            all_data.forEach( row => {
                if(!row.is_empty){
                    this.is_loading = true;
                    let newRow = row.is_new;
                    // Only save new rows.
                    if (newRow) {
                        var params = {
                            id: newRow ? -1 : row.id,
                            // user_level_id: this.selected_level.id,
                            user_level_id: row.hierarchy,
                            name: row.name,
                            last_name: row.last_name,
                            email: row.email,
                            job_title: row.job_title,
                            start_date: row.start_date,
                            job_id: row.job_id,
                        };
                        this.saveEmployee(params)
                        .then((res)=>{
                            this.loadEmployeesData(this.selected_level.id)
                            .then(()=>{
                                this.is_loading = false;
                                this.toggleViewMode();
                                this.$eventHub.$emit('setActiveEmployee', res);
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            this.is_loading = false;
                        })
                    } else {
                        this.is_loading = false;
                    }
                }
            })
        },
        saveAvatar() {
            this.is_loading = true;
            const { canvas } = this.$refs.cropper.getResult();
            const params = new FormData();
            params.append('user_id', this.selectedUser.id)
            canvas.toBlob(blob => {
                params.append('avatar', blob)
                this.uploadAvatar(params)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                })
            }, 'image/jpeg');

        },
        selectImage() {
            document.getElementById("fileUpload").click();
        },
        selectedRowEvent(row) {
            this.selectedUser = row;
            this.selectedImage = "";
            this.$eventHub.$emit('setActiveEmployee', row.id);
        },
        onChangesMade(value){
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            this.delete_array = array;
        },
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
            if(obj.name != undefined){
                return (
                    obj.name
                        .toString()
                        .toLowerCase()
                        .indexOf(text) != -1
                    );
                }
            });
        },
        searchDate(value) {
            //* set date filters
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
            let start = parseISO(this.filter.dateStart);
            let end = parseISO(this.filter.dateEnd);

            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.name != undefined){
                    let s_d = parseISO(obj.start_date);
                    return (
                        isAfter(s_d, start) && isBefore(s_d, end)
                    );
                }
            });
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
            window.webix.$$(this.table_uid).filter();
        },
        filesChange(event) {
            let imgFile = event.target.files
            if (imgFile && imgFile[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.selectedImage = e.target.result
                }
                reader.readAsDataURL(imgFile[0])
            }

            this.$bvModal.show("cropperModal")
        },
        clearEmployeeSelection(){
            this.selectedUser = {};
        },
        showDeletePrompt(){

        }
    },
    mounted(){
        //* init table data
        this.mapEmployeesData();
        this.mapLevelJobsData();
        this.$eventHub.$on('newLevelSelected2', this.clearEmployeeSelection)
    },
    beforeDestroy(){
        this.$eventHub.$off('newLevelSelected2');
    }
}
</script>

<style>

</style>