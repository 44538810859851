<template>
    <b-modal title='Edit KR' id='keyResultModal' hide-footer>
        <b-form class="krForm">
            <b-row>
                <b-col cols="8" class="mb-1">
                    <label style="margin-bottom: 0.1rem;">Name</label>
                    <b-form-input v-model="form_data.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Status</label>
                    <div class="d-flex pt-2">
                        <b-form-input style="width: 90px;" @input="updateStatus($event)" v-model.number="form_data.status" min=0 max=100 type="range"></b-form-input>
                        <span class="ms-2" style="width: 20px;">{{ form_data.status_value }}</span>
                    </div>
                </b-col>

                <b-col cols="12" class="mb-1">
                    <label>Unit Type</label>
                    <vue-typeahead-bootstrap
                        ref="unitSearch"
                        :data="units"
                        size="sm"
                        v-model="unit_query"
                        placeholder="Search Unit Type"
                        @hit="selectedUnit"
                        showOnFocus
                        prepend="Type:"
                    ></vue-typeahead-bootstrap>
                    <!-- <b-form-input v-model="form_data.unit_type_id"></b-form-input> -->
                </b-col>
                <b-col cols="12" class="mb-1">
                    <label>Owner</label>
                    <vue-typeahead-bootstrap
                        ref="userSearch"
                        :data="users"
                        size="sm"
                        v-model="owner_query"
                        placeholder="Search name/email"
                        @hit="selectedOwner"
                        showOnFocus
                        prepend="User:"
                    ></vue-typeahead-bootstrap>
                    <!-- <b-form-input v-model="form_data.owner_user_id"></b-form-input> -->
                </b-col>

                <b-col cols="6" class="mb-1">
                    <label>Start Date</label>
                    <b-form-input
                        type="date"
                        v-model="form_data.start_date"
                        :min="min_date"
                        :max="max_date"
                    ></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label>Deadline</label>
                    <b-form-input
                        type="date"
                        v-model="form_data.deadline"
                        :min="min_date"
                        :max="max_date"
                    ></b-form-input>
                </b-col>

                <b-col cols="12" class="mb-1">
                    <label>Plan</label>
                    <b-form-textarea v-model="form_data.plan"></b-form-textarea>
                </b-col>
                <b-col cols="12" class="mb-1">
                    <label>Notes</label>
                    <b-form-textarea v-model="form_data.notes"></b-form-textarea>
                </b-col>
            </b-row>
        </b-form>
        <!-- footer -->
        <div class='d-flex'>
            <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
        </div>
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </b-modal>

</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { performanceComputed, performanceMethods, activityComputed, levelComputed } from '@/state/helpers';
export default {
    components:{
        VueTypeaheadBootstrap,
    },
    props:{
        is_parent:{
            type: Boolean,
            default: false,
        },
        edit_id: {
            default: -1,
        },
        available_key_results:{
            default: [],
        },
        objective: {
            default: false,
        }
    },
    data:() => ({
        is_loading: false,
        owner_query: '',
        unit_query: '',
        form_data:{
            id: '',
            name: '',
            unit_type_id: '3',
            owner_user_id: '38',
            milestone_objective_id: -1,
            start_date: "2023-02-20",
            deadline: "2023-12-12",
            plan: "",
            notes: '',
            status: 0,
            status_value: 0,
        }
    }),
    computed:{
        ...performanceComputed,
        ...levelComputed,
        ...activityComputed,
        min_date(){
            return this.current_objective.start_date.split(' ')[0]; //remove hh:mm:ss
        },
        max_date(){
            return this.current_objective.deadline.split(' ')[0]; //remove hh:mm:ss
        },
        users(){
            return this.all_users.map( user => {
                return `${user.name} | ${user.email}`;
            })
        },
        units(){
            return this.keyResultUnit.map( unit => {
                return unit.name;
            })
        },
        current_objective(){
            //* returns the object of the current objective being worked with
            // either from the store or from the prop\
            if(this.objective == false){
                return this.current_objective_details;
            }
            else{
                return this.objective;
            }
            
            // let item =  this.objecive != false
            //     ? this.objective //* prop
            //     : this.current_objective_details //* vuex objectives

            // console.log('LOOK HERE', item);
            // return item;
        },
    },
    methods:{
        ...performanceMethods,
        save(){
            this.is_loading = true;
            let key_results = [ this.form_data ]
            this.saveKeyResultData({key_results: key_results}).then(()=>{
                this.is_loading = false;
                this.$emit('savedEvent');
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        updateStatus(new_value){
            this.form_data.status_value = new_value
        },
        // selectedCategory(item){
        //     this.form_data.category = item;
        // },
        selectedOwner(item) {
            let email = item.split('|')[1];
            let email_string = email.trim();
            let user = this.all_users.find( item => {
                return item.email == email_string
            });
            this.form_data.owner_user_id = user.id
        },
        selectedUnit(unit_name) {
            let unit = this.keyResultUnit.find( item => {
                return item.name == unit_name
            });
            this.form_data.unit_type_id = unit.id
        },

        getUnitTypeName(id){
            let unit = this.keyResultUnit.find( item => {
                return item.id == id
            });
            this.unit_query = unit.name
        },
        getOwnerName(id){
            let user = this.all_users.find( item => {
                return item.id == id
            });
            this.owner_query = `${user.name} | ${user.email}`
        },
        // getCategoryName(){
        //     this.category_query = this.form_data.category;
        // }
    },
    mounted(){
        this.form_data.id = this.edit_id;
        if(this.edit_id == -1){
            console.log('DEBUG', this.current_objective)
            this.form_data.milestone_objective_id = this.current_objective.id;
            //set default value for start / end
            if(this.current_objective.start_date == undefined){return;}
            this.form_data.start_date = this.current_objective.start_date.split(' ')[0]; //remove hh:mm:ss
            this.form_data.deadline = this.current_objective.deadline.split(' ')[0]; //remove hh:mm:ss

        }
        else{
            let found_kr = this.available_key_results.find( (item) => {
                return this.edit_id == item.id;
            });
            console.log(found_kr)
            this.form_data.name = found_kr.name;
            // this.unit_query = String(found_kr.unit_type_id);
            this.form_data.unit_type_id = found_kr.unit_type_id;
            this.form_data.owner_user_id = found_kr.owner_user_id;
            this.form_data.milestone_objective_id = found_kr.milestone_objective_id;
            this.form_data.start_date = found_kr.start_date.split(' ')[0];
            this.form_data.deadline = found_kr.deadline.split(' ')[0];
            this.form_data.plan = found_kr.plan;
            this.form_data.notes = found_kr.notes;
            this.form_data.status = found_kr.status;
            //this.form_data.category = found_kr.category != undefined ? found_kr.category : '';
            // sync status
            this.form_data.status_value = this.form_data.status

            this.getUnitTypeName(found_kr.unit_type_id);
            this.getOwnerName(found_kr.owner_user_id);
            this.getCategoryName();
            
        }
    },
}
</script>

<style scoped>
.krForm label{
    margin-bottom: 0.1rem;
    margin-top: 0.2rem;
}
</style>