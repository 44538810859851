<template>
    <div> <!-- container renders the left and right arrows with graph in the middle -->
        <Container @navigatePrevious="handlePrevious" @navigateNext="handleNext">
            <template #graph>
                <apexchart
                    :type="'line'"
                    :width="'100%'"
                    height="100%"
                    :options="chartOptions"
                    :series="chartSeries"
                />
            </template>
        </Container>
    </div>
</template>

<script>
import Container from './graphContainer.vue'
import { format } from 'date-fns';
// eslint-disable-next-line
import ApexCharts from 'apexcharts';
export default {
    components:{
        Container
    },
    data:() => ({
        counter: 2,
    }),
    computed:{
        chartOptions(){
            return {
                chart: { id: 'RatingChart'},
                xaxis:{
                    categories: this.getCategories,
                }
            }
        },
        chartSeries(){
            return [
                {
                    name: 'Ratings',
                    data: this.getSeries,
                },
            ]
        },
        getCategories(){
            let days = [];
            for(let i = 0; i <= this.count; i++){
                days.push(this.createDate(i))
            }
            return days;
        },
        getSeries(){
            let series = [];
            for(let i = 0; i <= this.count; i++){
                series.push(Math.floor(Math.random() * this.count))
            }
            return series;
        }
    },
    methods:{
        createDate(string){
            let date = new Date(2022, 6, string, 0, 0, 0, 0).getTime();
            return format(date, 'dd MMM yy')
        },
        handleNext(){
            if(this.counter <= 22){
                this.counter + 1
            }
            else{
                this.counter = 1;
            }
            //ApexCharts.exec('')
        },
        handlePrevious(){
            if(this.counter > 1){
                this.counter - 1;
            }
            else{
                this.counter = 22;
            }
        }
    },
    mounted(){},
}
</script>

<style>

</style>