<template>
    <div>
        <b-row v-if="addMode">
            <b-col>
                <AddEmployee @back="handleBack" :activeEmployeeId="activeEmployeeId"></AddEmployee>
            </b-col>
        </b-row>
        <b-row v-if="activeEmployeeId == -1 && !addMode">
            <b-col cols="12">
                <!-- cards for level employees -->
                <EmployeeCards/>
            </b-col>
            <EmployeesTable v-if="false" @toggleAddMode="toggleAddMode"></EmployeesTable>
        </b-row>
        <b-row v-if="activeEmployeeId> -1 && !addMode">
            <b-col>
            <!-- added the v-if so that the employee
            details component only processes its data when a user is selected  old-- v-if="active_employee_data != null"-->
            <EmployeeDetails  :existing_user="true" :activeEmployeeId="activeEmployeeId" :addMode="addMode"></EmployeeDetails>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash';
import EmployeesTable from './components/employees/employeesTab.vue'
import EmployeeDetails from './components/employees/employeeDetails.vue'
import AddEmployee from './components/employees/addEmployee.vue'
import EmployeeCards from './components/employees/employeeCards.vue' 
import {
    peopleComputed, levelComputed, peopleMethods
} from "@/state/helpers";

export default {
    name: "Employees",
    components: {
        EmployeesTable,
        EmployeeDetails,
        AddEmployee,
        EmployeeCards,
    },
    data:() => ({
        activeEmployeeId: -1,
        addMode: false
    }),
    watch:{
        addMode(newValue){
            if(newValue == true){
                this.activeEmployeeId = -1;
            }
        },
        'selected_user_id'(newValue){
            if(newValue != null){
                //window.webix.$$('employeesTable').select(newValue)
                this.setActiveEmployee(newValue)
            }
            else{
                this.activeEmployeeId = -1;
            }
            
        },
        /*activeEmployeeId(){
            alert(this.activeEmployeeId)
        }*/
    },
    computed: {
        ...peopleComputed,
        ...levelComputed,
        active_employee_data() {
            let data = null;
            if(this.activeEmployeeId != -1) {
                let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                if(index != -1) {
                    data = this.levelEmployeesData[index];
                }
            }
            return data;
        }
    },
    methods: {
        ...peopleMethods,
        setActiveEmployee(id) {
            //if (this.addMode) this.addMode = false;
            this.activeEmployeeId = id;
        },
        toggleAddMode(state) {
            this.addMode = state;
        },
        handleBack(){
            this.toggleAddMode(false);
        }
    },
    mounted() {
        if(this.$route.params.user_id != undefined){
            this.setActiveEmployee(this.$route.params.user_id);
        }
        else if(this.selected_user_id != null){
            this.setActiveEmployee(this.selected_user_id);
        }
        this.$eventHub.$on("setActiveEmployee", this.setActiveEmployee);
    },
    beforeDestroy(){
        if(this.selected_level) {
            this.loadEmployeesData(this.selected_level.id)
        }
        this.$eventHub.$off("setActiveEmployee");
    }
}
</script>

<style>

</style>