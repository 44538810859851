<template>
    <b-modal id="unitTypesModal" hide-footer title="Manage Unit Types" style="height: 100%;">
            <Table
                v-if="show_table"
                :columns="columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="units"
                default_table_mode="edit"
                :is_loading="is_loading"
                :context_menu_items="['View', 'Edit', 'Delete']"
                prevent_first_row_select
                :pager_config="10"
                @saveTable="saveTable"
                @updateViewMode="updateViewMode"
                @onDataUpdate="onChangesMade"
                @deleteRows="deleteRow"
                />
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue';
import { activityComputed, activityMethods } from '@/state/helpers';
export default {
    data:() => ({
        show_table: false,
        table_view_mode: 'view',
        is_loading: false,
        delete_array:[],
        changes_made_flag: false,
        table_uid: 'unitTypesTable',

        search_text: '',
    }),
    watch:{
        search_text(text){
            window.webix.$$('unitTypesTable').filter("#name#", text);
        }
    },
    components:{
        Table,
    },
    computed:{
        ...activityComputed,
        units(){
            console.log(this.keyResultUnit);
            if(this.keyResultUnit){
                let data = [];
                this.keyResultUnit.forEach( (elem) => { 
                    data.push({ 'id': elem.id, 'name': elem.name })
                });
                return data;
            }
            else{
                return [];
            }
        },
        columns(){
            //var el = this;
            let columns = [
                { id: 'name', header: 'Unit Type', editor: 'text', fillspace: 1}
            ]
            return columns;
        }
    },
    methods:{
        ...activityMethods,
        updateViewMode(type){
            this.table_view_mode = type;
        },
        saveTable(){
            window.webix.$$(this.table_uid).editStop();
            this.$nextTick(()=>{
                let data = window.webix.$$(this.table_uid).serialize(null, true);
                let new_rows = data.filter( item => !item.is_empty);
                new_rows.forEach(element => {
                    if(element.is_new){
                        element.id = -1;
                    }
                });

                let units = new_rows.map( row => {
                    return { id: row.id, unit: row.name }
                })
                let params = {
                    units: units,
                }
                console.log(params)
                this.is_loading = true;
                this.saveUnitTypes(params).then(()=>{
                    this.getKeyResultUnitAPI()
                    .then(()=>{
                        this.is_loading = false;
                        this.$bvModal.hide('unitTypesModal');
                    })
                })
                /*let promises = [];
                new_rows.forEach( row => {
                    promises.push( this.addNewUnitType({unit: row.name}));
                })*/

                //this.is_loading = true;
            })
        },
        showDeletePrompt(){
            Swal.fire({
                    title: "Delete selected Units?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.deleteRow()
                    }
                });
        },
        deleteRow(data){
            let params = [];
            this.is_loading = true;
            // let used = false;
            // this.targets.forEach(mainItem => {
            //         data.forEach( item => {
            //             if(Number(mainItem.unit_type) == item.id) {
            //                used = true
            //             }
            //     })
               
            // })
            // if(!used) {

            // }
            data.forEach( item => { params.push(item.id)})
            this.deleteUnitTypes(params)
            .then( async ()=>{
                this.getKeyResultUnitAPI()
                .then(()=>{
                    this.is_loading = false;
                    this.table_view_mode = 'view'
                    this.$refs[this.table_uid].table_mode = 'view';
                    //this.$bvModal.hide('unitTypesModal');
                })
            })
            .catch( async (error) => {
                console.log('ERROR in ux',error.response)
                const keys = Object.keys(error);
                for(let i = 0; i< keys.length; i++){
                    let id = keys[i]; // id used as object's key
                    let unit = this.units.find( u => u.id == id);
                    if(unit){
                        switch(error[id]){
                            case 'has_kpis':{
                                Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Key Performance Indicators`);
                                break;
                            }
                            case 'has_operational_metrics':{
                                Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Operational Metrics`);
                                break;
                            }
                            case 'has_key_results':{
                                Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Operational Metrics`);
                                break;
                            }
                        }
                    }
                }
                //let msg = error.data;
                //const keys = Object.keys(msg);
                //for(let i = 0; i< keys.length; i++){
                //    let message_text = '';
                //    let key = keys[i];
                    /*switch(msg[key]){
                        case 'has_children':{
                            message_text = 'This objective has linked children Objectives';
                            break;
                        }
                        case 'has_activities':{
                            message_text = 'This objective has linked Activity Plans';
                            break;
                        }
                        case 'has_key_results':{
                            message_text = 'This objective has linked Key Results';
                            break;
                        }
                    }*/
                //    console.log(key, message_text);
                    /*if(msg[key] !== 'success'){
                        await this.handleRowDelete(key, message_text);
                    }*/
                //}
            })
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
    },
    mounted(){
        if(this.units.length == 0){
            this.table_view_mode = 'edit'
        }
        this.$nextTick(()=>{
            this.show_table = true;
        })
    },
}
</script>

<style>

</style>