<template>
<div>
    <div>
        <b-row>
            <b-col cols="7">
                <OnboardingHiresTable ></OnboardingHiresTable>
            </b-col>
            <b-col cols="5" v-if="activeHiringPositionId > -1">
                <OnboardingCandidatesTable ></OnboardingCandidatesTable>
            </b-col>
        </b-row>
        <hr v-show="activeHiringPositionId > -1">
        <b-row v-if="activeHiringPositionId > -1">
           <OnboardingChecklistTable :activeCandidateId="activeCandidateId"></OnboardingChecklistTable>
        </b-row>
    </div>
</div>
</template>
<script>
import OnboardingHiresTable from './components/onboarding/onboardingHiresTable.vue'
import OnboardingCandidatesTable from './components/onboarding/onboardingCandidatesTable.vue'
import OnboardingChecklistTable from './components/onboarding/onboardingChecklistTable.vue'
import _ from 'lodash';
import {
    peopleComputed, peopleMethods, levelComputed,
} from "@/state/helpers";

export default {
    name: "Admin",
    components: {
        OnboardingHiresTable,
        OnboardingCandidatesTable,
        OnboardingChecklistTable,
    },
    computed:{
        ...peopleComputed,
        ...levelComputed,
        active_job_data(){
            let data = null;
            if(this.activeJobId != -1){
                let index = _.findIndex(this.levelOnboardingData, {id:Number(this.activeJobId)});
                if(index != -1){
                    data = this.levelOnboardingData[index];
                }
            }
            return data;
        }
    },
    methods:{
        ...peopleMethods,
        /*setActiveJob(id) {
            this.activeJobId = id;
            this.activeCandidateId = -1;
        },
        setActiveCandidate(id) {
            this.activeCandidateId = id;
        },*/
        resetOffboarding(){
            this.setActiveCandidateId(-1);
            this.setActiveHiringId(-1);
        }
    },
    mounted() {
        this.loadOnboardingData(this.current_level_id)
        /*this.$eventHub.$on("setActiveJobOnboarding", this.setActiveJob);
        this.$eventHub.$on("setActiveCandidateOnboarding", this.setActiveCandidate);*/
        this.$eventHub.$on("newLevelSelected2", this.resetOffboarding);

    },
    beforeDestroy(){
        /*this.$eventHub.$off("setActiveJobOnboarding");
        this.$eventHub.$off("setActiveCandidateOnboarding");*/
        this.$eventHub.$off("newLevelSelected2");
        

    }

}
</script>
