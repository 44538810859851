<template>
        <div>
            <div class="d-flex flex-wrap mb-0" style="width: 100%;">
                <div class="me-2">
                    <h5 class="card-title mr-2 d-flex align-items-center">
                        Jobs
                        &nbsp;&nbsp;>&nbsp;&nbsp;
                        <span class="text-info">{{ levelName }}</span>
                    </h5>
                </div>

                <FilterComponent
                    class="ms-auto"
                    @searchText="searchText"
                    @searchDate="searchDate"
                    @resetFilter="resetFilter"
                    />
                <!-- buttons relating to values emitted from table -->
                <span></span>
                <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class=" me-3 dropdown-menu-end">
                    <strong>Save Table Changes</strong>
                </b-button>
                <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class=" me-3 dropdown-menu-end">
                    <strong>Delete Selected Rows</strong>
                </b-button>
                <!-- ------ -->

                <!-- -- dropdown -- -->
                <b-dropdown text="Button text via Prop" variant="white" toggle-tag="a" menu-class="dropdown-menu-end" toggle-class="font-size-16 p-0" right>
                    <template #button-content>
                        <i class="mdi mdi-dots-horizontal"></i>
                    </template>

                    <b-dropdown-item v-if="isViewMode == false" variant="primary" href="#" @click="toggleViewMode()" >
                        View Mode
                    </b-dropdown-item>

                    <b-dropdown-item v-if="isEditMode == false" variant="primary" href="#" @click="toggleEditMode()" >
                        Edit Mode
                    </b-dropdown-item>

                    <b-dropdown-item v-if="isDeleteMode == false" variant="danger" href="#" @click="toggleDeleteMode()" >
                        Delete Mode
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <!-- content -->
            <div>
            <b-alert :show="hiringData.length == 0" variant="warning">
                <strong>
                    No emplyees added for hiring
                </strong>
            </b-alert>
                <div>
                    <Table
                    :uid="table_uid" 
                    :is_loading="is_loading"
                    :data="hiringData"
                    :columns="columns"
                    :table_mode="table_view_mode"
                    :button_methods="returnOnClickMethods()"
                    :only_editable_columns="true"
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    :pager_config=5
                    :active_id="activeHiringPositionId"
                    :validation="table_validation_rules"
                    :sticky_columns=2
                    @updateViewMode="updateViewMode"
                    @selectedRowEvent="selectedRowEvent"
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                    />
                </div>
            </div>
        </div>
</template>

<script>
import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import _ from 'lodash';
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Table from '../../../widgets/table.vue'//* reusable table component
import Swal from "sweetalert2";
//eslint-disable-next-line
import { format, parseISO } from "date-fns";
export default {
    data:() => ({
        //hiringData:[], //* array of table rows
        changes_made_flag: false,

        //* props for webix table
        table_uid: 'hiringTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,

        //*filter
        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },
        //* -----
        company_level_id: null,
    }),
    props:{
        job_category:{}
    },
    components:{
        FilterComponent,
        Table, //Reusable webix table
    },
    watch: {
        storeHiringData() {
            //this.mapHiringData();
        },
        /*company_level_id:{
            immediate: true,
            handler(new_value){
                if(new_value != null){
                    this.load
                }
            }
        }*/
    },
    
    computed:{
        //* make vuex stores available
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        dropdownClassFix(){
            //* fix buttons position when displaying before the ... dropdown menu
            //changes_made_flag ? '' : 'ms-auto'
            if(this.changes_made_flag == true || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },

        //* computed prop for table
        columns(){
            let el = this;
            //* array of column items as objects
            return [
                { id: 'progress', header: 'Progress', width: '100'/*fillspace: 1,*/,
                    template: (obj)=>{
                        if(obj.is_new == undefined && obj.is_empty == undefined){
                            return `<div class="bar mt-1"><div class="bar-inner" style="width: ${obj.status}%"></div></div>`
                        }
                        return '';
                    }
                },
                { id: 'job_title', header: 'Job Title', width: '120', editor: "combo", options: el.availableJobs},//fillspace: 1
                { id: 'job_company', header: 'Company', width: '140', editor: "text",},//fillspace: 0.8
                { id: 'job_country', header: 'Country', width: '120', editor: "text",},//fillspace: 0.8
                { id: 'job_region', header: 'Region', width: '100', editor: "text",},//fillspace: 0.6
                { id: 'job_province', header: 'Province', width: '100', editor: "text",},//fillspace: 0.6
                { id: 'job_address', header: 'Address', width: '100', editor: "text",},//fillspace: 0.6
                { id: 'job_city', header: 'City', width: '100', editor: "text",},//fillspace: 0.6
                {
                    id: "level_id",
                    editor: "combo",
                    header: "Hierarchy",
                    /*fillspace: 1.5,*/
                    width: '120',
                    options: el.hierarchy,
                    //collection: this.hierarchy,
                    css: { "text-align": "left", "word-wrap": "break-word" },
                },
                //{ id: 'hierarchy', header: 'Hierarchy', fillspace: 1, editor: 'combo', options: this.hierarchy},
                { id: 'basic_from', header: 'Basic From', width:'100'/*fillspace: 1*/ , editor: "text",format:function(value){
                    if( value !== ''){
                        return window.webix.i18n.priceFormat(value);
                    }
                    else{
                        return '';
                    }
                }},
                { id: 'basic_to', header: 'Basic To', width:'100'/*fillspace: 1*/, editor: "text", format:function(value){
                    if( value !== ''){
                        return window.webix.i18n.priceFormat(value);
                    }
                    else{
                        return '';
                    }
                }},
                { id: 'bonus', header: 'Bonus', width:'100'/*fillspace: 1*/, editor: "text", format:function(value){
                    if( value !== ''){
                        return window.webix.i18n.priceFormat(value);
                    }
                    else{
                        return '';
                    }
                }},
                { id: 'start_date', header: 'Start Date', width:'100'/*fillspace: 1*/, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }

                },
                { id: 'report_to_user_id', header: 'Reporting To', width:'100'/*fillspace: 1*/, editor: "combo", options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'status', header: 'Status', width:'70'/*fillspace: 1*/, editor:'combo',
                    options: [
                    { id: '0', value: '0%'},
                    { id: '25', value: '25%'},
                    { id: '50', value: '50%'},
                    { id: '75', value: '75%'},
                    { id: '100', value: '100%'},
                ]}
            ]
        },
        table_validation_rules(){
            return {
                "job_title": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "job_company": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "job_country": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "job_region": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "job_province": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "job_city": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "basic_from": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "basic_to": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "bonus": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "report_to_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                /*"status": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },*/
                "level_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "start_date": function(item, obj){
                    if(item == {} || obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
        availableJobs(){
            return this.companyJobs.map(item => ({
                id:item.id,
                value: item.title,
            }));
        },

        storeHiringData() {
            let data = _.cloneDeep(this.levelHiringData);
            data.forEach(item => {
                item['hierarchy'] = item.level_id;
            })
            return data; 
        },

        levelName() {
            return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },

        isViewMode(){
            if(this.table_view_mode != 'view'){ return false; }
            else{ return true; }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){ return false; }
            else{ return true; }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){ return false; }
            else{ return true; }
        },
        hierarchy(){
            let data = [{id: 'null', name: 'Not Linked'}];
            this.levels.forEach( l =>{
                if(l.id == this.selected_level.id || l.parent_id == this.selected_level.id){
                    data.push(l);
                }
            })
            let final = []
            data.forEach( level => {
                final.push({
                    id: level.id,
                    value: level.name
                });
            })
            return final;
        },
        /*hierarchy() {
            let levels = [];
            levels = this.levels.map((level) => {
                //* hetHierarchy returns the level name for the given id
                return { id: level.id, value: this.getHierarchy(level.id) };
            });
            return levels;
        },*/

        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        hiringData(){
            //return this.storeHiringData.map(item => ({...item}));
            return this.storeHiringData.map( item => ({
                'id': item.id,
                'job_title': item.job_id,
                'job_company': item.position_company,
                'job_country': item.position_country,
                'job_region': item.position_region,
                'job_province': item.position_province,
                'job_address': item.position_address,
                'job_city': item.position_city,
                "level_id": item.level_id,
                'basic_from': item.basic_from,
                'basic_to': item.basic_to,
                'bonus': item.bonus,
                'start_date': item.start_date,
                'report_to_user_id': item.report_to_user_id,
                'status': item.status,
            }))
        },
    },
    methods:{
        //* vuex methods
        ...peopleMethods,
        
        mapHiringData() {
            //* return hiring data from the vuex store
            this.hiringData = this.storeHiringData.map(item => ({...item}));
        },
        returnOnClickMethods(){
            //* use button class name from column as variable name here
            let onClick = {
                //eslint-disable-next-line
                'unlink_button':function(e, id){
                    console.log('On button click', id);
                }
            };
            return onClick;
            
        },


        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },

        saveTable(){
            window.webix.$$(this.table_uid).editStop();
            this.$nextTick(()=>{
                //* Get all rows that contain valid data
                let table = window.webix.$$(this.table_uid);

                let result = window.webix.$$(this.table_uid).validate();
                if(result != true){
                    Swal.fire("Invalid rows found.", "", "warning")
                }
                else{
                    const all_data = table.serialize(true);
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        if(!row.is_empty){
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? -1 : Number(row.id),
                                job_id: Number(row.job_title),
                                level_id: this.selected_level.id,
                                position_company: row.job_company,
                                position_country: row.job_country,
                                position_city: row.job_city,
                                position_region: row.job_region,
                                position_province: row.job_country,
                                position_address: row.job_address,
                                status: row.status,
                                start_date: this.formatDateForPost(row.start_date),
                                basic_from: row.basic_from,
                                basic_to: row.basic_to,
                                bonus: row.bonus,
                                report_to_user_id: Number(row.report_to_user_id),
                                report_to_user_name: this.findNameForID(row.report_to_user_id)
                            };
                            rows_to_save.push(params);
                        }
                    })
                    //* Create and resolve an array of promises
                    let jobs_params = {
                        hiring_positions: rows_to_save,
                    }
                    this.is_loading = true;
                    this.saveHiringPosition(jobs_params)
                    .then(()=>{
                        //this.loadHiringData(this.selected_level.id)
                        //.then(()=>{
                            this.toggleViewMode();
                            this.loadHiringData(this.selected_level.id)
                            .then(()=>{
                                this.is_loading = false;
                            })
                        //})
                    })
                }
            })
            
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected jobs?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(){
            let was_active_id_deleted = false;
            let promises = [];
            this.delete_array.forEach( item => {
                let params = {id: item.id};
                promises.push(this.deleteHiringPosition(params));
                if(item.id == this.activeHiringPositionId){
                    was_active_id_deleted = true;
                }
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                if(was_active_id_deleted){
                    //* clear active id
                    this.$eventHub.$emit('setActiveJobHiring', -1);
                }
                this.loadHiringData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            })
        },


        //* table events start -----
        forceEditMode(){
            this.table_view_mode = 'edit';
        },
        updateViewMode(type){
            this.table_view_mode = type;
        },
        selectedRowEvent(row){
            //! job_title == item id.. from combo box
            if(row.id != this.activeHiringPositionId){
                //* Event returns the selected row
                //* set the active job in the hiring.vue component
                this.setActiveHiringId(row.id);
                //this.$eventHub.$emit('setActiveJobHiring', row.id);
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------

        //* filter component
        searchText(value) {
            this.filterText = value.toLowerCase();
        },
        searchDate(value) {
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
        },

        setCompanyLevelId(){
            return new Promise( resolve => {
                this.$nextTick(()=>{
                    const tree = window.webix.$$('levelsTree');
                    let item = tree.getItem(this.selected_level.id);
                    let top_level_id = -1;
                    if(item.parent_id === null){
                        //* current level is company level
                        top_level_id = item.id;
                    }
                    else{
                        top_level_id = tree.getParentId(this.selected_level.id);
                        let found = false;
                        while(!found){
                            let new_parnet_id = tree.getParentId(top_level_id);
                            if(new_parnet_id === 0){
                                found = true;
                            }
                            else{
                                top_level_id = new_parnet_id;
                            }
                        }
                    }
                    this.company_level_id = top_level_id;
                    resolve();
                })
            })
        },
        findNameForID(id){
            let index = _.findIndex(this.userList, {id: id});
            if(index != -1){
                return this.userList[index].value;
            }
            else{
                return 'User Not Found'
            }
        },
        formatDateForPost(date){
            let new_date = '';
            if(typeof date  === 'string'){
                new_date = new Date(date);
                new_date = format(new_date, 'yyyy-MM-dd');
            }else{
                new_date = format(date, 'yyyy-MM-dd');
            }
            return new_date;
        }
    },
    mounted(){
        //* init table data
        /*if(company_level_id == null){
            this.loadCompanyJobs
        }
        this.loadHiringData(this.selected_level.id)
        .then(()=>{
            this.mapHiringData();
        })*/
        if(this.selected_level != null){
            this.setCompanyLevelId()
        }
        
    },
}
</script>

<style lang="scss" scoped>
    ::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}
</style>