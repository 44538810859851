<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-03T18:57:08+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-04T16:15:27+02:00
@Copyright: Diversitude 2021
-->


<!-- //* Display key results graph and modal -->
<template>
    <div class=""> <!-- :style="maxHeight" -->
        <b-alert variant="warning" :show="hasUnitTypes">
            No unity types found, please add unit types from the right hand menu before adding Key Results
        </b-alert>
        <Table
            :active_id="selectedID"
            :uid="table_uid"
            :ref="table_uid"
            :is_loading="is_loading_kpis"
            :data="key_results"
            :default_values="default_values"
            :columns="columns"
            :simple_columns="simple_columns"
            :table_mode="table_view_mode"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :only_editable_columns="true"
            :validation="table_validation_rules"
            :compact_mode="show_kpis"
            :ignore_edit_only_rules="['id','linked_objective_id', 'parent_kr']"
            :button_methods="button_methods"
            re_order
            has_header_filters
            :hide_action_buttons="show_kpis"
            :disable_action_buttons="areMultipleObjectivesSelected"
            :variable_watchers="variable_watchers"
            :allow_duplication_feature="true"
            :child_uid="'krKpiTable'"
            
            prevent_first_row_select
            @handleDuplicateionItemsInsert="handleDuplicateionItemsInsert"
            @updatedOrderEvent="onRowIndexUpdate"
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @onDataUpdate="onChangesMade"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            enforce_structure_checks
            @saveTable="saveTable"
            :edit_permissions="{manager:'key-results-manager', user:'key-results'}"
            @deleteRows="deleteRow"
            :required_permissions="['key-results', 'key-results-manager']"
        >
            <template #title>
                {{ titleName }}
                &nbsp;&nbsp;>&nbsp;&nbsp;
                <span v-if="selected_kr != null" class="text-primary" >{{ selected_kr.name }}</span>
                <span v-else class="text-danger"  >No Key Result Selected</span>
            </template>
            <template #view-mode>
                <KeyResultBootstrapTable
                    :rows="key_results"
                    :selected_kr_id="-1"
                    hide_extra_items
                />
            </template>
            <template #buttons>
                <!-- <b-button variant="primary" size="sm" @click="showKeyResultPlan()">
                    Show KR Plan
                </b-button> -->

                <!-- <span class="btn-group me-2">
                    <b-button @click="toggleTableView()" size="sm" :variant="!show_table ? 'primary' : 'light'"  v-b-tooltip.hover.bottomright title="Graph View" >
                        <font-awesome-icon icon="fa-chart-area"></font-awesome-icon>
                    </b-button>
                    <b-button @click="toggleTableView()" size="sm" :variant="show_table ? 'primary' : 'light'"  v-b-tooltip.hover.bottomright title="Table View">
                        <font-awesome-icon icon="fa-table"></font-awesome-icon>
                    </b-button>
                </span>
                <b-button :disabled="key_results.length == 0 || is_editing" v-show="is_parent_level == false" :variant="show_kpis ? 'warning' : 'primary'" @click="show_kpis = !show_kpis" size="sm" :class="show_table ? 'me-2' : ''" >
                    <span v-show="show_kpis">Hide Kpis</span>
                    <span v-show="!show_kpis">Show Kpis ({{getKpiCount}})</span>
                </b-button> -->
            </template>
            <template #extra-items>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showUnitTypeModal">
                    <i class="fas fa-wrench me-1"></i>Manage Unit Types
                </b-dropdown-item>
            </template>
        </Table>
            <ObjectiveModal
            v-if="show_objective_modal"
            :payload="modal_payload"
            not_objective
            @selectedNewID="handleObjectiveChangedEvent"
        />
        
        <UnitTypeModal v-if="show_units_modal"/>
        <LinkedKeyResultModal @selectedNewID="updateKrLinkedParent" :payload="modal_payload" v-if="show_linked_kr_modal" />
    </div>
</template>

<script>
import GraphNav from '@/components/widgets/graphNavigationContainer.vue'

import _ from "lodash";
// eslint-disable-next-line
import ApexCharts from 'apexcharts';
// import * as webix from "../../../webix-pro/webix";
// import ObjectiveDropdown from "@/components/objectives/components/objective-dropdown.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
//eslint-disable-next-line
import { format, parseISO } from "date-fns";
//eslint-disable-next-line
import { isAfter, isBefore, isEqual, endOfWeek, endOfMonth, endOfQuarter, endOfYear} from 'date-fns'
import {
    levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    adminComputed,
    cardHeightComputed,
    cardHeightMethods,
    webixTableState,
    uxToggleComputed,
    uxToggleMethods,
    performanceComputed,
    performanceMethods,
} from "@/state/helpers";
import Table from '@/components/widgets/table/table.vue'
import Swal from 'sweetalert2';
import LineGraph from '../../../widgets/objectives/keyResultsGraph.vue'
import CardHeader from '@/components/widgets/cardHeader.vue'

import ObjectiveModal from '../misc/objectiveModals/linkedObjectiveModal.vue'

import UnitTypeModal from '../misc/unitTypesModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import tableMixin from '@/mixins/tableHelpers.js';
import KeyResultBootstrapTable from './bootstrapTables/krBootstrapTable.vue'
import LinkedKeyResultModal from '../misc/linkedKeyResultModal.vue'

export default {
    mixins:[ dateMixin, tableMixin ],
    components: {
        //eslint-disable-next-line
        Guideline,
        // ObjectiveDropdown,
        Table,
        //eslint-disable-next-line
        LineGraph,
        //eslint-disable-next-line
        CardHeader,
        //eslint-disable-next-line
        GraphNav,
        ObjectiveModal,
        UnitTypeModal,
        KeyResultBootstrapTable,
        LinkedKeyResultModal,
    },
    props:{
        is_parent_level:{
            default: false,
        },
    },
    data: () => ({
        visible_graph_id: null,
        //* bool to expand b-collapse with graph inside
        show_table: false,
        show: true,
        is_editing: false, //* is graph in edit mode
        //* value emitted to show kpis in parent component columns 
        //show_kpis: false,
        //* table vars
        changes_made_flag: false,
        table_uid: 'keyResultsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,
        //* ----------

        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },

        show_units_modal: false,
        show_objective_modal: false,
        modal_payload: null,

        isFormError: false,
        isEditGuideline: false,
        isShow: {
        isCancel: false,
        isView: true,
        isEditingExisting: false,
        isEditingNew: false,
        isComment: false,
        isGuideline: false,
        },
        flip: true,
        /*form input data*/

        can_render_graph:true,

        show_kr_plan: false,

        show_linked_kr_modal: false,

    }),
    watch: {
        //eslint-disable-next-line
        selected_objective(newVal, oldVal){
            if(!newVal || !oldVal){
                this.is_editing = false;
                return;
            }
            else if(newVal.id != oldVal.id){
                this.is_editing = false;
                // hide dataset edit table when selection changes
                this.setGraphEditState({
                    type: 'kr',
                    state: false,
                });
                this.setGraphEditState({
                    type: 'kpi',
                    state: false,
                });
                this.setGraphEditState({
                    type: 'metric',
                    state: false,
                });
            }
        },
        key_results(items){
            if(items.length == 0){
                this.setGraphToggleState({
                    type: 'kpi',
                    state: false,
                })
            }
            if(this.$refs[this.table_uid].table_mode == 'edit'){
                this.$refs[this.table_uid].table_mode = 'view';
            }
        },
        selected_key_result(new_key_result){
            if(new_key_result == null){
                this.setGraphToggleState({
                    type: 'kpi',
                    state: false,
                });
            }
        },
        show_kpi_toggle(val){
            if(val){
                this.can_render_graph = false;
                this.$nextTick(()=>{
                    this.can_render_graph = true
                })
            }
        },
        show_kpis(){
            this.$nextTick(()=>{
                if(window.webix.$$(this.table_uid) != undefined){
                    //* fix webix table size 
                    window.webix.$$(this.table_uid).refresh();
                }
                if(this.show_kpis == true && this.show_table == false){
                    //*fix graph not resizing
                    setTimeout(()=>{
                        ApexCharts.exec(this.visible_graph_id, 'resetSeries', true);
                        ApexCharts.exec(`brushChart${this.visible_graph_id}`, 'resetSeries', true);
                    }, 200);
                }
                //this.setGraphToggleState({
                //    type: 'kpi',
                //    state: this.show_kpis,
                //});
            })
        },
        mode(newValue){
            if(newValue != null){
                this.show_table = newValue;
            }
        },
        show_table(state){
            this.$emit('toggleTableMode', state);
            if(state == true){
                this.$nextTick(()=>{
                    window.webix.$$(this.table_uid).refresh();
                })
            }
        },
        canShowHeaderFilters(){
            this.$nextTick(()=>{
                //* check if ref is valid (should be)
                if(this.$refs[this.table_uid]){
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
    },
    methods: {
        ...performanceMethods,
        ...activityMethods,
        ...levelMethods,
        ...cardHeightMethods,
        ...uxToggleMethods,
        showKeyResultPlan(){
            this.show_kr_plan = !this.show_kr_plan;
        },
        async toggleTableView(){
            if(this.show_table){
                //* only run if table is visible 
                if(await this.$hasUnsavedChanges(this.table_uid)){
                    //* stop if unsaved changes are found
                    return false;
                }
                else{
                    this.$refs[this.table_uid].table_mode = 'view'; 
                }
            }
            this.show_table = !this.show_table;
        },
        setVisibleGraphID(id){
            this.visible_graph_id = id;
        },
        handleEditState(state){
            this.is_editing = state;
        },
        /* graph navigation methods*/
        graphNext(){
            if(this.graph_index < this.key_results.length-1){
                let id = this.key_results[this.graph_index+1].id;
                this.selectedRowEvent({id: id});
            }
            else{
                let id = this.key_results[0].id;
                this.selectedRowEvent({id: id});
            }
        },
        graphPrevious(){
            if(this.graph_index > 0){
                let id = this.key_results[this.graph_index-1].id;
                this.selectedRowEvent({id: id});
            }
            else{
                let id = this.key_results[this.key_results.length-1].id;
                this.selectedRowEvent({id: id});
            }

            
            
        },
        //* table methods
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.$refs[this.table_uid].table_mode = 'view'; 
            }
            
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.$refs[this.table_uid].table_mode = type; 
                }
            });
        },
        saveTable(data){
            let rows_to_save = [];
            data.forEach( row => {
                let params = {
                    "status": row.status,
                    "unit_type_id": row.unit_type_id,
                    "owner_user_id": row.owner_user_id,
                    "id": row.is_new ? -1 : row.id,
                    "milestone_objective_id": this.current_objective_details.id,
                    "start_date": row.start_date,
                    "deadline": row.deadline,
                    "plan": row.plan,
                    "parent_id": row.parent_id ? row.parent_id : this.selected_kr.id,
                    "notes": row.notes,
                    "name": row.name
                }
                if(params.start_date != undefined && typeof params.start_date !== 'string'){
                    params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                }
                if(params.deadline != undefined && typeof params.deadline !== 'string'){
                    params.deadline = format(new Date(params.deadline), 'yyyy-MM-dd')
                }
                rows_to_save.push(params);
            })
            this.saveKeyResultData({key_results: rows_to_save})
            .then(()=>{
                this.$emit('savedEvent');
            })
        },
        saveTable2(data){
            //* if a row is new, need to fire off seperate endpoint to save share type
            //let update_share_types = [];
            let rows_to_save = [];
            data.forEach( row => {
                let params = {
                    milestone_objective_id: row.milestone_objective_id ? row.milestone_objective_id : this.selected_objective.id,//row.milestone_objective_id,
                    id: row.is_new ? -1 : row.id,
                    level_id: this.selected_level.id,
                    owner_user_id: row.owner_user_id,
                    name: row.name,
                    target: row.target,
                    status: '',
                    start_date: row.start_date,
                    deadline: row.deadline,
                    unit_type_id: row.unit_type_id,
                    graph_type: row.graph_type,
                    min_value: row.min_value,
                    max_value: row.max_value,
                    current_time_period: row.current_time_period,
                    graph_size: '12', //only one graph displays at a time, so force full width graph
                    show_cumulative: Number(row.show_cumulative),
                    show_cumulative_target: Number(row.show_cumulative_target),
                    notes: row.notes != null ? row.notes : '',
                }
                if(params.start_date != undefined && typeof params.start_date !== 'string'){
                    params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                }
                if(params.deadline != undefined && typeof params.deadline !== 'string'){
                    params.deadline = format(new Date(params.deadline), 'yyyy-MM-dd')
                }
                rows_to_save.push(params)

                //* here we 
                if(row.must_update_target != undefined && row.must_update_target == true){
                    this.handleTargetUpdate(row);
                }
                if(row['must_remove_old_target']){
                    this.handleTargetRemove(row);
                }
            })
            //if(data){
            //    return;
            //}
            let key_result_params = {
                key_results: rows_to_save
            }
            this.is_loading = true;
            this.saveKeyResult(key_result_params)
            .then(()=>{
                this.changes_made_flag = false;
                if(this.isParentLevel){
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                    })
                }
                else{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$nextTick(()=>{
                            if(this.selected_key_result){
                                this.selectedRowEvent({id: this.selected_key_result.id})
                            }
                        })
                    })
                }
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        handleTargetRemove(row){
            let delete_date = null;
            switch(row.current_time_period){
                case 'daily':{
                    delete_date = new Date(row.old_target_date);
                    break;
                }
                case 'weekly':{
                    delete_date = endOfWeek(new Date(row.old_target_date));
                    break;
                }
                case 'monthly':{
                    delete_date = endOfMonth(new Date(row.old_target_date));
                    break;
                }
                case 'yearly':{
                    delete_date = endOfYear(new Date(row.old_target_date));
                    break;
                }
            }
            delete_date = this.getSimpleDate(delete_date);
            //must delete old record here
            let index = _.findIndex(row.tracking, item => {
                console.log('must remove target',this.getSimpleDate(item.achieved_date),this.getSimpleDate(delete_date))
                return this.getSimpleDate(item.achieved_date) == this.getSimpleDate(delete_date);
            })
            if(index != -1){
                let params = {
                    key_result_id: row.id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(delete_date),
                        value: row.tracking[index].value, //preserve value
                        //* save target val as 0 if it is null
                        target: null, // remove target
                    }],
                }
                this.saveKeyResultTracking(params)
                .catch( e => {
                    console.log('Error updating graphs target in dataset table', e);
                })
            }
        },
        handleTargetUpdate(row){
            let last_date = null;
            switch(row.current_time_period){
                case 'daily':{
                    last_date = new Date(row.deadline);
                    break;
                }
                case 'weekly':{
                    last_date = endOfWeek(new Date(row.deadline));
                    break;
                }
                case 'monthly':{
                    last_date = endOfMonth(new Date(row.deadline));
                    break;
                }
                case 'yearly':{
                    last_date = endOfYear(new Date(row.deadline));
                    break;
                }
            }
            last_date = this.getSimpleDate(last_date);
            // check the racking array for an existing index for the deadline date
            //* if no index exists, then use 0 as the achieved value
            let tracking_index = -1;
            if(row.tracking){
                // row has tracking data
                tracking_index = _.findIndex(row.tracking, item => {
                    return this.getSimpleDate(item.achieved_date) == last_date;
                })
            }
            if(tracking_index != -1){
                // grab the existing tracking value and save new target
                let params = {
                    key_result_id: row.id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(last_date),
                        value: row.tracking[tracking_index].value,
                        target: row.target,
                    }],
                }
                this.saveKeyResultTracking(params)
                .catch( e => {
                    console.log('Error updating graphs target in dataset table', e);
                })
            }
            else{
                // tracking value doesn't exist, save new trackign value
                let params = {
                    key_result_id: row.id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(last_date),
                        value: '0',
                        //* save target val as 0 if it is null
                        target: row.target,
                    }],
                }
                this.saveKeyResultTracking(params)
                .catch( e => {
                    console.log('Error updating graphs target in dataset table', e);
                })
            }
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected key results?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(){
            let promises = [];
            this.delete_array.forEach( item => {
                let params = item.id;
                promises.push(this.deleteKeyResult(params));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.changes_made_flag = false;
                this.delete_array = [];
                if(this.isParentLevel){
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'; 
                    })
                }
                else{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.toggleViewMode();
                    })
                }
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        //* -------------

        //* table events start -----
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        selectedRowEvent(row){
            if(!row){
                this.setSelectedKeyResult(null);
                //this.$eventHub.$emit("selectedKeyResult", null);
                return;
            }
            //* Event returns the selected row
            let index = _.findIndex(this.key_results, { id: row.id });
            if (index != -1) {
                let key_result = _.cloneDeep(this.key_results[index]);
                this.setSelectedKeyResult(key_result);
                //this.$eventHub.$emit("selectedKeyResult", key_result);
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        handleDuplicateionItemsInsert(payload){//table_uid and target_id
            let index = this.duplicate_clipboard.findIndex( clipboard => {
                return payload.table_uid == clipboard.uid;
            })
            if(index == -1){
                alert('Error')
                return;
            }
            else{
                this.is_loading = true;
                let data = {
                    destination_type: 'objective',
                    destination_id: this.selected_objective.id,
                    sources: this.duplicate_clipboard[index].rows,
                    source_type:'key_result',
                }
                this.sendDuplicationRequest(data)
                .then(()=>{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.$swal.fire({
                            icon:'success',
                            title:'Success',
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        //eslint-disable-next-line
        onRowIndexUpdate(sortData){
            this.is_loading = true;
            this.updateRowOrder({type:'kr', sortData:sortData})
            .then(()=>{
                let params = {
                    //id: this.selected_level.id,
                    id: this.selected_objective.id,
                    user_id: this.activeUserId,
                }
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.is_loading = false;
                })
            })
        },
        //* table events end ------
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.name != undefined){
                    return (
                    obj.name
                        .toString()
                        .toLowerCase()
                        .indexOf(text) != -1
                    );
                }
            });
        },
        searchDate(value) {
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
        },
        update() {
            this.isEditGuideline = false;
            this.clearView();
            this.$refs.isEditingNew = false;
            this.$refs.isEditingExisting = false;
            this.isShow.isGuideline = true;
        },
        clearView() {
            this.isEditGuideline = false;
            Object.keys(this.isShow).forEach((item) => {
                this.isShow[item] = false;
            });
        },
        showUnitTypeModal(){
            this.show_units_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('unitTypesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "unitTypesModal") {
                        this.show_units_modal = false;

                        //* refresh the array of avaliable unit types
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.clearAll();
                        this.availableUnitTypes.forEach((item)=>{
                            window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.add(item);
                        })
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                        window.webix.$$(this.table_uid).refreshColumns();
                        window.webix.$$(this.table_uid).getColumnConfig('unit_type_id').collection.refresh();
                        window.webix.$$(this.table_uid).refresh();
                    }
                });
            })
        },
        showLinkedKRModal(payload){
            this.modal_payload = payload;
            this.show_linked_kr_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('linkedKeyResultModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "linkedKeyResultModal") {
                        this.show_linked_kr_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        showObjectiveModal(payload){
            if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }
            else{
                if(this.table_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'Objective'
                    this.modal_payload['parent_id'] = payload.milestone_objective_id;
                    this.modal_payload['parent_objective_id'] = payload.milestone_objective_id;
                    this.show_objective_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('linkedObjectiveModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                                this.show_objective_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            }
        },
        updateKrLinkedParent(newID){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['parent_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('linkedKeyResultModal')
        },
        handleObjectiveChangedEvent(newID){
            //* update the selected table item with the new selected objective id
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['milestone_objective_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('linkedObjectiveModal')//hierarchyModal
            //this.saveTable(true)
        },
    },
    computed: {
        ...levelComputed,
        ...activityComputed,
        ...adminComputed,
        ...cardHeightComputed,
        ...webixTableState,
        ...uxToggleComputed,
        ...performanceComputed,
        selected_kr(){
            return this.getSelectedKR;
        },
        hasUnitTypes(){
            if(this.keyResultUnit == null){
                return 0;
            }
            else{
                return this.keyResultUnit.length == 0
            }
        },
        
        canShowHeaderFilters(){
            if(this.table_states[this.table_uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        show_kpis:{
            get: function(){
                return this.show_kpi_toggle
            },
            set: function(newValue){
                this.setGraphToggleState({
                    type: 'kpi',
                    state: newValue,
                });
            }
        },

        areMultipleObjectivesSelected(){
            return this.selected_objectives.length > 0
        },

        availableActions(){
            if(this.show_kpis == true){
                return ['view'];
            }
            else{
                return ['edit', 'delete', 'view']
            }
        },
        availableUnitTypes(){
            if(this.keyResultUnit){
                return this.keyResultUnit.map( item => {
                    return {
                        id: item.id,
                        value: item.name
                    }
                })
            }
            else{
                return [];
            }
        },
        getKpiCount(){
            let count = 0;
            //if(this.key_result){
            //    count = this.key_result.kpis.length;
            //}
            return count;
        },
        getAvailableObjectives(){
            let names = [
                {id: 'null', value: "Not Linked"}
            ];
            
            if(this.activity_data.parent_objectives != undefined){
                this.activity_data.parent_objectives.forEach( item =>{
                    names.push(item);
                })
            }
            this.activity_data.objectives.forEach( item => {
                names.push(item);
            })
            
            return names;
        },
        maxHeight(){
            if(this.row2.kr == this.row2.kpi){
                switch(this.row2.kr){
                    case 'default':{
                        return 'height: 16rem;'
                    }
                    case 'dataset':{
                        return 'height: 17rem;'
                    }
                    case 'expanded':{
                        return 'height: 26rem;'
                    }
                    default:{
                        return ''
                    }
                }
            }
            else{
                //* find biggest size
                switch(this.row2.kr){
                    case 'default':{
                        if(this.row2.kpi == 'dataset'){
                            return 'height: 17rem;'
                        }
                        else{
                            return 'height: 26rem;'
                        }
                    }
                    case 'dataset':{
                        if(this.row2.kpi == 'expanded'){
                            return 'height: 26rem;'
                        }
                        else{
                            return 'height: 17rem;'
                        }
                    }
                    case 'expanded':{
                        return 'height: 26rem;'
                    }
                    default:{
                        return ''
                    }
                }
            }
        },
        hasKeyResults(){
            if(this.key_results.length == 0){
                return false;
            }
            return true;
        },
        graphIndexText(){
            let text = '';
            if(this.key_results.length){
                text = (this.graph_index + 1) + ' of ' + this.key_results.length;
            }
            return text;
        },
        isParentLevel(){
            let valid = false;
            if(this.selected_level != null){
                valid = this.selected_level.parent_id == null
            }
            return valid;
        },
        activeUserId(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
              //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        graph_index(){
            if(this.selected_key_result == null){
                return -1;
            }

            let index = _.findIndex(this.key_results, { id: this.selected_key_result.id});
            return index;
        },

        //* table computed props
        variable_watchers(){
            var el = this;
            return {
                start_date(newVal, oldVal, id, is_new_row){
                    let item = window.webix.$$(el.table_uid).getItem(id);
                    if(is_new_row == false){
                        //update flags for table save
                        item['must_update_target'] = true;
                        window.webix.$$(el.table_uid).updateItem(id, item);

                        let new_date = parseISO(newVal);
                        let old_date = parseISO(oldVal);
                        if(isBefore(new_date, old_date)){
                            el.$swal.fire('New values from the adjusted start date will have to be updated in this graphs dataset view.')
                        }
                        else if(isAfter(new_date, old_date)){
                            el.$swal.fire('Some data will be lost due to new starting date');
                        }
                    }
                    else{
                        //* for a new row, set deadline to start date
                        item.deadline = newVal;
                        window.webix.$$(el.table_uid).updateItem(id, item);
                    }
                },
                deadline(newVal, oldVal, id, is_new_row){
                    if(is_new_row == false){
                        let new_date = parseISO(newVal);
                        let old_date = parseISO(oldVal);
                        if(isBefore(new_date, old_date)){
                            let item = window.webix.$$(el.table_uid).getItem(id);
                            item['must_update_target'] = true;
                            Swal.fire({
                                title: `${item.name} - Deadline Decreased`,
                                text: `Would you like to flag the old tagret value for ${el.getSimpleDate(old_date)} to be deleted when saving the table?`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#34c38f",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                            }).then((result) => {
                                if (result.value) {
                                    item['must_remove_old_target'] = true;
                                    item['old_target_date'] = old_date;
                                }
                                else{
                                    window.webix.$$(el.table_uid).updateItem(id, item)
                                }
                            });
                        }
                        else if(isAfter(new_date, old_date)){
                            let item = window.webix.$$(el.table_uid).getItem(id);
                            item['must_update_target'] = true;
                            Swal.fire({
                                title: `Deadline extended for graph: ${item.name}`,
                                text: `Would you like to remove the target value for the old deadline date (${el.getSimpleDate(old_date)}) when saving the table?`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#34c38f",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                            }).then((result) => {
                                if (result.value) {
                                    item['must_remove_old_target'] = true;
                                    item['old_target_date'] = old_date;
                                    window.webix.$$(el.table_uid).updateItem(id, item)
                                }
                                else{
                                    window.webix.$$(el.table_uid).updateItem(id, item)
                                }
                            })
                            //el.$swal.fire('New values from the adjusted end date will have to be updated in this graphs dataset view.');
                        }
                    }
                },
                //eslint-disable-next-line
                unit_type_id(newVal, oldVal, id, is_new_row){
                    if(!is_new_row && newVal != oldVal){
                        el.$swal.fire('Changing the Unit Type will affect the shareability of this graph');
                    }
                },
                //eslint-disable-next-line
                target(newVal, oldVal, id, is_new_row){
                    let item = window.webix.$$(el.table_uid).getItem(id);
                    if(!is_new_row){
                        item['must_update_target'] = true;
                        item.max_value = newVal;
                        window.webix.$$(el.table_uid).updateItem(id, item)
                    }
                    else{
                        //* for new rows, set max value to target value
                        item.max_value = newVal;
                        window.webix.$$(el.table_uid).updateItem(id, item)
                    }
                },
            }
        },
        simple_columns(){
            let el = this;
            let columns = [
                { id: "name", header: this.canShowHeaderFilters ? ["Key Results", {content: "textFilter"}] : "Key Results",
                    fillspace: true, tooltip: '',},
                { id: "unit_type_id", header: "Unit Type", editor: "combo", tooltip: '',
                    options: el.availableUnitTypes,
                    minWidth: 150,
                },
                // { id: "show_cumulative", editor: "combo", header: "Achieved Entry", minWidth: 100,
                //     options: [
                //         { id: 'null', value: 'Please select an option'},
                //         { id: '0', value: "Discrete" },
                //         { id: '1', value: "Cumulative" },
                //     ],
                // },
                // { id: "show_cumulative_target", editor: "combo", header: "Target Type", minWidth: 100,
                //     options: [
                //         { id: 'null', value: 'Please select an option'},
                //         { id: '0', value: "Discrete" },
                //         { id: '1', value: "Cumulative" },
                //     ],
                // },
                { id: "deadline", header: "Deadline", sort: "date", minWidth: 120, tooltip: '',
                    format:function(value){
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "owner_user_id", header: "Owner", tooltip: '',
                    minWidth: 120,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: 'notes',
                    header: "Notes",
                    width: 60,
                    template:function(obj){
                        if(obj.is_new || obj.is_empty){
                            return '';
                        }
                        if(obj.notes == null || obj.notes == ''){
                            return '';
                        }
                        return `
                        <div style='text-align: center' class="notes_button">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                        </div>
                        `
                    }
                }
            ]
            return columns;
        },
        columns(){
            var el = this;
            return [
                { id: "name", compact: true, header: this.canShowHeaderFilters ? ["Key Results", {content: "textFilter"}] : "Key Results",
                    editor: "text", sort: "text", width: 400,},
                // { id: "target", header: "Target", editor: "text", width: 120},
                { id: "unit_type_id", header: "Unit Type", editor: "combo",
                    options: el.availableUnitTypes, width: 120
                },
                {
                    id: "parent_kr", header: 'Parent KR', width: 120, template(obj){
                        if(obj.is_empty ){
                            return '';
                        }
                        let name = el.current_objective_details.key_results.find( item => {
                            return item.id == obj.parent_id;
                        })
                        if(name){
                            return `<div class="cursor-pointer edit_linked_kr">${name.name}</div>`
                        }
                        else{
                            return `<div class="edit_linked_kr">Not Linked</div>`
                        }
                        
                    },
                },
                { id: "start_date", header: "Start", editor: "date", sort: "date", width: 130,
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "deadline", header: "Deadline", editor: "date", sort: "date", width: 130,
                    format:function(value){
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                
                { id: "owner_user_id", header: "Owner", sort: "text", editor: "combo", width: 200,
                    options: this.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: 'notes', editor: 'text', header: 'Notes', width: 150,
                }
            ]
        },
        default_values(){
            return [
                { id: "parent_id", value: this.selected_kr_id },
                //{ id : "current_time_period", value: 'daily' },
                //{ id : "graph_type", value: 'line' },
                //{ id : "show_cumulative", value: '0'},
                //{ id : "show_cumulative_target", value: '0'},
                //{ id : "min_value", value: 0},
                { id : "owner_user_id", value: this.core.id},
                //{ id : "milestone_objective_id", value: this.selected_objective != null ? this.selected_objective.id : -1},
                //{ id : "progress_data_source", value: 'data_input'}
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                //eslint-disable-next-line
                'edit_linked_kr':function(e, id){
                    let item = this.getItem(id)
                    el.showLinkedKRModal(item);
                },
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                //eslint-disable-next-line
                'notes_button':function(e, id){
                    let item = this.getItem(id)
                    let note_data = {
                        item: item,
                        can_edit: false,
                        table: 'keyResultPlan'
                    }
                    //el.notes_text = item.notes;
                    el.$eventHub.$emit('showNotesModal', note_data);
                },
            }
            return onClick;
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        //* --------------------
        titleName() {
            return "Linked Key Results";
        },
        objectiveName() {
            if(this.areMultipleObjectivesSelected){
                if(this.selected_key_result != null){
                    let objective = this.activity_data.objectives.find( item => {
                        return item.id == this.selected_key_result.milestone_objective_id;
                    })
                    if(objective){
                        return objective.name;
                    }
                }
                return ''
            }
            else if(this.selected_objective != null){
                return this.selected_objective.name;
                /*return this.selected_objective
                ? this.findObjectiveLevel(this.selected_objective.id)
                : "No Objective Selected";*/
            }
            return '';
        },
        key_results(){
            if(!this.getSelectedKR){
                return [];
            }
            else{
                return _.cloneDeep(this.getSelectedKR.children);
            }
        },
        key_results_expired() {
            let data = [];
            //check if multiple objectives are selected or one objective is selected
            let has_objective_data = this.multiple_selected_objective_details || this.selected_objective_details;
            //* OLD - this.selected_objective_details != null && this.selected_objective
            if (has_objective_data) {
                if(this.selected_objectives.length == 0){
                    //use the normal selected objective object
                    data = _.cloneDeep(this.selected_objective_details.key_results);
                }
                else if(this.multiple_selected_objective_details != null){
                    data = _.cloneDeep(this.multiple_selected_objective_details.key_results);
                }
                //let total = this.selected_objective_details.key_results?.length;
                if (data.length > 0) {
                    //data = _.orderBy(data, ["name"], ["asc"]);

                    //TODO - if KR is missing show_cumulative flag, set to false
                    //! - also fix existing key results that have invalid old graph_type value
                    data.forEach( (item, idx) => {
                        if(item.show_cumulative == undefined){
                            data[idx]['show_cumulative'] = 0;
                        }
                        if(item.graph_type !== 'line'){
                            data[idx].graph_type = 'line';
                        }
                        if(item.multi_line){
                            item.share_type = 'multi-line'
                        }
                    })
                    return data;
                }
            }
            //data = _.orderBy(data, ['item_order']);
            return data;
        },
        key_result() {
            //* single key result
            if (this.selected_key_result != null) {
                let kr = _.cloneDeep(this.selected_key_result);
                return kr;
            }
            else {
                return null;
            }
        },
        title() {
            if (this.selected_objective != null) {
                return this.selected_objective.name;
            }
            else {
                return "No Milestone Objective Selected";
            }
        },
        table_validation_rules(){
            let el = this;
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                // "target": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             const is_greater_than_0 = isNaN(item) == false && Number(item) > 0;
                //             const is_less_than_max_value = isNaN(obj.max_value) == false && Number(obj.max_value) >= Number(item);
                //             if(is_greater_than_0 && is_less_than_max_value){
                //                 return true;
                //             }
                //             else{
                //                 return false;
                //             }
                            
                //         }
                //     }
                // },
                "unit_type_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                // "graph_type": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                // "min_value": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                // "max_value": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "start_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.deadline){
                                return el.startDateCheck(obj.start_date, obj.deadline)
                            }
                            return true;
                        }
                    }
                },
                "deadline": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.start_date && obj.deadline){
                                return el.endDateCheck(obj.deadline, obj.start_date)
                            }
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                /*"status": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },*/
            };
        },
        selectedID(){
            return -1;
            // if(this.selected_key_result != null){
            //     return this.selected_key_result.id;
            // }
            // else{
            //     return -1;
            // }
        }
    },
    mounted() {
        //this.show_kpis = this.show_kpi_toggle;
        /*this.getKeyResultUnitAPI();*/
        /*if(this.selected_objective == null){
            this.show_table = true;
        }
        else if(this.selected_objective_details != null){
            if(this.selected_objective_details.key_results.length > 0){
                this.show_table = false;
            }
        }*/
        //* only show graphs if an objective exists
        /*if(this.key_results.length > 0){
            alert(1)
            this.show_graphs = false;
        }*/
    },
};
</script>

<style lang="scss" scoped>
::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}



::v-deep .selected-item {
  font-weight: bold;
  text-decoration: underline;
}

::v-deep .bg-red {
  background: rgb(255, 92, 76) !important;
}

::v-deep .border-red {
  border-color: rgb(255, 92, 76) !important;
}

.tooltipwrapper {
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 180px;
    transform: translate(0%, -100%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      left: 50px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

.limit_text {
  display: block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upper-text{
    text-transform:capitalize;
}
</style>
