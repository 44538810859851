import { render, staticRenderFns } from "./boardingChecklist.vue?vue&type=template&id=450860fe&scoped=true"
import script from "./boardingChecklist.vue?vue&type=script&lang=js"
export * from "./boardingChecklist.vue?vue&type=script&lang=js"
import style0 from "./boardingChecklist.vue?vue&type=style&index=0&id=450860fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450860fe",
  null
  
)

export default component.exports