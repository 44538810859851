<template>
    <div class="p-2">
        <h6 class="mb-2">User Role</h6>
        <div v-if="finishedLoading == false">
            <b-alert show>
                Loading Permission Data
            </b-alert>
        </div>
        <div v-else>
            <b-form inline class="d-inline-flex">
                <b-form-radio
                    v-for="role in system_roles.roles" :key="role.id"
                    v-model="permission_data.selected_role"
                    class="me-4"
                    :value="role.id"
                    plain
                    >{{role.name}}</b-form-radio
                >
            </b-form>
            <hr class="mt-1" />
            <b-alert variant="danger" :show="permission_data.selected_role == null">
                <font-awesome-icon class="fa-xl me-2" icon="fa-exclamation-triangle" />
                Select a user role before selecting permissions
            </b-alert>
            <b-overlay variant="white" :show="permission_data.selected_role == null">
                <template #overlay>
                    <span> </span>
                </template>
                <b-form  class="">
                    <b-row >
                        <b-col cols="4" v-for="(group, idx) in system_roles.permissions" :key="idx">
                            <b-card class="border border-light border-primary-hover" >
                                <label style="font-weight: bold; width: 100%;" for="">{{group.group_name}}</label>
                                <div class="checkbox-grid d-flex" style="flex-direction: column; flex-wrap: wrap; height: 5rem">
                                    <b-form-checkbox
                                        v-for="permission in group.group_permissions" :key="permission.id"
                                        v-model="permission_data.selected_permissions[permission.id]"
                                        :value=true
                                        :unchecked-value=false
                                        class="form-checkbox me-4"
                                    ><span class="w-100">{{permission.name}}</span></b-form-checkbox
                                    >
                                </div>
                            </b-card>
                        </b-col>
                        <b-col class="d-flex" cols="12">
                            <b-button v-show="hasAssignLevelPermission" class="me-2" @click="toggleLevelAccessSelection()" variant="primary" >Choose Level Access</b-button>
                            <b-button @click="saveRolesAndPerms()" variant="success">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-overlay>
        </div>
        <b-overlay :show="is_saving" no-wrap></b-overlay>
    </div>
</template>

<script>
import {
    levelComputed,
    levelMethods,
    peopleMethods,
    peopleComputed,
    adminComputed,
    adminMethods,
} from "@/state/helpers";
export default {
    data:() => ({
        table_uid: 'permissionsTable',
        permission_data:{
            selected_role: null,
            selected_permissions:{},
        },
        finishedLoading: false,
        is_saving : false,
        show_level_access_btn: false,
    }),
    watch:{
        'permission_data.selected_role'(value){
            if(this.finishedLoading){
                this.permission_data.selected_permissions = {};
                let role = this.system_roles.roles.find( role => role.id == Number(value));
                if(role){
                    role.permissions.forEach( perm => {
                        this.permission_data.selected_permissions[perm.id] = true;
                    })
                }
            }
        },
        'permission_data.selected_permissions'(value){
            this.show_level_access_btn = false;
            //* hardcoded the 'allowed-level-access' id
            if(value[2] != undefined && value[2] == true){
                this.show_level_access_btn = true
            }
        },
        'selected_user_id'(){
            this.finishedLoading = false;
            this.permission_data = {
                selected_role: null,
                selected_permissions:{},
            };
            this.loadComponentData();
        }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        hasAssignLevelPermission(){
            //let perms = this.permission_data.selected_permissions;
            //if(perms[2] && perms[2] == true){
            //    return true;
            //}
            //else{
            //    return false;
            //}
            //* if user has this perm then they can assign level access to others
            return this.$hasPerm('assign-level-access')
        },
        columns(){
            return [
                { id: 'name', header: 'Name', fillspace: 1},
                { id: 'edit', header: 'Edit',
                    template(object, common){
                        if(object.is_empty == undefined){
                            return common.checkbox(object, common, true, false);
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'view', header: 'View',
                    template(object, common){
                        if(object.is_empty == undefined){
                            return common.checkbox(object, common, true, false);
                        }
                        else{
                            return '';
                        }
                    }
                },
            ]
        },
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        ...adminMethods,
        saveRolesAndPerms(){
            this.is_saving = true;
            let payload = {
                user_id: this.selected_user_id,
                save_data:{
                    roles: this.permission_data.selected_role != null ? this.permission_data.selected_role : [],
                    user_permissions: [],
                    allowed_levels:[],
                }
            }
            //* find all permission keys with true as their value
            Object.entries(this.permission_data.selected_permissions).forEach(([key, value]) => {
                if(value){
                    payload.save_data.user_permissions.push(Number(key));
                }
            })
            //set allowed levels from webix tree
            if(this.allow_tree_selection){
                // get all selected checkbox items
                payload.save_data.allowed_levels = window.webix.$$('levelsTree').getChecked();
            }
            else{
                // just use existing array from vuex store
                payload.save_data.allowed_levels = this.selected_user_permissions.allowed_levels;
            }
            this.saveRolesAndPermissions(payload)
            .then(()=>{
                if(this.selected_user_id == this.core.id){
                    this.loadCoreData();
                }
                this.setLevelAccessSelectionFlag(false);
                this.loadComponentData();
                this.is_saving = false;
                this.$swal.fire({
                    icon: 'success',
                    title: 'Updated permissions',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                })
            })
            .catch(()=>{
                this.is_saving = false;
            })
        },
        setSelectedPermissionState(){
            //* set permissions before setting the finishedLoading flag (suppresses watcher event)
            if(this.selected_user_permissions.roles && this.selected_user_permissions.roles.length > 0){
                this.permission_data.selected_role = this.selected_user_permissions.roles[0].id;
            }
            if(this.selected_user_permissions.permissions){
                this.selected_user_permissions.permissions.forEach( perm => {
                    this.permission_data.selected_permissions[perm.id] = true;
                })
            }
        },
        toggleLevelAccessSelection(){
            // enable level selection from the webix tree menu to the left
            this.$swal.fire('Select the Structures this user can access from the Organisational Structure tree menu');
            this.setLevelAccessSelectionFlag(true);
        },
        handleTreeCheckboxes(){
            this.$nextTick(()=>{
                this.selected_user_permissions.allowed_levels.forEach( level_id => {
                    window.webix.$$('levelsTree').checkItem(level_id);
                })
            })
        },
        loadComponentData(){
            let promises = [
                this.loadSystemPermissions(),
                this.loadUserPermissions(Number(this.selected_user_id))
            ];
            Promise.all(promises)
            .then(()=>{
                this.setSelectedPermissionState();
                this.$nextTick(()=>{
                    this.finishedLoading = true;
                })
            })
        },
        
    },
    mounted(){
        this.loadComponentData();

        this.$eventHub.$on('treeCheckboxesEnabled', this.handleTreeCheckboxes);
    },
    beforeDestroy(){
        this.$eventHub.$off('treeCheckboxesEnabled');
        this.setLevelAccessSelectionFlag(false);
    }
}
</script>

<style>
.checkbox-grid>div>input{
    margin-right: 0.3rem;
}
.border-primary-hover:hover{
    border-color: #556ee6 !important;
}
.form-checkbox>label{
    cursor: pointer;
}
.form-checkbox>input{
    cursor: pointer;
}
</style>