<template>
    <div>
        <b-card v-if="awaiting_load == false" @click="selectItem" no-body class="newTile" :style="{...propBackground}" :class="[getSelectedClass]" >
            <template #header>
                <div class="d-flex" style="margin-top: -0.15rem;">
                    <span class="badge-item badge-item-objective" style="margin-top: -0.15rem;">

                    <b-overlay :show="canShowLoadingSpinner">
                        <RadialProgressBar size="md" class="mt-1" :percentage="item.progress"></RadialProgressBar>
                    </b-overlay>  
                    </span>

                        <strong class="sub-heading-text cursor-pointer" @click="selectItem(item)" >
                            <span  style="display: block;" class="title-text-header">{{ item.name}}</span>
                        </strong>
                    
                    <div v-if="hasOwnerUserId(item)" class="d-flex ms-auto ps-1">
                        <transition name="fade">
                            <div style="min-width: 12rem;">
                                <div class="d-flex px-2 small-text">
                                    <span class="ms-auto me-1">
                                        <span class="ms-1">{{ getLinkedLevel }}</span>
                                    </span>
                                </div>
                                <div class="d-flex px-2 small-text">
                                    <span class="ms-auto me-1">
                                        <span class="ms-1">{{ getDateFormat.start }}</span>
                                        <span class="text-secondary"> to </span>
                                        <span>{{ getDateFormat.end }}</span>
                                    </span>
                                </div>
                                <div class="d-flex px-2 small-text">
                                    <span class="ms-auto me-1">
                                        <span class="">
                                            <span v-if="daysToDeadline > -1">
                                                <b-badge class="bg-info bg-soft font-size-10 text-dark">
                                                    <strong>{{daysToDeadline}}</strong>
                                                    &nbsp;
                                                    Days
                                                </b-badge>
                                            </span>

                                            <span v-else>
                                                <b-badge class="bg-success font-size-10 bg-soft text-dark">
                                                    Deadline Reached
                                                </b-badge>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </transition>
                        <img :src="getImgSrc(item)" alt="" class="cursor-pointer avatar-xxs rounded-circle " v-b-tooltip.hover :title="ownerName(item)">
                        <span v-if="false" class="mt-auto">
                            <b-button style="margin-bottom: -0.6rem;" variant="ligght"  size="sm">
                                <font-awesome-icon icon="fa-comment"></font-awesome-icon>
                            </b-button>
                        </span>
                    </div>
                    
                </div>

            </template>

            <KeyResultBootstrapTable :rows="item.key_results" :objective="item"/>
            
        </b-card>

        <b-card v-else>
            <div class="d-flex">
                <span class="ms-auto me-auto">
                    Building Element
                </span>
            </div>
        </b-card>
    </div>
</template>

<script>
import { levelComputed, performanceMethods, performanceComputed , webixTableState } from '@/state/helpers'
import RadialProgressBar from '../components/tiles/radialProgressBar.vue'
import KeyResultBootstrapTable from '../components/cards/bootstrapTables/krBootstrapTable.vue'
import { format, parseISO} from 'date-fns'
import {findIndex} from 'lodash';
export default {
    components:{
        KeyResultBootstrapTable,
        RadialProgressBar,
    },
    props:{
        item:{
            required: true,
        },
        list_index:{
            required: true,
        }
    },
    data:() => ({
        awaiting_load: true,
    }),
    computed:{
        ...levelComputed,
        ...performanceComputed,
        ...webixTableState,
        daysToDeadline() {
            const today = new Date();
            const deadline = new Date(this.item.deadline);

            // Check if deadline is past
            if (deadline < today) {
                return -1;
            }

            // Calculate days difference
            const timeDiff = deadline.getTime() - today.getTime();
            const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 

            return dayDiff;
        },
        canShowLoadingSpinner(){
            if(this.key_result_loading_id == -1){
                return false;
            }
            else{
                let idx = findIndex(this.item.key_results, {id: this.key_result_loading_id});
                return idx > -1
            }

        },
        getDateFormat(){
            const start = format(parseISO(this.item.start_date), 'dd MMM');
            const end = format(parseISO(this.item.deadline), 'dd MMM');
            return {
                start: start,
                end: end,
            }
        },
        card_expanded(){
            return this.expanded_card_ids.includes('objectivesTableGOAL');
            //return false;
        },
        getLinkedLevel(){
            let lvl = this.levels.find( level => {
                return level.id == this.item.level_id
            })
            if(lvl){
                return lvl.name
            }
            return 'N/A'
        },
        propBackground(){
            if(this.isCurrentItemSelected){
                return {    'border-color': `${this.tile_color} !important`,
                            'border-left': `4px solid ${this.tile_color}`,
                            'background-color': this.tile_color_bg,
                }
            }
            return {};
        },
        isCurrentItemSelected(){
            return this.selected_goal_objective == this.item.id;
        },
        getSelectedClass(){
            return this.isCurrentItemSelected
            ? 'border border-primary'
            : '';
            
        },
    },
    methods:{
        ...performanceMethods,
        selectItem(){
            window.webix.$$('objectivesTableGOAL').select(this.item.id);
        },
        ownerName(obj){
            if(!obj.owner_name){
                return '';
            }
            if(obj.owner_name != 'default'){
                return obj.owner_name
            }
            else{
                let user = this.all_users.find( item => { return item.id == this.item.owner_user_id});
                if(user){
                    console.log('user', user);
                    return user.name
                }
                else{
                    return '';
                }
            }
        },
        hasOwnerUserId(item){
            return item.owner_user_id != undefined;
        },
        getImgSrc(item){
            let img_src = '/img/user.png';
            if(item.owner_user_id){
                let user_object = this.all_users.find( user => {
                    return user.id == item.owner_user_id;
                })
                if(user_object){
                    img_src = user_object.avatar_src;
                }
            }
            return img_src;
        },
    },
    mounted(){
        setTimeout(()=>{
            this.awaiting_load = false;
        },this.list_index*15)
    },
}
</script>

<style scoped>
.card-header{
    padding-left: 0.3rem !important; 
    padding-right: 0.3rem !important; 
}
</style>