<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-15T20:37:40+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T14:25:59+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>
        <div class="" style="">
            <CardHeader>
                <template #title>
                    <span class="text-primary">{{ jobName }}</span>
                    <span class="text-primary">&nbsp;> {{ getFirstName }}</span>
                    
                </template>
                <template #buttons>
                    <!-- buttons for save and delete -->
                    <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="me-2 dropdown-menu-end">
                        <strong>Save Table Changes</strong>
                    </b-button>
                    <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="me-2 dropdown-menu-end">
                        <strong>Delete Selected Rows</strong>
                    </b-button>
                    <TableActionButton
                        :currentMode="table_view_mode"
                        :actions="['edit', 'delete', 'view']"
                        @updateMode="updateViewMode"
                        />
                </template>
            </CardHeader>
            <!-- Flip -->
            <div class="">
                <div>
                <!-- flip front -->
                <div v-show="flip" class="" style="text-align: left;">
                    <Table
                    :uid="table_uid"
                    :is_loading="is_loading"
                    :data="candidateQualifications"
                    :columns="columns"
                    :table_mode="table_view_mode"
                    :pager_config=5
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                    /><!--@selectedRowEvent="selectedRowEvent"-->
                </div>
                <!-- flip back -->
                <div class="" v-if="!flip">
                  <!-- Guideline -->
                  <Guideline
                    v-if="showGuideline"
                    ref="guideline"
                    :cardName="'PlanOverview'"
                    :isEditGuideline="false"
                  />
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
//import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from 'lodash';
import Swal from "sweetalert2";
import Table from '@/components/widgets/table.vue'
import TableActionButton from '@/components/widgets/tableActionsButton.vue'
import CardHeader from '@/components/widgets/cardHeader.vue'
export default {
    name: "Admin",
    components: {
        //FilterComponent,
        Guideline,
        Table,
        TableActionButton,
        CardHeader
    },
    data:() => ({
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'jobQualifications',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,
        searchText: "",
        searchDate: "",
        resetFilter: "",
        isEditingNew: false,
        flip: true,
        showGuideline: false,
        editableId: -1,
        candidateData: [],
        addingRow: false,
        tableFields: [
            { key: "qualification_name", label: "Qualification Name"},
            { key: "type", label: "Type"},
            { key: "institution", label: "Institution Name"},
            { key: "grade", label: "Grade"},
            { key: "year", label: "Year"},
            { key: "comment", label: "Comment"},
        ]
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        dropdownClassFix(){
            //* fix buttons position when displaying before the ... dropdown menu
            if(this.changes_made_flag == true || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
        isViewMode(){
            if(this.table_view_mode != 'view'){
                return false;
            }
            else{
                return true
            }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){
                return false;
            }
            else{
                return true
            }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){
                return false;
            }
            else{
                return true
            }
        },
        columns(){
            return [
                { id: "qualification_name", header: "Qualification Name", fillspace: 1, editor: 'text'},
                { id: "type", header: "Type", fillspace: 1, editor: 'text'},
                { id: "institution", header: "Institution Name", fillspace: 1, editor: 'text'},
                { id: "grade", header: "Grade", fillspace: 1, editor: 'text'},
                { id: "year", header: "Year", fillspace: 1, editor: 'text'},
                { id: "comment", header: "Comment", fillspace: 1, editor: 'text'},
            ]
        },
        
        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        storeHiringData() {
            return this.levelPeopleData.hiring;
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            else{
                return 'No Job'
            }
            /*var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex].job_title;
            }
            return "";*/
        },
        storeCandidateData() {
            var el = this;
            var findIndex = _.fidndIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex].candidates;
            }
            return [];
        },
        activeCandidate() {
            var el = this;
            var findIndex = _.findIndex(this.storeCandidateData, function(o) { return o.id == el.activeCandidateId; });
            if (findIndex > -1) {
                return this.storeCandidateData[findIndex];
            }
            return [];
        },
        getFirstName(){
            if(this.active_candidate_data != null){
                return this.active_candidate_data.first_name;
            }
            else{
                return '';
            }
        },
        candidateQualifications() {
            if (this.active_candidate_data != null) {
                return this.active_candidate_data.qualifications
            }
            return [];
        },
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
    },
    watch: {
        storeHiringData() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        },
        activeHiringPositionId() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        },
        activeCandidateId() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        }
    },
    methods:{
        ...peopleMethods,
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'drag':{
                    this.toggleDragMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view');
            }
            else{
                this.table_view_mode = 'view';
            }
        },
        showDiscardPrompt(type){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            console.log(row.id);
            //this.selected_candidate = row.id;
            //this.$eventHub.$emit('setActiveCandidate', this.selected_candidate);
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------
        saveTable(){
            //eslint-disable-next-line
            let table = $$(this.table_uid);
            table.editStop();
            const all_data = table.serialize(true);
            let rows_to_save = [];
            all_data.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    let params = {
                        id: newRow ? -1 : row.id,
                        candidate_id: this.active_candidate_data.id,
                        qualification_name: row.qualification_name,
                        type: row.type,
                        institution: row.institution,
                        grade: row.grade,
                        year: row.year,
                        comment: row.comment,
                    }
                    rows_to_save.push(params);
                }
            })
            let qualifications_params = {
                candidate_id: this.active_candidate_data.id,
                qualifications: rows_to_save,
            }
            this.is_loading = true;

            this.saveCandidateQualification(qualifications_params).then(() => {
                this.changes_made_flag = false;
                this.loadHiringData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            });

            /*this.saveHiringJob(qualifications_params)
            .then(()=>{
                this.loadHiringData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            })*/
        },
        mapCandidateData() {
            this.candidateData = this.candidateQualifications.map(item => ({...item, isEdit: false}));
        },
        setRowActive(data) {
            if (data[0] && data[0].id) {
                this.editableId = data[0].id;
            }
        },
        editRow() {
            var el = this;
            var findIndex = _.findIndex(this.candidateData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                this.candidateData[findIndex].isEdit = !this.candidateData[findIndex].isEdit;
            }
        },
        addRow() {
            var rowData = {
                id: -1,
                candidate_id: this.activeCandidateId,
                qualification_name: "",
                type: "",
                institution: "",
                grade: "",
                year: "",
                comment: "",
                isEdit: true
            };
            this.cancelEdit();
            this.addingRow = true;
            this.candidateData.push(rowData);
        },
        cancelEdit() {
            var el = this;
            //eslint-disable-next-line no-unused-vars
            this.addingRow = false;
            _.forEach(this.candidateData, function(value, index) {
               el.candidateData[index].isEdit = false;
            });
        },
        saveRow() {
            var findIndex = _.findIndex(this.candidateData, function(o) { return o.isEdit == true; });
            if (findIndex > -1) {
                this.saveCandidateQualification(this.candidateData[findIndex]).then(() => {
                    this.loadPeopleData(this.selected_level.id);
                    this.addingRow = false;
                });
            } else {
                alert("no rows being edited");
            }
        },
        deleteRow() {
            Swal.fire({
                title: "Are you sure you want to delete this qualification?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    var params = {qualification_id: this.editableId};
                    this.deleteCandidateQualification(params).then(() => {
                        this.loadPeopleData(this.selected_level.id);
                    });
                }
            });
        }

    },
    mounted(){
        this.mapCandidateData();
    }
}
</script>
