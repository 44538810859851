<template>
    <span id="filter-component" class="d-flex filter-component">
        <span class="d-flex" v-if="showFilter">
            <span v-if="!hideDateFilter" class="d-flex" style="font-size: 11px;">
                <span class="pt-1">Start Date:</span>
                <b-form-input size="sm" id="date" v-model="date.start_date" type="date"></b-form-input>
                <span class="ms-2 pt-1">
                End Date:
                </span>
                <b-form-input size="sm" class="me-2" id="date" v-model="date.end_date" type="date"></b-form-input>
                <b-button v-if="showResetButton" @click="reset" size="sm" variant="warning" class="me-2">Reset</b-button>
                <b-button @click="applyDate" size="sm" variant="primary" class="me-2">Apply</b-button>
                <b-button id="filterDate" variant="outline-dark" class="me-2" size="sm" @click="back">
                    <i class="fas fa-arrow-right"></i>
                </b-button>
            </span>
        </span>
        <span style="max-height: 27px;" class="d-flex" v-if="showSearch">
            <b-form-input 
            @keyup.enter="$emit('searchText', searchText)"
            size="sm"
            placeholder="Search..."
            v-model="searchText"
            class="me-2"
            type="text"></b-form-input>
            
            <b-button v-if="searchText != ''" variant="danger" class="me-2" size="sm" @click="resetTextField">
                <i class="fas fa-times"></i>
            </b-button>
            <b-button class="me-2" size="sm" variant="primary" @click="$emit('searchText', searchText)">
                <i class="fas fa-search"></i>
            </b-button>
            <b-button id="searchBack" variant="outline-dark" active-class="" class="me-2" size="sm" @click="back">
                <i class="fas fa-arrow-right"></i>
            </b-button>
        </span>
        <span v-if="showFilter == false && showSearch == false" class="">
            <b-button class="me-1" title="Date Range Filter" id="showFilterBtn" @click="showDateFilter" variant="info" size="sm">
                <i class="fas fa-filter"></i>
            </b-button>
            <b-button title="Search Text" id="showSearchBtn" @click="showSearchBar" variant="info" size="sm" class="me-2">
                <i class="fas fa-search"></i>
            </b-button>
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            hideDateFilter: {
                required: false
            }
        },
        data: () => ({
            date: {
                start_date: null,
                end_date: null,
            },
            searchText: "",
            showFilter: false,
            showSearch: false,
        }),
        computed:{
            showResetButton(){
                if(this.date.end_date != null || this.date.start_date != null ){
                    return true;
                }
                return false;
            },
            showResetTextButton(){
                if(this.searchText !== ''){
                    return true;
                }
                else{
                    return false;
                }
            }
        },
        methods: {
            applyDate() {
                if (this.date.start_date == null && this.date.end_date == null){
                    alert('No dates selected');
                    return;
                }
                if (this.date.end_date < this.date.start_date) {
                    alert("End date must be equal or greater than start date");
                    return;
                }

                //* Dates are valid
                this.$emit("searchDate", this.date);
            },
            resetTextField(){
                this.$emit('searchText', '');
                this.searchText = "";
            },

            reset() {
                this.date.start_date = null;
                this.date.end_date = null;
                this.searchText = "";
                this.$emit("resetFilter");
            },
            showSearchBar(){
                this.showSearch = true;
            },
            showDateFilter(){
                this.showFilter = true;
            },
            back(){
                this.showSearch = false;
                this.showFilter = false;
            }
        },
        mounted() {},
    };
</script>

<style lang="scss" scoped>
::v-deep #date {
  width: 7.5rem !important;
}
::v-deep input {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 24px;
    max-height: 24px;
}

.filter-component button{
    height: 24px !important;
}


::v-deep input[type="date"]::-webkit-inner-spin-button,
::v-deep input[type="date"]::-webkit-outer-spin-button,
::v-deep input[type="date"]::-webkit-calendar-picker-indicator {
    margin-inline-start: 0px;
}
</style>
