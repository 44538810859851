<template>
        <div v-if="show" class="border border-grey p-2" style="width: 100%; min-height: 14rem;" :class="is_key_result == false ? 'mt-1' : ''">
            <GraphHeader>
                <template #text>
                    {{data.name}}
                </template>
                <template #badges>
                    <b-badge v-if="hasSharedDataset" variant="primary" class="bg-primary bg-soft text-dark me-1 text-capitalize">{{data.share_type}}</b-badge>
                    <b-badge class="bg-info bg-soft text-dark me-1 text-capitalize">{{returnTrackingPeriodTitle}}</b-badge>
                    <b-badge variant="primary" class="text-dark me-1 bg-success bg-soft">{{getUnitType(data.unit_type_id)}}</b-badge>
                    
                    <b-badge v-if="data.show_cumulative == 1" variant="primary" class="bg-warning bg-soft text-dark me-1 cursor-pointer"
                    v-b-popover.hover="cumulativePopover"
                    title="Cumulative Achieved"
                    >
                        <font-awesome-icon class="ms-1 me-1" icon=" fa-info" /> Cumulative Achieved
                    </b-badge>
                    <b-badge v-if="data.show_cumulative == 0" variant="primary" class="bg-warning bg-soft text-dark me-1 cursor-pointer"
                    v-b-popover.hover="discretePopover"
                    title="Discrete Achieved"
                    >
                        <font-awesome-icon class="ms-1 me-1" icon=" fa-info" /> Discrete Achieved
                    </b-badge>

                    <b-badge v-if="data.show_cumulative_target == 1" variant="primary" class="bg-info bg-soft text-dark me-1 cursor-pointer"
                    v-b-popover.hover="cumulativePopover"
                    title="Cumulative Target"
                    >
                        <font-awesome-icon class="ms-1 me-1" icon=" fa-info" /> Cumulative Target
                    </b-badge>
                    <b-badge v-if="data.show_cumulative_target == 0" variant="primary" class="bg-info bg-soft text-dark me-1 cursor-pointer"
                    v-b-popover.hover="discretePopover"
                    title="Discrete Target"
                    >
                        <font-awesome-icon class="ms-1 me-1" icon=" fa-info" /> Discrete Target
                    </b-badge>

                    <b-badge variant="primary" class="bg-secondary bg-soft text-dark me-1 cursor-pointer"
                    v-b-popover.hover="calculationPopover"
                    title="Discrete Target"
                    >
                        <font-awesome-icon class="ms-1 me-1" icon=" fa-info" /> Progress Calculation
                    </b-badge>
                </template>
                <template #buttons>
                    <span v-if="edit_mode == false">
                        <span class="btn-group  me-2">
                            <b-button :disabled="data.tracking.length == 0" v-show="canShow('daily')" @click="setCustomTimePeriod('daily')" :variant="custom_time_period === 'daily' ? 'dark' : 'light'" size="sm">D</b-button>
                            <b-button :disabled="data.tracking.length == 0" v-show="canShow('weekly')" @click="setCustomTimePeriod('weekly')" :variant="custom_time_period === 'weekly' ? 'dark' : 'light'" size="sm">W</b-button>
                            <b-button :disabled="data.tracking.length == 0" v-show="canShow('monthly')" @click="setCustomTimePeriod('monthly')" :variant="custom_time_period === 'monthly' ? 'dark' : 'light'" size="sm">M</b-button>
                            <b-button :disabled="data.tracking.length == 0" v-show="canShow('quarterly')" @click="setCustomTimePeriod('quarterly')" :variant="custom_time_period === 'quarterly' ? 'dark' : 'light'" size="sm">Q</b-button>
                            <b-button :disabled="data.tracking.length == 0" v-show="canShow('yearly')" @click="setCustomTimePeriod('yearly')" :variant="custom_time_period === 'yearly' ? 'dark' : 'light'" size="sm">Y</b-button>
                        </span>

                        <span v-if="middle_text !== ''" style="" class="me-2">
                            <b-button style="width: 4rem;" size="sm" variant="light">
                                {{middle_text}}
                            </b-button>
                        </span>
                    </span>
                    <span v-if="edit_mode && dataset_mode === 'shared'" class="me-4">
                        <b-dropdown variant="success" size="sm" toggle-class="mb-1 text-capitalize" :text="calculationTypeTitle" >
                            <template #button-content>
                                <font-awesome-icon class="me-1" icon="fa-solid fa-caret-down" />
                                <span class="text-capitalize">{{calculationTypeTitle}}</span>
                            </template>
                            <b-dropdown-item @click="setCalculationType('sum')">
                                Sum
                            </b-dropdown-item>
                            <b-dropdown-item @click="setCalculationType('product')">
                                Product
                            </b-dropdown-item>
                            <b-dropdown-item @click="setCalculationType('average')">
                                Average
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="setCalculationType('multi-line')">
                                Overlay
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-button size="sm" @click="saveSharedDataset" variant="primary" class="ms-2 mb-1">
                            Save
                        </b-button>
                    </span>
                    <span v-show="edit_mode" class="btn-group me-2">
                        

                        <b-button size="sm" :variant="dataset_mode == 'default' ? 'dark' : 'light'" @click="toggleDatasetType('default')">
                            Data Table
                        </b-button>
                        <b-button size="sm" :variant="dataset_mode == 'shared' ? 'dark' : 'light'" @click="toggleDatasetType('shared')">
                            Linked Graphs Table
                        </b-button>
                    </span>
                    <span class="btn-group">
                        
                        <ToolBar v-if="edit_mode && dataset_mode == 'default'" :table_id="table_uid"/>
                        <b-button v-show="edit_mode == false" style="width: 2rem;" title="Number of linked datasets" variant="outline-dark" size="sm">
                            {{getDatasetCount}}
                        </b-button>
                        <b-button variant="outline-primary" size="sm" style="width: 2rem;" @click="toggleGraphDatasetEditMode()">
                            <span v-show="edit_mode" title="Cancel">
                                <font-awesome-icon icon="fa-solid fa-times" />
                            </span>
                            <span v-show="edit_mode == false" title="Show Data View">
                                <font-awesome-icon icon="fa-solid fa-database" />
                            </span>
                        </b-button>

                        <b-button @click="expandGraph()" size="sm" style="width: 2rem;">
                            <!--<i class="fas fa-arrows-alt-v"></i>-->
                            <font-awesome-icon icon="fa-solid fa-arrows-alt-v" />
                        </b-button> 
                    </span>
                </template>
            </GraphHeader>

            <div v-if="!edit_mode">
                <b-overlay :show="is_scrubber_loading" >
                    <div>
                        <!-- Main Chart -->
                        <apexchart
                            :ref="'mainChart'+graph_id"
                            type="area"
                            v-if="chart_config != null"
                            :height="230"
                            :options="chart_config"
                            :series="all_graph_series_top"
                        ></apexchart>

                        <!-- Scrubber Chart -->
                        <b-collapse v-model="show_expanded_view">
                                <apexchart
                                    :ref="'scrubber'+graph_id"
                                    v-if="show_scrubber_graph && scrubber_config != null"
                                    type="line"
                                    height="100"
                                    :options="scrubber_config"
                                    :series="all_graph_series"
                                ></apexchart>
                        </b-collapse>
                    </div>
                </b-overlay>
            </div>
            <div class="pt-1" style="height: 14.5rem;" v-if="edit_mode && dataset_mode == 'shared'">
                <DatasetTable ref="sharedDatasetTable" :share_type="data.share_type" @savedChanges="closeEditMode" @updated="was_data_updated = true" />
            </div>
            <div style="min-height: 230px;" class="pt-1" v-if="edit_mode && dataset_mode == 'default'">

                <EditKRGraph
                v-if="is_key_result"
                :time_period="this.data.current_time_period"
                :table_name="this.data.id+'edit'"
                :is_key_result="is_key_result"
                :is_metric="is_metric"
                @updated="was_data_updated = true"
                @savedChanges="closeEditMode"
                @back="closeEditMode"
                :item_id="data.id"
                :tracking_data="this.data.tracking"
                :dates="this.getKRDate"
                :target="this.data.target"
                @returnWebixUID="handleTableUID"
                />
                
                <!--<EditGraph
                v-else
                :time_period="this.data.current_time_period"
                :table_name="this.data.id+'edit'"
                :is_key_result="is_key_result"
                :is_metric="is_metric"
                @updated="was_data_updated = true"
                @savedChanges="closeEditMode()"
                @back="closeEditMode()"
                :item_id="data.id"
                :tracking_data="this.data.tracking"
                :dates="this.date_range"
                />-->
            </div>
            <b-overlay :show="is_loading_data" no-wrap></b-overlay>
        </div>
</template>

<script>
//eslint-disable-next-line
import { getWeekOfMonth, getMonthOfYear, getMonth, getQuarter} from 'date-fns';
//eslint-disable-next-line
import {format, parseISO} from 'date-fns';
//eslint-disable-next-line
import { eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns';
//eslint-disable-next-line
import { startOfMonth, endOfMonth } from 'date-fns';
//eslint-disable-next-line
import { startOfYear, endOfYear } from 'date-fns';
//eslint-disable-next-line
import { startOfWeek, endOfWeek } from 'date-fns';
//eslint-disable-next-line
import { addDays, addMonths, isBefore, subDays } from 'date-fns';
//eslint-disable-next-line
import { eachYearOfInterval, eachQuarterOfInterval, startOfQuarter, endOfQuarter, isWithinInterval } from 'date-fns';
import _ from 'lodash';
import {
    cardHeightMethods,
    activityMethods, activityComputed,
    levelMethods, levelComputed,
    uxToggleComputed, uxToggleMethods, uxGraphMethods
} from "@/state/helpers";
import EditKRGraph from "../../objectives/components/graph-components/KeyResultDatasetTable.vue"
//eslint-disable-next-line
import ApexCharts from 'apexcharts'

import dateMixin from '@/mixins/dateMixin.js'
import graphHelpers from '@/mixins/graphHelpers.js'

import ToolBar from './graphWidgets/datatableToolbar.vue'
import DatasetTable from '../../objectives/components/graph-components/combindedDatasetTable.vue'
import GraphHeader from '@/components/widgets/graphHeader.vue'
import Swal from 'sweetalert2'

export default {
    mixins: [dateMixin, graphHelpers],
    components:{
        EditKRGraph,
        ToolBar,
        DatasetTable,
        GraphHeader
    },
    props:{
        data:{
            required: true,
        },
        is_metric:{
            default: () => {
                return false;
            },
        },
        is_key_result:{
            default: () => {
                return false;
            },
        },
        middle_text:{
            default: '',
        },
        are_kpis_visible:{
            required: true,
            default: () => {
                return false;
            },
        }
    },
    data:() => ({
        table_uid: null,
        debug_data:null,
        show: true,
        show_more_options: false,
        custom_time_period: null,
        //edit_mode: false,
        chart_config: null,
        series:[],  //* graph series
        tracking_values:[],//* objects with date,target,achieved for the date_range
        date_range:{//* graphs starting and ending values
            start: null,
            end: null,
        },
        custom_date_range:{//* used when viewing a graph where the current_tracking_period != custom_tracking_period
            start: null,
            end: null,
        },
        value_range:{
            min: 0,
            max: 0
        },
        hasMounted: false,

        was_data_updated: false,//* track edit mode changes made,

        //* prevent watcher from firing while updating the custom_date_ranges
        //!  might end up being an anti-pattern. 
        freeze_watcher: false,
        graph_id:null, //* main graph id, used by the scrubber graph
        show_expanded_view: false,

        scrubber_series:[],
        scrubber_config: null,
        is_scrubber_loading: false,
        show_scrubber_graph: false,

        is_loading_data: false,

        dataset_mode: 'default', // default or shared
        calculation_type: '',

        graph_legend:[]
    }),
    watch:{
        // watch value to clear the ui varaibles when exiting dataset view mode
        is_editing_kr_dataset(newValue){
            if(newValue == false){
                //reset calc type value
                this.calculation_type = '';
            }
        },
        edit_mode:{
            immediate: true,
            handler(){
                this.$emit('toggleEdit', this.edit_mode)
            }
        },
        // 'graph_scrubber_state.is_kpi_scrubber_expanded'(newValue){
        //     if(this.show_expanded_view != newValue){
        //         this.expandGraph();
        //     } 
        // },
        show_expanded_view(){
            this.setScrubberState({
                //* set state to graph vuex store (used to sync expanded state for key results and kpis)
                type: 'key_result',
                state: this.show_expanded_view,
            });
            if(this.show_expanded_view == true){
                this.setNewHeightState({
                    size: 'expanded',
                    type: 'kr',
                });
            }
            else{
                this.setNewHeightState({
                    size: 'default',
                    type: 'kr',
                });
            }
            this.$emit('expanded', this.show_expanded_view);
        },
        custom_date_range:{
            deep: true,
            handler(){
                if(this.freeze_watcher == false){
                    //this.processSeries();
                }
            }
        },
        data(oldVal, newVal){
            this.setGraphEditState({
                type: 'kpi',
                state: false,
            })
            this.graph_legend = [];
            if(oldVal.id != newVal.id){
                //this.show = false;
                
                //this.scrubber_config = null;
                //this.graph_config = null;
                setTimeout(()=>{
                    //this.show = true;

                    this.initComponent();
                },1)
                
            }
            else{
                //this.processSeries();
            }
            
        },
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...uxToggleComputed,
        edit_mode:{
            get(){
                return this.is_editing_kr_dataset;
            },
            set(newValue){
                this.setGraphEditState({
                    type: 'kr',
                    state: newValue,
                });
            }
        },
        cumulativePopover(){
            return {
                customClass: 'datatype-popover-class',
                placement: 'auto',
                title: 'Cumulative Data',
                content: `Cumulative data represents the accumulation of data over a certain period of time. It is the sum or total of all the individual data points up to a particular point in time. For example, if you are tracking the total revenue earned over a month, the data for each day would be cumulative, as it represents the accumulation of revenue up to that day. Cumulative data is typically represented as a running total on a graph or chart, and progress is measured based on the overall trend of the accumulated values over time. For instance, you may track the total revenue generated over a month, with each day's value added to the cumulative total.`
            }
        },
        discretePopover(){
            return {
                customClass: 'datatype-popover-class',
                placement: 'auto',
                title: 'Discrete Data',
                content: `Discrete data refers to individual data points or measurements taken at specific points in time. It represents data that is not accumulated over time but rather captured as individual, separate values. For example, if you are tracking the number of products sold per day, the data for each day would be discrete. Discrete data is typically represented as isolated values on a graph or chart, and progress is measured based on the change in values between specific points in time. For instance, you may track the number of units produced per hour, with each hour's value considered as a discrete data point.`
            }
        },
        calculationPopover(){
            return {
                customClass: 'datatype-popover-class',
                placement: 'auto',
                title: 'How progress is calculated',
                content: `Add refined description of the progess calc`
            }
        },
        calculationTypeTitle(){
            if(this.calculation_type == null || this.calculation_type == ''){
                return 'Select Calculation Mode'
            }
            else{
                return `${this.calculation_type} Mode`
            }
        },
        getDatasetCount(){
            if(this.data.share_type == null){
                return 1;
            }
            else{
                return this.data.shared_graph_count;
            }
        },
        hasSharedDataset(){
            return this.data.share_type != null ? true : false;
        },
        getKRDate(){
            let dates = {
                start: this.data.start_date,
                end: this.data.deadline,
            }
            dates.start = format(parseISO(dates.start),'yyyy-MM-dd');
            dates.end = format(parseISO(dates.end),'yyyy-MM-dd');
            return dates;
        },
        returnTrackingPeriodTitle(){
            if(this.data.current_time_period === this.custom_time_period){
                return `${this.data.current_time_period}`;
                //return `(${this.data.current_time_period})`;
            }
            else{
                return `${this.data.current_time_period} as ${this.custom_time_period}`
                //return `(${this.data.current_time_period} as ${this.custom_time_period})`
            }
        },
        activeUserID(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
              //* return user id that was selected on the left menu
                return this.selected_user_id;
            }
            else{
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        all_graph_series(){
            if(this.data.multi_line){
                return this.all_graph_series_top;
            }
                let array = [];
                let array_2 = [];
                this.data.tracking.forEach( (item) => {
                    let date = format(parseISO(item.achieved_date), "dd MMM yyyy")
                    date = new Date(date + ' GMT');
                    date = date.getTime();
                    if(date != 'NaN'){
                        array.push(
                            {
                                x: date,
                                y: item.value,
                            }
                        );
                        array_2.push(
                            {
                                x:date,
                                y: item.target_value,
                            }
                        )
                    }
                    else{
                        alert('No')
                    }
                    
                });
                array = _.sortBy(array, function(obj) {
                    return new Date(obj.x);
                });
                array_2 = _.sortBy(array_2, function(obj) {
                    return new Date(obj.x);
                });
                let first_date = format(new Date(this.data.start_date), 'yyyy-MM-dd');
                first_date = new Date(first_date).getTime();
                if(array_2.length == 0){
                    array_2.push({
                        x: first_date,
                        y: 0,
                    })
                }
                else{
                    if(array_2[0].y == null){
                        array_2[0].y = 0;
                    }
                }
                let last_date = format(new Date(this.data.deadline), 'yyyy-MM-dd');
                last_date = new Date(last_date).getTime();
                let index = _.findIndex(array_2, {x: last_date});
                if(index == -1){
                    array_2.push({
                        x: last_date,
                        y: this.data.target,
                    });
                }
                else{
                    array_2[index].y = this.data.target;
                }
                let temp_array = []
                array_2.forEach((item,idx)=>{
                    if(idx == 0){
                        temp_array.push(item);
                    }
                    if(idx > 0){
                        if(item.y > 0){
                            temp_array.push(item)
                        }
                    }
                })

                array_2 =_.cloneDeep(temp_array);
                return [
                    {name: 'Achieved', data: array},
                    {name: 'Target', data: array_2}
                ];
        },
        all_graph_series_top(){
            if(this.selected_key_result.multi_line){
                return this.processMultilineValues(this.data, this.custom_time_period);
            }
            else{
                let has_target = true //* key results always have targets
                // please fogive the fliped show cululative logic
                let has_cumulative = !this.data.show_cumulative;

                return this.processNormalValues(this.data, this.custom_time_period, has_target, has_cumulative);
            }
        },
        
        trackingDates(){
            //* the goal is to return all the valid dates for the
            if(this.date_range.start == null || this.date_range.end == null){
                return [];
            }
            let tracking_dates = [];

            let start = this.getDate(this.selected_key_result.start_date);
            let end = this.getDate(this.selected_key_result.deadline);



            switch(this.data.current_time_period){
                case 'daily':{
                    tracking_dates = eachDayOfInterval({ start, end });
                    break;
                }
                case 'weekly':{
                    tracking_dates = eachWeekOfInterval({ start, end });
                    tracking_dates.forEach( (date, idx) => {
                        let end_of_week = endOfWeek(date);
                        tracking_dates[idx] = this.getDate(end_of_week);
                    });
                    break;
                }
                case 'monthly':{
                    tracking_dates = eachMonthOfInterval({ start, end });
                    tracking_dates.forEach( (date, idx) => {
                        let end_of_month = endOfMonth(date);
                        tracking_dates[idx] = this.getDate(end_of_month);
                    });
                    break;
                }
                case 'quarterly':{
                    tracking_dates = eachQuarterOfInterval({ start, end });
                    tracking_dates.forEach( (date, idx) => {
                        let end_of_q = endOfQuarter(date);
                        tracking_dates[idx] = this.getDate(end_of_q);
                    });
                    break;
                }
                case 'yearly':{
                    //tracking_dates = eachMonthOfInterval({ start, end });
                    tracking_dates = [];
                    break;
                }

            }
            return tracking_dates;
        },
        displayTrackingFrequency(){
            if(this.custom_time_period === this.data.current_time_period){
                //* Display default set period
                return `${this.data.current_time_period} Tracking`
            }
            else{
                //* if a custom period is set then the graph data will be a summary of data
                //* -------------------------------------------------
                //* eg if a graph is daily but the custom period is set to weekly then
                //* the weekly values displayed are just summed up daily values (not triggering the traversal endpoint)
                return `${this.data.current_time_period} Tracking Shown as ${this.custom_time_period}`
            }
        }
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...cardHeightMethods,
        ...uxToggleMethods,
        ...uxGraphMethods,
        debugMe(){
            console.log(this.$refs[`mainChart${this.graph_id}`])
        },
        isDataValidForPeriod(period){
            if(this.data.tracking.length == 0){
                return true;
            }

            let interval = {
                start: this.data.start_date,
                end: this.data.deadline,
            }
            //* note we need to return the opposite boolean value
            return this.isDatasetWithinPeriod(period, interval);
        },
        getUnitType(unit_type_id){
            let unit = this.keyResultUnit.find( item => item.id == unit_type_id);
            return unit != undefined ? unit.name : '';
        },
        handleTableUID(uid){
            this.table_uid = uid;
        },
        setCalculationType(type){
            this.calculation_type = type;
            this.$refs['sharedDatasetTable'].updateCalculationMode(type);
        },
        saveSharedDataset(){
            this.$eventHub.$emit('saveSharedDataset');
        },
        setCustomTimePeriod(type){
            // igniore daily type
            if(type != 'daily' && this.isDataValidForPeriod(type) == false){
                this.$swal.fire({
                    icon: 'info',
                    title: 'Incompatible data',
                    text: `This graphs data can not be displayed as ${type}.`
                })
                return false;
            }
            this.is_scrubber_loading = true;
            setTimeout(()=>{
                this.custom_time_period = type;
                this.is_scrubber_loading = false;
            },150)
            
            /*if(this.show_expanded_view == false){
                this.expandGraph();
            }*/
        },
        returnMultilineDataset(){
            let series_data = _.cloneDeep(this.data);
            series_data.tracking = [];

            //* if no custom time perios is set - eg weekly graph is weekly
            if(series_data.current_time_period === this.custom_time_period){
                //* here we need to check if the graphs deadline date is the last date in the travking array
                let graph_series = []; //{ name, data }
                let el = this;
                series_data.multi_line_data.forEach(( graph )=>{
                    let name = `${graph.name} (${graph.owner_name})`;
                    let data = [];
                    graph.tracking.forEach(( item )=>{
                        let date = this.getDate(item.achieved_date);
                        if(date != 'NaN'){
                            data.push(
                                {
                                    x: date.getTime(),
                                    y: item.value,
                                }
                            );
                        }
                    })
                    data = _.sortBy(data, function(obj) {
                        return el.getDate(obj.x);
                    });
                    graph_series.push({ name, data });
                })
                el = undefined;
                return graph_series;
            }
            else{
                let tracking = [];
                let array = [];
                let array_2 = [];
                if(this.custom_time_period === 'weekly'){
                    let weekly_intervals = eachWeekOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    weekly_intervals.forEach((date, idx)=>{
                        let start = startOfWeek(date);
                        let end = endOfWeek(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'monthly'){
                    let monthly_intervals = eachMonthOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfMonth(date);
                        let end = endOfMonth(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }

                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'quarterly'){
                    let monthly_intervals = eachQuarterOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfQuarter(date);
                        let end = endOfQuarter(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }
                if(this.custom_time_period === 'yearly'){
                    let monthly_intervals = eachYearOfInterval({start: new Date(series_data.start_date), end: new Date(series_data.deadline)});
                    monthly_intervals.forEach((date, idx)=>{
                        let start = startOfYear(date);
                        let end = endOfYear(date);
                        let total_value = 0;
                        let total_target = 0;
                        while(isBefore(start, end)){
                            //console.log('WHILE LOG x')
                            let current_date = format(start, "dd-MM-yyyy");
                            let index = _.findIndex(series_data.tracking, (item) => {
                                let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                                return item_date == current_date;
                            });
                            if (index != -1) {
                                total_value += series_data.tracking[index].value;
                                total_target += series_data.tracking[index].target_value
                            }
                            start = addDays(start, 1);
                        }
                        let formatted_date = format(date, 'dd MMM yyyy');
                        formatted_date = new Date(formatted_date + ' GMT');

                        tracking.push({
                            date: formatted_date.getTime(),
                            value: total_value,
                            target_value: total_target,
                            id: idx,
                        });
                    })
                }

                //eslint-disable-next-line
                tracking.forEach( (item, item_idx) => {
                    let date = item.date;
                    if(date != 'NaN'){
                        array.push(
                            {
                                x: date,
                                y: item.value,
                            }
                        );
                        if(item.target_value < 1 && item_idx < 1){
                            array_2.push(
                                {
                                    x:date,
                                    y: 0,
                                }
                            )
                        }
                        else if(item.target_value > 0){
                            array_2.push(
                                {
                                    x:date,
                                    y: item.target_value,
                                }
                            )
                        }
                    }
                    else{
                        alert('No')
                    }
                    
                });
                //* achieved
                array = _.sortBy(array, function(obj) {
                    return new Date(obj.x);
                });
                //* target
                array_2 = _.sortBy(array_2, function(obj) {
                    return new Date(obj.x);
                });

                return [
                    {name: 'Achieved', data: array,},
                    {name: 'Target', data: array_2, }
                ]
            }
        },
        toggleDatasetType(new_type){
            if(this.calculation_type == ''){
                this.dataset_mode = new_type;
            }


            if(this.dataset_mode === 'shared' && new_type === 'default'){
                Swal.fire({
                    title: "Warning!",
                    text: "Click yes to unlink all shared graphs. You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, unlink all!",
                }).then((result) => {
                    if (result.value) {
                        this.$refs['sharedDatasetTable'].removeSharedDataset();
                    }
                });
            }
            else{
                this.dataset_mode = new_type;
            }
        },

        closeEditMode(clear_changes_flag = false){
            if(this.was_data_updated){
                this.is_loading_data = true;
                let params = {
                    id: this.selected_objective.id,
                    user_id: this.activeUserID,
                }
                //TODO - FIX ME!!
                this.loadActivityData(this.selected_level.id).then(()=>{
                    this.loadObjectiveDetailAPI(params).then((returned_objective) => {
                        //this.$nextTick(()=>{
                            this.setGraphEditState({
                                type: 'kr',
                                state: false,
                            });
                            this.updateObjectiveProgress(returned_objective);
                            let index = _.findIndex(this.selected_objective_details.key_results, { id: this.selected_key_result.id });
                            if (index != -1) {
                                let key_result = _.cloneDeep(this.selected_objective_details.key_results[index]);
                                this.$eventHub.$emit("selectedKeyResult", key_result);
                                this.$nextTick(()=>{
                                    this.chart_config = this.buildGraphConfig();
                                    this.was_data_updated = false;
                                    this.edit_mode = false;
                                    this.is_loading_data = false;
                                })
                            }
                        //})
                    });
                })
                
            }
            if(clear_changes_flag){
                this.was_data_updated = false;
                this.setGraphEditState({
                    type: 'kr',
                    state: false,
                });
            }
        },
        resetCurrentTrackingPeriod(){
            this.custom_time_period = this.data.current_time_period;
            //this.processSeries();
            if(this.show_expanded_view){
                this.expandGraph();
            }
        },
        setDateRange(){
            let start = new Date();
            let end = new Date();
            switch(this.data.current_time_period){
                case 'daily':{
                    start = startOfMonth(start);
                    end = endOfMonth(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'weekly':{
                    start = startOfMonth(start);
                    end = endOfMonth(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'monthly':{
                    start = startOfYear(start);
                    end = endOfYear(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                case 'yearly':{
                    start = startOfYear(start);
                    end = endOfYear(end);
                    start = format(start, "yyyy-MM-dd");
                    end = format(end, "yyyy-MM-dd");
                    break;
                }
                default:{
                    start = null;
                    end = null;
                }
            }
            
            this.date_range = {
                start: start,
                end: end,
            }
            this.custom_date_range = _.cloneDeep(this.date_range);
        },
        processSeries(){
            return new Promise( resolve => {
                let series_a = [];
                //let series_b = [];
                this.tracking_values = [];

                //* flag is set to false when there is a valid index found in th loop below
                let contains_empty_values = true;
                //* if flag is true then set the series values as []
                

                //! should make this it's own method but ehh
                if(this.data.current_time_period === this.custom_time_period){
                    //* If custom and current periods match then just find the values and push to the series array
                    this.trackingDates.forEach( date => {
                        let current_date = format(date, "dd-MM-yyyy");
                        let index = _.findIndex(this.data.tracking, (item) => {
                            let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                            return item_date == current_date;
                        });
                        if (index != -1) {
                            this.tracking_values.push({
                                date: date,
                                value: this.data.tracking[index].value,
                                target_value: this.data.tracking[index].target_value,
                                id: this.data.tracking[index].id,
                            });
                            //* valid tracking value found
                            contains_empty_values = false;
                        }
                        else {
                            this.tracking_values.push({
                                date: date,
                                value: 0,
                                target_value: 0,
                                isEmpty: true,
                            });
                        }
                    });
                    if(contains_empty_values == false){
                        this.tracking_values.forEach((item) => {
                            series_a.push({ x: item.date.getTime(), y: item.value });
                            //series_b.push({ x: formatted_date, y: item.target_value });
                        });
                    }
                }
                else{
                    //* Here we need to sum the current tracking values to the custom period set.
                    let range_start = this.date_range.start;
                    let range_end = this.date_range.end;
                    if(this.custom_time_period === 'weekly'){
                        this.processCustomWeeklySeries(range_start, range_end);
                    }
                    if(this.custom_time_period === 'monthly'){
                        this.freeze_watcher = true;
                        this.custom_date_range.start = format(startOfYear(parseISO(this.custom_date_range.start)), 'yyyy-MM-dd');
                        range_start = this.custom_date_range.start;
                        this.custom_date_range.end = format(endOfYear(parseISO(this.custom_date_range.end)), 'yyyy-MM-dd');
                        range_end = this.custom_date_range.end;
                        this.$nextTick(()=>{
                            this.freeze_watcher = false;
                        })
                        this.processCustomMonthlySeries(range_start, range_end);
                    }
                    this.tracking_values.forEach((item) => {
                        let formatted_date = item.date
                        series_a.push({ x: formatted_date, y: item.value });
                        //series_b.push({ x: formatted_date, y: item.target_value });
                    });
                }
                

                this.series = [
                    {
                        name: "Achieved",
                        data: series_a,
                    },
                    /*{
                        name: "Target",
                        data: series_b,
                    }*/
                ];
                resolve();
            })
        },
        initChart(){
            //this.processSeries()
            //.then(()=>{
                this.chart_config = this.buildGraphConfig();
            //})
        },
        //eslint-disable-next-line
        setGraphZoom(){
            this.$refs[`mainChart${this.graph_id}`].updateOptions({
                yaxis: {
                    max: undefined,
                },
                xaxis:{
                    type: 'datetime',
                    tickAmount: 'dataPoints',
                }
            })
        },
        expandGraph(){
            this.show_more_options = false;
            if(this.show_expanded_view){
                this.is_scrubber_loading = true;
                //this.scrubber_config = null;
                this.$nextTick(()=>{
                    this.show_expanded_view = false;
                    
                    setTimeout(()=>{
                        this.is_scrubber_loading = false;
                        this.show_scrubber_graph = false;
                        this.setGraphZoom();
                    },100)
                })
            }
            else{
                //this.is_scrubber_loading = true;
                this.$nextTick(()=>{
                    this.show_expanded_view = true;
                    this.show_scrubber_graph = true;
                    
                })
                
            }
        },
        //eslint-disable-next-line
        handleBrushScroll: _.debounce(function(start_date, end_date){
            this.is_scrubber_loading = true;
            let start = new Date(start_date);
            let end = new Date(end_date);
            let dates = eachDayOfInterval({ start, end });
            let yaxis_scale = 0;
            let longest_series_length = 0;
            this.all_graph_series_top.forEach(( series )=>{
                let series_length = 0;
                dates.forEach( date => {
                    let tracking_index = series.data.findIndex( tracking_obj => {
                        return this.getSimpleDate(tracking_obj.x) == this.getSimpleDate(date);
                    })
                    if(tracking_index != -1){
                        if(series.data[tracking_index].y > yaxis_scale){
                            yaxis_scale = series.data[tracking_index].y
                        }
                        //* date was valid, increase count for current series
                        series_length++;
                    }
                })
                //* get the longest series length to use as the graphs xaxis tick amount 
                if(series_length > longest_series_length){
                    longest_series_length = series_length;
                }
            })

            
            //only bother to set scale if is a valid no
            if(yaxis_scale > 0){
                setTimeout(()=>{
                    let updated_options = {
                        yaxis: { max: yaxis_scale },
                    }
                    if(longest_series_length > 0){
                        updated_options['xaxis'] = { type: 'numeric', tickAmount: longest_series_length}
                    }
                    this.$refs[`mainChart${this.graph_id}`].updateOptions(updated_options);
                    this.is_scrubber_loading = false;
                }, 250)
            }
            else{
                this.is_scrubber_loading = false;
            }
        }, 300),
        buildGraphConfig(){
            this.graph_id = window.webix.uid();
            this.$emit('graphID', this.graph_id);
            //eslint-disable-next-line
            let show_legend = this.data.tracking.length > 0 ? true : false

            let el = this;
            let config = {
                legend:{
                    position: 'bottom',
                    fontSize: '13px'
                },
                chart: {
                    parentHeightOffset: '0px',
                    id: this.graph_id,
                    /*sparkline: {
                        enabled: true
                    },*/
                    events:{
                        //eslint-disable-next-line
                        legendClick: function(chartContext, seriesIndex){
                            let series_name = el.all_graph_series_top[seriesIndex].name;
                            if(el.$refs[`scrubber${el.graph_id}`]){
                                el.$refs[`scrubber${el.graph_id}`].toggleSeries(series_name);
                            }

                            if(el.graph_legend.includes(series_name)){
                                let idx = _.indexOf(el.graph_legend, series_name);
                                el.graph_legend.splice(idx, 1);
                            }
                            else{
                                el.graph_legend.push(series_name)
                            }
                        },
                        
                        //eslint-disable-next-line
                        mounted: function(context, config){
                            //restore scrubber state
                            
                            //el.setGraphZoom();
                            el.buildScrubberConfig();
                        },
                        //eslint-disable-next-line
                        updated: function(context, config){
                            el.graph_legend.forEach(( name )=>{
                                el.$refs['mainChart'+el.graph_id].hideSeries(name);
                            })
                        },
                    },
                        type: "area",
                        height: 230,
                        foreColor: "#ccc",
                        toolbar: {
                        autoSelected: "pan",
                        show: false
                        },
                        animations:{
                        enabled: false,
                    },
                    },
                    
                    //colors: ["#00BAEC", "#ff00ec", "#12EC00"],
                    stroke: {
                        width: 2,
                    },
                    grid: {
                        borderColor: "#555",
                        clipMarkers: false,
                        yaxis: {
                        lines: {
                            show: false
                        }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        gradient: {
                        enabled: false,
                        opacityFrom: 0.25,
                        opacityTo: 0
                        }
                    },
                    tooltip:{
                        x:{
                            formatter(value){
                                return el.getSimpleDate(value);
                            }
                        }
                    },
                    markers: {
                        size: 0,
                        //colors: ["#041cb5"],
                        //strokeColor: ["#00BAEC", "#ff00ec"],
                        strokeWidth: 3
                    },
                    xaxis: {
                        type: 'datetime',
                        //type: 'numeric',
                        tickAmount: 'dataPoints',
                        //type: "category",
                        //tickPlacement: 'on',
                        //tickAmount: this.all_graph_series_top[0] ? this.all_graph_series_top[0].data.length - 1  : 10 ,
                        labels: {
                            //eslint-disable-next-line
                            formatter: function (value, timestamp) {
                                if(isNaN(value) || timestamp == undefined){
                                    return '';
                                }
                                switch(el.custom_time_period){
                                    
                                    case 'weekly':{
                                        return 'W' + getWeekOfMonth(new Date(value)) + ' ' + format(new Date(value),'MMM yy');
                                    }
                                    case 'monthly':{
                                        return format(new Date(value),'MMM') + ' ' + format(new Date(value),'yy');
                                    }
                                    case 'quarterly':{
                                        return format(new Date(value),'QQQ') + ' ' + format(new Date(value),'yyyy');
                                    }
                                    case 'yearly':{
                                        return format(new Date(value),'yyyy');
                                    }
                                    default:{
                                        return format(new Date(value), 'dd MMM yy');
                                    }
                                }
                                //return 'a'//new Date(timestamp) // The formatter function overrides format property
                            }, 
                        }
                    },
                    yaxis: {
                        min: 0,
                        //tickAmount: 4,
                        //showForNullSeries: true,
                        forceNiceScale: true
                    },
                noData: {
                    text: 'No Chart Data',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: -10,
                    style: {
                        color: 'grey',
                        fontSize: '14px',
                        //fontFamily: undefined
                    }
                },
            }
            if(show_legend == false){
                config.xaxis.labels.show = false;
            }
            else{
                config.xaxis.labels.show = true;
            }
            return config;
        },
        buildScrubberConfig(){
            let el = this;
            //this.is_scrubber_loading = true;
            let config = {
                chart: {
                    parentHeightOffset: '0px',
                    id: "brushChart"+this.graph_id,
                    height: 150,
                    type: "line",
                    foreColor: "#ccc",
                    brush: {
                    target: this.graph_id,
                    enabled: true
                    },
                    events:{
                        //eslint-disable-next-line
                        brushScrolled: function(chartContext, { xaxis, yaxis }) {
                            el.handleBrushScroll(xaxis.min, xaxis.max);
                        },
                    },
                    animations:{
                        enabled: false,
                    },
                    selection: {
                        enabled: true,
                        //fill: {
                        //    color: "#C7F3FF",
                        //    opacity: 0.4
                        //},
                        xaxis: {
                            //min: start.getTime(),
                            //max: end.getTime()
                            /*min: start.getTime(),
                            max: end.getTime()*/
                        }
                    }
                },
                legend:{
                    show: false,
                },
                //colors: ["#00BAEC", "#ff00ec"],
                //series: this.all_graph_series,
                stroke: {
                    width: 2
                },
                grid: {
                    borderColor: "#444"
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    //min: start.getTime(),
                    //max: end.getTime(),
                    type: "datetime",
                    /*tooltip: {
                    enabled: false
                    }*/
                },
                
                yaxis: {
                    tickAmount: 1
                }
            }
            this.scrubber_config = config;
        },
        formatTrackingDate(date){
            let date_arr = date.split("-");
            let new_date = new Date(date_arr[2]+' '+date_arr[1]+' '+date_arr[0] +' GMT');
            if(this.data.current_time_period === 'daily'){
                return format(new_date, 'iii dd MMM yyyy');
            }
            else{
                return format(new_date, 'dd MMM yyyy');
            }
            
        },
        processCustomWeeklySeries(range_start, range_end){
            let dates = [];
            dates = eachWeekOfInterval({ start:parseISO(range_start), end:parseISO(range_end) });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfWeek(date);
                let end = endOfWeek(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');
                this.tracking_values.push({
                    
                    //date: `${format(start, "dd MMM")} > ${format(end, "dd MMM")}`,
                    date: formatted_date.getTime(),
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        processCustomMonthlySeries(range_start, range_end){
            let dates = [];
            dates = eachMonthOfInterval({ start:parseISO(range_start), end:parseISO(range_end) });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfMonth(date);
                let end = endOfMonth(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');
                this.tracking_values.push({
                    date: formatted_date.getTime(),
                    //date: `${format(start, "dd MMM")} > ${format(end, "dd MMM")}`,
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        processCustomYearlySeries(range_start, range_end){
            let dates = [];
            dates = eachMonthOfInterval({ start:range_start, end:range_end });
            dates.forEach( (date, idx) => {
                //* get the start and end of each week
                let start = startOfMonth(date);
                let end = endOfMonth(date);
                let total_value = 0;
                let total_target = 0;
                while(isBefore(start, end)){
                    //console.log('WHILE LOG x')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(this.data.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += this.data.tracking[index].value;
                        total_target += this.data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                this.tracking_values.push({
                    date: 'current_date',
                    value: total_value,
                    target_value: total_target,
                    id: idx,
                });
            });
        },
        canShow(type){
            let valid = false;
            switch(this.data.current_time_period){
                case 'daily':{
                    if(type === 'daily' || type === 'weekly' || type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'weekly':{
                    if(type === 'weekly' || type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'monthly':{
                    if(type === 'monthly' || type === 'quarterly' || type === 'yearly' ){
                        valid = true;
                    }
                    break;
                }
                case 'quarterly':{
                    if(type === 'quarterly' || type === 'yearly'){
                        valid = true;
                    }
                    break;
                }
                case 'yearly':{
                    if(type === 'yearly'){
                        valid = true;
                    }
                    break;
                }
            }
            return valid;
        },
        emitGraphSize(size){
            this.$emit('updateColSize', size);
        },
        traverseGraph(type){
            if(this.is_metric){
                let params = {
                    metric_id: this.data.id,
                    type: type,
                }
                this.traverseMetric(params)
                .then(()=>{
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserID,
                    }
                    this.loadObjectiveDetailAPI(params).then(() => {
                        //this.processSeries();
                    });
                })
            }
            else if(this.is_key_result){
                //* kr graphs

            }
            else{
                //* kpi graphs
                let params = {
                    kpi_id: this.data.id,
                    type: type,
                }
                this.traverseKPI(params)
                .then(()=>{
                    this.reloadData();
                })
            }
            
        },
        deleteItem(id){
            if(this.is_metric){
                let params = {
                    metric_id: id,
                }
                this.this.deleteMetric(params)
                .then(()=>{
                    this.reloadData();
                })
            }
            else if(this.is_key_result){
                console.log('handle delete')
            }
            else{
                let params = {
                    kpi_id: id,
                };
                this.deleteKpi(params)
                .then(()=>{
                    this.reloadData();
                })
            }
        },
        reloadData(){
            let params = {
                id: this.selected_objective.id,
                user_id: this.activeUserID,
            }
            this.loadObjectiveDetailAPI(params).then(() => {
                //this.processSeries();
            });
        },
        showModal(){
            //tell parent component to display this graph component in a fullscreen modal
            this.$emit('openFullscreenModal', this.data.id);
        },
        toggleGraphDatasetEditMode(){
            if(this.are_kpis_visible){
                this.$swal.fire("Please hide KPI graphs before editing the current Key Result's dataset");
                return;
            }
            this.setDatasetMode();
            this.setGraphEditState({
                    type: 'kr',
                    state: !this.is_editing_kr_dataset,
            });

            
            
        },
        initComponent(){
            this.custom_time_period = this.data.current_time_period;
            this.value_range = { min: this.data.min_value, max: this.data.max_value };
            this.setDateRange();
            this.initChart();
            this.$nextTick(()=>{ this.hasMounted = true; })
        },
        setDatasetMode(){
            if(this.selected_key_result.share_type !== null){
                this.dataset_mode = 'shared';
                this.calculation_type = this.selected_key_result.share_type;
            }
            else{
                this.dataset_mode = 'default';
            }
        }
    },
    mounted(){
        //Reset scrubber states -- soft fix for desync
        this.setScrubberState({
            type: 'key_result',
            state: false,
        });
        this.setScrubberState({
            type: 'kpi',
            state: false,
        });
        //this.show_expanded_view = this.graph_scrubber_state.is_kr_scrubber_expanded
        
        this.initComponent();
        this.setDatasetMode();
    },
    beforeDestroy(){
        if(this.$refs['mainChart'+this.graph_id]){
            this.$refs['mainChart'+this.graph_id].destroy();
        }
        else{
            console.log('Main chart undefined')
        }
        if(this.$refs['brushChart'+this.graph_id]){
            this.$refs['brushChart'+this.graph_id].destroy();
        }
        else{
            console.log('brush chart undefined')
        }
    }
}
</script>

<style>
    .apexcharts-legend-text{
        font-size: 12px !important;
    }
    /*.apexcharts-text tspan {
        font-size: 10px;
    }*/
    .datatype-popover-class.popover{
        border: 1px solid rgb(120, 120, 120);
    }
    .datatype-popover-class > .popover-body{
        /*color: white;*/
    }


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>