<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-15T13:33:16+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T14:24:05+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>
        <b-card class="card-hover" style="">
            <div class="d-flex flex-wrap mb-3" style="width: 100%;">
                <div class="me-2">
                    <h5 class="card-title mr-2 d-flex align-items-center">
                        Job Skills - &nbsp;
                        <span class="text-info">{{ jobName }}</span>
                        <!--<span class="text-info" v-if="activeCandidateId > -1">&nbsp;> {{ activeCandidate.first_name }}</span>-->
                    </h5>
                </div>
                <div style="height: 50px;">

                </div>

                <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="ms-auto me-3 dropdown-menu-end">
                    <strong>Save Table Changes</strong>
                </b-button>
                <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="ms-auto me-3 dropdown-menu-end">
                    <strong>Delete Selected Rows</strong>
                </b-button>


                <!-- -- dropdown -- -->
                <b-dropdown
                    class="ms-auto"
                    text="Button text via Prop"
                    variant="white"
                    toggle-tag="a"
                    menu-class="dropdown-menu-end"
                    toggle-class="font-size-16 p-0"
                    right
                >
                    <template #button-content>
                        <i class="mdi mdi-dots-horizontal"></i>
                    </template>


                    <b-dropdown-item v-if="table_view_mode != 'view'" variant="primary" href="#" @click="toggleViewMode()" >
                        View Mode
                    </b-dropdown-item>
                    <b-dropdown-item v-if="table_view_mode != 'edit'" variant="primary" href="#" @click="toggleEditMode()" >
                        Edit Mode
                    </b-dropdown-item>
                    <b-dropdown-item v-if="table_view_mode != 'delete'" variant="danger" href="#" @click="toggleDeleteMode()" >
                        Delete Mode
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <!-- Flip -->
            <div class="">
              <div>
                <!-- flip front -->
                <div v-show="flip" class="" style="text-align: left;">
                    <b-alert v-show="skillsData.length == 0" show>No Skills Added</b-alert>
                    <Table
                    :uid="table_uid" 
                    :is_loading="is_loading"
                    :data="skills"
                    :columns="columns"
                    :table_mode="table_view_mode"
                    @selectedRowEvent="selectedRowEvent"
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                    />
                </div>
                <!-- flip back -->
                <div class="" v-if="!flip">
                  <!-- Guideline -->
                  <Guideline
                    v-if="showGuideline"
                    ref="guideline"
                    :cardName="'PlanOverview'"
                    :isEditGuideline="false"
                  />
                </div>
              </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
//import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from 'lodash';
import Swal from "sweetalert2";
import Table from '@/components/widgets/table.vue'
export default {
    name: "Admin",
    components: {
        //FilterComponent,
        Guideline, Table
    },
    data:() => ({
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'jobSkillsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,


        searchText: "",
        searchDate: "",
        resetFilter: "",
        isEditingNew: false,
        flip: true,
        showGuideline: false,
        editableId: -1,
        skillsData: [],
        addingRow: false,
        tableFields: [
            { key: "skill_name", label: "Skill"},
            { key: "proficiency_achieved", label: "Proficiency Achieved"},
            { key: "interviewer_user_id", label: "Interviewer"},
            { key: "comment", label: "Comment"},
        ],
        statusOptions: [
            {value: 10, text: "10%"},
            {value: 25, text: "25%"},
            {value: 50, text: "50%"},
            {value: 75, text: "75%"},
            {value: 100, text: "100%"}
        ]
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        active_job_data(){
            let data = null;
            if(this.activeJobId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeJobId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
        candidate_name(){
            if(this.active_candidate_data != null){
                return this.active_candidate_data.first_name;
            }
            else{
                return '';
            }
            
        },
        skills(){
            let data = [];
            if(this.active_job_data != null){
                data = this.active_job_data.skills;
            }
            return data;
        },
        columns(){
            return [
                //TODO ask about current_salary
                { id: 'skill_name', header: 'Skill Name', fillspace: 1, editor: "text",},
                { id: 'skill_description', header: 'Description', fillspace: 4, editor: "text",},
            ]
        },



        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        storeHiringData() {
            return this.levelPeopleData.hiring;
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            return "";
        },
        activeJob() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeJobId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex];
            }
            return [];
        },
        storeSkillsData() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeJobId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex].skills;
            }
            return [];
        },
        activeCandidate() {
            var el = this;
            var findIndex = _.findIndex(this.activeJob.candidates, function(o) { return o.id == el.activeCandidateId; });
            if (findIndex > -1) {
                return this.activeJob.candidates[findIndex];
            }
            return [];
        },
        userList() {
            var users = [];
            _.forEach(this.level_users, function(value) {
               users.push({value: value.id, text: value.name});
            });
            return users;
        }
    },
    props: {
        activeJobId: {
            required: true
        },
        activeCandidateId: {
            default: -1,
        }
    },
    watch: {
        activeJobId() {
            this.$nextTick(() => {
                this.mapSkillsData();
            });
        },
        storeHiringData() {
            this.$nextTick(() => {
                this.mapSkillsData();
            });
        },
        activeCandidateId() {
            this.$nextTick(() => {
                this.mapCandidateSkills();
            });
        }
    },
    methods:{
        ...peopleMethods,
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            console.log(row.id);
            //this.selected_candidate = row.id;
            //this.$eventHub.$emit('setActiveCandidate', this.selected_candidate);
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------

        saveTable(){
            let table = window.webix.$$(this.table_uid);
            let is_valid = true;
            this.skills.forEach( item => {
                let result = table.validate(item.id);
                if(result != true){
                    is_valid = false;
                }
            });
            if(is_valid){
                this.is_loading = true;
                const all_data = table.serialize(null, true);
                let rows_to_save = [];
                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        var params = {
                            id: newRow ? -1 : row.id,
                            skill_name: row.skill_name,
                            skill_description: row.skill_description,
                        };
                        rows_to_save.push(params);
                    }
                })

                let skills_params = {
                    job_id: this.activeJobId,
                    skills: rows_to_save,
                }
                this.saveJobSkill(skills_params)
                .then(()=>{
                    this.loadHiringData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.changes_made_flag = false;
                    })
                })
            }
        },



        mapSkillsData() {
            this.skillsData = this.storeSkillsData.map(item => ({...item, isEdit: false, proficiency_achieved: "", interviewer_user_name: "", comment: "" }));
            if (this.activeCandidateId > -1) {
                this.mapCandidateSkills();
            }
        },
        mapCandidateSkills() {
            var el = this;
            var findIndex;
            _.forEach(el.skillsData, function(value, index) {
                findIndex = _.findIndex(el.activeCandidate.skills, function(o) { return o.skill_id == value.id; });
                if (findIndex > -1) {
                    el.skillsData[index]['proficiency_achieved'] = el.activeCandidate.skills[findIndex].proficiency_achieved;
                    el.skillsData[index]['interviewer_user_id'] = el.activeCandidate.skills[findIndex].interviewer_user_id;
                    //el.skillsData[index]['interviewer_user_name'] = el.activeCandidate.skills[findIndex].interviewer_user_name;
                    el.skillsData[index]['comment'] = el.activeCandidate.skills[findIndex].comment;
                } else {
                    el.skillsData[index]['proficiency_achieved'] = "";
                    el.skillsData[index]['interviewer_user_id'] = "";
                    //el.skillsData[index]['interviewer_user_name'] = "";
                    el.skillsData[index]['comment'] = "";
                }
            });
        },
        renderUserName(id) {
            var findIndex = _.findIndex(this.userList, function(o) { return o.value == id; });
            if (findIndex > -1) {
                return this.userList[findIndex].text;
            }
            return "";
        },
        setRowActive(data) {
            if (data[0] && data[0].id) {
                this.editableId = data[0].id;
                //this.$eventHub.$emit('setActiveSkill', this.editableId);
            }
        },
        editRow() {
            var el = this;
            var findIndex = _.findIndex(this.skillsData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                this.skillsData[findIndex].isEdit = !this.skillsData[findIndex].isEdit;
            }
        },
        addRow() {
            var rowData = {
                id: -1,
                job_id: this.activeJobId,
                skill_name: "",
                skill_description: "",
                proficiency_achieved: "",
                interviewer_user_id: "",
                comment: "",
                isEdit: true
            };
            this.cancelEdit();
            this.addingRow = true;
            this.skillsData.push(rowData);
        },
        cancelEdit() {
            var el = this;
            //eslint-disable-next-line no-unused-vars
            this.addingRow = false;
            _.forEach(this.skillsData, function(value, index) {
               el.skillsData[index].isEdit = false;
            });
        },
        saveRow() {
            var findIndex = _.findIndex(this.skillsData, function(o) { return o.isEdit == true; });
            if (findIndex > -1) {
                //save the evidence row
                //if editing candidate then save candidate performance
                this.saveJobSkill(this.skillsData[findIndex]).then((id) => {
                    if (this.activeCandidateId > -1) {
                        this.skillsData[findIndex]["skill_id"] = id;
                        this.skillsData[findIndex]["candidate_id"] = this.activeCandidateId;
                        this.saveCandidateSkill(this.skillsData[findIndex]).then(() => {
                            this.loadPeopleData(this.selected_level.id);
                            this.addingRow = false;
                        });
                    } else {
                        this.loadPeopleData(this.selected_level.id);
                    }
                });
            } else {
                alert("no rows being edited");
            }
        },
        deleteRow() {
            Swal.fire({
                title: "Are you sure you want to delete this skill?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    var params = {skill_id: this.editableId};
                    this.deleteJobSkill(params).then(() => {
                        this.loadPeopleData(this.selected_level.id);
                    });
                }
            });
        }

    },
    mounted(){
        //this.mapSkillsData();
    }
}
</script>
