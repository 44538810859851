<template>
    <div>
        <!-- search bar -->
        <div class="d-flex m-1">
            <span class="me-2 pt-1"><h6>Employees</h6></span>
            <b-form-input size="sm" id="search-bar" placeholder="Search..." onchange="searchUser()" class="w-25 me-2" v-model="search"></b-form-input>
            <b-button size="sm" v-on:click="searchClear()">
                <i class="fas fa-search"></i>
            </b-button>
            <span class="ms-auto" style="display: inline-flex; align-items: center;">
                <GuidelineModalButton style="padding: 0.125rem 0.25rem;" class="ms-1 me-1" guideline_key="employee_card_list"/>
                <span class="d-flex" style="font-size: 10px;">
                    <b-button v-if="$hasPerm('people-manager')" title="Add User" @click="showAddUserModal" style="padding: 0.125rem 0.25rem;" variant="outline-success" size="sm">
                        <span style="font-size: 10px;">
                            Add User
                        </span>
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </b-button>
                </span>
                <span class="form-check form-switch d-flex" style="font-size: 10px; margin-left: 1rem; display: inline-flex; align-items: center;">
                    <span>
                        <input class="form-check-input" style="cursor:pointer;" v-model="show_archived_users" type="checkbox" id="showArchived"/>
                        <label class="form-check-label" for="showArchived">
                            Show Archived Users
                        </label>
                    </span>
                </span> 
            </span>
                   
        </div>
        <!-- all user cards -->
        <b-row class="" v-if="search === ''">
            <b-col cols="12" class=" mb-2"> <!-- media -->
                <div class="d-flex flex-wrap" style="justify-content: flex-start;">
                    <span  v-for="user in users" :key="user.id" @click="selectUser(user.id)">
                        <userCard :user="user"/>
                    </span>
                    
                </div>
            </b-col>
        </b-row>
        <!-- searched user cards -->
        <div v-else>
            <b-row v-if="searchUser().length != 0">
                <b-col cols="12" class=" mb-2"> <!-- media -->
                    <div class="d-flex flex-wrap" style="justify-content: flex-start;">
                        <span  v-for="user in searchUser()" :key="user.id" @click="selectUser(user.id)">
                            <userCard :user="user"/>
                        </span>
                    </div>
                </b-col>
                <!--<b-col cols="4" v-for="user in searchUser()" :key="user.id" @click="selectUser(user.id)" class="media mb-2">
                    <userCard :user="user"/>
                </b-col>-->
            </b-row>
            <!-- alert error message -->
            <div v-else>
                <b-alert variant="warning" class="d-flex flex-column m-3" show>
                    <h4 class="p-1"><b-icon icon="exclamation-circle" class="m-2"></b-icon>No Employees Found</h4>
                    <div>
                        <ul>
                            <li>
                                <h6>Try Looking under a different hierarchy</h6>
                            </li>
                            <li>
                                <h6>Check your spelling</h6>
                            </li>
                        </ul>
                    </div>
                </b-alert>
            </div>
        </div>
        <AddNewUserModal v-if="show_modal" />
    </div>
</template>

<script>
//* import level store
import { levelComputed, adminComputed, levelMethods, peopleComputed, peopleMethods } from '@/state/helpers'
import userCard from './userCard.vue'
import AddNewUserModal from '@/components/menu/addNewUserModal.vue'
import { sortBy, uniqBy } from 'lodash'
import GuidelineModalButton from '@/components/widgets/misc/guidelineModalButton.vue';

export default {
    data(){
        return{
            search: '',
            show_archived_users: false, 
            show_modal: false    
        }
    },
    components:{
        userCard,
        AddNewUserModal,
        GuidelineModalButton
    },
    computed:{
        //* merge variables from store into current scope
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        users(){
            if(this.show_archived_users){
                let user_list = this.levelEmployeesData.filter( user => user.archived == true);
                user_list = uniqBy(user_list, 'id');
                return sortBy(user_list, 'name');
            }
            else{
                let user_list = this.levelEmployeesData.filter( user => user.archived == undefined);
                user_list = uniqBy(user_list, 'id');
                return sortBy(user_list, 'name');
            }
        },
        
        
        returnUsers(){
            //levelEmployeesData
            let users = [];
            for (var key in this.menu_users){
                // then loop the users
                this.menu_users[key].forEach( user =>{
                    users.push(user);
                })
            }
            return users
        },
        // returnUsers(){
        //     let users = [];
        //     for (var index in this.levelEmployeesData.length){
        //         this.levelEmployeesData[index].forEach(user => {
        //             users.push(user)
        //         });
        //     }
        //     return users;
        // }
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        showAddUserModal(){
            //check if no tables are in edit mode (webixTableState store)
            this.show_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('newUserModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.componentId == "newUserModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_modal = false;
                        //* reset payload
                        //this.modal_payload = null;
                    }
                });
            })
        },
        selectUser(user_id){
            //if user is archived then push router with user_id as a param
            //if(this.show_archived_users){
            //    this.$router.push(`/people/${user_id}`);
            //    return;
            //}
//
            //TODO - check if id matches self id
            //TODO - check if people manager id match

            let user = this.users.find( user => user.id == user_id);
            let job_key = user.current_job != null ? user.current_job.title : 'Unassigned'
            let payload = {
                id: user_id,
                job_key: job_key,
            }
            if(this.employeeDetails) {
                if(this.employeeDetails.id != user.id) {
                    this.loadEmployeeData(user.id).then(() => {
                        
                    })
                }
            }
            this.$nextTick(() =>{
                this.setSelectedUser(payload);
            })    
        },

        //   search and push employees into new array
        searchUser(){
            let searchedUsers = [];
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].name.toLowerCase().includes(this.search.toLowerCase())){
                    searchedUsers.push(this.users[i])
                }
            }
            return searchedUsers;
        },

        //   clear search bar
        searchClear(){
            this.search = ''
        },

    },
    mounted(){},
}
</script>
