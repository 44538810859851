//* Generic utility methods
export default {
    methods:{
        flattenArray(array_data){
            const flatten = (members) => {
                let data = [];
                return members.map(m => {
                    if (m.data && m.data.length) {
                        data = [...data, ...m.data];
                    }
                    return m;
                }).concat(data.length ? flatten(data) : data);
            };
            return flatten(array_data);
        }
    }
}