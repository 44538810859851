<template>
    <span class="d-flex " >
        <!-- //TODO - remove the related event -->

        <!--<b-button variant="primary" class="me-1" size="sm" @click="fireSaveEvent()">Save Changes</b-button>-->

        
        <span v-if="false && datepicker_type != 'none'" class="d-inline-flex me-1"><!-- datepickers -->
            <date-picker ref="rangePicker" v-if="datepicker_type === 'range'" style="width: 50px;" v-model="range_date" size="sm" placeholder="Date Range" range append-to-body lang="en"></date-picker>
            <date-picker ref="jumpPicker" v-if="datepicker_type === 'jump'" style="width: 50px;" v-model="jump_to_date" size="sm" placeholder="Jump to Date" append-to-body lang="en"></date-picker>
        </span>
        <span v-if="false"><!-- options icons -->
            <span class="">
                <b-button :variant="hasDateRange ? 'primary': 'outline-primary'" @click="setDatepickerType('range')" class="me-1" size="sm" title="Filter Range">
                    <!--<i class="fas fa-calendar-week"></i>-->
                    Range
                </b-button>
                <b-button variant="outline-primary" @click="setDatepickerType('jump')" class="me-1" size="sm" title="Jump To Date">
                    <!--<i class="fas fa-calendar"></i>-->
                    Jump To
                </b-button>
            </span>

        </span>
        
    </span>
</template>

<script>
import { format, isWithinInterval} from 'date-fns';
import DatePicker from "vue2-datepicker";
import isEqual from 'date-fns/isEqual/index';
import Swal from 'sweetalert2';
export default {
    props:{
        table_id:{
            required: true,
        },
        is_kpi:{
            default:function(){
                return false;
            }
        }
    },
    components:{
        DatePicker
    },
    data:() => ({
        range_date: null,
        jump_to_date: null,
        datepicker_type: 'none', // range or jump
    }),
    watch:{
        
        range_date(newValue){
            if(newValue != null){
                this.jump_to_date = null;
                if(newValue[0] != null){
                    window.webix.$$(this.table_id).filter(function(obj) {
                        let start = newValue[0];
                        let end = newValue[1];
                        //* removes the time issue when comparing 2 dates
                        let no_time_date = new Date(
                            format(new Date(obj.date), 'yyyy'),
                            format(new Date(obj.date), 'MM')-1,
                            format(new Date(obj.date), 'dd'),
                            0,
                            0,
                            0,
                            0
                        );
                        if(isWithinInterval(new Date(obj.date), {start: start, end: end})){
                            return true;
                        }
                        else if(isEqual(no_time_date, end)){
                            return true;
                        }
                        else{
                            return false;
                        }
                        //return isWithinInterval(new Date(obj.date), {start: start, end: end});
                    })
                }
                else{
                window.webix.$$(this.table_id).filter();
                }
            }
        },
        jump_to_date(newValue){
            if(newValue != null){
                window.webix.$$(this.table_id).filter();
                this.range_date = null;
                let date = format(newValue,'yyyy-MM-dd');
                let result = window.webix.$$(this.table_id).find(function(obj){
                    return obj.date === date;
                })
                if(result.length > 0){
                    window.webix.$$(this.table_id).select(result[0].id);
                    window.webix.$$(this.table_id).editCell(result[0].id, 'value');
                }
                else{
                    let title = format(newValue,'dd-MMM-yyyy') + ' not found.'
                    Swal.fire({ icon:'warning', title: title, toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                }
            }
            else{
                window.webix.$$(this.table_id).filter();
            }
        }
    },
    computed:{
        progress(){
            return 50;
        },
        hasDateRange(){
            if(this.range_date != null && this.range_date[0] != null){
                return true
            }
            else{
                return false;
            }
        },
    },
    methods:{
        setDatepickerType(type){
            if(this.datepicker_type === type){
                this.datepicker_type = 'none';
            }
            else{
                this.datepicker_type = type;
                this.$nextTick(()=>{
                    if(type === 'range'){
                        this.$refs.rangePicker.focus();
                    }
                    if(type === 'jump'){
                        this.$refs.jumpPicker.focus();
                    }
                    
                })

            }
        },
        fireSaveEvent(){
            if(this.is_kpi){
                this.$eventHub.$emit('saveGraphDatatableKPI', this.table_id)
            }
            else if(this.is_metric){
                this.$eventHub.$emit('saveGraphDatatableMetriuc', this.table_id)
            }
            else{
                this.$eventHub.$emit('saveGraphDatatable', this.table_id)
            }
            
        }
        //* also checkout shortcuts
        /*disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

              return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },*/

    },
    mounted(){},
}
</script>

<style>
.mx-datepicker > .mx-input-wrapper > input{
    font-size: 10.5px;
    padding: 0px 6.4px !important;
    height: 24px !important;
    width: 170px;
}

.progBar{
    background-color: #cecece;
}
</style>
