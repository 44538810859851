<!--
@Author: Calvin Green <calvin>
@Date:   2022-02-02T10:45:30+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2022-02-02T11:27:35+02:00
@Copyright: Diversitude 2021
-->



<template>
    <b-overlay :show="is_loading" >
        <div v-if="show_table" :class="viewModeClass"> <!-- @mouseleave="closeWebixEditor" < removed due to isses with selecting a date from picker(triggerd close element) -->
            <webix-ui :config="ui"></webix-ui>
            <!-- temp removed pager because it stopped working -->
            <!--<div v-show="table_mode === 'edit' && table_mode == 'disabled'" :id="uid + 'pager'"></div>-->
            <div v-show="hasPagerProp" :id="uid + 'pager'" style="width: 100%;"></div>

            <div v-if="canShowErrors" class="error-list">
                <span v-for="(error, idx) in error_messages" :key="idx">
                    <span v-if="idx != 0">, </span>{{error}}
                </span>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import {webixTable, webixTableState} from "@/state/helpers.js";
import Swal from 'sweetalert2'
import _ from 'lodash';
export default {
    props:{
        uid:{//* Parent component gives the table a unique id/name
            required: false,
            default(){
                return window.webix.uid();
            }
        },
        active_id:{//* get the row that should be active from the parent component
            default: -1,
        },
        data:{//* array of table rows
            required: true,
        },
        columns:{//* array of column configuration objects
            required: true,
        },
        table_mode:{//* string of current table mode ->  (view - edit - delete - drag)
            required: false,
            default(){
                return 'view'
            }
        },
        treetable:{//* Boolean value to set table view as datatable or treetable
            type: Boolean,
            default:function(){
                return false;
            }
        },
        is_loading:{//* show b-overlay spinner over table
            type: Boolean,
            default:function(){
                return false;
            }
        },
        validation:{//* an object containing validation rules for columns
            default:function(){
                return {};
            }
        },
        validation_messages:{ //* validation messages with the same key as the column validation rule
            default:function(){
                return {};
            }
        },
        button_methods:{
            default:function(){
                return false;
            }
        },
        no_extra_rows:{
            //TODO fix this --
            type: Boolean,
            default:function(){
                return false;
            }
        },
        context_menu_items:{
            //*! ONLY SUPPORTS THE FOLLOWING ACTIONS
            //* ['View', 'Edit', 'Delete', 'Drag']  
            default:function(){
                return [];
            }
        },
        hide_clear_column:{
            //* by default a Clear colum gets inserted during edit mode (used to clear the data of a new row -- resets data for that row)
            //* set to true to hide and disable this feature
            default: false,
        },
        only_editable_columns:{
            //* Hide all non editable columns while in edit mode
            type: Boolean,
            default: false
        },
        pager_config:{
            type: Number,
            required: false,
            default:function(){
                return 0;
            },
        },
        prevent_first_row_select:{
            //* By default tables select the first row (if avaliable)
            type: Boolean,
            default: false,
        },
        sticky_columns:{//! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            //* freeze columns from scrolling (starting from the left)
            //! If using sticky columns > 0 then each column needs to have a width property set
            default: 0
        },
        compact_mode:{
            //* only show columns with the compact flag
            default: false,
        },
        default_values:{
            default:function(){
                return null;
            }
        },
        custom_editor_rules:{
            default:function(){
                return false;
            }
        },
        table_height:{
            default:function(){
                return false;
            }
        },
        //* flag to save the row id to a vuex store when opening an editor in a cell
        enable_editor_id_store:{
            type: Boolean,
            default: false
            //! need to find a better solution.. 
        },
        //* array of column id's that must ignore the
        //* 'only_editable_columns' rule
        ignore_edit_only_rules:{
            type: Array,
            default:function(){
                return [];
            }
        },
        re_order:{
            type: Boolean,
            default: false
        }

    },
    data:() => ({
        //* used to save the opened state of treetable views
        last_tree_state: null,

        //* keep track of previous selected id, used to determine if the treestate must be restored;
        oldActiveID: -1,

        //* id of treetable row when right clicked
        right_clicked_id: null,

        context_menu_id: null,

        //* get the last added row - used when using right click to add child row (add the row then select it)
        last_added_row: null,

        block_set_state_event: false,

        show_table: false, //* set to true after processing columns/data
        hasMounted: false,
        //* variables emitted to parent component
        changes_made_flag: false, //* track if data was changed in edit mode
        delete_array:[], //* used to return an array of the items flagged for delete

        webix_resize_event: null, //* assign resize event to var. (var used to destroy event in the beforeDestroy hook)

        last_selected_item: null,

        item_moved_flag: false, //* set true when item is moved

        //* boolean to allow the onValidationError event to fire 
        show_error_msg: false,
        error_messages: [],
        //TODO - make computed function
        canShowErrors: true,

        ui: { //* Webix config object - data/columns gets populated in the mounted hook
            id: "",//* set unique id from uid prop
            view: "treetable",
            css: "textClass",
            select: "row",
            tooltip:true,
            autoheight: true,
            fillspace: true,
            editaction: "click",
            editable: false,
            drag:false,
            data:[],
            //scrollY: true,
            responsive: true,
            columns: [],
            scroll: false,
            scheme:{
                $change:function(item){
                    if (item.is_new != undefined){
                        item.$css = "new_row_style";
                    }
                    else if(item.delete_selection != undefined){
                        if(item.delete_selection == true){
                            item.$css = "delete_row_style"
                        }
                    }
                }
            },
            onClick: {
                //eslint-disable-next-line no-unused-vars
                'unlink_button':function(event, item){
                    //!! DEPRECIATED - delete
                    //eslint-disable-next-line
                    let el = this.$scope.$parent.$parent;

                    el.$emit('onParentUnlink', {id: item.row})
                },
                //eslint-disable-next-line no-unused-vars
                'move-item-up':function(event, item){
                    //eslint-disable-next-line no-unused-vars
                    let el = this.$scope.$parent.$parent;
                    if(el.changes_made_flag){
                        Swal.fire('Please save unsaved changes before reordering')
                    }
                    else{
                        //eslint-disable-next-line
                        let row = this.getItem(item.row);
                        let idx = null;
                        if(el.treetable){
                            idx = this.getBranchIndex(item.row);
                        }
                        else{
                            idx = this.getIndexById(item.row);
                        }
                        idx = idx - 1;//* set as previous index
                        //* only items with a parent id need to have the extra params
                        if(row.$parent != 0){
                            this.move(item.row, idx, this, {parent:row.$parent});
                            el.processOrderArray(row.$parent);
                        }
                        else{
                            this.move(item.row, idx);
                            el.processOrderArray();
                        }
                    }
                    
                },
                //eslint-disable-next-line no-unused-vars
                'move-item-down':function(event, item){
                    //eslint-disable-next-line no-unused-vars
                    let el = this.$scope.$parent.$parent;
                    if(el.changes_made_flag){
                        Swal.fire('Please save unsaved changes before reordering')
                    }
                    else{
                        //eslint-disable-next-line
                        let row = this.getItem(item.row);
                        let idx = null;
                        if(el.treetable){
                            idx = this.getBranchIndex(item.row);
                        }
                        else{
                            idx = this.getIndexById(item.row);
                        }
                        idx = idx + 1;//* set next index
                        //* only items with a parent id need to have the extra params
                        if(row.$parent != 0){
                            this.move(item.row, idx, this, {parent:row.$parent});
                            el.processOrderArray(row.$parent);
                        }
                        else{
                            this.move(item.row, idx);
                            el.processOrderArray();
                        }
                    }
                    
                },
                //eslint-disable-next-line no-unused-vars
                'clear_new_row':function(event, item){
                    //eslint-disable-next-line
                    let el = this.$scope.$parent.$parent;
                    el.$nextTick(()=>{
                        this.editCancel();
                        el.$nextTick(()=>{
                            
                            this.remove(item.row);
                            this.add({
                                id: window.webix.uid(),
                                is_empty: true,
                            });
                            this.clearValidation();
                        })
                    })
                    
                }
            },
            on: {
                onItemDblClick(){
                    let el = this.$scope.$parent.$parent;
                    if(el.table_mode === 'view'){

                        el.$emit('updateViewMode', 'edit');
                    }
                },
                onBeforeSelect(selection){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    let valid = true;
                    if(el.table_mode === 'view'){
                        let item = this.getItem(selection.row);
                        if(item.is_empty == undefined){
                            return true;
                        }
                        if(item.is_empty == true){
                            return false;
                        }
                    }
                    return valid;
                },
                onAfterSelect(id){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    el.last_tree_state = this.getState();
                    if(el.table_mode === 'view'){
                        el.last_selected_item = this.getSelectedId();
                        let item = this.getItem(id);
                        //* return selected row to the parent component
                        let is_new_id = Number(el.active_id) != Number(item.id);
                        //el.msg(`${is_new_id} --> ${Number(el.active_id)} ${Number(item.id)}`)
                        if(is_new_id){
                            el.$nextTick(()=>{
                                //el.$emit('selectedRowEvent', item);
                                el.emitRowSelection(item)
                            })
                        }
                        //* added wait time for parent view to properly render before sending the new row
                        
                    }
                },
                onAfterAdd(id){
                    let el = this.$scope.$parent.$parent;
                    el.last_added_row = id;
                },
                onAfterEditStop(state, target){
                    let el = this.$scope.$parent.$parent;//* get parent context

                    //* check if cell had a valid change
                    if((state.old == undefined || state.old == '') && state.value){
                        let id = target.row;
                        let item = this.getItem(id);
                        if(item.is_empty != undefined){
                            //* flag an empty row as a new row so that the validation rules apply.
                            delete item.is_empty;
                            item['is_new'] = true;
                            if(el.default_values != null){
                                el.default_values.forEach( default_item => {
                                    //* only set the default values on items that are empty or null
                                    if(item[default_item.id] == null || item[default_item.id] === ''){
                                        item[default_item.id] = default_item.value;
                                    }
                                })
                            }
                            //*update the row in webix to reflect the changes
                            this.updateItem(id, item);
                            this.refresh();
                        }
                    }
                },
                onBeforeEditStart(cell){
                    let el = this.$scope.$parent.$parent;
                    if(el.enable_editor_id_store){
                        el.setStoreVariable(cell.row);
                    }
                },
                onBeforeEditStop(state){
                    if(state.old == undefined && state.value === ''){
                        this.editCancel();
                        //delete state.value;
                        state.value = undefined
                    }
                },
                onDataUpdate(){
                    //eslint-disable-next-line
                    var el = this.$scope.$parent.$parent;
                    if(el.table_mode == 'edit'){
                        //* flag changes made to the datatable
                        el.changes_made_flag = true;
                        el.$emit('onDataUpdate', el.changes_made_flag);
                        let empty_row_count = 0;
                        this.eachRow( row => {
                            const rec = this.getItem(row);
                            if(rec.is_empty != undefined){
                                empty_row_count++;
                            }
                        })
                        if(empty_row_count == 0){
                            let row = {
                                id: `newRow-${window.webix.uid()}`,
                                is_empty: true,
                            }
                            this.add(row);
                        }
                    }
                },
                //eslint-disable-next-line
                onCheck(rowID, colID, state){
                    if(state == 1){
                        let el = this.$scope.$parent.$parent;
                        let index = _.findIndex(el.delete_array, {id: rowID});
                        if(index == -1){
                            el.delete_array.push({id: rowID});
                        }
                    }
                    else{
                        let el = this.$scope.$parent.$parent;
                        let index = _.findIndex(el.delete_array, {id: rowID});
                        if(index != -1){
                            el.delete_array.splice(index, 1);
                        }
                    }
                },
                onSelectChange(){
                    //! Changed way of deleting items to use checkboxes
                    /*
                    let el = this.$scope.$parent.$parent;
                    //* only handle the selection array when in delete mode
                    if(el.table_mode == 'delete'){
                        el.delete_array  = this.getSelectedId(true);
                        el.$emit('onDeleteArrayUpdate', el.delete_array);
                    }
                    */
                },
                //eslint-disable-next-line
                onBeforeDragIn:function(context){
                    console.log(context)
                    const start = context.start;
                    const parent = context.parent;
                    let item = this.getItem(start);
                    console.log(start, item.$parent, parent)
                    if(item.$parent == parent){
                        console.log('Valid');
                        //return true
                    }
                    else{
                        console.log('False')
                        //return false;
                    }
                    /*var targetItem = this.getItem(context.target);
                    if(targetItem != undefined){
                        return targetItem.$count ||targetItem.type=="folder";
                    }*/
                },
                //eslint-disable-next-line
                onBeforeDrop:function(context){//context
                    /*var targetItem = this.getItem(context.target);
                    if(targetItem.id == context.start){
                        return false;
                    }
                    context.parent = context.target;	//drop as child
                    context.index = -1;//as last child*/
                },
                /*onAfterDrop:function(obj){
                    let id = obj.start; // current item id
                    let parent_id = obj.parent.row;

                    let el = this.$scope.$parent.$parent;
                    el.$emit('onRowDrag', {id: id, parent_id: parent_id});
                },*/
                //eslint-disable-next-line
                onBeforeDrag:function(context){
                    let el = this.$scope.$parent.$parent;
                    if(el.table_mode === 'view' || el.table_mode === 'edit' || el.table_mode === 'delete'){
                        return false;
                    }
                    let item = this.getItem(context.start);
                    context.html = `<span class="webix_drag_item">${item.name}</span>`;
                },
                //eslint-disable-next-line
                onBeforeContextMenu:function(id, e, node){
                    let el = this.$scope.$parent.$parent;
                    el.right_clicked_id = id;

                    let ctx_menu =  window.webix.$$(el.context_menu_id);
                    if(ctx_menu != undefined){
                        ctx_menu.clearAll();
                        let options = el.getContextMenuItems;
                        ctx_menu.define('data', options);
                        ctx_menu.refresh();
                    }
                },
                
                //eslint-disable-next-line
                onValidationError:function(id, obj, details){
                    //* parent component sets the show_error_msg to true via allowValidationMessage() (uses a ref that is the same as the table_uid)
                    let el = this.$scope.$parent.$parent;
                    if(el.show_error_msg)
                    {
                        el.show_error_msg = false;
                        el.error_messages = [];
                        //* details = the column keys that failed validation
                        const keys = Object.keys(details);
                        keys.forEach( key => {
                            el.error_messages.push(el.validation_messages[key]);
                        });
                    }
                },

                onAfterClose(){
                    let el = this.$scope.$parent.$parent;
                    let can_set_state = (el.table_mode === 'view' || el.table_mode === 'edit') && !el.block_set_state_event;
                    if(can_set_state){
                        el.last_tree_state = this.getState();
                    }
                    
                },
                onAfterOpen(){
                    let el = this.$scope.$parent.$parent;
                    let can_set_state = (el.table_mode === 'view' || el.table_mode === 'edit') && !el.block_set_state_event;
                    if(can_set_state){
                        el.last_tree_state = this.getState();
                    }
                },
                
            },
            ready(){
                let el = this.$scope.$parent.$parent;
                el.context_menu_id = el.ui.id+'-'+window.webix.uid()
                window.webix.ui({
                    view:'contextmenu',
                    id: el.context_menu_id,
                    //data: el.getContextMenuItems,
                    on:{
                        onMenuItemClick:function(id){
                            //* Same actions that appear in the 3 dot menu
                            switch(id){
                                case 'Delete':{
                                    el.$emit('updateViewMode', 'delete');
                                    break;
                                }
                                case 'Edit':{
                                    el.$emit('updateViewMode', 'edit');
                                    break;
                                }
                                case 'View':{
                                    el.$emit('updateViewMode', 'view');
                                    break;
                                }
                                case 'Drag':{
                                    el.$emit('updateViewMode', 'drag');
                                    break;
                                }
                                case 'Add Child Row':{
                                    let row = window.webix.$$(el.uid).getItem(el.right_clicked_id);
                                    //* Prevent adding new rows to rows with the 'is_new' flag
                                    if(row.is_new){
                                        Swal.fire('Unable to add a child row to a new unsaved row. Please save and try again.');
                                        return;
                                    }
                                    let new_row = {
                                        is_new: true,
                                        level_id: row.level_id,
                                        parent_objective_id: row.id,
                                        parent_id: row.id,
                                    };
                                    window.webix.$$(el.uid).add(new_row, 0, el.right_clicked_id);
                                    window.webix.$$(el.uid).open(row.id);
                                    el.$nextTick(()=>{
                                        window.webix.$$(el.uid).select(el.last_added_row);
                                    })

                                    el.changes_made_flag = true;
                                    el.$emit('onDataUpdate', el.changes_made_flag);
                                    

                                }
                            }
                        }
                    }
                }).attachTo(this);
            }
        },
    }),
    computed:{
        ...webixTableState,
        getDebugID(){
            return window.debug_id;
        },
        getContextMenuItems(){
            let arr = [];
            if(this.table_mode === 'edit' && this.treetable == true){
                arr.push('Add Child Row');
                //* return merged array
                return [...arr, ...this.context_menu_items];
            }
            else{
                return this.context_menu_items;
            }
        },
        getView(){//! no idea what this is for??
            if(window.webix.$$(this.uid) != undefined){
                return window.webix.$$(this.uid).config.view;
            }
            else{
                return '-'
            }
        },
        table_data(){
            //* apply fitering/mutations to the table data;
            let data = _.cloneDeep(this.data);

            if(this.treetable){
                data.forEach((item)=>{
                    item.type = 'folder';
                    //item.open = true;
                })
            }

            if(this.table_mode === 'view' ){
                //fill the pager space with empty rows
                if(this.hasPagerProp){
                    let remainder = data.length % this.pager_config;
                    let rows_to_add = this.pager_config - remainder;
                    for(let i = 0; i < rows_to_add; i++){
                        data.push({
                            id: window.webix.uid(),
                            is_empty: true,
                        })
                    }
                }
                else if(data.length <= 5){//* Show empty unclickable rows
                    //* add 5 rows
                    let count = 5 - data.length;
                    for(let i = 0; i < count; i++){
                        data.push({
                            id: window.webix.uid(),
                            is_empty: true,
                        })
                    }
                }
            }
            else if(this.table_mode === 'edit'){
                if(this.hasPagerProp){
                    let remainder = data.length % this.pager_config;
                    //* get available rows to fill rest of the rows as empty
                    let rows_to_add = (this.pager_config - remainder);
                    if(this.hasPagerProp){
                        //* add extra rows to the end of the table using the same no of rows used as the pager config (eg if pager shows 10 rows, add 10)
                        rows_to_add += this.pager_config;
                    }

                    for(let i = 0; i < rows_to_add; i++){
                        data.push({
                            id: window.webix.uid(),
                            is_empty: true,
                        })
                    }
                }
            }

            return data;
        },
        viewModeClass(){
            switch(this.table_mode){
                case 'view':{
                    //return 'border border-light';
                    return 'tableViewMode'
                }
                case 'edit':{
                    //return 'border border-primary';
                    return 'tableEditMode'
                }
                case 'delete':{
                    //return 'border border-danger';
                    return 'tableDeleteMode'
                }
                case 'drag':{
                    //return 'border border-success';
                    return 'tableDragMode'
                }
                default :{
                    return '';
                }
            }
        },
        hasPagerProp(){
            if(this.pager_config > 0){
                return true;
            }
            else{
                return false;
            }
        }
    },
    watch:{
        data:{
            deep: true,
            handler(){
                if (this.hasMounted) {
                    this.$nextTick(()=>{
                        this.updateTableData()
                        //this.refreshConfig(6)
                        .then(()=>{
                            /*if(this.uid == 'objectivesTable'){
                                alert('New')
                            }*/
                            this.item_moved_flag = true;
                            if(this.treetable == true){
                                if(window.webix.$$(this.uid) != undefined && this.last_tree_state != null){
                                    this.$nextTick(()=>{
                                        if(this.table_mode === 'view'){
                                            this.$nextTick(()=>{
                                                this.restoreTreeState();
                                            })
                                        }
                                        else if(this.table_mode === 'drag' || this.table_mode === 'delete'){
                                            window.webix.$$(this.uid).openAll();
                                        }
                                    })
                                }
                            }
                            /*else{
                                alert('No supposed to')
                            }*/
                        })
                    })
                }
            }
        },
        //* set table view type based on the table_mode prop
        table_mode(newValue){
            let table = window.webix.$$(this.uid);
            if(table != undefined && this.treetable == true && this.last_tree_state != null){
                //this.last_tree_state = table.getState();
                this.restoreTreeState();    
            }
            //* reset tracking variables
            this.changes_made_flag = false;
            this.$emit('onDataUpdate', this.changes_made_flag);
            this.delete_array = [];
            //this.$emit('onDeleteArrayUpdate', this.delete_array);

            //* reset error messages
            this.error_messages = [];

            switch(newValue){
                case 'edit':{
                    this.enableEditMode();
                    break;
                }
                case 'delete':{
                    this.enableDeleteMode();
                    break;
                }
                case 'drag':{
                    this.enableDragMode();
                    break;
                }
                default :{
                    this.enableViewMode();
                    break;
                }
            }
        },
        active_id(newValue, oldValue){
            if(this.hasMounted){
                this.oldActiveID = oldValue;
                if(newValue == -1){
                    let table = window.webix.$$(this.uid);
                    if(table != undefined){
                        window.webix.$$(this.uid).clearSelection();
                    }
                }
                else{
                    this.restoreSelection();
                }
            }
        },
        compact_mode(){
            this.refreshConfig(5, true);
        },
        delete_array(){
            this.$emit('onDeleteArrayUpdate', this.delete_array);
        }

    },
    methods:{
        ...webixTable,
        msg(text){
            if(this.uid === 'keyResultsTable' && 'false' == this.uid){
                console.log('MSG: ', text)
            }
        },
        //* set the row id into a webix store - used in computed properties
        //* where different options are required based on level id
        setStoreVariable(id){
            this.setLastEditorID(id)
        },
        allowValidationMessage(){
            this.show_error_msg = true;
        },

        //* restores the expanded treetable nodes before the table was switched to edit mode
        restoreTreeState: _.debounce(function(){
            console.log('MSG RESTORED TREE STATE!!',this.last_tree_state)
            if(this.last_tree_state != null){
                let table = window.webix.$$(this.uid);
                //* HERE WE SET THE LAST SELECTED ID -> using table.open triggers the getState while (upon table reload there is no selection) 
                //eslint-disable-next-line
                let last_selected_id = null;
                if(this.last_tree_state.select.length > 0){
                    last_selected_id = this.last_tree_state.select[0].id;
                }
                
                if(this.last_tree_state.open != undefined){ //* the open array will be undefined if no branches are expanded
                    this.block_set_state_event = true;
                    this.last_tree_state.open.forEach( item => {
                        if(table.exists(item)){
                            table.open(item);
                        }
                    })
                    this.block_set_state_event = false;
                }
                if(localStorage.getItem('treetable_states')){
                    let ls_tree_state = JSON.parse(localStorage.getItem('treetable_states'))
                    if(ls_tree_state[this.uid] != undefined){
                        ls_tree_state[this.uid].forEach((item)=>{
                            if(table.exists(item)){
                                table.open(item);
                            }
                        })
                    }
                }
                

                /*if(last_selected_id != null){
                    table.select(last_selected_id);
                }*/
                if(this.active_id != -1 && this.table_mode === 'view' && table.exists(this.active_id)){
                    table.select(this.active_id);
                }
                if(this.active_id == -1 && this.table_mode === 'view' && this.table_data.length > 0){
                    let id = this.table_data[0].id;
                    if(window.webix.$$(this.uid).exists(id)){
                        window.webix.$$(this.uid).select(id);
                    }
                }
                
            } 
            //window.webix.$$(this.uid).setState(this.last_tree_state);
        }, 100),
        emitRowSelection(item){
            this.$emit('selectedRowEvent', item)
        },

        //* Force webix to process new data
        updateTableData(){
            return new Promise( resolve => {
                let table = window.webix.$$(this.uid);
                let table_pager = window.webix.$$(this.uid+'pager')
                if(table != undefined){
                    table.clearAll();
                    table.parse(this.table_data, 'json', true);
                    table.refresh();
                }
                if(table_pager != undefined){
                    table_pager.refresh();
                }
                this.$nextTick(()=>{
                    this.restoreSelection();
                    //* resolve after ui updates
                    resolve();
                })
            })
        },
        /*handleRowSelection(){

        }*/

        //* Modify the columns of a table based on the selected view mode
        initColumns(prevent_reselect = false){
            console.log(prevent_reselect)
            return new Promise( resolve => {
                //* Process columns
                let columns = _.cloneDeep(this.columns);
                columns.forEach( item => {
                    if(item.readonly == true){
                        item.css = 'read-only-column'
                    }
                })

                if(this.table_mode === 'delete'){
                    //* adds a checkbox to all rows to mark rows for deletion
                    let index = _.findIndex(columns, {id: 'delete_selection'});
                    if(index == -1){
                        columns.unshift({
                            header: 'Delete',
                            id: 'delete_selection',
                            template:"{common.checkbox()}",
                            width:80
                        })
                    }
                }
                else if(this.table_mode === 'drag'){
                    console.log('No drag columns')
                }
                else if(this.table_mode === 'edit'){
                    //* hide non editable columns if 'only_editable_columns' prop is true
                    if(this.only_editable_columns){
                        let filtered_columns = [];
                        columns.forEach( col => {
                            if(col.editor != undefined || this.ignore_edit_only_rules.includes(col.id)){
                                filtered_columns.push(col);
                            }
                        })

                        columns = _.cloneDeep(filtered_columns);

                    }
                    
                    //* insert column to clear newly changed rows
                    //! can be hidden if the hide_clear_column prop is set to TRUE
                    if(this.hide_clear_column == false){
                        let index = _.findIndex(columns, {id: 'remove_new_row'});
                        if(index == -1){
                            columns.push({
                                header: '',
                                id: 'remove_new_row',
                                //template:"<button><i class='fa fas-trash'><i/><button/>",
                                width: 20,
                                template:function(obj){
                                    let html = '';
                                    if(obj.is_new != undefined){
                                        if(obj.is_new == true){
                                            //html = "<div class='webix_el_button'><button style='width: 100%;' class='unlink_button'> Unlink </button></div>"
                                            html = "<div class='clear_new_row'> <i class='fa fa-trash'></i> </div>"//"<button ><i class='fa fas-trash'><i/><button/>"
                                            //html = obj.is_empty
                                        }
                                    }
                                    return html;
                                },
                            })
                        }
                        if(this.re_order){
                            //* adds a delete icon for all newly created rows (unsaved rows)
                            index = _.findIndex(columns, {id: 'unlink_button'});
                            if(index == -1){
                                columns.unshift({
                                    header: 'Row Order',
                                    id: 'unlink_selection',
                                    template:function(obj){
                                        let html = '';
                                        if(obj.is_empty == undefined){
                                            html = `<span>
                                                        <i class='action_icon move-item-up fas fa-arrow-up'></i>
                                                        <i class='action_icon move-item-down fas fa-arrow-down'></i>
                                                    </span>`
                                        }
                                        return html;
                                    },
                                    width:80
                                })
                            }
                        }
                        
                    }
                }
                else{
                //* for VIEW mode
                    //remove checkbox column
                    let index1 = _.findIndex(columns, {id: 'delete_selection'});
                    if(index1 != -1){
                        delete columns[index1];
                    }

                    //remove unlink button column
                    let index2 = _.findIndex(columns, {id: 'unlink_button'});
                    if(index2 != -1){
                        delete columns[index2];
                    }

                    //remove clear new row column
                    let index3 = _.findIndex(columns, {id: 'remove_new_row'});
                    if(index3 != -1){
                        delete columns[index3];
                    }
                }

                //* Optional Prop --
                //* hide all columns that have the 'compact' property added
                if(this.compact_mode == true && this.table_mode != 'edit'){
                    columns.forEach( item => {
                        if(item.compact == true){
                            item['hidden'] = false;
                        }
                        else{
                            item['hidden'] = true;
                        }
                    })
                }

                //* inject columns
                window.webix.$$(this.uid).define({columns: columns});
                window.webix.$$(this.uid).refreshColumns();
                //* inject data
                this.updateTableData().then(()=>{
                    /*if(this.prevent_first_row_select == false){
                        this.$nextTick(()=>{
                            if(this.table_mode === 'view'){
                                if(this.active_id > -1){
                                    alert('RESTORE SELECTION -> initColumns')
                                    this.restoreSelection();
                                }
                                else if(this.active_id != null){
                                    this.selectFirstItem()
                                }
                            }
                        })
                    }*/
                    resolve();
                })
            })
        },
        selectFirstItem(){
            if(this.treetable){
                if(this.table_data.length > 0){
                    let first_row = this.table_data[0];
                    if(window.webix.$$(this.uid) != undefined && first_row.is_empty == undefined){
                        window.webix.$$(this.uid).select(first_row.id)
                    }
                }
            }
            else{
                if(this.data.length > 0){
                    /*let first_row = this.data[0];
                    if(window.webix.$$(this.uid) != undefined && first_row.is_empty == undefined){
                        window.webix.$$(this.uid).select(first_row.id)
                    }*/
                    //* use webix native method to select first row
                    let table = window.webix.$$(this.uid);
                    if(table != undefined){
                        let first_id = table.getFirstId()
                        if(first_id){
                            table.select(first_id)
                        }
                    }
                }
            }
        },
        restoreSelection(){
            if(this.prevent_first_row_select == false){
                if(this.treetable){
                    if(this.table_data.length > 0){
                        if(this.last_tree_state != null){
                            if(window.webix.$$(this.uid) != undefined){
                                this.$nextTick(()=>{
                                    this.restoreTreeState();
                                })
                            }
                        }

                        else if(window.webix.$$(this.uid) != undefined){
                            if(window.webix.$$(this.uid).exists(this.active_id)){
                                window.webix.$$(this.uid).select(this.active_id);
                            }
                            else{
                                //* else select first item
                                let id = this.table_data[0].id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    window.webix.$$(this.uid).select(id);
                                }
                            }
                        }
                    }
                }
                else{
                    this.msg('Doing the selection')
                    if(this.data.length > 0 ){
                        let index = _.findIndex(this.data, {id:this.active_id});
                        if(window.webix.$$(this.uid) != undefined){
                            if(index != -1){
                                //select valid item
                                let id = this.active_id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    window.webix.$$(this.uid).select(id);
                                }
                            }
                            else{
                                //else select forst item
                                let id = this.data[0].id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    this.msg('See mee')
                                    window.webix.$$(this.uid).select(id);
                                }
                            }
                        }
                    }
                }
            }
            else{
                if(this.data.length > 0 ){
                    let index = _.findIndex(this.data, {id:this.active_id});
                    if(window.webix.$$(this.uid) != undefined){
                        if(index != -1){
                            //select valid item
                            let id = this.active_id;
                            if(window.webix.$$(this.uid).exists(id)){
                                window.webix.$$(this.uid).select(id);
                            }
                        }
                    }
                }
            }
        },
        insertEmptyRows(rows = 5){// default of 5
            //* add new rows with a unique id and is_empty flag
            for(let i = 0; i < rows; i++){
                let row = {
                    id: `newRow-${window.webix.uid()}`,
                    is_empty: true,
                }
                if(this.treetable){
                    row['data'] = [];
                }
                //eslint-disable-next-line
                $$(this.uid).add(row);
            }
        },
        enableEditMode(){
            //* object of last selected row/col
            //const table_selection = window.webix.$$(this.uid).getSelectedId();
            //* used for opening an editor for the first cell
            //! temp removed
            //let table_selection = this.last_selected_item;

            let pager_position = null;
            if(this.hasPagerProp){
                pager_position = window.webix.$$(this.uid).getPage();
            }
            
            this.changes_made_flag = false;
            window.webix.$$(this.uid).define({ editable: true });
            window.webix.$$(this.uid).define({ multiselect: false });
            window.webix.$$(this.uid).define({ rules: this.validation });
            if(this.ui.drag != undefined){
                delete this.ui.drag;
            }
            this.$nextTick(()=>{
                this.refreshConfig(4)
                .then(()=>{

                    //eslint-disable-next-line
                    let table = $$(this.uid)
                    if(table != undefined && pager_position != null){
                        table.setPage(pager_position);
                    }
                    //* Add a few empty rows to add new items
                    if(this.hasPagerProp == false){
                        //*only add if no pager
                        //*if there is a pager then the computed property will auto fill empty rows
                        this.insertEmptyRows();

                        //* here we can focus on the last selected row/col
                        //! Need to check if the selected column is an editable column
                        //! If it isn't then find the first editable column from the columns array
                        this.$nextTick(()=>{
                            if(this.last_tree_state != null && this.last_tree_state.open    ){
                                if(window.webix.$$(this.uid) != undefined){
                                    this.$nextTick(()=>{
                                        this.last_tree_state.open.forEach( id => {
                                            window.webix.$$(this.uid).open(id);
                                        })
                                    })
                                }
                            }
                        });
                    }
                })
            })
        },
        enableDeleteMode(){
            window.webix.$$(this.uid).define({ editable: false });
            window.webix.$$(this.uid).define({ multiselect: false });
            window.webix.$$(this.uid).define({ rules: {} });
            window.webix.$$(this.uid).define({ drag: false });
            if(this.ui.drag != undefined){
                delete this.ui.drag;
            }
            this.changes_made_flag = false;
            //* Redraw the table with updated config
            //this.destroyTable();
            this.$nextTick(()=>{
                this.refreshConfig(3)
                .then(()=>{
                    if(this.treetable){
                        window.webix.$$(this.uid).openAll();
                    }
                })
            })
        },
        enableViewMode(){
            //* set config options for view mode
            window.webix.$$(this.uid).define({ editable: false });
            window.webix.$$(this.uid).define({ multiselect: false });
            window.webix.$$(this.uid).define({ rules: {} });
            window.webix.$$(this.uid).define({ drag: false });
            if(this.ui.drag != undefined){
                delete this.ui.drag;
            }
            this.changes_made_flag = false;

            //* Redraw the table with updated config
            //this.destroyTable();
            this.$nextTick(()=>{
                this.refreshConfig(2)
                .then(()=>{
                    //*
                })
            })

        },
        enableDragMode(){

            //* set config options for delete mode
            this.changes_made_flag = false;

            window.webix.$$(this.uid).define({ editable: false });
            window.webix.$$(this.uid).define({ multiselect: false });
            window.webix.$$(this.uid).define({ drag: 'inner' });
            window.webix.$$(this.uid).define({ rules: {} });
            //window.webix.$$(this.uid).clearAll()

            //* Redraw the table with updated config
            //this.destroyTable();
            this.$nextTick(()=>{
                this.refreshConfig(1)
                .then(()=>{
                    window.webix.$$(this.uid).openAll();
                })
            })
        },
        refreshConfig(debug, prevent_reselect = false){
            console.log(this.uid,'Refresh config')
            return new Promise(resolve => {
                if(window.webix.$$(this.uid) != undefined){
                    window.webix.$$(this.uid).clearAll();
                    //* set columns based on table_mode
                    this.initColumns(prevent_reselect)
                    .then(()=>{
                        this.$nextTick(()=>{
                            //* refresh table and pager
                            if(window.webix.$$(this.uid) != undefined){
                                window.webix.$$(this.uid).refresh();
                                if(window.webix.$$(this.uid+'pager') != undefined){
                                    window.webix.$$(this.uid+'pager').refresh();
                                }
                                /*else{
                                    console.log(this.uid+'pager');
                                }*/
                            }
                            resolve();
                        })
                    });
                }
                //this.show_table = false;
                //* set columns based on table_mode
                /*this.initColumns()
                .then(()=>{
                    this.$nextTick(()=>{
                        //* refresh table and pager
                        if(window.webix.$$(this.uid) != undefined){
                            window.webix.$$(this.uid).refresh();
                            if(window.webix.$$(this.uid+'pager') != undefined){
                                window.webix.$$(this.uid+'pager').refresh();
                            }
                        }
                        resolve();
                    })
                });*/
            });
        },
        processOrderArray(parent_id = null){
            let table = window.webix.$$(this.uid);
            let data = null;
            if(parent_id != null){
                data = table.serialize(parent_id);
            }
            else{
                data = table.serialize();
                data = data.filter( row => row.is_empty == undefined);
            }
            let sortData = [];
            data.forEach( data => {
                sortData.push({
                    id: data.id,
                    item_order: this.treetable ? (table.getBranchIndex(data.id) + 1) : (table.getIndexById(data.id) + 1)
                })
            });
            this.$nextTick(()=>{
                this.$emit('updatedOrderEvent', sortData);
            })
            //
        },
        destroyTable(){
            if(window.webix.$$(this.uid+'pager') != undefined){
                //eslint-disable-next-line
                $$(this.uid+'pager').destructor();
            }

            this.$nextTick(()=>{
                if(window.webix.$$(this.context_menu_id) != undefined){
                    window.webix.$$(this.context_menu_id).destructor();
                }
                if(window.webix.$$(this.uid) != undefined){
                    window.webix.$$(this.uid).destructor();
                }
            })
        },
        closeWebixEditor(){
            window.webix.$$(this.uid).editStop();
        },
        resizeTable(){
            if(window.webix.$$(this.uid) != undefined){
                window.webix.$$(this.uid).refresh();
            }
        },
        configurePager(){
            //* set up webix config with props from parent
            if(this.hasPagerProp){
                this.ui['pager'] = {
                    container: this.uid + 'pager',
                    size: this.pager_config,
                    group: 5,
                    template: `{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}`
                }
            }
        },
    },
    mounted(){
        //this.$eventHub.$on('windowResizedEvent', this.resizeTable);
        
        //* misc table config
        this.configurePager();
        this.ui.id = this.uid; //* assign the given uid from prop

        //* Table has 2 modes -- tree and normal.
        if(this.treetable == false){
            this.ui.view = 'datatable';
        }
        
        //* if the sticky_columns prop is > 0 then the 'fillspace:true' property must be false
        if(this.sticky_columns > 0){
            this.ui.fillspace = false;
            this.ui.scroll = true;
            this.ui.leftSplit = this.sticky_columns;
        }

        //* merge custom click actions
        //! might be removed 
        if(this.button_methods != false){
            this.ui.onClick = {
                ...this.ui.onClick,
                ...this.button_methods
            }
        }

        //* defines rules for if a specified column should allow it's editor to show
        if(this.custom_editor_rules != false){
            /*this.ui.on = {
                ...this.ui.on,
                ...this.custom_editor_rules,
            }*/
            this.ui.on['onBeforeEditStart'] = this.custom_editor_rules
        }

        //* display the table element so that it's available for the webix.$$ selector
        this.show_table = true;

        //* Table pre config finished, now render table
        this.$nextTick(()=>{
            this.refreshConfig(7)
            .then(()=>{
                if(window.webix.$$(this.uid) != undefined){
                    window.webix.$$(this.uid).refresh();
                    this.hasMounted = true;

                    //* Configure native resize event
                    let table_uid = this.uid;
                    this.webix_resize_event = window.webix.event(window, "resize", function(){
                        window.webix.$$(table_uid).refresh();
                    });
                }
            })
        });
    },
    beforeDestroy(){
        //*remove resize event
        window.webix.eventRemove(this.webix_resize_event);
        //* prevent non unique id console error when hot refreshing
        this.destroyTable();
        this.webix_resize_event = null;
        //this.$eventHub.$off('windowResizedEvent');
    }
}
</script>

<style>
    /*.textClass{ border: 0;}
    .webix_cell .webix_row_select{
        color: dodgerblue !important;
    }

    .tableEditMode .webix_hcell  { background: rgb(162, 208, 255) !important; }
    .tableEditMode .webix_dtable  {
        -webkit-box-shadow: 0px 0px 3px 1px dodgerblue; 
        box-shadow: 0px 0px 3px 1px dodgerblue;
    }
    .tableViewMode .webix_dtable  {
        -webkit-box-shadow: 0px 0px 0px 1px #afafaf; 
        box-shadow: 0px 0px 3px 0px #afafaf;
    }

    .tableDeleteMode .webix_hcell  { background: rgb(255, 162, 162) !important; }
    .tableDeleteMode .webix_dtable  {
        -webkit-box-shadow: 0px 0px 3px 1px red; 
        box-shadow: 0px 0px 3px 1px red;
    }

    .tableDragMode .webix_hcell  { background: rgb(195, 255, 193) !important; }
    .tableDragMode .webix_dtable  { border-color: var(--bs-success); }

    .webix_drag_item{
        background-color: transparent !important;
        padding-left: 7px;
        padding-right: 7px;
        font-size: 13px;
        border: 1px solid grey;
        border-radius: 0.1rem;
        padding-bottom: 0px;
    }


    .new_row_style{
        background-color: rgb(217, 255, 217);
    }
    .delete_row_style{
        background-color: rgb(255, 249, 217);
    }

    a[webix_l_id=View]{
        color: var(--bs-primary) !important;
    }
    a[webix_l_id=Edit]{
        color: var(--bs-info) !important;
    }
    a[webix_l_id=Delete]{
        color: var(--bs-danger) !important;
    }
    a[webix_l_id=Drag]{
        color: var(--bs-success) !important;
    }

    .clear_new_row:hover>i{
        color: cornflowerblue;
    }
    .clear_new_row{
        cursor: pointer;
        width: 100% !important; 
    }

    .webix_invalid.webix_invalid_cell{
        background-color: #222;
        animation-name: color;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
    }
    .webix_pager{
        width: 100% !important;
    }
    @keyframes color {
      0% {
        background-color: #ffdedb;
      }
      50% {
        background-color: #ffc3bd;
      }
      100% {
        background-color: #ffdedb;
      }
    }

    .error-list{
        font-weight: bold;
        color: rgb(255, 62, 62)
    }
    .action_icon{
        text-align: center;
        width: 20px;
    }
    .action_icon:hover{
        cursor: pointer;
        color: dodgerblue;
    }

    .card-style-1{
        background: radial-gradient(circle, rgba(116,220,196,0.4) 37%, rgba(85,128,235,0.4) 100%);
    }
    .card-style-2{
        background: radial-gradient(circle, rgba(136,93,227,0.4) 36%, rgba(229,125,161,0.4) 100%);
    }
    .card-style-3{
        background: radial-gradient(circle, rgba(255,102,54,0.4) 36%, rgba(236,77,111,0.4) 100%);
    }
    .card-style-4{
        background: radial-gradient(circle, rgba(255,184,7,0.4) 36%, rgba(255,130,3,0.4) 100%);
    }


    .read-only-column{
        background: #f7f7f7;
        cursor: no-drop;
    }*/
</style>