<template>
    <div>   
        <div class="d-flex flex-wrap mb-1 align-items-center" style="width: 100%;">
            <div class="me-2">
                <h5 class="card-title mr-2 d-flex align-items-center">
                    Hires -&nbsp;
                    <span class="text-info">{{ levelName }}</span>
                </h5>
            </div>

            <FilterComponent
            class="ms-auto"
            @searchText="searchText"
            @searchDate="searchDate"
            @resetFilter="resetFilter"/>
        </div>

        <div>
            <b-alert show v-if="onboardingData.length == 0" variant="warning">
                <strong>No jobs available</strong>
            </b-alert>
            <Table
                :active_id="activeJobId"
                :uid="table_uid" 
                :is_loading="is_loading"
                :data="onboardingData"
                :columns="columns"
                :table_mode="table_view_mode"
                :button_methods="returnOnClickMethods()"
                :pager_config=5
                @selectedRowEvent="selectedRowEvent"
            />
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Table from '../../../widgets/table.vue'//* reusable table component
import { format, parseISO, isAfter, isBefore } from "date-fns";
export default {
    data:() => ({
        onboardingData:[], //* array of table rows

        //* props for webix table
        table_uid: 'onboardingHiresTable',
        table_view_mode: 'view', //* (view - edit - delete)
        is_loading: false,

        filterText: "",
        filter: {
            dateStart: "",
            dateEnd: "",
        },
    }),
    components:{
        Table, 
        FilterComponent, 
    },
    watch: {
        storeOnboardingData() {
            this.mapOnboardingData();
        }
    },
    computed:{
        //* make vuex stores available
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        active_job_data(){
            let data = null;
            if(this.activeJobId != -1){
                let index = _.findIndex(this.levelOnboardingData, {id:Number(this.activeJobId)});
                if(index != -1){
                    data = this.levelOnboardingData[index];
                }
            }
            return data;
        },

        //* computed prop for table
        columns(){
            let el = this;
            //* array of column items as objects
            return [
                { id: 'progress', header: 'Progress', width: '100',
                    template: (obj)=>{

                        if(obj.is_new == undefined && obj.is_empty == undefined){
                            return `<div class="bar"><div class="bar-inner" style="width: ${Number(obj.status)}%"></div></div>`
                        }
                        return '';
                    }
                },
                { id: 'job_id', header: 'Job Title', fillspace: 1,
                    format(value){
                        if(value != ''){
                            return el.getJobTitle(Number(value))
                        }
                        else{
                            return '';
                        }
                        
                    }
                },
                { id: 'candidate_count', header: 'Candidates', fillspace: 1},
                { id: 'level_id', header: 'Hierarchy', fillspace: 1, editor: 'combo', options: this.hierarchy},
                { id: 'start_date', header: 'Start Date', fillspace: 1, editor: "date",
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: 'report_to_user_name', header: 'Reporting To', fillspace: 1},
                { id: 'status', header: 'Status', fillspace: 1},
            ]
        },

        storeOnboardingData() {
            let data = _.cloneDeep(this.levelOnboardingData);
            data.forEach(item => {
                item['candidate_count'] = item.candidates.length;
            })
            return data; 
        },

        levelName() {
            return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },

        hierarchy() {
            let levels = [];
            levels = this.levels.map((level) => {
                //* hetHierarchy returns the level name for the given id
                return { id: level.id, value: this.getHierarchy(level.id) };
            });
            return levels;
            //return this.getHierarchy(this.selected_level.id)
        },
    },
    methods:{
        //* vuex methods
        ...peopleMethods,
        mapOnboardingData() {
            //* return onboarding data from the vuex store
            this.onboardingData = this.storeOnboardingData.map(item => ({...item}));
        },
        returnOnClickMethods(){
            //* use button class name from column as variable name here
            let onClick = {
                //eslint-disable-next-line
                'unlink_button':function(e, id){
                    console.log('On button click', id);
                }
            };
            return onClick;
            
        },
        getJobTitle(id){
            let title = '';
            let index = _.findIndex(this.companyJobs, {id:  id});
            if(index != -1){
                title = this.companyJobs[index].title;
            }
            else{
                title = 'Job not found'
            }
            return title;
        },


        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            //this.$eventHub.$emit('setActiveJobOnboarding', row.id);
            this.setActiveHiringId(row.id)
        },
        //* table events end ------

        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
            if(obj.job_title != undefined){
                return (
                obj.job_title
                    .toString()
                    .toLowerCase()
                    .indexOf(text) != -1
                );
            }
            });
        },
        searchDate(value) {
            //* set date filters
            this.filter.dateStart = value.start_date;
            this.filter.dateEnd = value.end_date;
            let start = parseISO(this.filter.dateStart);
            let end = parseISO(this.filter.dateEnd);

            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
                if(obj.job_title != undefined){
                    let s_d = parseISO(obj.start_date);
                    return (
                        isAfter(s_d, start) && isBefore(s_d, end)
                    );
                }
            });
        },
        resetFilter() {
            this.filterText = "";
            this.filter.dateStart = "";
            this.filter.dateEnd = "";
            window.webix.$$(this.table_uid).filter();
        },
    },
    mounted(){
        //* init table data
        this.mapOnboardingData();
    },
}
</script>

<style lang="scss" scoped>
::v-deep .bar {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background: #e9ecef;
  overflow: hidden;
  margin-top: 0.35rem;

  &-inner {
    // width: attr(data-percent);
    height: 100%;
    background: dodgerblue;
  }
}
</style>