<template>
    <b-modal hide-header-close :title="note_title" id='notesModal' hide-footer>
        <b-form >
            <b-form-textarea :class="setEditClass" v-model="edit_note_text"></b-form-textarea>
        </b-form>
        <!-- footer -->
        <div class='d-flex' v-if="editable == true">
            <b-button @click="saveNote" variant="success" class="ms-auto mt-2 me-2">Save</b-button>
            <b-button @click="$bvModal.hide('notesModal')" class=' mt-2' variant='primary'>Close</b-button>
        </div>
        <div class='d-flex' v-if="editable == false">
            <b-button @click="$bvModal.hide('notesModal')" class='ms-auto mt-2' variant='primary'>Close</b-button>
        </div>
    </b-modal>

</template>

<script>
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
//import Swal from "sweetalert2";
import {
  activityComputed,
  activityMethods,
  levelComputed,
  levelMethods,
  adminComputed,
  adminMethods,
} from "@/state/helpers";
export default {
    props:{
        note_data:{
            required: true,
        },
        editable:{
            type: Boolean,
            default: true,
        }
    },
    data:() => ({
        edit_note_text:'',
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        note_title(){
            if(!this.note_data){
                return 'Invalid item'
            }
            else{
                return 'Notes: ' + this.note_data.name; 
            }
        },
        note_text(){
            if(!this.note_data){
                return 'No notes added'
            }
            else{
                let text = this.note_data.note;
                return text != null ? text : '';
            }
        },
        has_valid_note(){
            //returns false if note is null or ''
            return this.note_data.note != null || this.note_data.note != '';
        },
        setEditClass(){
            if(this.editable){
                //* return nothing for editable
                return '';
            }
            else{
                //return class to prevent mouse events
                return 'block-click'
            }
        }
    },
    methods:{
        ...activityMethods,
        ...levelMethods,
        ...adminMethods,
        saveNote(){
            switch (this.note_data.table) {
                case 'missionMilestones':
                    this.saveMissionMilestoneChanges();
                    break;
                case 'missionObjectives':
                    this.saveMissionObjectivesChanges();
                    break;
                case 'actionPlan':
                    this.saveActionPlanChanges();
                    break;
                case 'keyResultPlan':
                    this.saveKeyResultPlanChanges();
                    break;
                case 'kpis':
                    this.saveKPIChanges();
                    break;
                case 'metrics':
                    this.saveMetricChanges();
                    break;
                default:
                    return "No value found";
            } 
        },
        getFormattedDate(day) {
            const today = new Date(day);
            return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
                -2
            )}-${("0" + today.getDate()).slice(-2)}`;
        },
        reloadMilestone() {
            return new Promise(resolve => {
                this.loadCompanyMilestones(this.company_id)
                .then(()=>{
                    resolve();
                })
                /*this.$eventHub.$emit("reloadObjective");
                setTimeout(()=>{
                resolve();
                }, 150)*/
            })
        },
        saveMissionMilestoneChanges(){
                let row_to_save = [];
                let row = this.note_data.item
                var param = {
                    id: row.id,
                    parent_id: row.parent === "none" ? null : row.parent,
                    level_id: row.level_id != undefined ? row.level_id : this.selected_level.id,
                    name: row.name,
                    year: row.year != undefined ? row.year : '-1',
                    draft: 0,
                    month: row.month != undefined ? row.month : '-1',
                    quarter: row.quarter != undefined ? row.quarter : '-1',
                    start_date: this.getFormattedDate(row.start_date),
                    end_date: this.getFormattedDate(row.end_date),
                    owner_id: row.owner_user_id,//this.findLevelUserId(row.owner_name)?.id,
                    notes: this.edit_note_text
                };
                row_to_save.push(param)

                let params = {
                level_id: this.selected_level.id,
                milestones: row_to_save
                }

                this.is_loading = true;
                console.log(JSON.parse(JSON.stringify(params)))
                this.saveMilestone(params)
                .then(()=>{
                    this.reloadMilestone()
                    .then(()=>{
                        this.changes_made_flag = false;
                        this.$bvModal.hide('notesModal')
                        this.is_loading = false;

                    })
                })
                .catch((e)=>{
                    this.is_loading = false;
                    console.log('ERROR:', e);
                })
        },
        saveMissionObjectivesChanges(){
            this.is_loading = true;
            let data = [this.note_data.item]
            let row_params = [];
            data.forEach( row => {
                let milestone_id = row.level_milestone_id ? row.level_milestone_id : 0;
                if(this.selected_milestone != null && milestone_id == 0){
                    milestone_id = this.selected_milestone.id;
                }
                if(row.year === 'N/A'){//Fix bug caused by value being == to 'N/A' .. Must me index id instead
                    row.year = "-1"
                }
                if(row.quarter === 'N/A'){
                    row.quarter = "-1"
                }
                if(row.month === 'N/A'){
                    row.month = "-1"
                }
                let updated_row = {
                    id: row.is_new ? -1 : row.id,
                    milestone_id: milestone_id,//this.selected_milestone == null ? null : this.selected_milestone.id,
                    //parent_id: row.parent == undefined ? 0 : row.parent,//row.is_new ? 0 : row.parent,
                    parent_id: row.parent_objective_id,
                    owner_id: row.owner_user_id,
                    name: row.name,
                    year: row.year != undefined && row.year !== '' ? row.year : '-1',
                    quarter: row.quarter != undefined ? row.quarter : '-1',
                    month: row.month != undefined ? row.month : '-1',
                    status: row.status != undefined ? row.status : 0,
                    deadline: row.deadline != undefined ? row.deadline : format(new Date(), 'yyyy-MM-dd'),
                    //deadline: row.deadline != undefined ? row.deadline : format(new Date(), 'dd-MMM-yyyy'),
                    start_date: row.start_date != undefined ? row.start_date : format(new Date(), 'yyyy-MM-dd'),
                    //end_date: row.deadline != undefined ? row.deadline : format(new Date(), 'dd-MMM-yyyy'),
                    //end_date: row.end_date != undefined ? row.end_date : format(new Date(), 'dd-MMM-yyyy'),
                    draft: false,
                    level_id: row.level_id != undefined ? row.level_id : this.selected_level.id,
                    //TODO - Integrate with SMART component
                    smart_validation: {"measurable": "1", "time_based": "1", "realistic": "1", "acheivable": "1", "specific": "1"},
                    notes: this.edit_note_text,
                }
                if(!updated_row.level_id){
                    updated_row.level_id = null;
                }
                if(updated_row.level_id === 'null'){
                    updated_row.level_id = null;
                }

                if(updated_row.level_id == null){
                    updated_row.level_id = this.selected_level.id
                }
                row_params.push(updated_row);
            })

            let params = {
                level_id: this.selected_level.id,
                objectives: row_params,
            }
            this.saveObjectiveAPI(params)
            .then(()=>{
                this.changes_made_flag = false;
                this.loadActivityData(this.selected_level.id)
                .then(() => {
                    this.delete_array = [];
                    this.changes_made_flag = false;
                    this.$bvModal.hide('notesModal')
                    this.is_loading = false;
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
            /*if(this.show_risks == false){
                this.saveObjectivesTable();
            }
            else{
                this.saveRisksTable();
            }*/
        },
        saveActionPlanChanges(){
            let row_to_save = [];
            let row = this.note_data.item
            var params = {
                id: row.id,
                parent_id: null,
                level_id:  this.selected_level.id,
                objective_id: row.milestone_objective_id != undefined ? row.milestone_objective_id : this.selected_objective.id,
                key_result_id: row.linked_key_result,
                name: row.name,
                start_date: this.getFormattedDate(row.start_date),
                end_date: this.getFormattedDate(row.end_date),
                type: 'task',
                description: 'Empty',
                body: 'Empty',
                notes: this.edit_note_text,
                status: row.status,
                priority: row.priority,
                owner_id: row.owner_id != undefined ? row.owner_id : this.core.id,
                responsible_user_id: Number(row.responsible_user_id),// row.owner_user_id//this.findLevelUserId(row.owner_name)?.id,
            };
            row_to_save.push(params)
            let activity_params = {
                //level_id: this.selected_level.id,
                //objective_id: objective_id,
                plans: row_to_save
            }
            this.is_loading = true;
            console.log(JSON.parse(JSON.stringify(activity_params)));
            this.saveActivityInbox(activity_params)
            .then(()=>{
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.delete_array = [];
                    this.changes_made_flag = false;
                    this.$bvModal.hide('notesModal')
                    this.is_loading = false;
                })
            })
        },
        saveKPIChanges(){
            this.is_loading = true;
            //eslint-disable-next-line
            let data = [this.note_data.item]
            let rows_to_save = [];
            data.forEach( row => {
                if(!row.is_empty){
                    var params = {
                        id: row.id,
                        level_id: -1,
                        owner_id: row.owner_user_id,
                        objective_id: -1,
                        key_result_id: this.selected_key_result.id,
                        name: row.name,
                        unit_type_id: row.unit_type_id,
                        graph_type: 'line',
                        description: row.description,
                        min_value: row.min_value,
                        max_value: row.max_value,
                        current_time_period: row.time_period,
                        start_date: row.start_date,
                        end_date: row.end_date,
                        graph_size: '12', //only one graph displays at a time, so force full width graph
                        notes: this.edit_note_text,
                    };
                    if(params.start_date != undefined && typeof params.start_date !== 'string'){
                        //alert(params.start_date)
                        params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                    }
                    if(params.end_date != undefined && typeof params.end_date !== 'string'){
                        //alert(params.end_date);
                        params.end_date = format(new Date(params.end_date), 'yyyy-MM-dd')
                    }
                    rows_to_save.push(params);
                }
            })

            let params = {
                kpis: rows_to_save,
            }
            this.is_loading = true;
            this.saveKpi(params)
            .then(()=>{
                let table_ref = this.$refs[this.table_uid];
                if(table_ref){
                    table_ref.changes_made_flag = false;
                }
                
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    //window.webix.$$('keyResultsTable').select(this.selected_key_result.id)
                    this.$bvModal.hide('notesModal')
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })

        },
        saveKeyResultPlanChanges(){
            //* if a row is new, need to fire off seperate endpoint to save share type
            //let update_share_types = [];
            let data = [this.note_data.item]
            let rows_to_save = [];
            data.forEach( row => {
                let params = {
                    milestone_objective_id: row.milestone_objective_id ? row.milestone_objective_id : this.selected_objective.id,//row.milestone_objective_id,
                    id: row.id,
                    level_id: this.selected_level.id,
                    owner_user_id: row.owner_user_id,
                    name: row.name,
                    target: row.target,
                    status: '',
                    start_date: row.start_date,
                    deadline: row.deadline,
                    unit_type_id: row.unit_type_id,
                    graph_type: row.graph_type,
                    min_value: row.min_value,
                    max_value: row.max_value,
                    current_time_period: row.current_time_period,
                    graph_size: '12', //only one graph displays at a time, so force full width graph
                    show_cumulative: Number(row.show_cumulative),
                    show_cumulative_target: Number(row.show_cumulative_target),
                    notes: this.edit_note_text,
                }
                if(params.start_date != undefined && typeof params.start_date !== 'string'){
                    params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                }
                if(params.deadline != undefined && typeof params.deadline !== 'string'){
                    params.deadline = format(new Date(params.deadline), 'yyyy-MM-dd')
                }
                rows_to_save.push(params)

                //* here we 
                // if(row.must_update_target != undefined && row.must_update_target == true){
                //     this.handleTargetUpdate(row);
                // }
                // if(row['must_remove_old_target']){
                //     this.handleTargetRemove(row);
                // }
            })
            let key_result_params = {
                key_results: rows_to_save
            }
            this.is_loading = true;
            this.saveKeyResult(key_result_params)
            .then(()=>{
                this.changes_made_flag = false;
                    let params = {
                        id: this.selected_objective.id,
                        user_id: this.activeUserId,
                    }
                    this.loadObjectiveDetailAPI(params)
                    .then(()=>{            
                        this.is_loading = false;
                        this.$bvModal.hide('notesModal')
                    })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        saveMetricChanges(){
            this.is_loading = true;
            let data = [this.note_data.item]
            let rows_to_save = [];
            data.forEach( row => {
                if(!row.is_empty){
                    let params = {
                        id: row.id,
                        owner_id: Number(row.owner_user_id),
                        level_id: -1,
                        objective_id: -1,
                        key_result_id: -1,//this.selected_key_result.id,
                        name: row.name,
                        unit_type_id: row.unit_type_id,
                        graph_type: 'line',
                        description: row.description ? row.description : ' ',
                        min_value: row.min_value,
                        max_value: row.max_value,
                        current_time_period: row.current_time_period,
                        start_date: row.start_date,
                        end_date: row.end_date,
                        notes: this.edit_note_text,
                        show_cumulative: 0,
                        //show_cumulative: Number(row.show_cumulative),
                        graph_size: '12' //only one graph displays at a time, so force full width graph
                    };
                    if(params.start_date != undefined && typeof params.start_date !== 'string'){
                        //alert(params.start_date)
                        params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
                    }
                    if(params.end_date != undefined && typeof params.end_date !== 'string'){
                        //alert(params.end_date);
                        params.end_date = format(new Date(params.end_date), 'yyyy-MM-dd')
                    }

                    //*Set level or objective linked id
                    if(this.metric_display_type === 'Objective'){
                    params.objective_id = this.selected_objective.id;
                    }
                    else{
                    params.level_id = this.selected_level.id
                    }
                    rows_to_save.push(params);
                }
            });
            let params = {
                metrics: rows_to_save,
            }
            this.is_loading = true;
            this.saveMetric(params)
            .then(()=>{
                this.changes_made_flag = false;
                let params = {id:this.selected_objective.id, user_id: this.activeUserId}
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    this.$bvModal.hide('notesModal')
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    mounted(){
        if(this.has_valid_note){
            this.edit_note_text = cloneDeep(this.note_data.item.notes)
        }
    },
}
</script>

<style>

</style>