<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-15T10:38:12+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T14:26:42+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>
            <div class="d-flex flex-wrap mb-0" style="width: 100%;">
                <div class="me-2">
                  <h5 class="card-title mr-2 d-flex align-items-center">
                    Candidates&nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-info">{{ jobName }}</span>
                    <span class="text-info" v-if="editableId > -1">&nbsp;> {{ activeCandidate.first_name }}</span> 
                  </h5>
                </div>

                

                <span style="margin-bottom: 2px;" class="ms-auto" >
                    <b-button @click="clearCandidateSelection" :disabled="activeCandidateId == -1" right class="me-2" size="sm" variant="primary">
                        <strong>Clear Candidate Selection</strong>
                    </b-button>
                    <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="me-2 dropdown-menu-end">
                        <strong>Save Table Changes</strong>
                    </b-button>
                    <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="me-2 dropdown-menu-end">
                        <strong>Delete Selected Rows</strong>
                    </b-button>
                </span>

                <!-- -- dropdown -- -->
                <TableActionButton
                    :currentMode="table_view_mode"
                    :actions="['edit', 'delete', 'view']"
                    @updateMode="updateViewMode"
                />
            </div>
            <!-- Flip -->
            <div class="">
              <div
                class=""
              >
                <!-- flip front -->
                <div v-show="flip" class="" style="text-align: left;">
                    <div v-if="show_skills_table == false">
                        <!-- :active_id="activeCandidateId" -->
                        <Table
                        :active_id="activeCandidateId"
                        :uid="table_uid" 
                        :is_loading="is_loading"
                        :data="candidates"
                        :columns="columns"
                        :table_mode="table_view_mode"
                        :only_editable_columns="true"
                        :validation="table_validation_rules"
                        prevent_first_row_select
                        @selectedRowEvent="selectedRowEvent"
                        @onDataUpdate="onChangesMade"
                        @onDeleteArrayUpdate="onDeleteArrayUpdate"
                        />
                    </div>
                    <div v-else>
                        <JobSkills/>
                    </div>
                </div>
                <!-- flip back -->
                <div class="flip-widget-back" v-if="!flip">
                  <!-- Guideline -->
                  <Guideline
                    v-if="showGuideline"
                    ref="guideline"
                    :cardName="'PlanOverview'"
                    :isEditGuideline="false"
                  />
                </div>
              </div>
            </div>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
import Table from '@/components/widgets/table.vue';
import TableActionButton from '@/components/widgets/tableActionsButton.vue';
//import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from 'lodash';
import Swal from "sweetalert2";

import JobSkills from '../employees/jobSkills.vue'

export default {
    name: "Admin",
    components: {
        //FilterComponent,
        Guideline,
        Table,
        JobSkills,
        TableActionButton
    },
    data:() => ({
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'candidatesTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,

        //selected_candidate: null,

        show_skills_table: false,

        searchText: "",
        searchDate: "",
        resetFilter: "",
        isEditingNew: false,
        flip: true,
        showGuideline: false,
        editableId: -1,
        candidateData: [],
        addingRow: false,
        tableFields: [
            { key: "first_name", label: "First Name"},
            { key: "last_name", label: "Last Name"},
            { key: "applied_for", label: "Applied For"},
            { key: "date_received", label: "Date Received"},
            { key: "source", label: "Source"},
            { key: "days_passed", label: "Days Passed"},
            { key: "current_salary", label: "Current Renumeration"},
            { key: "status", label: "Status"},
            { key: "cv_src", label: "CV Link"},
        ],
        statusOptions: [
            {value: "Accepted", text: "Accepted"},
            {value: "Declined", text: "Declined"},
            {value: "1st Interview", text: "1st Interview"},
            {value: "2nd Interview", text: "2nd Interview"},
            {value: "Hired", text: "Hired"}
        ]
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        dropdownClassFix() {
            //* fix buttons position when displaying before the ... dropdown menu
            //changes_made_flag ? '' : 'ms-auto'
            if(this.changes_made_flag == true || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {job_id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        candidates(){
            let data = [];
            if(this.levelHiringData != null){
                console.log(1)
                let index = _.findIndex(this.levelHiringData, {id: this.activeHiringPositionId})
                console.log('INDEX ', index);
                if(index != -1){
                    console.log(2)
                    console.log(this.levelHiringData[index].candidates)
                    data = this.levelHiringData[index].candidates;
                }
            }
            return data;
        },
        columns(){
            return [
                //TODO ask about current_salary
                { header: 'Firstname', id: 'first_name', fillspace: 0.9, editor: "text",},
                { header: 'Last Name', id: 'last_name', fillspace: 0.9, editor: "text",},
                { header: 'Email', id: 'email', fillspace: 1.1, editor: "text",},
                { header: 'Applied For', id: 'applied_for', fillspace: 1.3,},
                { header: 'Date Recieved', id: 'date', fillspace: 0.8,},
                { header: 'Source', id: 'source', fillspace: 0.6, editor: "combo",
                    options: [
                        {id: "Internet", value: "Internet"},
                        {id: "LinkedIn", value: "LinkedIn"},
                    ]
                },
                { header: 'Days Passed', id: 'days', fillspace: 0.9,},
                { header: 'Current Remuneration', id: 'current_salary', fillspace: 1, editor: "text", format:function(value){
                    if( value !== ''){
                        return window.webix.i18n.priceFormat(value);
                    }
                    else{
                        return '';
                    }
                }},
                { header: 'Status', id: 'status', fillspace: 0.8, editor: "combo",
                    options: [
                        {id: "accepted", value: "Accepted"},
                        {id: "declined", value: "Declined"},
                        {id: "1st Interview", value: "1st Interview"},
                        {id: "2nd Interview", value: "2nd Interview"},
                        {id: "hired", value: "Hired"}
                    ]
                },
                { header: 'CV', id: 'cv', fillspace: 0.6,},
            ]
        },
        table_validation_rules(){
            return {
                "first_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "last_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "email": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "source": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "status": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "current_salary": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },

        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        storeHiringData() {
            return this.levelPeopleData.hiring;
        },
        jobName() {
            if(this.activeHiringPositionId){
                let hire_idx = _.findIndex(this.levelHiringData, {id: this.activeHiringPositionId} );
                if(hire_idx != -1){
                    let job_idx = _.findIndex(this.companyJobs, {id: this.levelHiringData[hire_idx].job_id});
                    if(job_idx != -1){
                        return this.companyJobs[job_idx].title
                    }
                }
            }
            /*if(this.companyJobs != null){
                let index = _.findIndex(this.companyJobs, {id: this.activeHiringId});
                if(index != -1){
                    console.log('Active',this.companyJobs[index])
                    return this.companyJobs[index].title;
                }
                else{
                    return ''
                }
            }*/
            return "Nothing Selected";
        },
        storeCandidateData() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex].candidates;
            }
            return [];
        },
        activeCandidate() {
            var el = this;
            var findIndex = _.findIndex(this.storeCandidateData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                return this.storeCandidateData[findIndex];
            }
            return {};
        }
    },
    watch: {
        activeHiringPositionId() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        },
        storeHiringData() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        },
        candidates(newValue){
            if(newValue.length == 0){
                this.$nextTick(()=>{
                    this.$eventHub.$emit('setActiveCandidateHiring', -1);
                })
            }
        }
    },
    methods:{
        ...peopleMethods,
        showSkillsTable(){
            this.show_skills_table = !this.show_skills_table;
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        saveTable(){
            let table = window.webix.$$(this.table_uid);
            let is_valid = true;
            this.candidates.forEach( item => {
                let result = table.validate(item.id);
                if(result != true){
                    is_valid = false;
                }
            });
            if(is_valid){
                this.is_loading = true;
                const all_data = table.serialize(null, true);
                let rows_to_save = [];
                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        var params = {
                            id: newRow ? -1 : row.id,
                            status: row.status,
                            first_name: row.first_name,
                            middle_name: 'empty',
                            last_name: row.last_name,
                            source: row.source,
                            email: row.email,
                            current_salary: row.current_salary,
                        };
                        
                        rows_to_save.push(params);
                    }
                })

                let candidate_params = {
                    hiring_position_id: this.activeHiringPositionId,
                    candidates: rows_to_save,
                }
                this.is_loading = true;
                this.saveJobCandidate(candidate_params)
                .then(()=>{
                    this.loadHiringData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.changes_made_flag = false;
                        this.toggleViewMode();
                    })
                })
                .catch((e)=>{
                    console.log('ERROR:', e);
                    this.is_loading = false;
                })
            }
        },
        
        showDeletePrompt(){
            Swal.fire({
                title: "Delete?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(){
            /*let promises = [];
            this.delete_array.forEach( item => {
                let params = {job_id: item.id};
                promises.push(this.deleteHiringJob(params));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.loadHiringData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            })*/
        },
        //* table events start -----
        selectedRowEvent(row){
            if(this.activeCandidateId != row.id){
                //this.activeCandidateId = row.id;
                this.$nextTick(()=>{
                    this.setActiveCandidateId(row.id);
                    //console.log('Selected candidate')
                    //this.$eventHub.$emit('setActiveCandidateHiring', row.id);
                })
            }
            
        },
        clearCandidateSelection(){
            this.$eventHub.$emit('setActiveCandidateHiring', -1);
            //window.webix.$$(this.table_uid).clearSelection()
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------
        mapCandidateData() {
            this.candidateData = this.storeCandidateData.map(item => ({...item, isEdit: false}));
        },
        editRow() {
            var el = this;
            var findIndex = _.findIndex(this.candidateData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                this.candidateData[findIndex].isEdit = !this.candidateData[findIndex].isEdit;
            }
        },
        addRow() {
            var rowData = {
                id: -1,
                job_id: this.activeHiringPositionId,
                first_name: "",
                last_name: "",
                source: "",
                status: "",
                current_salary: "",
                isEdit: true
            };
            this.cancelEdit();
            this.addingRow = true;
            this.candidateData.push(rowData);
        },
        cancelEdit() {
            var el = this;
            //eslint-disable-next-line no-unused-vars
            this.addingRow = false;
            _.forEach(this.candidateData, function(value, index) {
                el.candidateData[index].isEdit = false;
            });
        },
        saveRow() {
            var findIndex = _.findIndex(this.candidateData, function(o) { return o.isEdit == true; });
            if (findIndex > -1) {
                this.saveJobCandidate(this.candidateData[findIndex]).then(() => {
                    this.loadPeopleData(this.selected_level.id);
                    this.addingRow = false;
                });
            } else {
                alert("no rows being edited");
            }
        },
    

    },
    mounted(){
        this.mapCandidateData();
    }
}
</script>