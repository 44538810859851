<template>
    <b-overlay :show="is_loading">
        <h3>
            <b-button variant="info" @click="navigateBack">
                <font-awesome-icon icon="fa-arrow-left"/>
                &nbsp;
                <strong>Cancel</strong>
            </b-button>
            &nbsp;
            Add New User Wizard
        </h3>
        <b-card>
            <form-wizard @on-complete="emitSaveEvent" color="#556ee6" finishButtonText="Save and Close" nextButtonText="Next">
                <tab-content :before-change="beforeTab1Switch"  title="Basic Info">
                    <div>
                        <b-form autocomplete="off" class="userForm mt-3">
                            <div class="me-2">
                                <h5 class="mb-4">Personal Details</h5>
                                <hr />
                            </div>
                            <b-row>
                                <b-col cols="4">
                                    <label for="">First Name</label>
                                    <b-form-input v-model="name"></b-form-input>
                                </b-col>
                                <b-col cols="4">
                                    <label for="">Surname</label>
                                    <b-form-input v-model="last_name"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4">
                                    <label for="">Email</label>
                                    <b-form-input :autocomplete="'off'" v-model="email"></b-form-input>
                                </b-col>
                                <b-col cols="4">
                                    <label style="width: 100%;" for="">Start Date</label>
                                    <b-form-input style="width: 100%;" type="date" id="date" v-model="start_date" class=" mb-2"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4">
                                    <label for="">Job Title</label>
                                    <JobTypeahead
                                    :isNewUser="true"
                                    :enableEdit="true"
                                    @returnJob="handleTypeaheadUpdate"/>
                                </b-col>
                                <b-col cols="4">
                                    <label style="width: 100%;" for="">Assigned Level</label>
                                    <b-row>
                                        <b-col cols="8">
                                            <b-form-input readonly style="width: 100%;" type="text" id="level" v-model="getLevelForID" class=" mb-2"></b-form-input>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-button variant="primary" @click="showHierarchyModal">
                                                <i class="fas fa-pen"></i>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12"><hr></b-col>
                                <b-col cols="4">
                                    <label for="">Password</label>
                                    <b-form-input autocomplete="off" id="newPassword" type="password" v-model="password"></b-form-input>
                                </b-col>
                                <b-col cols="4">
                                    <label for="">Confirm Password</label>
                                    <b-form-input autocomplete="off" id="confirmNewPassword" type="password" v-model="confirm_password"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </tab-content>
                <tab-content title="Details">
                    <EmployeeDetails @back="$emit('back')"  v-if="activeEmployeeId > -1" :hideEdit="true" :activeEmployeeId="activeEmployeeId" :hideUpdateButton="true" />
                    <b-alert v-else show>Save User Details First</b-alert>
                </tab-content>
            </form-wizard>
        </b-card>
        <HierarchyModal :payload="{id: 69, parent_id: null}"
            show_all_levels 
            v-if="show_hierarchy_modal"
            @selectedNewID="updateLevelId"
        />
    </b-overlay>
</template>

<script>
import Swal from 'sweetalert2'
import EmployeeDetails from './employeeDetails.vue'
import JobTypeahead from './changeJobCard.vue'
import { FormWizard, TabContent } from "vue-form-wizard";
import {
    peopleComputed,
    levelComputed,
    peopleMethods
} from "@/state/helpers";
import { format } from 'date-fns';
import _ from 'lodash'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'

export default {
    components: { 
        FormWizard,
        TabContent,
        EmployeeDetails,
        JobTypeahead,
        HierarchyModal
    },
    data:() => ({
        name: null,
        last_name: "",
        email: "",
        job_title: "",
        start_date: '',
        level_id: null,
        responsible_user_id: null,
        new_job_id: -1,
        password: '',
        confirm_password:'',
        assigned_level_id: -1,

        is_loading: false,

        activeEmployeeId: -1,
        ui_hierarchy_var: null,

        show_hierarchy_modal: false,

        //* webix combo boxes as input fields
        ui_hierarchy(){
            return{
                view:"combo", width:300,
                label: 'Fruit',  name:"fruit",
                options:[ 
                    { id:1, value:"Banana" },
                    { id:2, value:"Papaya" }, 
                    { id:3, value:"Apple" }
                ],
                container: 'hierarchyCombo', 
                inputWidth: 200,

            }
        }
    }),
    computed: {
        ...peopleComputed,
        ...levelComputed,
        levelJobsList() {
            let jobs = [];
            jobs = this.levelJobsData.map((job) => {
                return { value: job.id, text: job.title };
            });
            jobs.push({ value: null, text: 'Select Job Title' })
            return jobs;
        },
        hierarchy() {
            let data = [];
            this.levels.forEach( lvl =>{
            if(lvl.id == this.selected_level.id || lvl.parent_id == this.selected_level.id){
                data.push(lvl);
            }
            })
            let final = []
            data.forEach( level => {
                final.push({
                    value: level.id,
                    text: level.name
                });
            })
            return final;
        },
        getLevelForID(){
            let name = 'N/A';
            if(this.assigned_level_id){
                let index = _.findIndex(this.levels, {id:this.assigned_level_id})
                if(index != -1){
                    name = this.levels[index].name;
                }
            }
            return name;
        }
    },
    methods: {
        ...peopleMethods,
        beforeTab1Switch(){
            return new Promise((resolve,reject) => {
                if(this.activeEmployeeId != -1){
                    resolve(true);
                }
                else{
                    if(!this.name){
                        Swal.fire({ icon:'warning', title:'Enter Name Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                        reject();
                        return;
                    }
                    if(this.email === ''){
                        Swal.fire({ icon:'warning', title:'Enter Email Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                        reject();
                        return;
                    }
                    if(this.last_name === ''){
                        Swal.fire({ icon:'warning', title:'Enter Surname Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                        reject();
                        return;
                    }
                    /*if(this.new_job_id == -1){
                        this.showNewJobPrompt();
                        reject();
                        return;
                    }*/
                    if(this.password === ''){
                        Swal.fire({ icon:'warning', title:'Enter Password Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, });
                        reject();
                        return;
                    }
                    if(this.password !== '' && this.password != this.confirm_password){
                        Swal.fire({ icon:'warning', title:"Passwords don't match", toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                        reject();
                        return;
                    }
                    this.createEmployee()
                    .then(()=>{
                        this.$eventHub.$emit('savedNewUser');
                        resolve(true);
                        return true;
                    })
                    /*.catch(()=>{
                        reject();
                    })*/
                }
            })
        },
        showNewJobPrompt(){
            Swal.fire({
                title: "Entered job doesn't exist.",
                text: "Create New Job?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.$eventHub.$emit('showNewJobModal');
                }
            });
        },
        createEmployee(){
            return new Promise((resolve,reject) => {
                this.is_loading = true;
                let parent = this.levels.find( level => {
                    return level.id == this.assigned_level_id
                });
                var params = {
                    id: -1,
                    level_id: this.selected_level.id,
                    name: this.name,
                    last_name: this.last_name,
                    reports_to_user_id: parent ? parent.lead_user.id : this.core.id,
                    email: this.email,
                    start_date: this.start_date,
                    job_id: this.new_job_id,
                    password: this.password,
                };
                this.saveEmployee(params)
                .then((id)=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.activeEmployeeId = id;
                        //this.$eventHub.$emit('setActiveEmployee', res);
                        resolve();
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                    reject();
                })
            })
        },
        emitSaveEvent(){
            //* save user from the employeeDetails tab
            this.$eventHub.$emit('saveUserFromWizard');
        },
        navigateBack(){
            this.$router.back();
        },

        //* image modal related methods
        saveAvatar() {
            this.is_loading = true;
            const { canvas } = this.$refs.cropper.getResult();
            const params = new FormData();
            params.append('user_id', this.selectedUser.id)
            canvas.toBlob(blob => {
                params.append('avatar', blob)
                this.uploadAvatar(params)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                })
            }, 'image/jpeg');

        },
        handleTypeaheadUpdate(job){
            this.job_title = job.text;
            this.new_job_id = job.id;
        },
        updateLevelId(new_id){
            this.$bvModal.hide('hierarchyModal')
            this.assigned_level_id = Number(new_id);
        },
        showHierarchyModal(){
            //* NEED TO SET modal_payload here 
            this.show_hierarchy_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('hierarchyModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_hierarchy_modal = false;
                        //* reset payload
                        //this.modal_payload = null;
                    }
                });
            })
        },
    },
    mounted(){
        this.assigned_level_id = this.selected_level.id;
        this.job_title = null;
        this.start_date = format(new Date(), 'yyyy-MM-dd');
    },
}
</script>
<style>
.userForm > .row {
    margin-bottom: 1rem;
}
.userForm > .row > .col-2 {
    padding-top: 0.55rem;
}

.wizard-tab-content{
    padding-top: 0 !important;
}
/*.wizard-navigation > .wizard-progress-with-circle{
    display: none !important;
}
.wizard-navigation > .wizard-nav{
    display: none !important;
}*/

</style>
