<template>
    <b-card no-header class="bg-light bg-soft">  
        <div v-if="this.employeeDetails">   
            <b-card v-if="!employeeDetails.current_job.skills || employeeDetails.current_job.skills.length < 3">
                <b-alert style="margin: 0 auto" show>
                    Please ensure the linked job has the required skills to View the Competency Graphs
                </b-alert>
            </b-card> 
            <b-card v-if="employeeDetails.current_job.skills.length >= 3" class="m-1">
                <div class="d-flex directionStyle" style="height: 100%; width: 100%">
                    <div id="chart" style="width: 100%">
                        <competencyRadar/>
                    </div>
                    <div id="chart" style="width: 100%">
                        <competencyBar/>
                    </div>
                </div>    
            </b-card>
            <b-card>
                <MissionObjectives objective_type="personal" />
            </b-card>
            <ActionPlans key_action_type="personal"/>
        </div>

        <div v-else>
            <b-alert show>
                Error Fetching Employee Details
            </b-alert>
        </div>
    </b-card>
</template>

<script>

import { levelMethods,
    levelComputed,
    activityMethods,
    peopleComputed,
    peopleMethods,
    activityComputed,
    performanceComputed,
    performanceMethods,
    ganttComputed,
    ganttMethods } from "@/state/helpers.js";
import competencyRadar from "@/components/people/components/competency/competencyRadar.vue";
import competencyBar from "@/components/people/components/competency/competencyBar.vue";
import MissionObjectives from "@/components/objectives/components/cards/mission-objectives-new.vue";
import ActionPlans from '@/components/objectives/components/cards/action-plans.vue';
export default {
    name: 'competency',
    data:() => ({
        name:null
    }),
    components:{
        competencyRadar,
        competencyBar,
        MissionObjectives,
        ActionPlans,
    },
    computed:{
        ...ganttComputed,
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        ...peopleComputed,
    },
    methods:{
        ...ganttMethods,
        ...levelMethods,
        ...activityMethods,
        ...performanceMethods,
        ...peopleMethods
    },
    beforeDestroy(){
    },
    mounted(){
        this.loadPerformanceScreenData({
            type: 'level',
            params: {
                level_id: this.current_level_id, milestone_data: 1
            },
        })
        this.setShowKanban(false)
    },
}
</script>

<style>
@media (max-width: 1390px) {
    .directionStyle {
       flex-direction: column !important;
    }
    
}
</style>