<template>
    <!-- //TODO create child components from both b-tabs -->
        <div>
            <b-overlay :show="loading_new_user">
            <div v-if="user != null">
            <div class="d-flex">
                <span>
                    <b-button v-show="user.active == 0" size="sm" variant="primary" class="ms-auto m-1" @click="setSelectedUser(null)">Back to Active Employees</b-button>
                </span>
                <b-alert show v-show="user.active == 0" style="width: 100%;" variant="danger">
                    <div class="d-flex">
                        <strong class="mt-1">This user is ARCHIVED and is hidden from the system. </strong>
                        <b-button size="sm" variant="primary" class="ms-auto" @click="restoreSelectedUser()">Restore Archived User</b-button>
                    </div>
                </b-alert>
            </div>
                <div class="media mb-2 reduced-font-size">
                    <div class="me-3 mt-3">
                        <div style="width: 120px; height: 120px; position: relative;" @mouseover="hoverOverImg = true" @mouseleave="hoverOverImg = false">
                            <b-overlay :show="is_saving_avatar">
                                <font-awesome-icon v-if="hoverOverImg" @click="selectImage" class="fa-fw hoverEditIcon" icon="fa-pen"/>
                                <b-img
                                    fluid
                                    @click="selectImage"
                                    :src="user.avatar_src"
                                    alt
                                    class="avatar-md rounded-circle img-thumbnail header-profile-user-view"
                                    style="width:120px; height: 120px;"
                                ></b-img>
                            </b-overlay>
                            <div v-if="user.avatar_src">
                                <b-form-file style="display: none" id="fileUpload" accept="image/*" v-on:change="filesChange"></b-form-file>
                            </div>
                        </div>
                    </div>
                    <div class="media-body align-self-center d-flex">
                        <div class="move-info" style="width: 400px; margin-left: 0px">
                            <b-row>
                                <b-col class="p-0 col-10 fullNameStyles">{{firstName}} {{ lastName }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-4"><label class="me-2 mb-0">Job Title: </label></b-col>
                                <!-- <b-col class="p-0"><change-job-card @changedJob="changedJob" :hideEdit="hideEdit" :title="getJobTitle" :hideTitle="true" :hasValidJob="hasValidJob" :isLoading="is_loading_job" :changes_made_flag="form_data_changed"/></b-col> -->
                                <b-col>{{ getJobTitle }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-4"><label class="me-2 mb-0">Reports To: </label></b-col>
                                <b-col>{{ reports_to }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-4"><label style="width: 110%" class="me-2 mb-0">Employment Date: </label></b-col>
                                <b-col class="">{{ employmentDate }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="col-4"><label class="me-2 mb-0">Level: </label></b-col>
                                <b-col class="" style="width:1000px"><EmployeeHierarchy @setActiveUserData="setActiveUser" :user="user" :changes_made_flag="form_data_changed"/></b-col>
                                <!-- <b-col class="p-0">{{ employeeHierarchy }} <font-awesome-icon class="fa-fw ms-1 mt-1 editIcon" icon="fa-pen" @click="showJobsCardModal(Job_title)"></font-awesome-icon></b-col> -->
                            </b-row>
                        </div>
                            
                        
                    </div>
                    <div>
                        <div class="d-flex mb-2">
                            <GuidelineModalButton class="ms-auto me-1" guideline_key="user_profile_header"/>
                            <b-dropdown v-show="$hasUserRole('Power User')" menu-class="dropdown-menu-end" variant="danger" no-caret size="sm">
                                <template #button-content>
                                    Power User Actions
                                    <font-awesome-icon class="fa-fw ms-1" icon="fa-caret-down"/>
                                </template>
                                <b-dropdown-item @click="deleteSelectedUser()">
                                    <span class="text-danger">
                                        Archive User
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-4">
                                    <p style="font-weight:bold">Work Rating</p>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4" style="font-weight:bold">
                                    <p>Rate Date</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4" style="padding-right: 0">
                                    <p>Work Engagement</p>
                                </div>
                                <div class="col-4">
                                    <!-- <b-form-input class="" style="width: 100%" v-model.number="engagement" @input="updateEngagement()" min=0 max=5 type="range" :disabled="core.id != selected_user_id"></b-form-input> -->
                                    <RangeSlider v-if="refreshed" @updatedValue="engagement = $event; updateEngagement()" :value="engagement" :min_val="0" :max_val="5" :disabled="core.id != selected_user_id"/>
                                    
                                </div>
                                <div class="col-4">
                                    <p>{{ engagement_date }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4" style="padding-right: 0">
                                    <p>Job Satisfaction</p>
                                </div>
                                <div class="col-4">
                                    <!-- <b-form-input class=""  style="width: 100%" v-model.number="satisfaction" @input="updateSatisfaction()" min=0 max=5 type="range" :disabled="core.id != selected_user_id"></b-form-input> -->
                                    <RangeSlider v-if="refreshed" @updatedValue="satisfaction = $event; updateSatisfaction()" :value="satisfaction" :min_val="0" :max_val="5" :disabled="core.id != selected_user_id"/>
                                </div>
                                <div class="col-4">
                                    <p>{{ satisfaction_date }}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                
                    
                </div>
            </div>
            </b-overlay>
            <b-overlay :show="is_saving_user" no-wrap></b-overlay>
            <b-modal no-close-on-backdrop v-if="show_modal" id="cropperModal" title="Crop Image" size="lg" ok-only ok-title="Save" @ok="saveAvatar">
                <cropper
                    ref="cropper"
                    class="cropper"
                    :src="this.selectedImage"
                    
                />
            </b-modal>
            <changeJobCardModal
                v-if="show_job_card_modal"
                :payload="modal_payload"
                @changedJob="changedJob"
             />
            <changeLinkedEmployeeModal
                v-if="show_responsible_employee_modal"
                :payload="modal_payload"
                @changedLinkedEmployee="changedLinkedEmployee"
             />
        </div>
</template>
    
    <script>
    import _ from 'lodash';
    import {
        levelComputed,
        levelMethods,
        peopleMethods,
        peopleComputed,
        adminComputed,
        adminMethods,
        profileComputed,
        profileMethods
    } from "@/state/helpers";
    // import Swal from "sweetalert2";
    //eslint-disable-next-line
    import { format, isThisSecond, parseISO } from "date-fns";
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    //import ChangeJobCard from './changeJobCard.vue'
    import EmployeeHierarchy from './employeeLevelSpan.vue'
    import changeJobCardModal from '../../modals/changeJobCardModal.vue'
    import changeLinkedEmployeeModal from '../../modals/changeLinkedEmployeeModal.vue'
    import RangeSlider from '@/components/widgets/misc/rangeSlider.vue'
    import GuidelineModalButton from '@/components/widgets/misc/guidelineModalButton.vue';
    
    import Swal from 'sweetalert2';
    export default {
        components: {
            Cropper,
            //ChangeJobCard,
            EmployeeHierarchy,
            changeJobCardModal,
            RangeSlider,
            changeLinkedEmployeeModal,
            GuidelineModalButton
        },
        props: {
            activeEmployeeId: {
                required: true
            },
            existing_user:{
                default: false,
            },
            hideUpdateButton:{
                default: false,
            },
            hideEdit:{
                default: false
            }
        },
        data:() => ({
            show_modal: false,
            user: null, //* old -- user: {}
            user_display_name: '', //copy of name and surname (fixes display name changing when editing first/last name)
            firstName: '',
            lastName: '',
            engagement: 0,
            engagement_date: "No Rating",
            satisfaction: 0,
            satisfaction_date: "No Rating",
            employmentDate: '',
            reports_to: '',
            is_loading_job: false,
            table_uid: 'jobHistoryTable',
            table_leave_uid: 'leaveTable',
            table_remuneration_uid: 'remunerationTable',
            table_assets_uid: 'assetTable',
            leave_table_view_mode: 'view',
            remuneration_table_view_mode: 'view',
            processedData: [],
            processedRemunerationData: [],
            linkableAssetsData: [],
            savedLeaveTypes: [],
            popup_date: null,
            assets_table_view_mode: '',
            is_loading: false,
            is_saving_avatar: false,
            is_saving_user: false,
            leave_changes_made_flag: false,
            remuneration_changes_made_flag: false,
            assets_changes_made_flag: false,
            rating_changes_made: false,
            show_job_card_modal: false,
            show_hierarchy_modal: false,
            show_responsible_employee_modal: false,
            loading_new_user: false,
            refreshed: false,
    
            hasMounted: false,
            active_collapse_index: 1,
    
            selectedImage: "",
            hoverOverImg: false,
    
            editJobDetails: false,
            edit_current_job:null,
    
            block_form_watcher: false, //* block watcher from firing when the user object is updated with new user data
            form_data_changed: false,//* true when form input fields change
    
            typeahead_query: '',
    
            leave_dropdown_options:[
                { id: 'annual', value: 'Annual'},
                { id: 'maternity', value: 'Maternity'},
                { id: 'sick', value: 'Sick'},
                { id: 'family_responsibility', value: 'Family Responsibility'},
                { id: 'study', value: 'Study'},
                { id: 'religious', value: 'Religious'},
                { id: 'personal', value: 'Personal'},
            ],
    
            full_remuneration_dropdown_options:[
                    { id: 'salary', value: 'Salary'},
                    { id: 'overtime', value: 'Overtime'},
                    { id: 'sick', value: 'Sick'},
                    { id: 'bonus_payment', value: 'Bonus Payment'},
                    { id: 'commission', value: 'Commission'},
            ]
    
        }),
        watch: {
            activeEmployeeId() {
                this.loading_new_user = true
                //* update the active user data when the activeEmployeeId changes
                //this.$nextTick(() => {
                    this.loadEmployeeData(this.selected_user_id).then(() => {
                        this.setActiveUser()
                    }) 
            },
            active_collapse_index(newValue){
                if(newValue == 2){
                    //* refresh job history table
                    window.webix.$$(this.table_uid).refresh();
                }
                else if(newValue == 3){
                    //* regresh leave/assets.. tables
                    window.webix.$$(this.table_leave_uid).refresh();
                    window.webix.$$(this.table_assets_uid).refresh();
                    window.webix.$$(this.table_remuneration_uid).refresh();
                }
            },
            user:{
                deep: true,
                handler(){
                    if(this.block_form_watcher == false){
                        this.form_data_changed = true;
                    }
                }
            },
            employeeDetails() {
                //this.loading_new_user = true
                this.setActiveUser()
            }
        },
        computed:{
            ...levelComputed,
            ...peopleComputed,
            ...adminComputed,
            ...profileComputed,
            storeEmployeesData() {
                return this.levelEmployeesData.employees;
            },
            getJobTitle(){
                let title = '';
                let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                if(index != -1){
                    title = this.companyJobs[index].title;
                }
                else{   
                    title = 'No Job Linked'
                }
                return title;
            },
            hasValidJob(){
                if(this.user != undefined){
                    let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                    console.log(this.companyJobs);
                    if(index == -1){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return false;
                }
            },
            activeEmployee() {
                var el = this;
                var findIndex = _.findIndex(this.storeEmployeesData, function(o) { return o.id == el.activeEmployeeId; });
                if (findIndex > -1) {
                    return this.storeEmployeesData[findIndex];
                }
                return {};
            },
            active_employee_data() {
                let data = null;
                if(this.activeEmployeeId != -1){
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                    if(index != -1){
                        data = this.levelEmployeesData[index];
                    }
                }
                return data;
            },
        
        },
        methods:{
            ...adminMethods,
            ...peopleMethods,
            ...levelMethods,
            ...profileMethods,
            updateEngagement: _.debounce(function(){
                this.saveRating('engagement',this.engagement);
            }, 500),
            updateSatisfaction: _.debounce(function(){
                this.saveRating('satisfaction',this.satisfaction)
            }, 500),
            // showHierarchyModal(payload) {
            // this.modal_payload = payload;
            // console.log(JSON.parse(JSON.stringify(this.modal_payload)));
            // this.show_hierarchy_modal = true;
            // this.$nextTick(() => {
            //     this.$bvModal.show('levelLinkingModal');
            //     this.$root.$once("bv::modal::hidden", (event) => {
            //         if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
            //             this.show_hierarchy_modal = false;
            //         }
            //     });
            //   })
            // },
            showJobsCardModal(title, report_to){
                this.modal_payload = { title: title, reports_to: report_to, reports_to_user_id: this.user.reports_to_user_id };
                console.log(this.modal_payload)            
                this.show_job_card_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('changeJobCardModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "changeJobCardModal") {
                            this.show_job_card_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
            },
            showResponsibleEmployeeModal(data){
                if(this.employeeDetails.current_job.id == null) {
                    Swal.fire({
                        title: "Please link a job above to change the report to user?",
                        icon: "warning",
                        confirmButtonText: "Ok",
                    })
                } else {
                    this.modal_payload = { title: data };
                    console.log("@@@@@@@@@@@@");
                    console.log(this.modal_payload)            
                    this.show_responsible_employee_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('changeLinkedEmployeeModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "changeLinkedEmployeeModal") {
                                this.show_responsible_employee_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
                
            },
            restoreSelectedUser(){
                Swal.fire({
                    title: "Are you sure you want to restore this employee?",
                    //text: "You won't be able to revert this!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        this.user.active = true;
                        //let payload = _.find(this.levelEmployeesData, {id:Number(this.selected_user_id)});
                        this.setEmployeeArchiveState(this.user)
                        .then(()=>{
                            //let user_name = `${this.user.name} ${this.user.last_name}`
                            //this.showReloadingPrompt(user_name, false);
                            this.loadEmployeesData(this.selected_level.id)
                            this.loadAllUsers();
                            this.loadMenuUsers(this.selected_level.id);
                        })
                    }
                });
            },
            deleteSelectedUser(){
                if(Number(this.selected_user_id) === Number(this.core.id)){
                    Swal.fire("Warning, can't archive logged in user.");
                    return;
                }   
                Swal.fire({
                    title: "Are you sure you want to archive this employee?",
                    //text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "YES",
                }).then((result) => {
                    if (result.value) {
                        this.user.active = 0;
                        //let payload = _.find(this.levelEmployeesData, {id:Number(this.selected_user_id)});
                        this.setEmployeeArchiveState(this.user)
                        .then(()=>{
                            //let user_name = `${this.user.name} ${this.user.last_name}`
                            //this.showReloadingPrompt(user_name, true)
                            this.loadEmployeesData(this.selected_level.id)
                            this.loadAllUsers();
                            this.loadMenuUsers(this.selected_level.id);
                        })
                    }
                });
            },
            showReloadingPrompt(user_name, archived = false){
                let msg = archived ? 'Archived user' : 'Restored'
                Swal.fire({
                    title: `${msg}: ${user_name}`,
                    text: 'Reloading data.',
                    timer: 5000,
                    timerProgressBar: true,
                    icon: "success",showConfirmButton: false,
                }).then(()=>{
                    this.$router.go();
                })
            },
            formatStartDate(date){
                console.log('DATE', date)
                if(date == null){
                    return ''
                }
                if(date !== ''){
                    if(typeof date === 'string'){
                        date = parseISO(date);
                    }
                    return format(date, 'dd-MMM-yyyy');
                }
                else{
                    return '';
                }
            },
            updateUser() {
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                this.updateEmployee(this.user)
                .then(()=>{
                    if(Number(this.core.id) == Number(this.selected_user_id)){
                        this.loadCoreData();
                    }
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.loadLevels();
                        //refresh owner users for datatable columns
                        this.loadAllUsers();
                        //refrrsh users for current level
                        this.loadMenuUsers(this.selected_level.id);
                        if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            updateJobDetails(){
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                let updatedUser = _.cloneDeep(this.user);
                updatedUser.current_job = _.cloneDeep(this.edit_current_job);
                this.updateEmployee(updatedUser)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        /*if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }*/
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                        this.editJobDetails = false;
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            returnOnClickMethods(){
                let onClick = {
                    //eslint-disable-next-line
                    'unlink_button':function(e, id){
                        console.log('On button click', id);
                    }
                };
                return onClick;
            },
            // onLeaveChangesMade(value){
            //     this.leave_changes_made_flag = value;
            // },
            // onRemunerationChangesMade(value){
            //     this.remuneration_changes_made_flag = value;
            // },
            onAssetsChangesMade(value){
                this.assets_changes_made_flag = value;
            },
            setActiveUser(){
                this.refreshed = false
                console.log("RUNNING HERE TO SET THE USER")
                this.form_data_changed = false;
                //* moved this block of code from the 
                //* activeEmployeeId watcher (method used in activeEmployeeId watcher and mounted hook)
                if (this.active_employee_data != null) {
                    this.block_form_watcher = true;
                    this.user = _.cloneDeep(this.active_employee_data);
                    this.user_display_name = this.user.name + ' ' + this.user.last_name
                    this.firstName = this.user.name
                    this.lastName = this.user.last_name
                    if(this.user.created_at) {
                        this.startDate = format(parseISO(this.user.created_at), 'dd/MMM/yyyy')
                    }
                    this.startDate = format(parseISO(this.user.created_at), 'dd/MMM/yyyy')
                    if(this.user.current_job.id) {
                        this.employmentDate = format(parseISO(this.user.current_job.start_date), 'dd/MMM/yyyy')
                    } else {
                        this.employmentDate = 'No Job Started'
                    }
                    if(this.user.current_job) {
                        this.Job_title = this.user.current_job.title
                    }
                
                    console.log(this.user.ratings.engagement);
                    if(this.user.ratings.engagement) {
                        this.engagement = this.user.ratings.engagement[0].rating_value
                        this.engagement_date = format(parseISO(this.user.ratings.engagement[0].updated_at), 'dd/MMM/yyyy')
                    } else {
                        this.engagement = 0
                        this.engagement_date = "No Rating"
                    }

                    if(this.user.ratings.satisfaction) {
                        this.satisfaction = this.user.ratings.satisfaction[0].rating_value
                        this.satisfaction_date = format(parseISO(this.user.ratings.satisfaction[0].updated_at), 'dd/MMM/yyyy')
                    } else {
                        this.satisfaction = 0
                        this.satisfaction_date = "No Rating"
                    }

                    //find the person the user reports to
                    let reports_to = 'No user'
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.user.reports_to_user_id)});
                    if(index != -1){
                        reports_to = this.levelEmployeesData[index];
                    }
                    console.log(reports_to.name)
                    if(reports_to.name == undefined) {
                        this.reports_to = 'No user'
                    } else {
                        this.reports_to = reports_to.name + ' ' + reports_to.last_name
                    }
                    
                    //this.employmentType = "No type"
                    
                    console.log(JSON.parse(JSON.stringify(this.user)))
                        if(this.user.current_job == undefined){
                            this.user['current_job'] = {}
                        }
                        this.user.level_id = this.user.pivot.level_id;
                        this.$nextTick(()=>{
                            this.refreshed = true
                            this.block_form_watcher = false;
                            this.hasMounted = true;
                            this.loading_new_user = false
                        })
                       
                    //* fix issue with older user where user.current_job == null
                    
                }
            },
            saveFromWizardEvent(){
                this.updateUser();
            },
            handleUpdatedUserLevels(){
                let promises = [
                    this.loadEmployeesData(this.selected_level.id),
                    this.loadMenuUsers(this.selected_level.id)
                ]
                Promise.all(promises)
                .then(()=>{
                    let index = _.findIndex(this.levelEmployeesData, { id: this.selected_user_id})
                    if(index != -1){
                        this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                        this.setActiveUser();
                    }
                    else{
                        this.setSelectedUser(null);
                    }
                })
            },
            editUserJobDetails(){
                this.edit_current_job = _.clone(this.user.current_job)
                this.editJobDetails = true;
            },
    
            //* image related methods
            selectImage() {
                if(this.form_data_changed){
                    this.$swal.fire('Please SAVE or CLEAR changes to the employee details form before uploading a profile image')
                }
                else if(this.hasValidPerms()){
                    document.getElementById("fileUpload").click();
                }
                else{
                    this.$swal.fire('Permission not avaliable')
                }
                
            },
            isUserManager(){
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id) ;
                if(is_editing_as_manager){
                    return '';
                }
                return 'block-click';
            },
            hasValidPerms(){
                //let is_editing_self = this.$hasPerm('people') && this.core.id ==  this.selected_user_id
                //let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                //console.log(is_editing_self, is_editing_as_manager)
                //if(is_editing_self){
                //    return true;
                //}
                //else if(is_editing_as_manager){
                //    return true;
                //}
                //return false;
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return true;
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return true;
                }
                return false;
            },
            debugInput(){
    
            },
            
            filesChange(event) {
                let imgFile = event.target.files
                if (imgFile && imgFile[0]) {
                    let reader = new FileReader
                    reader.onload = e => {
                        this.selectedImage = e.target.result
                    }
                    reader.readAsDataURL(imgFile[0])
                    this.show_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show("cropperModal");
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "cropperModal") {
                                this.show_modal = false;
                                this.selectedImage = "";
                            }
                        });
                    })
                }
                
            },
            saveAvatar() {
                this.is_saving_avatar = true;
                const { canvas } = this.$refs.cropper.getResult();
                const params = new FormData();
                let user_id = this.selected_user_id != null ? this.selected_user_id : this.$route.params.user_id
                params.append('user_id', user_id)
                canvas.toBlob(blob => {
                    params.append('avatar', blob)
                    this.uploadAvatar(params)
                    .then(()=>{
                        this.loadAllUsers();
                        this.loadEmployeesData(this.selected_level.id)
                        .then(()=>{
                            this.is_saving_avatar = false;
                            this.setActiveUser();
                            if(Number(this.core.id) == Number(user_id)){
                                this.loadCoreData();
                            }
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.is_saving_avatar = false;
                    })
                }, 'image/jpeg');
            },
            changedJob(){
                this.is_loading_job = true;
                this.loadMenuUsers(this.selected_level.id)
                .then(()=>{
                    this.loadEmployeeData(this.selected_user_id).then(() => {
                        this.setActiveUser();
                        this.is_loading_job = false;
                    })
                    
                    // this.loadEmployeesData(this.selected_level.id)
                    //     .then(()=>{
                    //         this.loadEmployeeData(this.selected_user_id).then(() => {
                                
                               
                    //         })
                            
                    // })
                })
            },
            changedLinkedEmployee(){
                this.is_loading_job = true;
                this.loadMenuUsers(this.selected_level.id)
                .then(()=>{
                    this.loadEmployeeData(this.selected_user_id).then(() => {
                        this.setActiveUser();
                        this.is_loading_job = false;
                    })
                    
                })
            },
            getJobByID(id){
                let job_name = 'Invalid Job'
                let index = _.findIndex(this.companyJobs, {id: id});
                if(index != -1){
                    job_name = this.companyJobs[index].title;
                }
                return job_name;
            },
            saveRating(type ,value) {
            let params = {
                type: type, rating_value: value,
            }
            console.log(JSON.parse(JSON.stringify(params)))
            this.saveEmployeeRating(params).then(()=>{
                this.loadEmployeesData(this.selected_level.id).then(() => {
                    this.loadEmployeeData(this.selected_user_id).then(() => {
                        this.setActiveUser()
                    })
                })
            })
        }

        },
        
        mounted(){
            console.log('running the mount')
            if(this.user == null){
                this.$nextTick(() => {
                    this.setActiveUser();
                })
            }
            this.$eventHub.$on('saveUserFromWizard', this.saveFromWizardEvent);
            this.$eventHub.$on('updatedUserLevels', this.handleUpdatedUserLevels)
        },
        beforeDestroy(){
            this.$eventHub.$off('saveUserFromWizard');
            this.$eventHub.$off('updatedUserLevels');
        }
    }
    </script>
    
    <style>
    .userForm > .row {
        margin-bottom: 1rem;
    }
    .userForm > .row > .col-2 {
        padding-top: 0.55rem;
    }
    .header-profile-user-view{
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #f6f6f6;
        padding: 3px;
    }
    
    /* @media (max-width: 1200px) {
        .move-info{
            display:none
        }
    }     */
    
    .hoverEditIcon{
        cursor: pointer;
        position: absolute;
            bottom: 5px;
            right: 5px;
    }

    .reduced-font-size {
        font-size: 0.7rem !important;
    }

    .fullNameStyles {
        font-size: 1rem !important;
        border-bottom: 1px solid black;
        margin-left: 0.75rem;
        margin-bottom: 1rem;
    }
    </style>