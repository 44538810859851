<!-- //* Webix table to edit tracking values - displayed when a graph gets flipped to edit mode -->
<template>
    <div @mouseleave="closeWebixEditor">
        <b-row>
            <b-col cols="10">
                <webix-ui
                
                    id="datasetTable"
                    v-if="showTable"
                    :config="ui"
                    v-model="tracking_summed"
                ></webix-ui>
                <b-overlay :show="isSaving" no-wrap></b-overlay>
            </b-col>
            <b-col cols="2">

                <div style="width: 100%;">
                    <label for>Progress: {{ progress }}%</label>
                    <b-progress
                        :value="progress"
                        :max="100"
                        class="mb-4"
                    ></b-progress>
                </div>
                <b-button
                    @click="toggleActionsCol"
                    style="padding: 0rem 0rem;"
                    variant="outline-primary"
                    class="w-md"
                >
                    <i
                        v-if="show_actions_col == false"
                        class="fas fa-toggle-off me-1"
                    ></i>
                    <i v-else class="fas fa-toggle-on me-1 text-success"></i>
                    Actions
                </b-button>
                <div v-if="false">
                    <b-button
                        variant="primary"
                        @click="applyTableChanges"
                        class="w-100 mb-1"
                        >Save</b-button
                    >
                </div>
                <div v-if="false">
                    <b-button
                        variant="outline-secondary"
                        @click="$emit('back')"
                        class="w-100"
                        >Cancel</b-button
                    >
                </div>
            </b-col>
        </b-row>
        <NotesModal :note_data="note_data" v-if="show_notes_modal" />
    </div>
</template>

<script>
    import NotesModal from '@/components/objectives/components/misc/notesModal.vue'

    //eslint-disable-next-line
    import {
        format,
        parseISO,
        isAfter,
        isBefore,
        isEqual,
        closestTo,
    } from "date-fns";
    //eslint-disable-next-line
    import { eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, isWithinInterval
    } from "date-fns";
    //eslint-disable-next-line
    import {
        startOfMonth,
        endOfMonth,
        startOfYear,
        endOfYear,
        endOfWeek,
    } from "date-fns";
    import _ from "lodash";
    import dateMixin from '@/mixins/dateMixin.js'
    import { activityMethods, activityComputed, webixTableState, webixTable, levelComputed } from "@/state/helpers";
    export default {
        components: {
            NotesModal
            //simplebar,
        },
        mixins: [dateMixin],
        props: {
            linking_type: {
                default() {
                    return "Level";
                },
            },
            range: {},
            item_id: {},
            time_period: {
                required: true,
            },
            dates: {},
            tracking_data: {},
            is_key_result: {},
            is_metric: {},
            table_name: {
                required: true,
            },
            target: {
                default() {
                    return 0;
                },
            },
        },
        data: () => ({
            resize_event: null, //* size watcher for datatable -- resize table on window size change
            range_date: null,
            jump_to_date: null,
            was_data_updated: false,

            show_actions_col: false,

            isSaving: false,

            //notes
            note_data: null,
            show_notes_modal: false,

            //webix config
            ui: {
                navigation: true,
                select: "cell",
                //* webix config
                id: "",
                undo: true,
                view: "datatable",
                css: "datasetTable webix_data_border webix_header_border",
                editable: true,
                autoheight: true,
                scrollY: true,
                scrollX: false,
                fillspace: true,
                resizeColumn:true,
                columns: [
                    {
                        id: "date",
                        header: "Date",
                        fillspace: true,
                        format: function(value) {
                            let date = _.cloneDeep(value);
                            if (date !== "") {
                                if (typeof date === "string") {
                                    date = parseISO(date);
                                }
                                return format(date, "dd-MMM-yyyy");
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        id: "target_value",
                        header: "Target",
                        fillspace: 0.6,
                        editor: "text",
                    },
                    {
                        id: "value",
                        header: "Achieved",
                        fillspace: 0.6,
                        editor: "text",
                    },
                    {
                        id: "total",
                        header: "Cumulative Total",
                        fillspace: 0.6,
                        css: "read-only-col",
                    },
                    /*{ id: 'notes', header: 'Notes', width: 70,
                        template: function(obj){
                            if(!obj.notes){
                                return `
                                <div style='text-align: center' class="notes_button empty_notes">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                                </div>`
                            }
                            else{
                                return `
                                <div style='text-align: center' class="notes_button">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen"><path fill="currentColor" d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" class=""></path></svg>
                                </div>`
                            }
                            
                        }
                    },*/
                    {
                        id: "actions",
                        header: "Actions",
                        hidden: true,
                        fillspace: 1,
                        template: function() {
                            return `
                                <span title="Duplicate Value Up" class=" d-up hoverDatasetIcon me-1"><i class="datasetIcon text-primary fas fa-angle-double-up"></i></span>
                                <span title="Duplicate Value Down "class="d-down hoverDatasetIcon me-1"><i class="datasetIcon text-primary fas fa-angle-double-down"></i></span>
                                <span title="Clear Values Up" class="c-up hoverDatasetIcon me-1"><i class="datasetIcon text-danger fas fa-angle-up"></i></span>
                                <span title="Clear Values Down" class="c-down hoverDatasetIcon me-1"><i class="datasetIcon text-danger fas fa-angle-down"></i></span>
                                <span title="Clear All Values" class="c-all hoverDatasetIcon"><i class="datasetIcon text-dark fas fa-times"></i></span>
                                `;
                        },
                    },
                ],
                ready() {
                    let el = this.$scope.$parent;
                    this.config.columns[1].header = el.getTargetColumnTitle();
                    this.config.columns[2].header = el.getAchievedColumnTitle();
                    this.refreshColumns();
                    if(el.selected_key_result.show_cumulative == 1){
                        this.hideColumn('total');
                    }
                    el.resize_event = window.webix.event(
                        window,
                        "resize",
                        function() {
                            el.$nextTick(() => {
                                window.webix.$$(el.ui.id).refresh();
                            });
                        }
                    );
                    if(el.dataset_table_states['key_results'] != undefined){
                        this.setState(
                            {size: el.dataset_table_states['key_results']}
                        )
                    }
                    window.webix
                        .ui({
                            view: "contextmenu",
                            id: "editGraphContext" + el.ui.id,
                            on: {
                                onMenuItemClick: function(id) {
                                    var context = this.getContext();
                                    let payload = {
                                        column: context.id.column,
                                        row: context.id.row,
                                    };

                                    switch (id) {
                                        case "Duplicate Down": {
                                            el.duplicateDown(payload);
                                            break;
                                        }
                                        case "Duplicate Up": {
                                            el.duplicateUp(payload);
                                            break;
                                        }
                                        case "Clear All": {
                                            el.clearAll(payload);
                                            break;
                                        }
                                        case "Clear Down": {
                                            el.clearDown(payload);
                                            break;
                                        }
                                        case "Clear Up": {
                                            el.clearUp(payload);
                                            break;
                                        }
                                    }
                                },
                            },
                            data: [
                                "Duplicate Down",
                                "Duplicate Up",
                                "Clear All",
                                "Clear Down",
                                "Clear Up",
                            ],
                        })
                        .attachTo(this);
                },
                on: {
                    //eslint-disable-next-line
                    onColumnResize:_.debounce(function(id, newWidth, oldWidth, user_action){
                        if(user_action){
                            let el = this.$scope.$parent;
                            let column_state = this.getState();
                            el.saveDatasetTableState({
                                uid: 'key_results',
                                col_state: column_state.size,
                            });
                        }
                    }, 100),
                    onBeforeEditStart(editor){
                        let el = this.$scope.$parent;
                        const is_cumulative_target_conditional = editor.column == 'target_value' && el.selected_key_result.show_cumulative_target == 1;
                        if(is_cumulative_target_conditional){
                            // need to check if target entry is cumulative, then only make last row editable
                            const last_row_id = this.data.getLastId();
                            if(last_row_id == editor.row){
                                return true;
                            }
                            else{
                                el.$swal.fire('This graph has a cumulative target type, only the last row can be updated');
                                return false;
                            }
                        }
                    },
                    onAfterEditStop(state, editor){
                        let el = this.$scope.$parent;
                        //let found_bigger_value = false;
                        if(state.value != state.old){
                            if(el.selected_key_result.show_cumulative == 1){
                                //get current index to check if cumulative value entered is bigger than the previous values
                                const row_index = this.data.getIndexById(editor.row);
                                if(row_index > 0){
                                    let idx = 0;
                                    while(idx < row_index){
                                        let current_row = this.getItem(this.data.getIdByIndex(idx));
                                        if(Number(current_row[editor.column]) != 0 && Number(current_row[editor.column]) > Number(state.value)){
                                            //found_bigger_value = true;
                                            break;
                                        }
                                        idx++;
                                    }
                                }

                                // if(found_bigger_value){
                                //     this.undo();
                                //     setTimeout(()=>{
                                //         el.$swal.fire({
                                //             title: "Invalid cumulative value",
                                //             text: "The cumulative value entered can not be smaller than previous values",
                                //             icon: "warning",
                                //         })
                                //     }, 250)
                                //     return;
                                // }
                            }
                            

                            let payload = {
                                column: editor.column,
                                row: editor.row,
                            }
                            el.saveChange(payload)
                        }
                    },
                    //eslint-disable-next-line
                    onKeyPress: function(code, e) {
                        const UP = 38;
                        const DOWN = 40;
                        const ENTER = 13;
                        //* we need to check if the editor is open - if open then close the editor and select prev/next row
                        //* if the value from getEditor is 0 then there is no editor open
                        if (code == UP || code == DOWN) {
                            const last_editor = this.getEditor();
                            if (last_editor) {
                                this.editStop();
                                switch (code) {
                                    case UP: {
                                        this.moveSelection("up");
                                        let new_selection = this.getSelectedItem();
                                        this.editCell(
                                            new_selection.id,
                                            last_editor.column
                                        );
                                        break;
                                    }
                                    case DOWN: {
                                        this.moveSelection("down");
                                        let new_selection = this.getSelectedItem();
                                        this.editCell(
                                            new_selection.id,
                                            last_editor.column
                                        );
                                        break;
                                    }
                                }
                            }
                        }
                        if (code == ENTER) {
                            const last_editor = this.getEditor();
                            if (!last_editor) {
                                let item = this.getSelectedId();
                                setTimeout(() => {
                                    this.select(item.row, item.column);
                                }, 50);
                                setTimeout(() => {
                                    this.editCell(item.row, item.column);
                                }, 100);
                            }
                        }
                    },
                },
                onClick: {
                    //eslint-disable-next-line
                    "notes_button": function(e, obj) {
                        let el = this.$scope.$parent;
                        let item = this.getItem(obj)
                        el.showNotesModal(item);
                    },
                    //eslint-disable-next-line
                    "d-up": function(e, obj) {
                        let el = this.$scope.$parent;
                        let payload = {
                            column: "value",
                            row: obj.row,
                        };
                        this.editStop();
                        el.$nextTick(() => {
                            el.duplicateUp(payload);
                        });
                    }, //eslint-disable-next-line
                    "d-down": function(e, obj) {
                        let el = this.$scope.$parent;
                        let payload = {
                            column: "value",
                            row: obj.row,
                        };
                        this.editStop();
                        el.$nextTick(() => {
                            el.duplicateDown(payload);
                        });
                    }, //eslint-disable-next-line
                    "c-up": function(e, obj) {
                        let el = this.$scope.$parent;
                        let payload = {
                            column: "value",
                            row: obj.row,
                        };
                        this.editStop();
                        el.$nextTick(() => {
                            el.clearUp(payload);
                        });
                    }, //eslint-disable-next-line
                    "c-down": function(e, obj) {
                        let el = this.$scope.$parent;
                        let payload = {
                            column: "value",
                            row: obj.row,
                        };
                        this.editStop();
                        el.$nextTick(() => {
                            el.clearDown(payload);
                        });
                    }, //eslint-disable-next-line
                    "c-all": function(e, obj) {
                        let el = this.$scope.$parent;
                        let payload = {
                            column: "value",
                            row: obj.row,
                        };
                        this.editStop();
                        el.$nextTick(() => {
                            el.clearAll(payload);
                        });
                    },
                },
            },
            uid: 0,
            showTable: false,
            frequency: "monthly",
            tracking: [], //      {date, value}
        }),
        computed: {
            ...webixTableState,
            ...activityComputed,
            ...levelComputed,
            progress() {
                if(this.tracking.length == 0){
                    return 0;
                }
                
                //let dd = this.findNewestItem(this.tracking, 'value')

                let prog = 0;
                let progress_calculation_type = this.getProgressType();
                switch(progress_calculation_type){
                    case 'discrete_achieved_discrete_target':{
                        if (this.tracking_summed.length > 0) {
                            let total = this.tracking_summed[
                                this.tracking_summed.length - 1
                            ].total;
                            if (total > 0) {
                                prog = (total / this.target) * 100;
                                prog = Math.round((prog + Number.EPSILON) * 100) / 100;
                            }
                        }
                        break;
                    }
                    case 'discrete_achieved_cumulative_target':{
                        if (this.tracking_summed.length > 0) {
                            let total = this.tracking_summed[
                                this.tracking_summed.length - 1
                            ].total;
                            if (total > 0) {
                                prog = (total / this.target) * 100;
                                prog = Math.round((prog + Number.EPSILON) * 100) / 100;
                            }
                        }
                        break;
                    }
                    case 'cumulative_achieved_discrete_target':{
                        let latest_achieved = this.findNewestValue('value');
                        let latest_achieved_date = this.findNewestDate('value');
                        console.log(latest_achieved, latest_achieved_date);
                        if(latest_achieved == 0 || latest_achieved_date == -1){
                            prog = 0;
                        }
                        else{
                            let closest_target_value = this.findNewestValueBeforeDate('target_value', new Date(latest_achieved_date))
                            if(closest_target_value > 0){
                                prog = ( latest_achieved / closest_target_value ) * 100;
                                prog = Math.round((prog + Number.EPSILON) * 100) / 100;
                            }
                        }
                        break;
                    }
                    case 'cumulative_achieved_cumulative_target':{
                        let latest_achieved = this.findNewestValue('value');
                        let latest_target = this.target;
                        // dont do calc if any value is == 0
                        if(latest_achieved && latest_target){
                            prog = ( latest_achieved / latest_target ) * 100;
                            prog = Math.round((prog + Number.EPSILON) * 100) / 100;
                        }
                        break;
                    }
                    default:{
                        break;
                    }
                }
                return prog
                //return this.getProgressValue();
            },
            tracking_summed() {
                //let tracking = _.cloneDeep(this.tracking);
                this.tracking.forEach((item, idx) => {
                    item.total = 0;
                    if (idx == 0) {
                        item.total = item.value;
                    } else {
                        item.total =
                            Number(item.value) +
                            Number(this.tracking[idx - 1].total);
                    }
                });
                return this.tracking;
            },
            computed_columns() {
                if (this.is_key_result) {
                    return [
                        { id: "date", header: "Date", fillspace: true },
                        {
                            id: "target_value",
                            //header: "Target",
                            header: this.getTargetColumnTitle(),
                            fillspace: 0.8,
                            editor: "text",
                        },
                        {
                            id: "value",
                            header: this.getAchievedColumnTitle(),
                            //header: "Value",
                            fillspace: 0.8,
                            editor: "text",
                        },
                        { id: "total", header: "Totals", fillspace: 0.8 },
                        
                    ];
                } else {
                    return [
                        { id: "date", header: "Date", fillspace: true },
                        {
                            id: "target_value",
                            hidden: true,
                            header: "Target",
                            fillspace: 0.8,
                            editor: "text",
                        },
                        {
                            id: "value",
                            //header: "Value",
                            header: this.getAchievedColumnTitle(),
                            fillspace: 0.8,
                            editor: "text",
                        },
                    ];
                }
            },
        },
        watch: {
            isSaving() {
                this.$emit("savingState", this.isSaving);
            },
        },
        methods: {
            ...webixTable,
            ...activityMethods,
            findNewestValue(key) {
                const validEntries = this.tracking
                .filter(entry => entry[key] > 0)
                .sort((a, b) => new Date(b.date) - new Date(a.date));

                if (validEntries.length > 0) {
                    return validEntries[0].value; // Return the value of the newest valid entry
                } else {
                    return 0; // Return 0 if no valid entry is found
                }
            },
            findNewestValueBeforeDate(key, before_date) {
                const validEntries = this.tracking
                .filter(entry => entry[key] > 0 && new Date(entry.date) <= before_date)
                .sort((a, b) => new Date(b.date) - new Date(a.date));

                if (validEntries.length > 0) {
                return validEntries[0][key];
                } else {
                return 0;
                }
            },
            findNewestDate(key){
                const validEntries = this.tracking
                .filter(entry => entry[key] > 0)
                .sort((a, b) => new Date(b.date) - new Date(a.date));

                if (validEntries.length > 0) {
                    return validEntries[0].date; // Return the value of the newest valid entry
                } else {
                    return -1; // Return 0 if no valid entry is found
                }
            },


            showNotesModal(row){
                let note_object = row;
                console.log(row);
                row['note'] = row.notes;
                row['name'] = this.getSimpleDate(row.date)
                row['type'] = "kpi"
                //* note_object contains:
                //? item_id -> id of the item that is being displayed
                //? name -> name of item being displayed
                //? note -> note text
            this.note_data = note_object;
            this.show_notes_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('notesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "notesModal") {
                        this.show_notes_modal = false;
                        this.note_data = null;
                    }
                });
            })
            },
            findNewestItem(array, key){
                let reduced = array.reduce((newestItem, currentItem) => {
                    // Check if the current item's date is newer and its value is not 0
                    if (currentItem.achieved_date > newestItem.achieved_date && currentItem[key] !== 0) {
                        return currentItem; // Update the newest item
                    }
                    return newestItem;
                }, { date: new Date(0) }); // Initial value with the lowest possible date
                console.log('REDCUED', reduced)
                return reduced;
            },
            getProgressValue(){
                
            },
            getProgressType(){
                let achieved_type = this.selected_key_result.show_cumulative ? 'cumulative_achieved' : 'discrete_achieved';
                let target_type = this.selected_key_result.show_cumulative_target ?  'cumulative_target' : 'discrete_target';
                //* combine types to form string for switch statement 
                return `${achieved_type}_${target_type}` ;

                // returns true/false for unit types cumulative value
                //let unit = this.keyResultUnit.find( unit_type => {
                //    return unit_type.id == this.selected_key_result.unit_type_id;
                //})
                //return unit.cumulative;

                //return this.selected_key_result.show_cumulative;
            },
            toggleActionsCol() {
                this.show_actions_col = !this.show_actions_col;
                let table = window.webix.$$(this.ui.id);
                if (this.show_actions_col) {
                    table.showColumn("actions");
                } else {
                    table.hideColumn("actions");
                }
            },
            // eslint-disable-next-line
            closeWebixEditor() {
                let table = window.webix.$$(this.ui.id);
                if(table){
                    table.editStop();
                }
            },
            duplicateDown(payload) {
                let id = payload.row;
                let col = payload.column;
                let item = window.webix.$$(this.ui.id).getItem(id);
                let dateToCompare = parseISO(item.date);
                this.tracking.forEach((graph_item) => {
                    let date = parseISO(graph_item.date);
                    if (isAfter(date, dateToCompare)) {
                        graph_item[col] = item[col];
                    }
                });
                this.$nextTick(() => {
                    window.webix.$$(this.ui.id).define("data", this.tracking);
                    window.webix.$$(this.ui.id).refresh();
                });
            },
            duplicateUp(payload) {
                let id = payload.row;
                let col = payload.column;
                let item = window.webix.$$(this.ui.id).getItem(id);
                let dateToCompare = parseISO(item.date);
                this.tracking.forEach((graph_item) => {
                    let date = parseISO(graph_item.date);
                    if (isBefore(date, dateToCompare)) {
                        graph_item[col] = item[col];
                    }
                });
                this.$nextTick(() => {
                    window.webix.$$(this.ui.id).define("data", this.tracking);
                    window.webix.$$(this.ui.id).refresh();
                });
            },
            clearDown(payload) {
                let id = payload.row;
                let col = payload.column;
                let item = window.webix.$$(this.ui.id).getItem(id);
                let dateToCompare = parseISO(item.date);
                this.tracking.forEach((graph_item) => {
                    let date = parseISO(graph_item.date);
                    if (
                        isAfter(date, dateToCompare) ||
                        isEqual(date, dateToCompare)
                    ) {
                        graph_item[col] = 0;
                    }
                });
                this.$nextTick(() => {
                    window.webix.$$(this.ui.id).define("data", this.tracking);
                    window.webix.$$(this.ui.id).refresh();
                });
            },
            clearUp(payload) {
                let id = payload.row;
                let col = payload.column;
                let item = window.webix.$$(this.ui.id).getItem(id);
                let dateToCompare = parseISO(item.date);
                this.tracking.forEach((graph_item) => {
                    let date = parseISO(graph_item.date);
                    if (
                        isBefore(date, dateToCompare) ||
                        isEqual(date, dateToCompare)
                    ) {
                        graph_item[col] = 0;
                    }
                });
                this.$nextTick(() => {
                    window.webix.$$(this.ui.id).define("data", this.tracking);
                    window.webix.$$(this.ui.id).refresh();
                });
            },
            clearAll(payload) {
                let col = payload.column;
                this.tracking.forEach((graph_item) => {
                    graph_item[col] = 0;
                });
                this.$nextTick(() => {
                    window.webix.$$(this.ui.id).define("data", this.tracking);
                    window.webix.$$(this.ui.id).refresh();
                });
            },

            //eslint-disable-next-line
            isValidRange(value) {
                let isValid = true;
                /*if(this.range.min === 0 && this.range.max === 0){
        //* no min/max was supplied
        isValid = true;
      }
      else{
        if(value >= this.range.min && value <= this.range.max){
          isValid = true;
        }
      }*/
                return isValid;
            },
            setKpis() {
                this.uid = window.webix.uid();
                this.ui.id += "kpiDates" + this.uid;
                //this.ui.id = 'kpiDates';
                //* Return uid to parent component, used to redraw the webix table if the graph component changes size
                this.$emit("returnWebixUID", this.ui.id);
                let start_date = new Date(this.dates.start);
                let end_date = new Date(this.dates.end);
                let start = null;
                let end = null;
                let all_dates = [];
                let dates = [];

                if (this.frequency == "daily") {
                    //* Get every DAY for MONTH of the supplied DATE
                    start = new Date(this.dates.start);
                    end = new Date(this.dates.end);
                    dates = eachDayOfInterval({ start, end });
                } else if (this.frequency == "weekly") {
                    //TODO -> Fix, not working
                    //* Get every LAST DAY for WEEK of the supplied DATE
                    start = startOfMonth(start_date);
                    end = endOfMonth(end_date);
                    all_dates = eachWeekOfInterval({ start, end });
                    all_dates.forEach((week_date) => {
                        let new_d = week_date;
                        new_d = endOfWeek(new_d);
                        dates.push(this.getSimpleDate(new_d));
                    });

                    //let found_idx = _.findIndex(all_dates, new Date(this.dates.end))
                    //if(found_idx != -1){
                    //    dates.push(this.dates.end)
                    //}

                } else if (this.frequency == "monthly") {
                    //TODO -> Fix, not working
                    start = startOfMonth(start_date);
                    end = endOfMonth(end_date);
                    dates = eachMonthOfInterval({ start, end });
                    dates.forEach((single_date, idx) => {
                        let new_d = single_date;
                        new_d = endOfMonth(new_d);
                        dates[idx] = new_d;
                    });
                    /*dates.forEach( (current_item) => {
          current_item = endOfMonth(current_item);
        })*/
                } else if (this.frequency == "quarterly") {
                    //TODO -> Fix, not working
                    alert("Invalid");
                } else if (this.frequency == "yearly") {
                    //TODO -> Fix, not working
                    start = startOfYear(start_date);
                    end = endOfYear(end_date);
                    dates.push(start);
                    dates.push(end);
                }

                //* Add empty rows to edit data
                dates.forEach((date) => {
                    // let current_date = format(date, "dd-MM-yyyy");
                    let current_date = this.getSimpleDate(date);
                    let index = _.findIndex(this.tracking_data, (item) => {
                        //   let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        let item_date = this.getSimpleDate(
                            item.achieved_date
                        );
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        //* push the existing value
                        this.tracking.push({
                            date: this.getDate(current_date),
                            value: this.tracking_data[index].value,
                            target_value: this.tracking_data[index].target_value,
                            id: this.tracking_data[index].id,
                            total: 0,
                            //notes: this.tracking_data[index].notes,
                            notes: ''
                        });
                    }
                    else {
                        //* push an empty value
                        this.tracking.push({
                            date: this.getDate(current_date),
                            value: 0,
                            target_value: null,
                            isEmpty: true,
                            total: 0,
                            notes: '',
                        });
                    }
                });
                let last_index = this.tracking.length - 1;
                let has_existing_last_target_value = this.tracking[last_index].target_value == 0 ||this.tracking[last_index].target_value == null
                if (has_existing_last_target_value) {
                    this.tracking[last_index].target_value = this.target;
                    this.saveLastTarget(this.tracking[last_index])
                }

                this.showTable = false;
                if (this.is_key_result) {
                    this.ui.columns[1]["hidden"] = false;
                } else {
                    this.ui.columns[1]["hidden"] = true;
                }
                /*if(this.show_actions_col){
        this.ui.columns[4]['hidden'] = false;
      }
      else{
        this.ui.columns[4]['hidden'] = true;
      }*/
                setTimeout(() => {
                    //* wait for the flip animation to finish - webix needs to be rendered after
                    this.showTable = true;
                    setTimeout(() => {
                        this.selectCurrentDate();
                    }, 200);
                }, 200);
            },

            selectCurrentDate() {
                this.$nextTick(() => {
                    if (this.tracking_summed.length > 0) {
                        if(window.webix.$$(this.ui.id)){
                            //* cant remember why this is here :(
                            window.webix.$$(this.ui.id).filter();
                        }

                        this.range_date = null;
                        let date = format(new Date(), "yyyy-MM-dd");
                        let result = window.webix
                            .$$(this.ui.id)
                            .find(function(obj) {
                                return obj.date === date;
                            });
                        if (result.length > 0) {
                            window.webix
                                .$$(this.ui.id)
                                .select(result[0].id, "value");
                            //window.webix.$$(this.ui.id).editCell(result[0].id, 'value');
                        } else {
                            let date = new Date();
                            //let date = format(new Date(),'yyyy-MM-dd')
                            //let last_index = this.tracking_summed.length - 1;
                            let dates = this.tracking_summed.map((item) => {
                                return new Date(item.date);
                            });
                            let closest_date = closestTo(date, dates);
                            let result_2 = window.webix
                                .$$(this.ui.id)
                                .find(function(obj) {
                                    return (
                                        obj.date ===
                                        format(closest_date, "yyyy-MM-dd")
                                    );
                                });
                            if (result_2.length > 0) {
                                this.$nextTick(() => {
                                    window.webix
                                        .$$(this.ui.id)
                                        .select(result_2[0].id, "value");
                                    //window.webix.$$(this.ui.id).editCell(result_2[0].id, 'value');
                                });
                            }

                            //window.webix.$$(this.ui.id).select(this.tracking_summed[last_index].id);
                            //window.webix.$$(this.ui.id).editCell(this.tracking_summed[last_index].id, 'value');
                        }
                    }
                });
            },

            saveValue(params) {
                //TODO - need to clean up this mess.. sloppy :D
                //if(this.isValidRange(params.value)){
                if (this.is_key_result) {
                    console.log("B4 save", params);
                    //*Path for key result
                    params["key_result_id"] = this.item_id;
                    this.saveKeyResultTracking(params).then(() => {
                        this.$emit("updated");
                        this.$nextTick(() => {
                            this.$emit("savedChanges");
                        });
                    });
                } else if (this.is_metric) {
                    //* Save path for kpi
                    params["metric_id"] = this.item_id;
                    this.saveMetricTracking(params).then(() => {
                        this.$emit("updated");
                        this.$nextTick(() => {
                            this.$emit("savedChanges");
                        });
                    });
                } else {
                    //* Save path for kpi
                    params["kpi_id"] = this.item_id;
                    this.saveKpiTracking(params).then(() => {
                        this.$emit("updated");
                        this.$nextTick(() => {
                            this.$emit("savedChanges");
                        });
                    });
                }
            },
            saveLastTarget(tracking_object){
                let params = {
                    key_result_id: this.item_id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(tracking_object.date),
                        value: tracking_object.value == 0 ? null :  tracking_object.value,
                        //* save target val as 0 if it is null
                        target: Number(tracking_object.target_value)
                    }],
                }
                this.saveKeyResultTracking(params);
            },
            saveChange(payload){// contains column, row(id)
                let updated_item = window.webix.$$(this.ui.id).getItem(payload.row);
                let last_row_id = window.webix.$$(this.ui.id).getLastId();

                //* check if the current row is the last row in the series - use that target value to update the key result object's target
                // (so that the graph and its dataset has the same target value)
                if(last_row_id == updated_item.id){
                    //* first check if last value is valid ( cant be empty or 0)

                    const is_target_empty = updated_item.target_value == null || updated_item.target_value == '';
                    const is_target_0 = updated_item.target_value == 0;
                    if(is_target_empty || is_target_0){
                        this.$swal.fire('Invalid target value');
                        return;
                    }

                    //* here we need to update the current key result's target value
                    let params = {
                        key_results: []
                    };
                    params.key_results.push({
                        milestone_objective_id: this.selected_key_result.milestone_objective_id,
                        id: this.selected_key_result.id,
                        level_id: this.selected_level.id,
                        owner_user_id: this.selected_key_result.owner_user_id,
                        name: this.selected_key_result.name,
                        target: updated_item.target_value.toString(),
                        status: '',
                        start_date: format(new Date(this.selected_key_result.start_date), 'yyyy-MM-dd'),
                        deadline: format(new Date(this.selected_key_result.deadline), 'yyyy-MM-dd'),
                        unit_type_id: this.selected_key_result.unit_type_id,
                        graph_type: this.selected_key_result.graph_type,
                        min_value: this.selected_key_result.min_value,
                        max_value: this.selected_key_result.max_value,
                        current_time_period: this.selected_key_result.current_time_period,
                        graph_size: '12', //only one graph displays at a time, so force full width graph
                        show_cumulative: Number(this.selected_key_result.show_cumulative),
                    })
                    this.saveKeyResult(params);
                }
                let params = {
                    key_result_id: this.item_id,
                    tracking_data: [{
                        achieved_date: this.getBackendDateFormat(updated_item.date),
                        value: updated_item.value,
                        //* save target val as 0 if it is null
                        target: this.isNullOrEmpty(updated_item.target_value) ? null : Number(updated_item.target_value)
                        //target: updated_item.target_value == null ? 0 : Number(updated_item.target_value)
                    }],
                }

                this.saveKeyResultTracking(params).then(() => {
                    this.was_data_updated = true;
                    this.$emit("updated");
                });
            },
            applyTableChanges() {
                this.isSaving = true;
                let table = window.webix.$$(this.ui.id);
                let params = {
                    tracking_data: [],
                };
                table.eachRow((row) => {
                    let record = table.getItem(row);
                    if (record.isEmpty == undefined) {
                        console.log("Record", record);
                        if (record.target_value == 0) {
                            record.target_value = 0;
                        }
                        if (record.target_value === "") {
                            record.target_value = null;
                        }
                        //* if undefined then this is a normal row
                        params.tracking_data.push({
                            achieved_date: this.getBackendDateFormat(record.date),
                            target: Number(record.target_value),
                            value: Number(record.value),
                        });
                    } else if (record.isEmpty == true) {
                        //if(record.target_value != 0 || record.value != 0){
                        //* check if target or value was updated if newRow is true
                        let target_val = record.target_value ? Number(record.target_value) : null;
                        params.tracking_data.push({
                            achieved_date: this.getBackendDateFormat(record.date),
                            target: target_val,
                            value: Number(record.value),
                            is_empty: true,
                        });
                    }
                    
                });
                //if(table){
                //    console.log(params)
                //    this.isSaving = false;
                //    return;
                //}

                //console.log('SAVE PARAMS:',params);
                if (params.tracking_data.length > 0) {
                    this.saveValue(params);
                } else {
                    alert("Nothing to save");
                }
            },
            handleFiredSaveEvent(id) {
                if (this.ui.id == id) {
                    this.applyTableChanges();
                }
            },
            isNullOrEmpty(value){
                return value == null || value == '';
            },
            getTargetColumnTitle(){
                switch(this.getProgressType()){
                    case 'discrete_achieved_discrete_target':{
                        return 'Discrete Target';
                    }
                    case 'discrete_achieved_cumulative_target':{
                        return 'Cumulative Target';
                    }
                    case 'cumulative_achieved_discrete_target':{
                        return 'Discrete Target';
                    }
                    case 'cumulative_achieved_cumulative_target':{
                        return 'Cumulative Target';
                    }
                    default:{
                        return '';
                    }
                }
            },
            getAchievedColumnTitle(){
                switch(this.getProgressType()){
                    case 'discrete_achieved_discrete_target':{
                        return 'Discrete Achieved';
                    }
                    case 'discrete_achieved_cumulative_target':{
                        return 'Discrete Achieved';
                    }
                    case 'cumulative_achieved_discrete_target':{
                        return 'Cumulative Achieved';
                    }
                    case 'cumulative_achieved_cumulative_target':{
                        return 'Cumulative Achieved';
                    }
                    default:{
                        return '';
                    }
                }
            }
        },
        created() {
            this.frequency = this.time_period;
        },
        mounted() {
            this.$eventHub.$on("saveGraphDatatable", this.handleFiredSaveEvent);
            window.kr_el = this;
            this.frequency = this.time_period;
            //* Give the webix table a unique id
            this.setKpis();
        },
        beforeDestroy() {
            if(this.was_data_updated){
                this.$emit("savedChanges");
            }
            this.$eventHub.$off("saveGraphDatatable");
            window.kr_el = null;
            window.webix.eventRemove(this.resize_event);

            //destroy context menu
            if(window.webix.$$("editGraphContext" + this.ui.id) != undefined ) {
                window.webix.$$("editGraphContext" + this.ui.id).destructor();
            }
            //destroy table
            if(window.webix.$$(this.ui.id) != undefined) {
                window.webix.$$(this.ui.id).destructor();
            }
        },
    };
</script>

<style>
    .read-only-col {
        background: #e4e4e4;
    }
    .hoverDatasetIcon {
        cursor: pointer;
        text-align: center;
    }
    .hoverDatasetIcon:hover {
        background-color: rgb(192, 192, 192) !important;
    }

    .datasetIcon {
        width: 15px;
    }

    /*.datasetTable .simplebar-track.simplebar-vertical{
  width: 22px !important;
}
.datasetTable .simplebar-track.simplebar-vertical{
  width: 22px !important;
}*/
    .datasetTable .simplebar-scrollbar {
        width: 8px;
    }
    .datasetTable .simplebar-scrollbar::before {
        background-color: var(--bs-primary);
    }
    .datasetTable .webix_cell_select {
        box-shadow: inset 0px 0px 0px 1px #000000;
    }

    .empty_notes{
        color: lightgrey;
    }
    .notes_button:hover{
        cursor: pointer;
        color: black;
    }
</style>
