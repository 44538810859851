<template>
    <div class="simple-editor" >
        <quill-editor ref="myQuillEditor" v-model="editor_content" :options="editorOption" />
        <!-- <b-form-textarea v-model="editor_content" rows="16"></b-form-textarea> -->
        <!-- <ckeditor ref="myckeditor" v-model="editor_content" :config="editorConfig" @ready="onEditorReady"></ckeditor> -->
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
//eslint-disable-next-line
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
//eslint-disable-next-line
import QuillEmoji from 'quill-emoji';
//* editor component
// import CKEditor from 'ckeditor4-vue';
export default {
    data: () => ({
        //* var to hold editors context
        editor: null,
        //* configuration options for ckeditor4
        editorConfig: {
            extraPlugins: 'dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,tableresize,panelbutton,panel,colorbutton,colordialog,menu,contextmenu,dialogadvtab,enterkey,find,listblock,font,format,horizontalrule,indent,indentblock,indentlist,justify,list,liststyle,pastefromword,pastetext,removeformat,resize,menubutton,sourcearea,stylescombo,tab,table,tabletools,toolbar,undo',
            removeButtons: 'About'
            //extraPlugins: 'dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,button,tableresize,panelbutton,panel,colorbutton,colordialog,menu,contextmenu,dialogadvtab,enterkey,find,listblock,font,format,horizontalrule,indent,indentblock,indentlist,justify,list,liststyle,pastefromword,pastetext,preview,removeformat,resize,save,menubutton,showborders,sourcearea,stylescombo,tab,table,tabletools,templates,toolbar,undo,wysiwygarea',
        },
        editor_content: '',
        editorOption: {
            // Some Quill options...
            theme: 'snow', // or 'bubble'
            placeholder: 'Enter Message Here',
            modules: {
                // Include quill-emoji module
                'emoji-toolbar': true,
                'emoji-textarea': false,
                'emoji-shortname': true,
                toolbar: [
                    [{ header: [false, 1, 2] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    ['emoji'], // Add emoji button to the toolbar
                    ['link', 'code'],
                ],
                keyboard: {
                    bindings: {
                        shift_enter: {
                            key: 13,
                            shiftKey: true,
                            handler: (range, ctx) => {
                                console.log(range, ctx); // if you want to see the output of the binding
                                this.editor.insertText(range.index, '\n');
                            },
                        },
                        enter: {
                            key: 13,
                            handler: () => {
                                if (this.content.length > 0) {
                                    this.onSendChatMessage(this.content);
                                }
                            },
                        },
                    },
                },
            },
        },
    }),
    components: {
        quillEditor,
    },
    props: {
        content: {
            default: "<h3>No content provided</h3>"
        }
    },
    watch: {
        content: {
            immediate: true,
            handler(newValue) {
                this.editor_content = newValue;
            }
        },
        editor_content(newValue) {
            this.$emit('contentChanged', newValue);
        }
    },
    // components: {
    //     ckeditor: CKEditor.component
    // },
    computed: {
        
    },
    methods: {
        //* set editor context
        onEditorReady(editor) {
            this.editor = editor;
        },
    },
    mounted() {

    },
    beforeDestroy() {
        this.editor = null;
    }
}
</script>

<style>
.simple-editor div .ql-container{
    min-height: 350px !important;
}
</style>