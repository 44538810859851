<template>
    <div>
        <div class="d-flex flex-wrap mb-0" style="width: 100%;">
            <div class="me-2">
                <h5 class="card-title mr-2 d-flex align-items-center">
                    <!--Interview Comments&nbsp;&nbsp;>&nbsp;&nbsp;-->
                    <span class="text-primary">{{ jobName }}</span>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-primary">{{active_candidate_data.first_name}}</span>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-primary">{{active_skill_name}}</span>
                    <span></span>
                </h5>
            </div>

            <!-- buttons relating to values emitted from table -->
            <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="ms-auto me-3 dropdown-menu-end">
                <strong>Save Table Changes</strong>
            </b-button>
            <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="ms-auto me-3 dropdown-menu-end">
                <strong>Delete Selected Rows</strong>
            </b-button>
            <!-- ------ -->

            <!-- -- dropdown -- -->
            <b-dropdown :class="dropdownClassFix" text="Button text via Prop" variant="white" toggle-tag="a" menu-class="dropdown-menu-end" toggle-class="font-size-16 p-0" right>
                <template #button-content>
                    <i class="mdi mdi-dots-horizontal"></i>
                </template>

                <b-dropdown-item v-if="isViewMode == false" variant="primary" href="#" @click="toggleViewMode()" >
                    View Mode
                </b-dropdown-item>

                <b-dropdown-item v-if="isEditMode == false" variant="primary" href="#" @click="toggleEditMode()" >
                    Edit Mode
                </b-dropdown-item>

                <b-dropdown-item v-if="isDeleteMode == false" variant="danger" href="#" @click="toggleDeleteMode()" >
                    Delete Mode
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <!-- content -->
        <div>
            <Table
            :uid="table_uid" 
            :is_loading="is_loading"
            :data="interviews"
            :columns="columns"
            :table_mode="table_view_mode"
            :pager_config=5
            @onDataUpdate="onChangesMade"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Table from '../../../widgets/table.vue'//* reusable table component
//import Swal from "sweetalert2";
//eslint-disable-next-line
import { format, parseISO } from "date-fns";

export default {
    data:() => ({
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'interviewTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,
    }),
    components:{
        Table,
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,      
        isViewMode(){
            if(this.table_view_mode != 'view'){ return false; }
            else{ return true; }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){ return false; }
            else{ return true; }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){ return false; }
            else{ return true; }
        },
        dropdownClassFix(){
            //* fix buttons position when displaying before the ... dropdown menu
            //changes_made_flag ? '' : 'ms-auto'
            if(this.changes_made_flag == true || this.delete_array.length > 0){
                return '';
            }
            else{
                return 'ms-auto'
            }
        },
        columns(){
            return [
                { id: 'interview_date', header: 'Date', fillspace: 0.3, editor: 'date',
                format:function(value){
                        if(value !== ''){
                            if(typeof value === 'string'){
                                value = parseISO(value);
                            }
                            return format(value, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }},
                { id: 'interviewer_user_id', header: 'Interviewer', fillspace: 0.4, editor: 'combo', options: this.userList},
                { id: 'experience', header: 'Experience', fillspace: 0.4, editor: 'text',
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },
                },
                { id: 'skill_level', header: 'Skill Level', fillspace: 0.4, editor: 'text',
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },
                },
                { id: 'team_fit', header: 'Team Fit', fillspace: 0.4, editor: 'text',
                    format:function(value){
                        if(value === ''){
                            return '';
                        }
                        else{
                            return value+'/10'
                        }
                    },

                },
                { id: 'total', header: 'Total', fillspace: 0.4},
                { id: 'recommended', header: 'Recommended', fillspace: 0.5, editor: 'select', options: ['Yes', 'No', 'N/A']},
                { id: 'comments', header: 'Comments', fillspace: 1.1, editor: 'text'},
            ]
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            return "";
        },
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
        active_skill_name(){
            let name = ''
            if(this.activeSkillId){
                let index = _.findIndex(this.active_job_data.skills, {id: this.activeSkillId});
                if(index != -1){
                    name = this.active_job_data.skills[index].skill_name;
                }
            }
            return name;
        },
        interviews(){
            let data = [];
            if(this.active_candidate_data != null){
                data = _.cloneDeep(this.active_candidate_data.interviews);
                data = _.filter(data, {skill_id: this.activeSkillId});
                data.forEach( item => {
                    item['total'] = item.experience + item.skill_level + item.team_fit;
                });
            }
            return data;
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
    },
    methods:{
        ...peopleMethods,
        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            console.log('Selected row obj', row);
            //* set the active job in the hiring.vue component
            //this.$eventHub.$emit('setActiveJob', row.id);
            this.$eventHub.$emit('updateActiveJobHiring', row.id);
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        saveTable(){
            let table = window.webix.$$(this.table_uid);
            const all_data = table.serialize(true);
            let rows_to_save = [];
            all_data.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        interview_date: row.interview_date,
                        recommended: row.recommended,
                        experience: row.experience,
                        skill_level: row.skill_level,
                        team_fit: row.team_fit,
                        comments: row.comments,
                        interviewer_user_id: row.interviewer_user_id,
                        skill_id: this.activeSkillId,
                    };
                    rows_to_save.push(params);
                }
            })

            let interview_params = {
                skill_id: this.activeSkillId,
                candidate_id: this.active_candidate_data.id,
                interviews: rows_to_save,
            }

            this.is_loading = true;
            this.saveInterviewComments(interview_params)
            .then(()=>{
                this.loadHiringData(this.selected_level.id)
                .then(()=>{
                    this.is_loading = false;
                    this.toggleViewMode();
                })
            })
        }

    },
    mounted(){},
}
</script>

<style>

</style>