<template>
    <div>
        <div class="card-title">
            Ratings Summary
        </div>
        <div>
            <b-row v-if="graph_data == null">
                <b-alert show>No candidate data available</b-alert>
            </b-row>
            <b-row v-else>
                <b-col xl="3" lg="4" md="6" sm="6">
                    <h6>Experience</h6>
                    <Graph :graph_data="graph_data.experience" />
                </b-col>
                <b-col xl="3" lg="4" md="6" sm="6">
                    <h6>Skill Level</h6>
                    <Graph :graph_data="graph_data.skill_level" />
                </b-col>
                <b-col xl="3" lg="4" md="6" sm="6">
                    <h6>Team Fit</h6>
                    <Graph :graph_data="graph_data.team_fit" />
                </b-col>
                <b-col xl="3" lg="4" md="6" sm="6">
                    <h6>Total</h6>
                    <Graph :graph_data="graph_data.total" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import {//* import vuex store
    levelComputed,
    peopleMethods,
    peopleComputed,
    adminComputed,
} from "@/state/helpers";
import Graph from './ratingGraph.vue'
export default {
    components:{
        Graph,
    },
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed, 
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
        summary_data(){
            let data = [];
            if(this.active_job_data != null){
                this.active_job_data.candidates.forEach( person => {
                    let person_data = {
                        id: person.id,
                        name: person.first_name,
                        values:{
                            experience: 0,
                            skill_level: 0,
                            team_fit: 0,
                            total: 0,
                        }
                    }
                    person.interviews.forEach( interview => {
                        person_data.values.experience += interview.experience;
                        person_data.values.skill_level += interview.skill_level;
                        person_data.values.team_fit += interview.team_fit;
                    })
                    if(person.interviews.length > 0){
                        person_data.values.experience = (Math.round((person_data.values.experience / person.interviews.length) * 10) / 10).toFixed(2);
                        person_data.values.skill_level = (Math.round((person_data.values.skill_level / person.interviews.length) * 10) / 10).toFixed(2);
                        person_data.values.team_fit = (Math.round((person_data.values.team_fit / person.interviews.length) * 10) / 10).toFixed(2);
                        console.log(person.first_name)
                        let a = Number(person_data.values.experience);
                        let b = Number(person_data.values.skill_level);
                        let c = Number(person_data.values.team_fit)
                        person_data.values.total = a + b + c;
                        person_data.values.total = +person_data.values.total.toFixed(2)
                    }
                    data.push(person_data);
                })
            }
            return data;
        },
        graph_data(){
            let data = {
                experience:{
                    series:[],
                    names:[],
                },
                skill_level:{
                    series:[],
                    names:[],
                },
                team_fit:{
                    series:[],
                    names:[],
                },
                total:{
                    series:[],
                    names:[],
                },
            }
            if(this.summary_data.length > 0){
                this.summary_data.forEach( item => {
                    data.experience.series.push(item.values.experience);
                    data.experience.names.push(item.name);

                    data.skill_level.series.push(item.values.skill_level);
                    data.skill_level.names.push(item.name);

                    data.team_fit.series.push(item.values.team_fit);
                    data.team_fit.names.push(item.name);

                    data.total.series.push(item.values.total);
                    data.total.names.push(item.name);
                })
            }
            else{
                data = null;
            }
            return data;
        },
        interviews(){
            let data = [];
            if(this.active_candidate_data != null){
                data = _.cloneDeep(this.active_candidate_data.interviews);
                data.forEach( item => {
                    item['total'] = item.experience + item.skill_level + item.team_fit;
                });
            }
            return data;
        },
    },
    methods:{
        ...peopleMethods,
    },
    mounted(){},
}
</script>

<style>

</style>