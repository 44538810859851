<template>
  <div>
    <CardHeader>
      <template #title>
        Objectives &nbsp; > &nbsp;
        <span class="text-primary">{{ selectedMemberName }}</span>
      </template>
      <template #buttons>
        <TableActionButton :currentMode="table_view_mode" :actions="['view']" />
      </template>
    </CardHeader>
    <Table
      :uid="table_uid"
      :is_loading="is_loading"
      :data="member_objectives"
      :columns="columns"
      :table_mode="table_view_mode"
      :only_editable_columns="true"
      :context_menu_items="['View']"
      :pager_config="8"
      @selectedRowEvent="selectedRowEvent"
    />
  </div>
</template>

<script>
import CardHeader from "@/components/widgets/cardHeader.vue";
import Table from "@/components/widgets/table.vue";
import TableActionButton from "@/components/widgets/tableActionsButton";

import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
  components: {
    CardHeader,
    Table,
    TableActionButton,
  },
  data: () => ({
    //* Variables for the Table Component
    table_uid: "teamMemberObjectivesTable",
    table_view_mode: "view", //* (view - edit - delete)
    is_loading: false,
  }),
  computed: {
    ...levelComputed,
    ...peopleComputed,

    member_objectives() {
      //* returns an array of all the skills for the selected job from the jobs table
      return this.memberObjectives
        ? this.getTableRows(this.memberObjectives)
        : [];
    },
    selectedMemberName() {
      if(this.teamMembersPerformance != null){// fixed console error for .find when teamMembersPerformance is null
        const member = this.teamMembersPerformance.find(
          (item) => item.member.id === this.activeMemberId
        )?.member;
        if (member) {
          return `${member?.name} ${member?.last_name}`;
        }
      }
      return "N/A";
    },
    //* column config for table component
    columns() {
      return [
        { id: "objective", header: "Objective", fillspace: 4, editor: "text" },
        { id: "hierarchy", header: "Hierarchy", fillspace: 2, editor: "text" },
        { id: "progress", header: "Progress", fillspace: 2, editor: "text" },
        { id: "deadline", header: "Deadline", fillspace: 2, editor: "text" },
        {
          id: "rating_frequency",
          header: "Rating Frequency",
          fillspace: 3,
          editor: "text",
        },
        {
          id: "next_rating",
          header: "Next Rating",
          fillspace: 2,
          editor: "text",
        },
        {
          id: "current_rating",
          header: "Current Rating",
          fillspace: 2,
          editor: "text",
        },
        {
          id: "avg_rating",
          header: "Average Rating",
          fillspace: 2,
          editor: "text",
        },
      ];
    },
  },
  methods: {
    ...peopleMethods,
    //* table events
    selectedRowEvent(row) {
      let params = { member_id: this.activeMemberId, objective_id: row.id }
      this.getObjectiveRating(params);
    },
    getTableRows(memberObjectives) {
      console.log('Hii', this.memberObjectives)
      return memberObjectives.map((item) => {
        return {
          id: item.objective.id,
          objective: `${item.objective?.name ?? "N/A"}`,
          hierarchy: `${item.hierarchy ?? "N/A"}`,
          progress: `${item.progress ?? "N/A"}`,
          deadline: `${item.objective?.deadline ?? "N/A"}`,
          rating_frequency: `${item.rating_frequency ?? "N/A"}`,
          next_rating: `${item.objective?.next_rating ?? "N/A"}`,
          current_rating: `${item.rating ?? "N/A"}`,
          avg_rating: `${item.avg_rating ?? "N/A"}`,
        };
      });
    },
  },
  mounted() { },
};
</script>

<style>
</style>