<template>
    <b-modal title='Linked Key Result' id='linkedKeyResultModal' hide-footer>
        <div>
            <webix-ui v-if="show_tree" style="height: 100%;" :config="ui" v-model="data_array" ></webix-ui>

        </div>
        <!-- footer -->
        <div class='d-flex'>
            <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
        </div>
    </b-modal>

</template>

<script>
import _ from 'lodash'
import { levelComputed, performanceComputed, performanceMethods } from '@/state/helpers'
export default {
    props:{
        payload:{}
    },
    data:() => ({
        show_tree: false,
    }),
    computed:{
        ...performanceComputed,
        ...levelComputed,
        data_array(){
            const nestedArray = [];
            const map = {};
            const flatten = (members) => {
                let data = [];
                return members.map(m => {
                    if (m.data && m.data.length) {
                        data = [...data, ...m.data];
                    }
                    return m;
                }).concat(data.length ? flatten(data) : data);
            };
            let all_data = [];
            if(this.current_level_id == this.company_id){
                all_data = flatten(this.milestone_goals);
            }
            else{
                all_data = flatten(this.goal_objectives_tree);
            }


            let pre_processed_array = [];
            let flat_array = [...this.parent_objectives_list, ...all_data];
            if(flat_array.length == 0){
                return [];
            }
            //* get unique by id
            pre_processed_array = _.uniqBy(flat_array, 'id');
            //* set to correct order
            //pre_processed_array = _.orderBy(pre_processed_array, ['item_order']);
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = {
                    parent_milestone_name: item.parent_milestone_name, 
                    parent_objective_name: item.parent_objective_name,
                    level_milestone_id: item.level_milestone_id,
                    parent_objective_id: item.parent_objective_id,
                    level_id: item.level_id,
                    id: item.id,
                    progress: item.progress,
                    year: item.year,
                    quarter: item.quarter,
                    month: item.month,
                    name: item.name,
                    parent_id: item.parent_objective_id,
                    start_date: item.start_date,
                    deadline: item.deadline,
                    owner_name: item.owner_name,
                    item_order: item.item_order, // no longer needed
                    owner_user_id: item.owner_user_id,
                    status: item.status,
                    change: item.change,
                    data: [], // array of children
                    type: "folder",
                    notes: item.notes,
                }
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            
            return nestedArray;
        },
        linkable_key_results(){
            let data = _.cloneDeep(this.current_objective_details.linkable_key_results);
            data.forEach( (item) => {
                item.name = `<span class="badge-item badge-item-key-result"></span> ${item.name}`
            });
            return data;
        },
        ui(){
            let el = this;
            return {
                css: "webixModal",
                id: 'treeModal',
                view: 'tree',
                autoheight: true,
                template:"{common.icon()}#name#",
                minHeight: 500,
                maxHeight: 700,
                ready:function(){
                    setTimeout(()=>{
                        this.openAll();
                        el.linkable_key_results.forEach( (kr) => {
                            this.data.add(kr, 0, kr.milestone_objective_id );
                        })
                    }, 50)
                },
                on:{
                    //eslint-disable-next-line
                    onItemClick(id){
                        let selected_item = this.getItem(id);
                        if(selected_item.id == el.payload.id){
                            el.$swal.fire({
                                icon:'error',
                                title:"Invalid selection (can't set the parent to itself)",
                            })
                        }
                        else{
                            el.$emit('selectedNewID', id);
                        }
                    },
                }
            }
        },
    },
    methods:{
        ...performanceMethods,
        save(){

        }
    },
    mounted(){
        setTimeout(()=>{
            this.show_tree = true;
        }, 100)
    },
}
</script>

<style>

</style>