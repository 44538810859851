<template>
    <b-modal hide-header-close title="Change Job & Reports To User" id='changeJobCardModal' hide-footer size="md">
        <b-overlay :show="is_loading">

        
        <div class="m-2">
            Selected Job: <span style="font-weight: bold" class="text-primary">{{ selected_title }}</span>
        </div> 
        <!-- <span class="d-flex"> -->
                <vue-typeahead-bootstrap
                    ref="testFocusInput"
                    :data="jobs"
                    v-model="query1"
                    @hit="selectJob($event)"
                    placeholder="Search for a job title"
                    :minMatchingChars="Number(-1)"
                    :autoClose="true"
                    prepend="Job Title:"
                    style="width:100%"
                ></vue-typeahead-bootstrap>
        <!-- <span> -->
        <div class="m-2 mt-3">
            Selected Reports To User: <span style="font-weight: bold" class="text-primary">{{ selected_reports_to_title }}</span>
        </div>
        <!-- <span class="d-flex"> -->
                <vue-typeahead-bootstrap
                    ref="testFocusInput"
                    :data="employees"
                    v-model="query2"
                    @hit="selectEmployee($event)"
                    placeholder="Search for a Employee Name"
                    :minMatchingChars="Number(-1)"
                    :autoClose="true"
                    prepend="Employee Name:"
                    style="width:100%"
                ></vue-typeahead-bootstrap>
        <!-- </span> -->
        <!-- footer -->
        <div class='d-flex' >
            <b-button v-show="contentChanged" @click="saveJobChange(selected_title)" variant="success" class="ms-auto mt-2 me-2" size="md">Save</b-button>
            <b-button @click="$bvModal.hide('changeJobCardModal')" class=' mt-2' variant='primary' size="md">Close</b-button>
        </div>
        </b-overlay>
    </b-modal>

</template>

<script>
//import { cloneDeep } from 'lodash';
import { format } from "date-fns";
import _ from "lodash";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import {
  activityComputed,
  activityMethods,
  peopleComputed,
  peopleMethods,
  levelComputed,
  levelMethods,
  adminComputed,
  adminMethods,
} from "@/state/helpers";
export default {
    components: {
        VueTypeaheadBootstrap
    },
    props:{
        payload:{
            required: true,
        },
    },
    data:() => ({
        edit_text:'',
        query1: "",
        query2: "",
        jobs: [],
        selected_title: "",
        selected_reports_to_title: "",
        is_loading: false,
        create_new: false,
        employees: [],
        selectedID: null
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...peopleComputed,
        modal_title(){
            let data = this.payload
            if(!data.type){
                return 'Invalid item'
            }
            else{
                return data.type; 
            }
        },
        has_valid_note(){
            //returns false if note is null or ''
            return this.employeeDetailsData.note != null || this.employeeDetailsData.note != '';
        },
        setEditClass(){
            if(this.editable){
                //* return nothing for editable
                return '';
            }
            else{
                //return class to prevent mouse events
                return 'block-click'
            }
        },
        
        isUserManager() {
            let is_editing_as_manager = this.$isManagerCheck(
                this.core.id,
                this.selected_user_id
            );
            if (is_editing_as_manager) {
                return true;
            }
            return false;
        },
        getCompanyLevelId() {
            const tree = window.webix.$$("levelsTree");
            let item = tree.getItem(this.selected_level.id);
            let top_level_id = -1;
            if (item.parent_id === null) {
                //* current level is company level
                top_level_id = item.id;
            } else {
                top_level_id = tree.getParentId(this.selected_level.id);
                let found = false;
                while (!found) {
                    let new_parnet_id = tree.getParentId(top_level_id);
                    if (new_parnet_id === 0) {
                        found = true;
                    } else {
                        top_level_id = new_parnet_id;
                    }
                }
            }
            return top_level_id;
        },
    },
    methods:{
        ...activityMethods,
        ...levelMethods,
        ...adminMethods,
        ...peopleMethods,
        selectEmployee(data) {
            let email = data.split('-')[1];
            console.log(email)
            let fullName = null
            let user = null
            this.levelEmployeesData.forEach((value) => {
                console.log(JSON.parse(JSON.stringify(value)))
                if(email.trim() == value.email) {
                    user = value
                    fullName = value.name + ' ' + value.last_name
                }
            });
            if(user != null) {
                this.selectedID = user.id
            }
            this.selected_reports_to_title = fullName
        },
        contentChanged(new_content){
            this.edit_text = new_content;
        },
        // saveText() {
        //     //this.is_loading = true;
        //     let params = {
        //         type: this.payload.type, text: this.edit_text,
        //     }
        //     console.log(JSON.parse(JSON.stringify(params)))
        //     this.is_loading = true;
        //     this.saveEmployeeDetails(params).then(()=>{
        //             this.loadEmployeeData(this.selected_user_id)
        //             .then(()=>{
        //                 this.$emit('savedNewDetails')
        //                 this.$bvModal.hide('addEmployeeDetailsModal');
        //             })
        //     })
        // },
        setEmployeesData() {
            this.employees = [];
            this.levelEmployeesData.forEach((value) => {
                this.employees.push(value.name + ' - ' + value.email);
            });
        },
        setJobsData() {
            this.jobs = [];
            /*Object.keys(this.menu_users).forEach((key) => {
                this.jobs.push(key);
            });
            
            this.levelHiringData.forEach( job =>{
                if(this.jobs.includes(job.job_title) == false){
                    this.jobs.push(job.job_title);
                }
            })*/
            this.companyJobs.forEach((job) => {
                this.jobs.push(job.title);
            });
        },
        saveJobChange(job_title) {
            this.is_loading = true

            let index = _.findIndex(this.companyJobs, { title: job_title });
            if(index == -1) {
                this.$swal.fire({
                    title: "Please select a valid Job",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.is_loading = false
                })
                return
            } 
            //let user_index = _.findIndex(this.levelEmployeesData, { name: reports_to });
            console.log(this.selectedID)
            if(this.selectedID == null) {
                this.$swal.fire({
                    title: "Please select a valid user to report to",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.is_loading = false
                })
                return
            } 
            if (index != -1) {
                console.log('getting in here to save')
                this.last_selected_id = this.companyJobs[index].id;
                if (this.isNewUser) {
                    let job = {
                        id: this.companyJobs[index].id,
                        text: job_title,
                    };
                    this.$nextTick(() => {
                        this.$emit("returnJob", job);
                    });
                } else {
                    let date = new Date();
                    date = format(date, "yyyy-MM-dd");
                    let update_params = {
                        user_id: Number(this.selected_user_id),
                        job_id: this.companyJobs[index].id,
                        reports_to_user_id: this.selectedID,
                        start_date: date,
                        create_new: this.companyJobs[index].id == this.employeeDetails.current_job.id ? false : true
                    };
                    console.log(update_params)
                    this.updateEmployeeJob(update_params).then(() => {
                        this.loadEmployeesData(this.selected_level.id).then(() => {
                            //this.loadEmployeeData(this.selected_user_id).then(() => {
                                this.$emit("changedJob");
                                this.$bvModal.hide('changeJobCardModal')
                                this.edit = false;
                            //})
                        }) 
                    });
                }
            } else {
                console.log("Error finding job ID");
            }
           
        },
        selectJob(data) {
            this.selected_title = data
        }
    },
    mounted(){
        this.edit = this.enableEdit;
        console.log(this.payload)
        this.selected_title = this.payload.title
        if(this.payload.reports_to_user_id != null) {
           this.selected_reports_to_title = this.payload.reports_to
           this.selectedID = this.payload.reports_to_user_id
        } else {
            this.selected_reports_to_title = 'No User Selected'
            this.selectedID = null
        }
        console.log(this.selectedID)
        if(this.selected_title == 'No Job Linked') {
            this.create_new = true
        }
        this.setJobsData();
        this.setEmployeesData();
    },
}
</script>

<style>

</style>