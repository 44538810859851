<template>
    <b-modal hide-header-close title="Reports to Employee Change" id='changeLinkedEmployeeModal' hide-footer size="md">
        <b-overlay :show="is_loading">

        
        <div class="m-2">
            Selected Employee: <span style="font-weight: bold" class="text-primary">{{ selected_title }}</span>
        </div>
        
        <span class="d-flex">
                <vue-typeahead-bootstrap
                    ref="testFocusInput"
                    :data="employees"
                    v-model="query"
                    @hit="selectEmployee($event)"
                    placeholder="Search for a Employee Name"
                    :minMatchingChars="Number(-1)"
                    :autoClose="true"
                    prepend="Employee Name:"
                    style="width:100%"
                ></vue-typeahead-bootstrap>
        </span>
        <!-- footer -->
        <div class='d-flex' >
            <b-button v-show="contentChanged" @click="saveLinkedEmployeeChange(selected_title)" variant="success" class="ms-auto mt-2 me-2" size="md">Save</b-button>
            <b-button @click="$bvModal.hide('changeLinkedEmployeeModal')" class=' mt-2' variant='primary' size="md">Close</b-button>
        </div>
        </b-overlay>
    </b-modal>

</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { format, parseISO } from "date-fns";
import {
  activityComputed,
  activityMethods,
  peopleComputed,
  peopleMethods,
  levelComputed,
  levelMethods,
  adminComputed,
  adminMethods,
} from "@/state/helpers";
export default {
    components: {
        VueTypeaheadBootstrap
    },
    props:{
        payload:{
            required: true,
        },
    },
    data:() => ({
        edit_text:'',
        query: "",
        employees: [],
        selected_title: "",
        is_loading: false,
        selectedID: null,
    }),
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        ...peopleComputed,
        modal_title(){
            let data = this.payload
            if(!data.type){
                return 'Invalid item'
            }
            else{
                return data.type; 
            }
        },
        has_valid_note(){
            //returns false if note is null or ''
            return this.employeeDetailsData.note != null || this.employeeDetailsData.note != '';
        },
        setEditClass(){
            if(this.editable){
                //* return nothing for editable
                return '';
            }
            else{
                //return class to prevent mouse events
                return 'block-click'
            }
        },
        
        isUserManager() {
            let is_editing_as_manager = this.$isManagerCheck(
                this.core.id,
                this.selected_user_id
            );
            if (is_editing_as_manager) {
                return true;
            }
            return false;
        },
    },
    methods:{
        ...activityMethods,
        ...levelMethods,
        ...adminMethods,
        ...peopleMethods,
        contentChanged(new_content){
            this.edit_text = new_content;
        },
        // saveText() {
        //     //this.is_loading = true;
        //     let params = {
        //         type: this.payload.type, text: this.edit_text,
        //     }
        //     console.log(JSON.parse(JSON.stringify(params)))
        //     this.is_loading = true;
        //     this.saveEmployeeDetails(params).then(()=>{
        //             this.loadEmployeeData(this.selected_user_id)
        //             .then(()=>{
        //                 this.$emit('savedNewDetails')
        //                 this.$bvModal.hide('addEmployeeDetailsModal');
        //             })
        //     })
        // },
        setEmployeesData() {
            this.employees = [];
            this.levelEmployeesData.forEach((value) => {
                this.employees.push(value.name + ' - ' + value.email);
            });
        },
        saveLinkedEmployeeChange(data) {
            this.is_loading = true
            console.log(data);
            if (this.selectedID != null && this.employeeDetails.current_job.id != null) {
                    let update_job_params = {
                        user_id: Number(this.selected_user_id),
                        job_id: this.employeeDetails.current_job.id,
                        reports_to_user_id: this.selectedID,
                        start_date: format(parseISO(this.employeeDetails.current_job.updated_at), 'yyyy-MM-dd'),
                        create_new: false
                    };
                    //start_date: format(parseISO(this.employeeDetails.current_job.updated_at), 'yyyy-MM-dd'),
                    // {"user_id":149,"job_id":36,"reports_to_user_id":43,"start_date":"2023-02-02","country":"South Africa","city":"Cape Town ","region":"Southern Seaboard","job_description":"Optional job description text","create_new":true}
                    this.updateEmployeeJob(update_job_params).then(() => {
                        this.loadEmployeesData(this.selected_level.id).then(() => {
                            //this.loadEmployeeData(this.selected_user_id).then(() => {
                                this.$emit("changedLinkedEmployee");
                                this.$bvModal.hide('changeLinkedEmployeeModal')
                                this.edit = false;
                            //})
                        }) 
                        
                        
                    });
            } else {
                this.$bvModal.hide('changeLinkedEmployeeModal');
                this.edit = false;
            }
        },
        selectEmployee(data) {
            let email = data.split('-')[1];
            console.log(email)
            let user = null
            this.levelEmployeesData.forEach((value) => {
                console.log(JSON.parse(JSON.stringify(value)))
                if(email.trim() == value.email) {
                    user = value
                }
            });
            if(user != null) {
                this.selectedID = user.id
            }
            this.selected_title = data
        }
    },
    mounted(){
        this.edit = this.enableEdit;
        this.selected_title = this.payload.title
        this.setEmployeesData();
    },
}
</script>

<style>

</style>