<template>
  <div class="mb-4">
    <Table
      :uid="table_uid"
      :ref="table_uid"
      :columns="columns"
      :data="formatRiskData"
      :context_menu_items="['View', 'Edit', 'Delete']"
      @saveTable="saveTable"
    >
        <template #title>
            Objective Risks
            &nbsp;&nbsp;>&nbsp;&nbsp;
            <span class="text-success">{{ objectiveName }}</span>
        </template>
        <template #extra-items>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="$emit('showObjectives')">
                Show Objectives
            </b-dropdown-item>
        </template>
    </Table>

    <div v-if="false">

    
        <CardHeader>
        <template #title>Title here</template>
        <template #buttons>btn</template>
        </CardHeader>
        <webix-ui :config="ui" v-model="formatRiskData"></webix-ui>
        <div id="paging_risk"></div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/widgets/table/table.vue";
import CardHeader from "@/components/widgets/cardHeader.vue";
// import ObjectiveDropdown from "@/components/objectives/components/objective-dropdown.vue";
import _ from "lodash";
import {
  levelComputed,
  activityMethods,
  activityComputed,
  adminComputed
} from "@/state/helpers";
export default {
  components: {
    Table,
    // ObjectiveDropdown,
    CardHeader
  },
  data: () => ({
    //table stuff
    table_uid: "risksTable",

    filterText: "",
    filter: {
      dateStart: "",
      dateEnd: ""
    },
    isFormError: false,
    flip: true,
    selected_risk: {},
    isEditGuideline: false,
    risk_data: [],
    show_modal: false,
    isShow: {
      isCancel: true,
      isView: true,
      isEditingNew: false,
      isEditingExisting: false,
      isGuideline: false
    },
    form_data: {
      id: -1,
      objective_id: null,
      name: null,
      date: null,
      likelyhood: null,
      impact: null
    },
    ui: {
      id: "risksTable",
      view: "datatable",
      css: "datatableUsers",
      select: "row",
      autoheight: true,
      fillspace: true,
      pager: {
        view: "pager",
        //template: "{common.prev()} {common.pages()} {common.next()}",
        container: "paging_risk",
        size: 5,
        group: 5
      },
      editaction: "dblclick",
      editable: true,
      columns: [
        { id: "id", header: "", hidden: true, fillspace: 0 },
        {
          id: "name",
          sort: "text",
          editor: "text",
          header: "Risk",
          fillspace: 2
        },
        {
          id: "date",
          map: "(date)#date#",
          sort: "date",
          editor: "date",
          header: "Date",
          fillspace: 2,
          template: (obj, common, value) => {
            if (!value) return "";
            return value;
          }
        },
        {
          id: "likelyhood",
          sort: "int",
          editor: "combo",
          header: "Likelyhood (1 Low - 5 High)",
          fillspace: 2,
          options: () => {
            var years = [];
            for (var i = 1; i < 6; i++) years.push({ id: i, value: i });
            return years;
          }
        },
        {
          id: "impact",
          sort: "int",
          editor: "select",
          header: "Impact  (1 Low - 5 High)",
          fillspace: 2,
          options: () => {
            var years = [];
            for (var i = 1; i < 6; i++) years.push({ id: i, value: i });
            return years;
          }
        }
      ],
      on: {
        onEnter() {
          console.log("onEnter");
          var el = this.$scope.$parent;
          if (el.isShow.isEditingExisting || el.isShow.isEditingNew) {
            el.save();
          }
        },
        /*onAfterEditStop() {
          // Force the table to keep editing mode
          var el = this.$scope.$parent;

          // eslint-disable-next-line
          let table = $$("risksTable");
          if (el.isShow.isEditingExisting) table.editRow(el.selected_risk.id);
          if (el.isShow.isEditingNew) table.editRow("newRow");
          if (el.isFormError) {
            const inputs = document.querySelectorAll(".webix_dt_editor input");
            const select = document.querySelectorAll(".webix_dt_editor select");
            inputs.forEach((item) => {
              item.classList.add("border-red");
            });
            select.forEach((item) => {
              item.classList.add("border-red");
            });

            // this.addRowCss(targetRow, "bg-red");
          }
        },*/
        /*onItemDblClick() {
          // if (el.isShow.isEditingExisting || el.isShow.isEditingNew) return;
          // var item = this.getItem(id);
          var el = this.$scope.$parent;

          el.switchView("edit");
        },*/
        onItemClick(id) {
          var el = this.$scope.$parent;
          if (el.isShow.isEditingExisting || el.isShow.isEditingNew) return;
          var item = this.getItem(id);

          el.selected_risk = _.find(el.risk_data, { id: item.id });

          el.form_data = item;

          /*var item = this.getItem(id)
                    var el = this.$scope.$parent;
                    var index = _.findIndex(el.objectives, {id:item.id});
                    if(index != -1){
                        let objective = _.cloneDeep(el.objectives[index]);
                        if(objective.emptyRow){
                            this.clearSelection();
                            if(el.selected_objective != null){
                                this.select(el.selected_objective.id);
                            }
                        }
                        else{
                            el.$eventHub.$emit('selectedObjective', objective);
                            el.getParentsAndChildren(item.id);
                        }
                    }
                    else{
                        this.clearSelection();
                        if(el.selected_objective != null){
                            this.select(el.selected_objective.id);
                        }
                    }*/
        }
      },
      onClick: {
        //* Click handler for action icons
      }
    }
  }),
  watch: {
    risks: {
      immediate: true,
      deep: true,
      handler() {
        console.log("selected_objective updated");
        this.risk_data = _.cloneDeep(this.risks);
        setTimeout(() => {
          //eslint-disable-next-line
          let table = $$("risksTable");
          table.refresh();
        }, 500);
        // this.addEmptyRows();
      }
    }
  },
  computed: {
    ...levelComputed,
    ...activityComputed,
    ...adminComputed,
    titleName() {
      let title = "Objective Risks";
      if (this.isShow.isView) return `${title} -`;
      else if (this.isShow.isEditingNew) return `${title} - Add -`;
      else if (this.isShow.isEditingExisting) return `${title} - Edit -`;
      else if (this.isEditGuideline) return `${title} - Edit Guideline -`;
      else if (this.isShow.isGuideline) return `${title} - Guideline -`;
      else return `${title} -`;
    },
    objectiveName() {
      return this.findObjectiveLevel(this.selected_objective.id);
    },
    cardStyle() {
      return this.flip ? "min-height: 21rem;" : "min-height: 15rem;";
    },
    title() {
      if (this.selected_objective != null) {
        return this.selected_objective.name;
      } else {
        return "Nothing Selected";
      }
    },
    risks() {
      if (this.selected_objective != null) {
        return this.selected_objective_details.risks;
      } else {
        return [];
      }
    },
    formatRiskData() {
      // let empty_rows = total % 5;
      // empty_rows = 5 - empty_rows;

      let result = this.risk_data.map(item => {
        return {
          date: item.date,
          id: item.id,
          impact: item.impact,
          likelyhood: item.likelyhood,
          milestone_objective_id: item.milestone_objective_id,
          name: item.name,
          user_id: item.user_id,
          user_name: item.user_name,
          emptyRow: item.emptyRow
        };
      });

      let sortedResult = _.orderBy(result, ["date", "title"], ["asc", "asc"]);
        return sortedResult;
      /*return sortedResult.filter(item => {
        if (
          // item.title.toLowerCase().includes(this.filterText) &&
          this.$dateInRange(item.date, this.filter)
        )
          return item;
      });*/
    },
    activeUserId() {
      if (this.selected_user_id != null && this.selected_user_id != undefined) {
        //* return user id that was selected on the left menu
        return this.selected_user_id;
      } else {
        return -1;
        //return this.core.id;// return logged in user
      }
    },
    columns() {
        let el = this;
        let columns = [
            {
            id: "name",
            sort: "text",
            editor: "text",
            header: "Risk",
            fillspace: 2,
            },
            {
            id: "date",
            sort: "date",
            editor: "date",
            header: "Date",
            fillspace: 2,
            format:function(value){
                            return el.parseDateFormat(value);
                        },
            },
            {
            id: "likelyhood",
            sort: "int",
            editor: "select",
            header: "Likelyhood (1 Low - 5 High)",
            fillspace: 2,
            options: () => {
                var years = [];
                for (var i = 1; i < 6; i++) years.push({ id: i, value: i });
                return years;
            },
            },
            {
            id: "impact",
            sort: "int",
            editor: "select",
            header: "Impact  (1 Low - 5 High)",
            fillspace: 2,
            options: () => {
                var years = [];
                for (var i = 1; i < 6; i++) years.push({ id: i, value: i });
                return years;
            },
            },
        ];
        return columns
    }
  },
  methods: {
    ...activityMethods,
    saveTable(data){
        let rows_to_save = [];
        data.forEach( row => {
            let param = {
                id: row.is_new ? -1 : row.id,
                objective_id: this.selected_objective.id,
                name: row.name,
                date: this.getFormattedDate(row.date),
                likelyhood: row.likelyhood,
                impact: row.impact
            }
            rows_to_save.push( param );
        });
        let save_payload = {
            risks: rows_to_save
        }

        this.saveObjectiveRisk(save_payload).then(() => {
            let reload_params = {
                id: this.selected_objective.id,
                user_id: this.activeUserId
            };
            this.loadObjectiveDetailAPI(reload_params).then(() => {
                let objective = _.find(this.activity_data.objectives, {
                    id: this.selected_objective.id
                });
                if (objective != undefined) {
                    this.setSelectedObjective(objective);
                }
                this.$nextTick(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                })
            });
        });
    },
    searchText(value) {
      this.filterText = value.toLowerCase();
    },
    searchDate(value) {
      this.filter.dateStart = value.start_date;
      this.filter.dateEnd = value.end_date;
    },
    resetFilter() {
      this.filterText = "";
      this.filter.dateStart = "";
      this.filter.dateEnd = "";
    },
    tmp() {
      console.log(this.selected_risk);
    },
    deleteRisk() {
      // console.log()
      this.deleteObjectiveRisk(this.selected_risk.id)
        .then(() => {
          this.$eventHub.$emit("reloadObjective");
        })
        .catch(err => {
          console.log("Delete error", err);
        });
    },
    update() {
      this.isEditGuideline = false;
      this.clearView();
      this.$refs.isEditingNew = false;
      this.$refs.isEditingExisting = false;
      this.isShow.isGuideline = true;
    },
    saveGuideline() {
      this.$refs.guideline.saveGuideline();
    },
    getLocaleDate(date) {
      return new Date(date).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "2-digit"
      });
    },
    clearView() {
      this.isFormError = false;
      this.isEditGuideline = false;
      Object.keys(this.isShow).forEach(item => {
        this.isShow[item] = false;
      });
    },
    getFormattedDate(day) {
      const today = new Date(day);

      return `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
        -2
      )}-${("0" + today.getDate()).slice(-2)}`;
    },
    addRisk() {
      this.clearView();
      this.isShow.isEditingNew = true;
      //eslint-disable-next-line
      let table = $$("risksTable");
      table.data.add(
        {
          date: "",
          id: "newRow",
          impact: "",
          likelyhood: "",
          milestone_objective_id: null,
          name: "",
          user_id: this.core.id,
          user_name: this.core.name
        },
        0
      );
      table.refresh();
      //table.editRow("newRow");
      table.select("newRow");
      // this.form_data.id = -1;
      // this.form_data.objective_id = this.selected_objective.id;

      // this.show_modal = true;

      // this.$nextTick(() => {
      //   this.$bvModal.show("riskModal");
      // });
    },
    save() {
      //eslint-disable-next-line
      let table = $$("risksTable");
      table.editStop();
      if (this.isShow.isEditingNew) table.select("newRow");
      // else table.select(this.selected_objective.id);

      // Validation
      let targetRowId = this.isShow.isEditingNew
        ? "newRow"
        : this.selected_risk.id;
      let row = table.getItem(targetRowId);

      if (!row.name || !row.date || !row.likelyhood || !row.impact) {
        let targetRow = row.id === "newRow" ? "newRow" : row.id;

        const inputs = document.querySelectorAll(".webix_dt_editor input");
        const select = document.querySelectorAll(".webix_dt_editor select");
        inputs.forEach(item => {
          item.classList.add("border-red");
        });
        select.forEach(item => {
          item.classList.add("border-red");
        });

        table.addRowCss(targetRow, "bg-red");
        this.isFormError = true;

        return;
      }
      // End of Validation

      this.isFormError = false;

      this.clearView();

      let payload = {
        id: row.id === "newRow" ? -1 : row.id,
        objective_id: this.selected_objective.id,
        name: row.name,
        date: this.getFormattedDate(row.date),
        likelyhood: row.likelyhood,
        impact: row.impact
      };

      this.saveObjectiveRisk(payload).then(() => {
        table.editStop();
        let params = {
          id: this.selected_objective.id,
          user_id: this.activeUserId
        };
        this.loadObjectiveDetailAPI(params).then(() => {
          let objective = _.find(this.activity_data.objectives, {
            id: this.selected_objective.id
          });
          if (objective != undefined) {
            this.setSelectedObjective(objective);
          }
        });
      });
    },
    addEmptyRows() {
      let total = this.risk_data.length;
      let empty_rows = total % 5;
      empty_rows = 5 - empty_rows;
      this.$nextTick(() => {
        if (this.risk_data.length > 0) {
          for (let i = 1; i <= empty_rows; i++) {
            this.risk_data.push({
              id: 999000 + i,
              emptyRow: true,
              name: "",
              date: "",
              owner_name: "",
              status: ""
            });
          }
          this.$nextTick(() => {
            //this.selectFirstMilestone();
          });
        } else {
          for (let i = 1; i <= 5; i++) {
            this.risk_data.push({
              id: 999000 + i,
              emptyRow: true,
              name: "",
              date: "",
              owner_name: "",
              status: ""
            });
          }
        }
      });
    },
    switchView(view) {
      //eslint-disable-next-line
      let table = $$("risksTable");
      const inputs = document.querySelectorAll(".webix_dt_editor input");
      const select = document.querySelectorAll(".webix_dt_editor select");

      switch (view) {
        case "cancel":
          // click back when editing
          table?.editCancel();
          if (this.isShow.isEditingNew) table.remove("newRow");
          table.refresh();

          this.clearView();

          table?.editCancel();

          inputs?.forEach(item => {
            item.classList.remove("border-red");
          });
          select?.forEach(item => {
            item.classList.remove("border-red");
          });
          table?.removeRowCss(this.selected_objective.id, "bg-red");

          this.isShow.isView = true;
          this.flip = true;

          this.$eventHub.$emit("reloadObjective");

          break;
        case "edit":
          if (!this.selected_objective) return;
          this.clearView();
          this.isShow.isEditingExisting = true;
          this.flip = true;
          table.editRow(this.selected_risk.id);
          break;
        case "guideline":
          this.clearView();
          this.isShow.isGuideline = true;
          this.flip = false;
          break;
        case "editguideline":
          this.clearView();
          this.isEditGuideline = true;
          break;
        case "cancelguideline":
          this.clearView();
          this.isShow.isGuideline = true;
          this.$refs.guideline.resetContent();
          break;
        case "backtoview":
          this.clearView();
          this.isShow.isView = true;
          this.flip = true;
          table?.select(this.selected_objective.id);
          table?.addCellCss(
            this.selected_objective.id,
            "name",
            "selected-item"
          );

          break;
      }
    },
    addNewRisk() {
      this.addRisk();
    }
  },
  mounted() {
    console.log("obj risk mounted");
    this.risk_data = _.cloneDeep(this.risks);

    // this.addEmptyRows();
    this.$eventHub.$on("addNewRisk", this.addNewRisk);
    this.$eventHub.$on("saveRisk", this.save);
  },
  beforeDestroy() {
    this.$eventHub.$off("addNewRisk");
    this.$eventHub.$off("saveRisk");
  }
};
</script>

<style lang="scss" scoped>
::v-deep .selected-item {
  font-weight: bold;
  text-decoration: underline;
}

::v-deep .bg-red {
  background: rgb(255, 92, 76) !important;
}

.tooltipwrapper {
  .tooltiptext {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 0 5px;

    /* Position the tooltip */
    background-color: #d3d5db;
    color: black;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    width: 180px;
    transform: translate(0%, -100%);
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + -1px);
      left: 50px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #d3d5db transparent transparent transparent;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

::v-deep .border-red {
  border-color: rgb(255, 92, 76) !important;
}
</style>
