//* methods to process tracking data for apex charts
//eslint-disable-next-line
import { format, parseISO } from 'date-fns'
import { addDays } from 'date-fns'
//eslint-disable-next-line
import { isBefore, isAfter, isEqual } from 'date-fns';
import { endOfWeek, startOfWeek, eachWeekOfInterval, eachDayOfInterval } from 'date-fns';
import { endOfMonth, startOfMonth, eachMonthOfInterval } from 'date-fns';
import { endOfQuarter, startOfQuarter, eachQuarterOfInterval } from 'date-fns';
import { endOfYear, startOfYear, eachYearOfInterval } from 'date-fns';
import { isWithinInterval, compareAsc } from 'date-fns';
import { isWeekend } from 'date-fns';
//import { isWithinInterval } from 'date-fns';

//date manipulation
import dateMixin from '@/mixins/dateMixin.js'

import store from '@/state/store'

import _ from 'lodash';
export default {
    mixins: [dateMixin],
    methods: {
        getGraphLabels(period, startDate, endDate, include_weekends) {
            let labels = [];
            switch (period) {
                case 'daily': {
                    let dates = eachDayOfInterval({ start: parseISO(startDate), end: parseISO(endDate) });
                    dates.forEach((date) => {
                        if(include_weekends){
                            let simple_date = this.getSimpleDate(date);
                            labels.push(simple_date);
                        }
                        else{
                            if(!isWeekend(date)){
                                let simple_date = this.getSimpleDate(date);
                                labels.push(simple_date);
                            }
                        }
                    })
                    break;
                }
                case 'weekly': {
                    let dates = eachWeekOfInterval({ start: parseISO(startDate), end: parseISO(endDate) });
                    dates.forEach((date) => {
                        let simple_date = this.getSimpleDate(endOfWeek(date))
                        labels.push(simple_date);
                    })
                    break;
                }
                case 'monthly': {
                    let dates = eachMonthOfInterval({ start: parseISO(startDate), end: parseISO(endDate) });
                    dates.forEach((date) => {
                        let simple_date = this.getSimpleDate(endOfMonth(date))
                        labels.push(simple_date);
                    })
                    break;
                }
                case 'quarterly': {
                    let dates = eachQuarterOfInterval({ start: parseISO(startDate), end: parseISO(endDate) });
                    dates.forEach((date) => {
                        let simple_date = this.getSimpleDate(endOfQuarter(date))
                        labels.push(simple_date);
                    })
                    break;
                }
            }
            return labels;
        },
        getGraphLabelsAsDates(period, startDate, endDate, include_weekends) {
            // console.log('DATE TO DEUBH',period, startDate, endDate);
            // let labels = [];
            // let dates = eachMonthOfInterval({ start:startDate, end:endDate });
            // dates.forEach( (date) => {
            //     let simple_date = endOfMonth(date)
            //     labels.push(simple_date);
            // })
            // return labels;

            let labels = [];
            switch (period) {
                case 'daily': {
                    let dates = eachDayOfInterval({ start: startDate, end: endDate });
                    dates.forEach((date) => {
                        if(include_weekends){
                            labels.push(date);
                        }
                        else{
                            if(!isWeekend(date)){
                                labels.push(date);
                            }
                        }
                    })
                    break;
                }
                case 'weekly': {
                    let dates = eachWeekOfInterval({ start: startDate, end: endDate });
                    dates.forEach((date) => {
                        let simple_date = endOfWeek(date)
                        labels.push(simple_date);
                    })
                    break;
                }
                case 'monthly': {
                    let dates = eachMonthOfInterval({ start: startDate, end: endDate });
                    dates.forEach((date) => {
                        let simple_date = endOfMonth(date)
                        labels.push(simple_date);
                    })
                    break;
                }
                case 'quarterly': {
                    let dates = eachQuarterOfInterval({ start: startDate, end: endDate });
                    dates.forEach((date) => {
                        let simple_date = endOfQuarter(date)
                        labels.push(simple_date);
                    })
                    break;
                }
            }
            return labels;
        },
        //* Graph series checks for "view as (weekly, monthly, quartery, yearly)"
        isDatasetWithinPeriod(period, interval) {
            //* period -> weekly, monthly, quarterly, yearly
            //* interval -> { start, end }
            // 1. get the 'period' end date for the start date of the graph (endOfMonth(start_date))
            // 2. check if graphs deadline is >= the period end date
            switch (period) {
                case 'weekly': {
                    //const period_start = this.getSimpleDate(interval.start);
                    const period_end = endOfWeek(parseISO(interval.start));
                    const tracking_end = new Date((interval.end));
                    return isAfter(tracking_end, period_end);
                }
                case 'monthly': {
                    //const period_start = this.getSimpleDate(interval.start);
                    const period_end = endOfMonth(parseISO(interval.start));
                    const tracking_end = new Date((interval.end));
                    return isAfter(tracking_end, period_end);
                }
                case 'quarterly': {
                    //const period_start = this.getSimpleDate(interval.start);
                    const period_end = endOfQuarter(parseISO(interval.start));
                    const tracking_end = new Date((interval.end));
                    return isAfter(tracking_end, period_end);
                }
                case 'yearly': {
                    //const period_start = this.getSimpleDate(interval.start);
                    const period_end = endOfYear(parseISO(interval.start));
                    const tracking_end = new Date((interval.end));
                    return isAfter(tracking_end, period_end);
                }
                default: {
                    return false;
                }
            }
        },
        // processSparklineSeries(graph_object){
        //     console.log('TRACKING', graph_object.tracking)
        //     return [];
        // },
        processSparklineSeries(data) {
            // Parse the start and end dates from the dateRange object
            const { range_start, range_end } = store.getters['performance/objectives/viewableRangeDate'];

            // Function to ensure date is parsed correctly, handling both ISO strings and Date objects
            function parseDate(dateInput) {
                if (dateInput instanceof Date) return dateInput; // If it's a Date object, return it directly
                try {
                    const parsedDate = parseISO(dateInput);
                    if (isNaN(parsedDate)) throw new Error('Invalid date'); // Check if parsedDate is "Invalid Date"
                    return parsedDate;
                } catch (error) {
                    console.error('Error parsing date:', error);
                    return null; // Return null or handle the error as appropriate
                }
            }

            const startDate = parseDate(range_start);
            const endDate = parseDate(range_end);

            if (!startDate || !endDate) {
                console.error('One of the dates is invalid.');
                return []; // Handle the error case, perhaps by not proceeding with the operation
            } else {
                // Proceed with your logic, as startDate and endDate are now valid Date objects
                // First, filter the data based on whether the achieved_date falls within the start and end dates
                let sortedAndFilteredValues = data.filter(item => {
                    const achievedDate = parseISO(item.achieved_date);
                    return isWithinInterval(achievedDate, { start: startDate, end: endDate });
                })
                    // Then, sort the filtered data by achieved_date
                    .sort((a, b) => compareAsc(parseISO(a.achieved_date), parseISO(b.achieved_date)))
                    // Finally, map the sorted and filtered results to an array of values, filtering out null and 0 values
                    .map(item => item.value)
                    .filter(item => item != null);

                return sortedAndFilteredValues;
            }
        },
        processSparklineSeries2(data,) {
            // Parse the start and end dates from the dateRange object
            const { range_start, range_end } = store.getters['performance/objectives/viewableRangeDate'];

            // Function to ensure date is parsed correctly, handling both ISO strings and Date objects
            function parseDate(dateInput) {
                if (dateInput instanceof Date) return dateInput; // If it's a Date object, return it directly
                try {
                    const parsedDate = parseISO(dateInput);
                    if (isNaN(parsedDate)) throw new Error('Invalid date'); // Check if parsedDate is "Invalid Date"
                    return parsedDate;
                } catch (error) {
                    console.error('Error parsing date:', error);
                    return null; // Return null or handle the error as appropriate
                }
            }

            const startDate = parseDate(range_start);
            const endDate = parseDate(range_end);

            if (!startDate || !endDate) {
                console.error('One of the dates is invalid.');
                return []
                // Handle the error case, perhaps by not proceeding with the operation
            } else {
                // Proceed with your logic, as startDate and endDate are now valid Date objects
                // Filter the data based on whether the achieved_date falls within the start and end dates
                const filteredValues = data.filter(item => {
                    const achievedDate = parseISO(item.achieved_date);
                    return isWithinInterval(achievedDate, { start: startDate, end: endDate });
                }).map(item => item.value); // Map the filtered results to an array of values

                return filteredValues.filter(item => {
                    return item != null && item != 0
                });
            }


        },


        //eslint-disable-next-line
        processNormalValues(graph_object, viewed_time_period, has_target = false, has_cumulative = false) {
            const range = store.getters['performance/objectives/viewableRangeDate'];
            if (graph_object.current_time_period === viewed_time_period) {
                let graph_end = graph_object.deadline != undefined ? graph_object.deadline : graph_object.end_date
                graph_end = format(range.range_end, 'yyyy-MM-dd') + ' 00:00:00';
                let cutoff_date = this.getDeadlineDateForPeriod(viewed_time_period, graph_end);
                let startoff_date = this.getSimpleDate(range.range_start)
                let graph_series = []; //{ name, data }
                let el = this;

                let data = [];
                let target = [];

                let date_labels = this.getGraphLabelsAsDates(viewed_time_period, range.range_start, range.range_end);
                //console.log("DATE TO COMPARE", date_labels );

                // const groupedByDate = _.groupBy(graph_object.tracking, 'achieved_date');
                // // Map through the groups and get the item with the latest 'updated_at' date
                // const filteredArray = _.map(groupedByDate, (group) => {
                // return _.maxBy(group, 'updated_at');
                // });

                const filteredArray = _.cloneDeep(graph_object.tracking);

                let final_array = [];

                date_labels.forEach((date) => {

                    const date_label = new Date(date.setHours(0, 0, 0, 0));

                    let index = _.findIndex(filteredArray, (item) => {
                        let compared_date = el.getDate(item.achieved_date);
                        let found = date_label.getTime() == compared_date.getTime();
                        return found;
                    })
                    if (index == -1) {
                        final_array.push({
                            value: null,
                            target_value: null,
                            achieved_date: `${el.getBackendDateFormat(date_label)} 00:00:00`
                        })
                    }
                    else {
                        final_array.push(filteredArray[index]);
                    }
                })

                let last_found_target = 0;
                //graph_object.tracking.forEach( (item, idx) => {
                _.sortBy(final_array, function (obj) {
                    return obj.achieved_date;
                }).forEach((item) => {
                    
                    let date = el.getDate(item.achieved_date);
                    if (isBefore(date, new Date(startoff_date)) || isAfter(date, new Date(cutoff_date))) {
                        return false;
                    }
                    if (date != 'NaN') {
                        data.push(
                            item.value
                        );

                        if (has_target) {
                            let is_target_valid = item.target_value != null && item.target_value != 0;
                            let last_target_value = Number(graph_object.target);

                            if (is_target_valid) {
                                target.push(
                                    //{ x: date.getTime(), y: item.target_value, }
                                    item.target_value
                                );
                                last_found_target = Number(item.target_value)
                                //last_target_value = Number(item.target_value)
                            }
                            else {
                                target.push(
                                    last_found_target != 0 
                                        ? last_found_target : last_target_value
                                    )
                            }

                        }
                    }
                    console.log('TARGETS DEBUG - LAST FOUND TARGET', last_found_target);
                })
                //!--------------------------------------------
                //TODO FIX THE SORTING ORDER PLS!!!
                //data = _.sortBy(data, function(obj) {
                //    return el.getDate(obj.x);
                //});
                //target = _.sortBy(target, function(obj) {
                //    return el.getDate(obj.x);
                //});
                //!--------------------------------------------

                // * Push achieved series
                graph_series.push({ name: 'Achieved', data });

                //* push target line data
                if (has_target) {
                    //TODO -- FIX TARGET POSITION FOR CATEGORIES TYPE CHART
                    //if(data.length > 0){
                    //    let idx = _.findIndex(target, {x: data[0].x});
                    //    if(idx == -1){
                    //        target.unshift({ x:data[0].x, y: 0})
                    //    }
                    //}
                    if (has_target) {
                        graph_series.push({ name: 'Target', data: target });
                        // graph_series.push({ name: 'Target', type: "line", data: [
                        //     50, 50, 50, 50, 50, 50, 50, 150, 150, 150, 200, 200
                        // ] });
                    }
                    // else{
                    //     graph_series.push({ name: 'Target', data: target });
                    // }
                }

                //* push cumulative line data
                if (has_cumulative) {
                    //* get cumulative value for normal tracking values
                    let target_summed = 0;
                    let cumulative_series = [];
                    data.forEach(achieved_item => {
                        if (achieved_item == null) {
                            cumulative_series.push(null);
                        }
                        else {
                            target_summed += Number(achieved_item);
                            cumulative_series.push(target_summed)
                        }
                    });
                    graph_series.push({ name: 'Cumulative', data: cumulative_series });
                }
                return graph_series;
            }

            else {
                let graph_series = [];
                let series = [];
                let el = this;
                if (viewed_time_period == 'weekly') {
                    series = this.processAsWeekly(graph_object, has_target);
                }
                else if (viewed_time_period == 'monthly') {
                    series = this.processAsMonthly(graph_object, has_target);
                }
                else if (viewed_time_period == 'quarterly') {
                    series = this.processAsQuarterly(graph_object, has_target);
                }
                else if (viewed_time_period == 'yearly') {
                    series = this.processAsYearly(graph_object, has_target);
                }

                series = _.sortBy(series, function (obj) {
                    return el.getDate(obj.date);
                });
                let data = [];
                series.forEach((item) => {
                    data.push(
                        // {
                        //     x: item.date,
                        //     y: item.value,
                        // }
                        item.value == 0 ? null : item.value
                    );
                })
                graph_series.push({ name: 'Achieved', data });
                if (has_target) {
                    let target_series = [];
                    target_series.forEach((item) => {
                        data.push(
                            // {
                            //     x: item.date,
                            //     y: item.target_value != null ? item.target_Value : 0,
                            // }
                            item.target_value != null ? item.target_Value : 0
                        );
                    })
                    graph_series.push({ name: 'Target', data: target_series });
                }
                if (has_cumulative) {
                    let cumulative_series = [];
                    let achieved_summed = 0;
                    // loop though achieved values and sum together
                    data.forEach(achieved_item => {
                        achieved_summed += Number(achieved_item);
                        cumulative_series.push({
                            // x: achieved_item.x,
                            // y: achieved_summed,
                            achieved_summed
                        })
                    });
                    graph_series.push({ name: 'Cumulative', data: cumulative_series });
                }
                return graph_series;
            }

        },
        //eslint-disable-next-line
        processMultilineValues(graph_object, viewed_time_period, has_target = false) {
            //* if no custom time perios is set - eg weekly graph is weekly
            if (graph_object.current_time_period === this.custom_time_period) {
                let graph_series = []; //{ name, data }
                let el = this;
                graph_object.multi_line_data.forEach((graph) => {
                    let name = `${graph.name} (${graph.owner_name})`;
                    let data = [];
                    graph.tracking.forEach((item, idx) => {
                        let date = el.getDate(item.achieved_date);
                        if (date == 'NaN') {
                            return;
                        }
                        if (idx == 0) {
                            data.push(
                                // {
                                //     x: date.getTime(),
                                //     y: item.value,
                                // }
                                item.value
                            );
                        }
                        else if (item.value > 0) {
                            data.push(
                                // {
                                //     x: date.getTime(),
                                //     y: item.value,
                                // }
                                item.value
                            );
                        }
                    })
                    // data = _.sortBy(data, function(obj) {
                    //     return el.getDate(obj.x);
                    // });
                    graph_series.push({ name, data });
                })
                el = undefined;
                return graph_series;
            }
            else {
                let el = this;
                let graph_series = [];
                graph_object.multi_line_data.forEach((graph) => {
                    let series = [];
                    if (viewed_time_period == 'weekly') {
                        series = this.processAsWeekly(graph);
                    }
                    else if (viewed_time_period == 'monthly') {
                        series = this.processAsMonthly(graph);
                    }
                    else if (viewed_time_period == 'quarterly') {
                        series = this.processAsQuarterly(graph);
                    }
                    else if (viewed_time_period == 'yearly') {
                        series = this.processAsYearly(graph);
                    }


                    let name = `${graph.name} (${graph.owner_name})`;
                    series = _.sortBy(series, function (obj) {
                        return el.getDate(obj.date);
                    });
                    let data = [];
                    series.forEach((item) => {
                        data.push(
                            // {
                            //     x: item.date,
                            //     y: item.value,
                            // }
                            item.value,
                        );
                    })

                    graph_series.push({ name, data });

                })
                return graph_series;
            }
        },
        processAsWeekly(graph, has_target = false) {
            let tracking = [];
            let graph_end = graph.deadline != undefined ? graph.deadline : graph.end_date
            let weekly_intervals = eachWeekOfInterval({ start: new Date(graph.start_date), end: new Date(graph_end) });
            weekly_intervals.forEach((date, idx) => {
                let start = startOfWeek(date);
                let end = endOfWeek(date);
                let total_value = 0;
                let total_target = 0;
                while (isBefore(start, end)) {
                    //console.log('WHILE LOG 13')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(graph.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += graph.tracking[index].value;
                        if (has_target) {
                            if (graph.tracking[index].target_value != null) {
                                total_target += graph.tracking[index].target_value;
                            }

                        }
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');
                let tracking_object = {
                    date: formatted_date.getTime(),
                    value: total_value,
                    id: idx,
                }
                if (has_target) {
                    tracking_object['target_value'] = total_target;
                }
                tracking.push(tracking_object);
            })
            return tracking;
        },
        processAsMonthly(graph, has_target = false) {
            const range = store.getters['performance/objectives/viewableRangeDate'];

            let tracking = [];
            //let graph_end = graph.deadline != undefined ? graph.deadline : graph.end_date;
            //let monthly_intervals = eachMonthOfInterval({start: new Date(graph.start_date), end: new Date(graph_end)});
            let monthly_intervals = eachMonthOfInterval({ start: range.range_start, end: range.range_end });
            monthly_intervals.forEach((date, idx) => {
                let start = startOfMonth(date);
                let end = endOfMonth(date);
                let total_value = 0;
                let total_target = 0;
                while (isBefore(start, end)) {
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(graph.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += graph.tracking[index].value;
                        if (has_target) {
                            total_target += graph.tracking[index].target_value
                        }
                        //total_target += series_data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }

                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');

                let tracking_object = {
                    date: formatted_date.getTime(),
                    value: total_value,
                    id: idx,
                }
                if (has_target) {
                    tracking_object['target_value'] = total_target;
                }
                tracking.push(tracking_object);
            })
            return tracking;
        },
        processAsQuarterly(graph, has_target = false) {
            let tracking = [];
            const range = store.getters['performance/objectives/viewableRangeDate'];

            //let graph_end = graph.deadline != undefined ? graph.deadline : graph.end_date;
            let quarter_intervals = eachQuarterOfInterval({ start: range.range_start, end: range.range_end });

            //let quarter_intervals = eachQuarterOfInterval({start: new Date(graph.start_date), end: new Date(graph_end)});
            quarter_intervals.forEach((date, idx) => {
                let start = startOfQuarter(date);
                let end = endOfQuarter(date);
                let total_value = 0;
                let total_target = 0;
                while (isBefore(start, end)) {
                    //console.log('WHILE LOG 15')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(graph.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += graph.tracking[index].value;
                        if (has_target) {
                            total_target += graph.tracking[index].target_value
                        }
                        //total_target += graph.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');

                let tracking_object = {
                    date: formatted_date.getTime(),
                    value: total_value,
                    id: idx,
                }
                if (has_target) {
                    tracking_object['target_value'] = total_target;
                }
                tracking.push(tracking_object);
            })
            return tracking;
        },
        processAsYearly(graph, has_target = false) {
            let tracking = [];
            const range = store.getters['performance/objectives/viewableRangeDate'];

            //let graph_end = graph.deadline != undefined ? graph.deadline : graph.end_date;
            let yearly_intervals = eachYearOfInterval({ start: range.range_start, end: range.range_end });
            yearly_intervals.forEach((date, idx) => {
                let start = startOfYear(date);
                let end = endOfYear(date);
                let total_value = 0;
                let total_target = 0;
                while (isBefore(start, end)) {
                    //console.log('WHILE LOG 16')
                    let current_date = format(start, "dd-MM-yyyy");
                    let index = _.findIndex(graph.tracking, (item) => {
                        let item_date = format(parseISO(item.achieved_date), "dd-MM-yyyy");
                        return item_date == current_date;
                    });
                    if (index != -1) {
                        total_value += graph.tracking[index].value;
                        if (has_target) {
                            total_target += graph.tracking[index].target_value
                        }
                        //total_target += series_data.tracking[index].target_value
                    }
                    start = addDays(start, 1);
                }
                let formatted_date = format(date, 'dd MMM yyyy');
                formatted_date = new Date(formatted_date + ' GMT');

                let tracking_object = {
                    date: formatted_date.getTime(),
                    value: total_value,
                    id: idx,
                }
                if (has_target) {
                    tracking_object['target_value'] = total_target;
                }
                tracking.push(tracking_object);
            })
            return tracking;
        },
        getDeadlineDateForPeriod(period, deadline) {
            switch (period) {
                case 'daily': {
                    return this.getSimpleDate(parseISO(deadline));
                }
                case 'weekly': {
                    return this.getSimpleDate(endOfWeek(parseISO(deadline)));
                }
                case 'monthly': {
                    return this.getSimpleDate(endOfMonth(parseISO(deadline)));
                }
                case 'quarterly': {
                    return this.getSimpleDate(endOfQuarter(parseISO(deadline)));
                }
                case 'yearly': {
                    return this.getSimpleDate(endOfYear(parseISO(deadline)));
                }
            }
        },
        //* prototype interpolation
        /*
        // Your existing data series with start and end values
const dataSeries = [
  { x: '2022-01-01', y: 10 },
  { x: '2022-01-02', y: 20 },
  { x: '2022-01-04', y: 30 },
  { x: '2022-01-05', y: 40 }
];

// Helper function to calculate the interpolated value between two data points
const interpolate = (a, b, x) => {
  const aVal = a.y;
  const bVal = b.y;
  const aDate = new Date(a.x);
  const bDate = new Date(b.x);
  const xDate = new Date(x);
  const t = (xDate - aDate) / (bDate - aDate);
  return aVal + (bVal - aVal) * t;
};

// Generate a new array of data points with interpolated values
const interpolatedData = [];
const startDate = new Date(dataSeries[0].x);
const endDate = new Date(dataSeries[dataSeries.length - 1].x);
for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
  const dateString = date.toISOString().slice(0, 10);
  const dataPoint = dataSeries.find(point => point.x === dateString);
  if (dataPoint) {
    interpolatedData.push(dataPoint);
  } else {
    const before = dataSeries.find(point => new Date(point.x) < date);
    const after = dataSeries.find(point => new Date(point.x) > date);
    if (before && after) {
      const interpolatedValue = interpolate(before, after, date);
      interpolatedData.push({ x: dateString, y: interpolatedValue });
    }
  }
}

// interpolatedData now contains all the original data points, plus interpolated values for any dates in-between
*/
    },
}