<template>
    <div class="krBootstrap">
        <b-table-simple class="" small bordered >
            <b-thead>
                <b-tr>
                    <b-th>Key Results</b-th>
                    <b-th style="width: 42px;">User</b-th>
                    <!-- <b-th style="width: 42px;">Category</b-th> -->
                    <b-th style="width: 120px;">Status</b-th>
                    <b-th style="width: 200px;">Gantt</b-th>
                    <b-th v-if="false" style="width: 100px;">Score</b-th>
                    <b-th style="width: 85px;">Action</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr :class="selected_kr_id == item.id ? 'selected-kr-bg' : ''" v-for="(item, idx) in table_data" :key="item.id">
                    <b-td @click="handleKeyResultSelect(item.id)" class="cursor-pointer">
                        <div class="pt-1">
                            {{ item.name }}
                        </div>
                    </b-td>
                    <b-td>
                        <div class="d-flex ms-1">
                            <img :src="getImgSrc(item.owner_user_id)" alt="" class="cursor-pointer avatar-xxxs rounded-circle " v-b-tooltip.hover.left>
                        </div>
                    </b-td>
                    <!-- <b-td>
                        <div class="d-flex">
                            <b-badge v-show="item.category != null && item.category != ''" variant="primary" class="bg-soft text-dark mt-1 ms-auto me-auto" :class="item.category == 'Leading' ? 'bg-primary' : 'bg-danger'">
                                {{ item.category }}
                            </b-badge>
                            <b-badge v-show="item.category == null || item.category == ''" variant="primary" class="bg-grey bg-soft text-dark mt-1 ms-auto me-auto">
                                No Type Set
                            </b-badge>
                        </div>
                    </b-td> -->
                    <b-td>
                        <div class="d-flex pt-1">
                            <b-overlay :show="key_result_loading_id == item.id" >
                            <RangeSlider @updatedValue="item.status = $event; updateStatus(idx)" :value="item.status" />
                            <!-- <b-form-input style="width: 90px;" @input="updateStatus(idx, $event)" v-model.number.lazy="item.status" min=0 max=100 type="range"></b-form-input>
                            <span class="ms-2" style="width: 20px;">{{ item.status_value }}</span> -->
                            </b-overlay>
                        </div>
                    </b-td>
                    <b-td>
                        <ProgressBar
                        :start_date="first_kr_date"
                        :end_date="last_kr_date"
                        :item_start_date="new Date(item.start_date)"
                        :item_end_date="new Date(item.deadline)"
                        />
                    </b-td>
                    <b-td v-if="false">
                        <b-form-input @change="notifyChange($event, item.id, 'score')" type="number" min=0 max=10 v-model.number="item.score" size="sm"></b-form-input>
                    </b-td>
                    <b-td>
                        <b-button size="sm" variant="outline-primary" @click="showModal(item.id)">
                            <font-awesome-icon icon="fa-edit"/>
                        </b-button>
                        &nbsp;
                        <b-button size="sm" variant="outline-danger" @click="showDeletePrompt(item.name,item.id)">
                            <font-awesome-icon icon="fa-trash"/>
                        </b-button>
                        
                    </b-td>
                    </b-tr>
                <b-tr v-show="!hide_extra_items">
                    <b-td colspan="6">
                        <b-button @click="showModal()" variant="outline-primary" class="w-sm" size="sm">
                            <font-awesome-icon class="me-1" icon="fa-plus"></font-awesome-icon>
                            Add KR
                        </b-button>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <KeyResultsModal v-if="show_kr_modal"
            is_parent
            :edit_id="edit_id"
            :available_key_results="table_data"
            @savedEvent="handleSaveEvent()"
            :objective="objective"
        />
        <!-- <b-overlay :show=is_loading_kpis no-wrap></b-overlay> -->
    </div>
</template>

<script>
import RangeSlider from '@/components/widgets/misc/rangeSlider.vue'
import { performanceComputed, performanceMethods, levelComputed } from '@/state/helpers';
import _ from 'lodash';
import { parse } from 'date-fns';
import KeyResultsModal from '../../misc/objectiveModals/keyResultModal.vue';
import ProgressBar from "../../misc/KeyResultGanttProgressBar.vue";
export default {
    data:() => ({
        table_data: [],
        show_kr_modal: false,
        edit_id: -1,
    }),
    components:{
        ProgressBar,
        KeyResultsModal,
        RangeSlider,
    },
    props:{
        rows:{
            default: () => {
                return [];
            },
        },
        'hide_extra_items':{
            type: Boolean,
            default: false,
        },
        objective:{
            default: false,
        }
    },
    computed:{
        ...performanceComputed,
        ...levelComputed,
        first_kr_date(){
            // let smallest_start = null;
            // this.table_data.forEach( item => {
            //     const start = parse(item.start_date, 'yyyy-MM-dd HH:mm:ss', new Date());
            //     if (!smallest_start || start < smallest_start) {
            //         smallest_start = start;
            //     }
            // });
            // return smallest_start
            if(this.objective != false){
                return parse(this.objective.start_date, 'yyyy-MM-dd HH:mm:ss', new Date());
            }
            else{
                return parse(this.current_objective_details.start_date, 'yyyy-MM-dd HH:mm:ss', new Date());
            }
        },
        last_kr_date(){
            // let biggest_deadline = null;
            // this.table_data.forEach( item => {
            //     const deadline = parse(item.deadline, 'yyyy-MM-dd HH:mm:ss', new Date());
            //     if (!biggest_deadline || deadline > biggest_deadline) {
            //         biggest_deadline = deadline;
            //     }
            // })
            // return biggest_deadline;
            if(this.objective != false){
                return parse(this.objective.deadline, 'yyyy-MM-dd HH:mm:ss', new Date());
            }
            else{
                return parse(this.current_objective_details.deadline, 'yyyy-MM-dd HH:mm:ss', new Date());
            }
        },
        // getSelectedKeyResult(){
        //     let kr = this.table_data.find( item => {
        //         return item.id == this.selected_kr_id;
        //     });
        //     return kr;
        // },
    },
    methods:{
        ...performanceMethods,
        showDeletePrompt(name, id){
            this.$swal.fire({
                    title: `Delete selected ${name}?`,
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    console.log(result)
                    if (result.value) {
                        console.log("running here")
                        //* If user selects yes
                        this.deleteKR(id)
                        .then(()=>{
                            if(this.objective != false){
                                this.$eventHub.$emit('reloadListObjectives', this.objective.id);
                            }
                            else{
                                this.reloadGoalObjectveData()
                                .then(()=>{
                                    setTimeout(()=>{
                                        this.$eventHub.$emit('reloadLinkedObjectives');
                                    }, 500)
                                })
                            }
                            
                        })
                    }
                });
        },
        showModal(id){
            this.edit_id = id;
            this.show_kr_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('keyResultModal')
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "keyResultModal") {
                        this.show_kr_modal = false;
                        this.edit_id = -1;
                    }
                });
            })
        },
        handleSaveEvent(){
            if(this.objective != false){
                this.$eventHub.$emit('reloadListObjectives', this.objective.id);
            }
            else{
                this.reloadGoalObjectveData().then(() => {
                    this.$bvModal.hide('keyResultModal');
                    setTimeout(()=>{
                        this.$eventHub.$emit('reloadLinkedObjectives');
                    }, 500)
                })
            }
            
        },
        updateStatus(index){
            // save endpoint takes array but here we are only saving a single item in the array
            let key_results = [ this.table_data[index] ];

            //* set loading spinner for individual row
            this.setKeyResultLoadingID(key_results[0].id);

            this.saveKeyResultData({key_results: key_results}).then(()=>{
                //this.reloadGoalObjectveData();
                if(this.objective != false){
                    this.$eventHub.$emit('reloadListObjectives', this.objective.id);
                }
                else{
                    this.$eventHub.$emit('reloadLinkedObjectives');
                }
                //this.$eventHub.$emit('routeFinishedLoading')
            })
        },
        handleKeyResultSelect(id){
            this.$emit('handleKeyResultSelect', id);
        },
        getImgSrc(user_id){
            let img_src = '/img/user.png';
            if(user_id){
                let user_object = this.all_users.find( user => {
                    return user.id == user_id;
                })
                if(user_object){
                    img_src = user_object.avatar_src;
                }
            }
            return img_src;
        },
        reloadGoalObjectveData(){

            return new Promise( (resolve, reject) => {
                let params = {};
                if(this.selected_goal_objective != -1) {
                    params = {
                        level_id: this.current_level_id,
                        user_id: this.selected_user_id ? this.selected_user_id : 0,
                        objective_id: this.selected_goal_objective,
                        objective_data: 1,
                        //graph_data: 1
                    }
                }
                if(this.selected_personal_objective != -1) {
                    params = {
                        level_id: this.current_level_id,
                        user_id: this.selected_user_id ? this.selected_user_id : 0,
                        objective_id: this.selected_personal_objective,
                        objective_data: 1,
                        //graph_data: 1
                    }
                }   
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                })
                .catch(()=>{
                    reject();
                })
            })
        }
    },
    watch:{
        rows:{
            immediate: true,
            handler(){
                this.table_data = _.cloneDeep(this.rows);
            }
        }
    },
    mounted(){
        this.$eventHub.$on('reloadActiveObjective', this.handleSaveEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('reloadActiveObjective');
    }
}
</script>

<style scoped>
.selected-kr-bg{
        background-color: rgba(85, 109, 230, 0.112) !important;
        font-weight: bold;
        transition: all .3s ease-in;
    }


</style>
<style>
.krBootstrap > .table-bordered .td{
    border: 1px solid #e90000 !important;
}
</style>