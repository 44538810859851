<template>
    <div>
        <!-- PLOT GRAPH FOR OBJECTIVE RATINGS OVER TIME -->
        <div class="graph-container">
            <div class="graph-side-button">
                <b-button @click="$emit('left')" variant="light" style="height: 100%;">
                    <font-awesome-icon icon="fa-arrow-left"/>
                </b-button>
            </div>
            <div class="graph-middle-content">
                <slot name="graph"></slot>
            </div>
            <div class="graph-side-button">
                <b-button @click="$emit('right')" variant="light" style="height: 100%;">
                    <font-awesome-icon icon="fa-arrow-right"/>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data:() => ({

    }),
    computed:{},
    methods:{},
    mounted(){},
}
</script>

<style>
    .graph-container{
        display: flex;
        height: 12rem;
    }
    .graph-side-button{
        width: 50;
    }
    .graph-middle-content{
        width: 100%;
        text-align: center;
    }
</style>