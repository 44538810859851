<template>
    <!-- //TODO create child components from both b-tabs -->
        <div>
            <div v-if="user != null">
                <b-overlay variant="white" :show="assign_user_levels_mode">
                        <b-row class="p-3 pb-0 pt-0">
                            <b-col>
                                <b-button :disabled="!$hasUserRole('Power User')" @click="changePassword" variant="primary" class="" size="sm" style="float: right;">
                                    Change Password
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-form class="userForm p-3" >
                            <b-row :class="canEditProfile">
                                <b-col cols="2">First Name</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.name"></b-form-input>
                                </b-col>
                                <b-col cols="2">Landline</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.landline_no"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row :class="canEditProfile">
                                <b-col cols="2">Middle Name</b-col>
                                <b-col cols="4">
                                    <b-form-input v-model="user.middle_name"></b-form-input>
                                </b-col>
                                <b-col cols="2">Cell / Mobile</b-col>
                                <b-col cols="4">
                                    <b-form-input   v-model="user.cell_no"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row :class="canEditProfile">
                                <b-col cols="2">Surname</b-col>
                                <b-col cols="4">
                                    <b-form-input   v-model="user.last_name"></b-form-input>
                                </b-col>
                                <b-col cols="2">Personal Email</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.personal_email"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row :class="canEditProfile">
                                <b-col cols="2">Work Email</b-col>
                                <b-col cols="4">
                                    <b-form-input   v-model="user.email"></b-form-input>
                                </b-col>
                                <b-col cols="2">Address</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.address"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row :class="canEditProfile">
                                <b-col cols="2">Next of Kin</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.next_of_kin"></b-form-input>
                                </b-col>
                                <b-col cols="2">Kin Contact</b-col>
                                <b-col cols="4">
                                    <b-form-input  v-model="user.next_of_kin_contact"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row >
                                <!-- <b-col cols="2">Password</b-col>
                                <b-col cols="4">
                                    <b-form-input :class="isSuperUser" v-model="user.password"></b-form-input>
                                </b-col> -->
                                <b-col cols="2">ID Number</b-col>
                                <b-col cols="4" :class="canEditProfile">
                                    <b-form-input  v-model="user.id_number"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row v-if="form_data_changed && hideUpdateButton == false">
                                <b-col>
                                    <b-button @click="setActiveUser" variant="danger" class="" style="float: right;">
                                        Clear Changes
                                    </b-button>
                                    <b-button @click="updateUser" variant="primary" class="me-2 ms-auto" style="float: right;">
                                        Update Changes
                                    </b-button>
                                    
                                </b-col>
                            </b-row>
                        </b-form>
                    <template #overlay>
                        Content disabled while editing user assigned levels
                    </template>
                </b-overlay>
            </div>
            <b-overlay :show="is_saving_user" no-wrap></b-overlay>
            <ChangePasswordModal v-if="show_password_modal" :payload="password_modal_payload" />
        </div>
    </template>
    
    <script>
    import _ from 'lodash';
    import {
        levelComputed,
        levelMethods,
        peopleMethods,
        peopleComputed,
        adminComputed,
        adminMethods,
        profileComputed,
        profileMethods
    } from "@/state/helpers";
    //eslint-disable-next-line
    import { format, parseISO } from "date-fns";
    import 'vue-advanced-cropper/dist/style.css';
    import ChangePasswordModal from './changePasswordModal.vue';
    import Swal from 'sweetalert2';
    export default {
        components: {
            ChangePasswordModal
        },
        props: {
            activeEmployeeId: {
                required: true
            },
            existing_user:{
                default: false,
            },
            hideUpdateButton:{
                default: false,
            },
            hideEdit:{
                default: false
            }
        },
        data:() => ({
            show_modal: false,
            user: null, //* old -- user: {}
            user_display_name: '', //copy of name and surname (fixes display name changing when editing first/last name)
            firstName: '',
            lastName: '',
            employmentDate: '',
            report_to: '',
            is_loading_job: false,
            processedData: [],
            processedRemunerationData: [],
            linkableAssetsData: [],
            savedLeaveTypes: [],
            popup_date: null,
            assets_table_view_mode: '',
            is_loading: false,
            is_saving_avatar: false,
            is_saving_user: false,
            leave_changes_made_flag: false,
            remuneration_changes_made_flag: false,
            assets_changes_made_flag: false,
            show_password_modal: false,
            password_modal_payload: null,
    
            hasMounted: false,
            active_collapse_index: 1,
    
            selectedImage: "",
            hoverOverImg: false,
    
            editJobDetails: false,
            edit_current_job:null,
    
            block_form_watcher: false, //* block watcher from firing when the user object is updated with new user data
            form_data_changed: false,//* true when form input fields change
    
            typeahead_query: '',
    
        }),
        watch: {
            activeEmployeeId() {
                //* update the active user data when the activeEmployeeId changes
                this.$nextTick(() => {
                    this.setActiveUser()
                });
            },
            user:{
                deep: true,
                handler(){
                    if(this.block_form_watcher == false){
                        this.form_data_changed = true;
                    }
                }
            },
        },
        computed:{
            ...levelComputed,
            ...peopleComputed,
            ...adminComputed,
            ...profileComputed,
            storeEmployeesData() {
                return this.levelEmployeesData.employees;
            },
            activeEmployee() {
                var el = this;
                var findIndex = _.findIndex(this.storeEmployeesData, function(o) { return o.id == el.activeEmployeeId; });
                if (findIndex > -1) {
                    return this.storeEmployeesData[findIndex];
                }
                return {};
            },
            active_employee_data() {
                let data = null;
                if(this.activeEmployeeId != -1){
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                    if(index != -1){
                        data = this.levelEmployeesData[index];
                    }
                }
                return data;
            },
            isSuperUser(){
                let allowed = this.$hasUserRole('Power User');
                if(allowed == false){
                    return this.canEditProfile;
                }
                else{
                    return '';
                }
            },
            canEditProfile(){
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return '';
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return '';
                }
                //else
                return 'block-click'
            },
            getJobTitle(){
                let title = '';
                let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                if(index != -1){
                    title = this.companyJobs[index].title;
                }
                else{   
                    title = 'Job not found'
                }
                return title;
            },
            hasValidJob(){
                if(this.user != undefined){
                    let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                    console.log(this.companyJobs);
                    if(index == -1){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return false;
                }
            },
        },
        methods:{
            ...adminMethods,
            ...peopleMethods,
            ...levelMethods,
            ...profileMethods,
            changePassword() {
                this.password_modal_payload = this.user;
                this.show_password_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('changePasswordModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "changePasswordModal") {
                            this.show_password_modal = false;
                            this.password_modal_payload = null;
                        }
                    });
                })
            },
            showReloadingPrompt(user_name, archived = false){
                let msg = archived ? 'Archived user' : 'Restored'
                Swal.fire({
                    title: `${msg}: ${user_name}`,
                    text: 'Reloading data.',
                    timer: 5000,
                    timerProgressBar: true,
                    icon: "success",showConfirmButton: false,
                }).then(()=>{
                    this.$router.go();
                })
            },
            formatStartDate(date){
                console.log('DATE', date)
                if(date == null){
                    return ''
                }
                if(date !== ''){
                    if(typeof date === 'string'){
                        date = parseISO(date);
                    }
                    return format(date, 'dd-MMM-yyyy');
                }
                else{
                    return '';
                }
            },
            updateUser() {
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                this.updateEmployee(this.user)
                .then(()=>{
                    if(Number(this.core.id) == Number(this.selected_user_id)){
                        this.loadCoreData();
                    }
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.loadLevels();
                        this.loadEmployeeData(this.selected_user_id)
                        //refresh owner users for datatable columns
                        this.loadAllUsers();
                        //refrrsh users for current level
                        this.loadMenuUsers(this.selected_level.id);
                        // if(this.existing_user == false){
                        //     //this.$emit('back');
                        //     this.$router.push('/people');
                        // }
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            updateJobDetails(){
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                let updatedUser = _.cloneDeep(this.user);
                updatedUser.current_job = _.cloneDeep(this.edit_current_job);
                this.updateEmployee(updatedUser)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        /*if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }*/
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                        this.editJobDetails = false;
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            setActiveUser(){
                this.form_data_changed = false;
                //* moved this block of code from the 
                //* activeEmployeeId watcher (method used in activeEmployeeId watcher and mounted hook)
                if (this.active_employee_data != null) {
                    this.block_form_watcher = true;
                    this.user = _.cloneDeep(this.active_employee_data);
                    this.user_display_name = this.user.name + ' ' + this.user.last_name
                    this.firstName = this.user.name
                    this.lastName = this.user.last_name
                    this.employmentDate = this.user.employment_date
                    this.progress_status = 70
                    let user = this.all_users.find( person => { return person.id == this.reports_to_user_id; })
                    console.log("!!!!!")
                    console.log(JSON.parse(JSON.stringify(this.user)));
    
                    if(user) {
                        this.report_to = user.name
                    }
                    console.log(JSON.parse(JSON.stringify(this.user)))
                        if(this.user.current_job == undefined){
                            this.user['current_job'] = {}
                        }
                        this.user.level_id = this.user.pivot.level_id;
                        this.$nextTick(()=>{
                            this.block_form_watcher = false;
                            this.hasMounted = true;
                        })
                       
                    //* fix issue with older user where user.current_job == null
                    
                }
            },
            saveFromWizardEvent(){
                this.updateUser();
            },
            handleUpdatedUserLevels(){
                let promises = [
                    this.loadEmployeesData(this.selected_level.id),
                    this.loadMenuUsers(this.selected_level.id)
                ]
                Promise.all(promises)
                .then(()=>{
                    let index = _.findIndex(this.levelEmployeesData, { id: this.selected_user_id})
                    if(index != -1){
                        this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                        this.setActiveUser();
                    }
                    else{
                        this.setSelectedUser(null);
                    }
                })
            },
            editUserJobDetails(){
                this.edit_current_job = _.clone(this.user.current_job)
                this.editJobDetails = true;
            },
            isUserManager(){
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id) ;
                if(is_editing_as_manager){
                    return '';
                }
                return 'block-click';
            },
            hasValidPerms(){
                //let is_editing_self = this.$hasPerm('people') && this.core.id ==  this.selected_user_id
                //let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                //console.log(is_editing_self, is_editing_as_manager)
                //if(is_editing_self){
                //    return true;
                //}
                //else if(is_editing_as_manager){
                //    return true;
                //}
                //return false;
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return true;
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return true;
                }
                return false;
            },

            changedJob(){
                this.is_loading_job = true;
                this.loadMenuUsers(this.selected_level.id)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                        .then(()=>{
                            this.setActiveUser();
                            this.is_loading_job = false;
                    })
                })
            },
            showDiscardPrompt(tableName,newMode){
                Swal.fire({
                    title: `Clear unsaved ${tableName} changes?`,
                    text: "Warning, changes will be deleted.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.table_view_mode = newMode;
                    }
                });
            },
            getJobByID(id){
                let job_name = 'Invalid Job'
                let index = _.findIndex(this.companyJobs, {id: id});
                if(index != -1){
                    job_name = this.companyJobs[index].title;
                }
                return job_name;
            }
        },
        
        mounted(){
            if(this.user == null){
                //* used v-if to show this component
                //* user will be null on load so we need to set the active user data
                //! could also use the activeEmployeeId watcher with the 'immediate' flag set to true 
                this.setActiveUser();
            }
    
            this.$eventHub.$on('saveUserFromWizard', this.saveFromWizardEvent);
            this.$eventHub.$on('updatedUserLevels', this.handleUpdatedUserLevels)
        },
        beforeDestroy(){
            this.$eventHub.$off('saveUserFromWizard');
            this.$eventHub.$off('updatedUserLevels');
        }
    }
    </script>
    
    <style>
    .userForm > .row {
        margin-bottom: 1rem;
    }
    .userForm > .row > .col-2 {
        padding-top: 0.55rem;
    }
    .header-profile-user-view{
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #f6f6f6;
        padding: 3px;
    }
    
    
    .hoverEditIcon{
        cursor: pointer;
        position: absolute;
            bottom: 5px;
            right: 5px;
    }
    </style>