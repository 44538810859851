<template>
    <div class="d-flex">
        <!-- LEFT ARROW -->
        <div style="width: 1.55rem;" class="d-flex">
            <b-button variant="light" size="sm" :style="getButtonStyles"  style="width: 1.55rem;" @click="graphPrevious()" :disabled="disable_buttons">
                <font-awesome-icon icon="fa-arrow-left"/>
            </b-button>
        </div>

        <!-- MAIN CONTENT -->
        <div class="fit-to-custom-container">
            <slot name="cards" style="box-sizing: border-box;"></slot>

            <transition name="fade">
                <div v-show="hide_graph">
                    <slot  name="graph" style="box-sizing: border-box;"></slot>
                </div>
            </transition>
        </div>

        <!-- RIGHT ARROW -->
        <div style="width: 1.55rem;"  class="d-flex">
            <b-button variant="light" size="sm" @click="graphNext()"  :style="getButtonStyles"  style="width: 1.55rem;"  :disabled="disable_buttons">
                <font-awesome-icon icon="fa-arrow-right"/>
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        disable_buttons:{
            type: Boolean,
            default: false,
        },
        hide_graph:{
            type: Boolean,
            default: true,
        },
        is_collapsed:{
            type: Boolean,
            default: true,
        }
    },
    data:() => ({}),
    computed:{
        getButtonStyles(){
            let style = "";

            if(this.hide_graph){
                style += " margin-top: 7.7rem;"
            }
            else{
                style += " 'margin-top: 0.0rem;"
            }

            if(this.is_collapsed){
                style += "  height: 5.2rem;"
            }
            else{
                style += "  height: 7.7rem;"
            }

            return style;
        }
    },
    methods:{
        graphNext(){
            this.$emit('next');
        },
        graphPrevious(){
            this.$emit('previous');
        }

    },
    mounted(){},
}
</script>

<style>
.fit-to-custom-container{
    width: calc(100% - 3.1rem);
}
</style>