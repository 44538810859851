<template>
    <span>
        <span class="btn-group">
            <b-button
                ref="viewButton"
                title="Set table to view mode"
                @click="setMode('view')"
                size="sm" v-if="canShow('view')"
                class="no-focus"
                :variant="currentMode==='view' ? 'outline-info':'none'">
                    <!--<i class="fas fa-eye"></i>-->
                    <font-awesome-icon icon="fa-solid  fa-eye" />
            </b-button>
            <b-button
                ref="editButton"
                title="Set table to edit mode"
                @click="setMode('edit')"
                size="sm" v-if="canShow('edit')"
                class="no-focus"
                :variant="currentMode==='edit' ? 'outline-primary':'none'">
                    <!--<i class="fas fa-pen"></i>-->
                    <font-awesome-icon icon="fa-solid  fa-pen" />
            </b-button>
            <b-button
                ref="dragButton"
                title="Set table to drag mode"
                @click="setMode('drag')"
                size="sm" v-if="canShow('drag')"
                class="no-focus"
                :variant="currentMode==='drag' ? 'success':'none'">
                    <!--<i class="fas fa-bars"></i>-->
                    <font-awesome-icon icon="fa-solid  fa-bars" />
            </b-button>
            <b-button
                ref="deleteButton"
                title="Set table to delete mode"
                @click="setMode('delete')"
                size="sm" v-if="canShow('delete')"
                class="no-focus"
                :variant="currentMode==='delete' ? 'outline-danger':'none'">
                    <!--<i class="fas fa-trash"></i>-->
                    <font-awesome-icon icon="fa-solid  fa-trash" />
            </b-button>
            <b-dropdown menu-class="dropdown-menu-end" class="no-focus" size="sm" variant="none">
                <template #button-content>
                    <!--<i class="fas fa-ellipsis-h"></i>-->
                    <font-awesome-icon icon="fa-solid  fa-ellipsis-h" />
                </template>
                <b-dropdown-item v-if="canShow('view')" @click="setMode('view')" class="text-primary">
                    View Mode
                </b-dropdown-item>
                <b-dropdown-item v-if="canShow('edit')" @click="setMode('edit')" class="text-info">
                    Edit Mode
                </b-dropdown-item>
                <b-dropdown-item v-if="canShow('delete')" @click="setMode('delete')" class="text-danger">
                    Delete Mode
                </b-dropdown-item>

                <!-- slot for extra content options -->
                <slot name="extra-items"></slot>
            </b-dropdown>
            
        </span>
    </span>
    <!--<span class="widthClass" @mouseenter="is_hovering = true" @mouseleave="is_hovering = false">
        <span class="btn-group" v-show="is_hovering">
            <b-button
                title="Set table to view mode"
                @click="setMode('view')"
                size="sm" v-if="canShow('view')"
                :variant="currentMode==='view' ? 'info':'outline-info'">
                    <i class="fas fa-eye"></i>
            </b-button>
            <b-button
                title="Set table to edit mode"
                @click="setMode('edit')"
                size="sm" v-if="canShow('edit')"
                :variant="currentMode==='edit' ? 'primary':'outline-primary'">
                    <i class="fas fa-pen"></i>
            </b-button>
            <b-button
                title="Set table to drag mode"
                @click="setMode('drag')"
                size="sm" v-if="canShow('drag')"
                :variant="currentMode==='drag' ? 'success':'outline-success'">
                    <i class="fas fa-bars"></i>
            </b-button>
            <b-button
                title="Set table to delete mode"
                @click="setMode('delete')"
                size="sm" v-if="canShow('delete')"
                :variant="currentMode==='delete' ? 'danger':'outline-danger'">
                    <i class="fas fa-trash"></i>
            </b-button>
        </span>

        <b-button size="sm" :class="is_hovering ? 'ms-1' : ''">
            <i class="fas fa-ellipsis-v"></i>
        </b-button>
    </span>-->
</template>

<script>

export default {
    props:{
        currentMode:{
            required: true,
        },
        actions:{//* edit, view, drag, delete
            default:[],
        }
    },
    data:() => ({
        is_hovering: false,
    }),
    computed:{
        getModeClass(){
            switch(this.currentMode){
                case 'view':{
                    return 'outline-info';
                }
                case 'edit':{
                    return 'outline-primary';
                }
                case 'drag':{
                    return 'outline-success';
                }
                case 'delete':{
                    return 'outline-danger';
                }
                default:{
                    return '';
                }
            }
        }
    },
    methods:{
        canShow(type){
            if(this.actions.includes(type)){
                return true;
            }
            else{
                return false;
            }
        },
        setMode(mode){
            if(mode !== this.currentMode){
                this.$emit('updateMode', mode);
            }
            else if(mode !== 'view'){
                let selectedBtn =  `${mode}Button`;
                this.$refs[selectedBtn].blur();
                this.$refs.viewButton.click();
            }
        }
    },
}
</script>

<style>
.widthClass{
    transition: all 0.2s linear;
}
.no-focus:focus{
    outline: 0 !important;
} 
.no-focus   {
    box-shadow: none !important;
}
</style>