<template>
    <div>
         <div class="d-flex flex-wrap mb-3 align-items-center" style="width: 100%;">
            <div class="me-2">
                <h5 class="card-title mr-2 d-flex align-items-center">
                    Candidates -&nbsp;
                    <span class="text-info">{{ levelName }}</span>
                </h5>
            </div>

            <FilterComponent
            :hideDateFilter="true"
            class="ms-auto"
            @searchText="searchText"
            @resetFilter="resetFilter"/>
        </div>

        <div>
            <Table
                :uid="table_uid" 
                :is_loading="is_loading"
                :data="candidates"
                :columns="columns"
                :table_mode="table_view_mode"
                :pager_config=8
                @selectedRowEvent="selectedRowEvent"
            />
        </div>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
import Table from '@/components/widgets/table.vue';
import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import _ from 'lodash';

export default {
    name: "Admin",
    components: {
        FilterComponent,
        Table,
    },
    data:() => ({
        //* props for webix table
        table_uid: 'offboardingCandidatesTable',
        table_view_mode: 'view', //* (view - edit - delete)
        is_loading: false,

        selected_candidate: null,

        filterText: "",

        isEditingNew: false,
        flip: true,
        showGuideline: false,
        editableId: -1,
        candidateData: [],
        addingRow: false,
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelOffboardingData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelOffboardingData[index];
                }
            }
            return data;
        },
        candidates(){
            let data = [];
            if(this.active_job_data != null){
                data = _.cloneDeep(this.active_job_data.employees)
            }
            return data;
        },
        columns(){
            return [
                //TODO ask about current_salary
                { header: 'Firstname', id: 'name', fillspace: 1},
                { header: 'Last Name', id: 'last_name', fillspace: 1},
                { header: 'Email', id: 'email', fillspace: 1},
            ]
        },



        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        storeOffboardingData() {
            return this.levelOffboardingData;
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            return "";
        },
        storeCandidateData() {
            var el = this;
            var findIndex = _.findIndex(this.storeOffboardingData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                return this.storeOffboardingData[findIndex].employees;
            }
            return [];
        },
        activeCandidate() {
            var el = this;
            var findIndex = _.findIndex(this.storeCandidateData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                return this.storeCandidateData[findIndex];
            }
            return {};
        }
    },
    watch: {
        activeHiringPositionId() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        },
        storeOffboardingData() {
            this.$nextTick(() => {
                this.mapCandidateData();
            });
        }
    },
    methods:{
        ...peopleMethods,
        //* table events start -----
        selectedRowEvent(row){
            //* Event returns the selected row
            this.selected_candidate = row.id;
            this.$eventHub.$emit('setActiveCandidate', this.selected_candidate);
        },
        //* table events end ------


        mapCandidateData() {
            this.candidateData = this.storeCandidateData.map(item => ({...item, isEdit: false}));
        },
        setRowActive(data) {
            if (data[0] && data[0].id) {
                this.editableId = data[0].id;
                this.$eventHub.$emit('setActiveCandidate', this.editableId);
            }
        },
        searchText(value) {
            let text = value.toString().toLowerCase();
            // eslint-disable-next-line
            $$(this.table_uid).filter(function(obj) {
            if(obj.first_name != undefined){
                return (
                obj.first_name
                    .toString()
                    .toLowerCase()
                    .indexOf(text) != -1
                );
            }
            });
        },
        resetFilter() {
            this.filterText = "";
            window.webix.$$(this.table_uid).filter();
        },

    },
    mounted(){
        this.mapCandidateData();
    }
}
</script>
