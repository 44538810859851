<template>
    <div class="p-1">
        <b-card class="border">
            <b-row>
                <!-- <b-col cols="2">
                    <div>
                        <label for="">Key Result Plan</label>
                    </div>
                    <b-form-textarea rows="1" readonly="" v-model="key_result_plan_text">

                    </b-form-textarea>
                </b-col> -->
                <b-col cols="12">
                    <div>
                        <KeyResultsTable @savedEvent="$emit('savedEvent');" :selected_kr="selected_kr" />
                    </div>
                </b-col>
            </b-row>
        </b-card>
        
        <!-- <hr class="m-1">
        <b-card class="border border-primary">
            <b-row>
                <b-col cols="12">
                    <div>
                        <ActivityList />
                    </div>
                </b-col>
            </b-row>
        </b-card> -->
    </div>
</template>

<script>
//import KeyResults from "./components/cards/key-results-plan.vue";
import KeyResultsTable from './key-results-plan.vue'

export default {
    components:{
        KeyResultsTable,
    },
    props:{
        selected_kr:{}
    },
    data:() => ({
        key_result_plan_text: '',
    }),
    watch:{
        'selected_kr':{
            immediate: true,
            handler(new_obj){
                this.key_result_plan_text = new_obj ? new_obj.plan : '';
            }}
    },
    computed:{},
    methods:{},
    mounted(){

    },
}
</script>

<style>

</style>