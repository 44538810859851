<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-15T13:33:16+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T14:24:05+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>   
        <CardHeader>
            <template #title>
                <span class="text-dark">Job Skills</span>
            </template>
            <template #buttons>
                <!-- buttons for save and delete -->
                <b-button @click="saveTable" size="sm" variant="primary" v-if="changes_made_flag" right class="me-2 dropdown-menu-end">
                    <strong>Save Table Changes</strong>
                </b-button>
                <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="delete_array.length > 0" right class="me-2 dropdown-menu-end">
                    <strong>Delete Selected Rows</strong>
                </b-button>
                <TableActionButton
                    :currentMode="table_view_mode"
                    :actions="['edit', 'delete', 'view']"
                    @updateMode="updateViewMode"
                    />
            </template>
        </CardHeader>
            <!-- Flip -->
            <div class="">
              <div>
                <!-- flip front -->
                <div v-show="flip" class="" style="text-align: left;">
                    <b-alert v-if="!edit_skills" :show="skills.length == 0">No Skills Added</b-alert>
                    <Table
                    v-if="edit_skills == false"
                    :uid="table_uid" 
                    :active_id="activeSkillId"
                    :is_loading="is_loading"
                    :data="skills"
                    :columns="columns"
                    :table_mode="table_view_mode"
                    :pager_config=5
                    :validation="table_validation_rules"
                    :prevent_first_row_select="true"
                    @selectedRowEvent="selectedRowEvent"
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                    />
                </div>
                <!-- flip back -->
                <div class="" v-if="!flip">
                  <!-- Guideline -->
                  <Guideline
                    v-if="showGuideline"
                    ref="guideline"
                    :cardName="'PlanOverview'"
                    :isEditGuideline="false"
                  />
                </div>
              </div>
            </div>
    </div>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  adminComputed,
} from "@/state/helpers";
//import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from 'lodash';
import Swal from "sweetalert2";
import CardHeader from '@/components/widgets/cardHeader.vue'
import Table from '@/components/widgets/table.vue'
import TableActionButton from '@/components/widgets/tableActionsButton'
export default {
    name: "Admin",
    components: {
        //FilterComponent,
        Guideline,
        Table,
        CardHeader,
        TableActionButton
    },
    data:() => ({
        
        changes_made_flag: false,
        //* props for webix table
        table_uid: 'candidateSkillsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        is_loading: false,

        edit_skills: false,


        searchText: "",
        searchDate: "",
        resetFilter: "",
        isEditingNew: false,
        flip: true,
        showGuideline: false,
        editableId: -1,
        skillsData: [],
        addingRow: false,
        tableFields: [
            { key: "skill_name", label: "Skill"},
            { key: "proficiency_achieved", label: "Proficiency Achieved"},
            { key: "interviewer_user_id", label: "Interviewer"},
            { key: "comment", label: "Comment"},
        ],
        statusOptions: [
            {value: 10, text: "10%"},
            {value: 25, text: "25%"},
            {value: 50, text: "50%"},
            {value: 75, text: "75%"},
            {value: 100, text: "100%"}
        ]
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        isViewMode(){
            if(this.table_view_mode != 'view'){
                return false;
            }
            else{
                return true
            }
        },
        isDeleteMode(){
            if(this.table_view_mode != 'delete'){
                return false;
            }
            else{
                return true
            }
        },
        isEditMode(){
            if(this.table_view_mode != 'edit'){
                return false;
            }
            else{
                return true
            }
        },
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            console.log('ACTIVE JOB data', data)
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            console.log('Candidate data', data)
            return data;
        },
        hiring_job_details(){
            let data = null;
            let index = _.findIndex(this.companyJobs, { id : this.active_job_data.job_id});
            if(index != -1){
                data = this.companyJobs[index];
            }
            console.log('HIRING JOB DETAILS', data)
            return data;
        },
        skills(){
            let data = [];
            if(this.active_candidate_data != null){
                data = _.cloneDeep(this.active_job_data.skills);
                data.forEach( item => {
                    //this.active_job_data.candidates.forEach( person => {
                        if(this.active_candidate_data.skills != null){
                            this.active_candidate_data.skills.forEach( skill => {
                                if(item.id == skill.skill_id){
                                    item['proficiency_achieved'] = skill.proficiency_achieved;
                                    item['interviewer_user_id'] = skill.interviewer_user_id;
                                    item['save_id'] = skill.id;
                                }
                            })
                            if(item.proficiency_achieved == undefined){
                                item['save_id'] = -1;
                            }
                        }
                })
            }

            return data;
        },
        job_skills(){
            let data = [];
            if(this.active_job_data != null){
                //data = this.active_candidate_data.skills
                data = this.active_job_data.skills;
                //let index = _.findIndex
            }
            return data;
        },
        columns(){
            return [
                { id: 'skill_name', header: 'Skill Name', readonly: true, fillspace: 1.7,},
                { id: 'proficiency_achieved', header: 'Proficiency (0% - 100%)', fillspace: 1.2, editor: "text", format:function(value){
                    if(value === ''){
                        return value
                    }
                    else{
                        return value +'%';
                    }
                }},
                { id: 'interviewer_user_id', header: 'Interviewer', fillspace: 1, editor: "combo", options: this.userList},
                { id: 'skill_description', header: 'Description', fillspace: 1.5, readonly: true},
            ]
        },
        edit_columns(){
            return [
                { id: 'skill_name', header: 'Skill Name', fillspace: 1, editor: "text",},
                { id: 'skill_description', header: 'Description', fillspace: 4, editor: "text",},
            ]
        },
        table_validation_rules(){
            return {
                "skill_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "proficiency_achieved": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(isNaN(item) == false){
                                return true;
                            }
                            else{
                                return false;
                            }
                            
                        }
                    }
                },
                "interviewer_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "skill_description": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },
        table_validation_rules_2(){
            return {}
        },
        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },
        storeHiringData() {
            return this.levelPeopleData.hiring;
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            return "";
        },
        activeJob() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex];
            }
            return [];
        },
        storeSkillsData() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringPositionId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex].skills;
            }
            return [];
        },
        activeCandidate() {
            var el = this;
            var findIndex = _.findIndex(this.activeJob.candidates, function(o) { return o.id == el.activeCandidateId; });
            if (findIndex > -1) {
                return this.activeJob.candidates[findIndex];
            }
            else{
                return null;
            }
            //return [];
        },
        userList(){
            //* return a list of users with their name, email and id (the dropdown column to
            //* search users will search the value and email fields)
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
    },
    watch: {
        activeHiringPositionId() {
            this.$nextTick(() => {
                this.mapSkillsData();
            });
        },
        storeHiringData() {
            this.$nextTick(() => {
                this.mapSkillsData();
            });
        },
        activeCandidateId() {
            this.$nextTick(() => {
                this.mapCandidateSkills();
            });
        },
        
    },
    methods:{
        ...peopleMethods,
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            this.table_view_mode = 'delete';
        },
        toggleViewMode(){
            this.table_view_mode = 'view';
        },
        //* table events start -----
        selectedRowEvent(row){
            //if(row.id != this.activeCandidateId){
                //* Event returns the selected row
                this.setActiveSkillId(row.id)
            //}
            
            //this.selected_candidate = row.id;
            //this.$eventHub.$emit('setActiveCandidate', this.selected_candidate);
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table events end ------
        saveTable(){
            if(this.edit_skills){
                this.saveJobSkills();
            }
            else{
                this.saveCandidateSkills();
            }
        },
        saveJobSkills(){
            let table = window.webix.$$(this.table_uid);
            table.editStop();
            let is_valid = true;
            this.skills.forEach( item => {
                let result = table.validate(item.id);
                if(result != true){
                    is_valid = false;
                }
            });
            if(is_valid){
                this.is_loading = true;
                const all_data = table.serialize(null, true);
                let rows_to_save = [];
                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        var params = {
                            id: newRow ? -1 : row.id,
                            skill_name: row.skill_name,
                            skill_description: row.skill_description,
                        };
                        rows_to_save.push(params);
                    }
                })

                let skills_params = {
                    job_id: this.activeHiringPositionId,
                    skills: rows_to_save,
                }
                this.saveJobSkill(skills_params)
                .then(()=>{
                    this.loadHiringData(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.changes_made_flag = false;
                        this.edit_skills = false;
                    })
                });
            }
        },
        saveCandidateSkills(){
            return new Promise((resolve, reject)=>{
                let table = window.webix.$$(this.table_uid);
                table.editStop();
                let is_valid = true;
                this.skills.forEach( item => {
                    let result = table.validate(item.id);
                    if(result != true){
                        is_valid = false;
                    }
                });
                if(is_valid){
                    this.is_loading = true;
                    const all_data = table.serialize(null, true);
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        console.log(row)
                        if(!row.is_empty){
                            let newRow = row.is_new;
                            var params = {
                                skill_id: newRow ? -1 : row.id,
                                skill_name: row.skill_name,
                                skill_description: row.skill_description,
                                job_id: this.activeHiringPositionId,
                                proficiency_achieved: row.proficiency_achieved,
                                interviewer_user_id: row.interviewer_user_id,
                                comment: "",
                            };
                            rows_to_save.push(params);
                        }
                    })

                    let skills_params = {
                        candidate_id: this.activeCandidateId,
                        skills: rows_to_save,
                    }
                    this.saveCandidateSkill(skills_params)
                    .then(()=>{
                        this.loadHiringData(this.selected_level.id)
                        .then(()=>{
                            this.is_loading = false;
                            this.changes_made_flag = false;
                            this.toggleViewMode();
                            resolve();
                        })
                        .catch(()=>{
                            reject();
                        })
                    })
                    .catch(()=>{
                        reject();
                    })
                }
                else{
                    reject();
                }
            })
        },


        mapSkillsData() {
            this.skillsData = this.storeSkillsData.map(item => ({...item, isEdit: false, proficiency_achieved: "", interviewer_user_name: "", comment: "" }));
            if (this.activeCandidateId > -1) {
                this.mapCandidateSkills();
            }
        },
        mapCandidateSkills() {
            var el = this;
            var findIndex;
            _.forEach(el.skillsData, function(value, index) {
                findIndex = _.findIndex(el.activeCandidate.skills, function(o) { return o.skill_id == value.id; });
                if (findIndex > -1) {
                    el.skillsData[index]['proficiency_achieved'] = el.activeCandidate.skills[findIndex].proficiency_achieved;
                    el.skillsData[index]['interviewer_user_id'] = el.activeCandidate.skills[findIndex].interviewer_user_id;
                    //el.skillsData[index]['interviewer_user_name'] = el.activeCandidate.skills[findIndex].interviewer_user_name;
                    el.skillsData[index]['comment'] = el.activeCandidate.skills[findIndex].comment;
                } else {
                    el.skillsData[index]['proficiency_achieved'] = "";
                    el.skillsData[index]['interviewer_user_id'] = "";
                    //el.skillsData[index]['interviewer_user_name'] = "";
                    el.skillsData[index]['comment'] = "";
                }
            });
        },
        renderUserName(id) {
            var findIndex = _.findIndex(this.userList, function(o) { return o.value == id; });
            if (findIndex > -1) {
                return this.userList[findIndex].text;
            }
            return "";
        },
        setRowActive(data) {
            if (data[0] && data[0].id) {
                this.editableId = data[0].id;
                //this.$eventHub.$emit('setActiveSkill', this.editableId);
            }
        },
        editRow() {
            var el = this;
            var findIndex = _.findIndex(this.skillsData, function(o) { return o.id == el.editableId; });
            if (findIndex > -1) {
                this.skillsData[findIndex].isEdit = !this.skillsData[findIndex].isEdit;
            }
        },
        addRow() {
            var rowData = {
                id: -1,
                job_id: this.activeHiringPositionId,
                skill_name: "",
                skill_description: "",
                proficiency_achieved: "",
                interviewer_user_id: "",
                comment: "",
                isEdit: true
            };
            this.cancelEdit();
            this.addingRow = true;
            this.skillsData.push(rowData);
        },
        cancelEdit() {
            var el = this;
            //eslint-disable-next-line no-unused-vars
            this.addingRow = false;
            _.forEach(this.skillsData, function(value, index) {
               el.skillsData[index].isEdit = false;
            });
        },
        saveRow() {
            var findIndex = _.findIndex(this.skillsData, function(o) { return o.isEdit == true; });
            if (findIndex > -1) {
                //save the evidence row
                //if editing candidate then save candidate performance
                this.saveJobSkill(this.skillsData[findIndex]).then((id) => {
                    if (this.activeCandidateId > -1) {
                        this.skillsData[findIndex]["skill_id"] = id;
                        this.skillsData[findIndex]["candidate_id"] = this.activeCandidateId;
                        this.saveCandidateSkill(this.skillsData[findIndex]).then(() => {
                            this.loadPeopleData(this.selected_level.id);
                            this.addingRow = false;
                        });
                    } else {
                        this.loadPeopleData(this.selected_level.id);
                    }
                });
            } else {
                alert("no rows being edited");
            }
        },
        deleteRow() {
            Swal.fire({
                title: "Are you sure you want to delete this skill?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    var params = {skill_id: this.editableId};
                    this.deleteJobSkill(params).then(() => {
                        this.loadPeopleData(this.selected_level.id);
                    });
                }
            });
        }

    },
    mounted(){
        /*this.$nextTick(()=>{
            alert('Active job id: ' + this.activeHiringId)
            this.mapSkillsData();
        })*/
    }
}
</script>
