<template>
    <!-- //TODO create child components from both b-tabs -->
        <div>
            <div v-if="user != null">
                <div v-if="$hasPerm('manage-permissions')" title="Permissions" lazy>
                    <PermissionsTable v-if="hasMounted"/>
                </div>
            </div>
        </div>
    </template>
    
    <script>
    import _ from 'lodash';
    import {
        levelComputed,
        levelMethods,
        peopleMethods,
        peopleComputed,
        adminComputed,
        adminMethods,
        profileComputed,
        profileMethods
    } from "@/state/helpers";
    import { format, parseISO } from "date-fns";
    import 'vue-advanced-cropper/dist/style.css';
    import Swal from 'sweetalert2';
    import PermissionsTable from '../components/employees/permissionsTab.vue'
    export default {
        components: {
            PermissionsTable,
        },
        props: {
            activeEmployeeId: {
                required: true
            },
            existing_user:{
                default: false,
            },
            hideUpdateButton:{
                default: false,
            },
            hideEdit:{
                default: false
            }
        },
        data:() => ({
            show_modal: false,
            user: null, //* old -- user: {}
            user_display_name: '', //copy of name and surname (fixes display name changing when editing first/last name)
            firstName: '',
            lastName: '',
            employmentDate: '',
            report_to: '',
            is_loading_job: false,
            assets_table_view_mode: '',
            is_loading: false,
            is_saving_avatar: false,
            is_saving_user: false,
            leave_changes_made_flag: false,
            remuneration_changes_made_flag: false,
            assets_changes_made_flag: false,
    
            hasMounted: false,
            active_collapse_index: 1,
    
            selectedImage: "",
            hoverOverImg: false,
    
            editJobDetails: false,
            edit_current_job:null,
    
            block_form_watcher: false, //* block watcher from firing when the user object is updated with new user data
            form_data_changed: false,//* true when form input fields change
    
            typeahead_query: '',
    
            leave_dropdown_options:[
                { id: 'annual', value: 'Annual'},
                { id: 'maternity', value: 'Maternity'},
                { id: 'sick', value: 'Sick'},
                { id: 'family_responsibility', value: 'Family Responsibility'},
                { id: 'study', value: 'Study'},
                { id: 'religious', value: 'Religious'},
                { id: 'personal', value: 'Personal'},
            ],
    
            full_remuneration_dropdown_options:[
                    { id: 'salary', value: 'Salary'},
                    { id: 'overtime', value: 'Overtime'},
                    { id: 'sick', value: 'Sick'},
                    { id: 'bonus_payment', value: 'Bonus Payment'},
                    { id: 'commission', value: 'Commission'},
            ]
    
        }),
        watch: {
            activeEmployeeId() {
                //* update the active user data when the activeEmployeeId changes
                this.$nextTick(() => {
                    this.setActiveUser()
                });
            },
            active_collapse_index(newValue){
                if(newValue == 2){
                    //* refresh job history table
                    window.webix.$$(this.table_uid).refresh();
                }
                else if(newValue == 3){
                    //* regresh leave/assets.. tables
                    window.webix.$$(this.table_leave_uid).refresh();
                    window.webix.$$(this.table_assets_uid).refresh();
                    window.webix.$$(this.table_remuneration_uid).refresh();
                }
            },
            user:{
                deep: true,
                handler(){
                    if(this.block_form_watcher == false){
                        this.form_data_changed = true;
                    }
                }
            },
        },
        computed:{
            ...levelComputed,
            ...peopleComputed,
            ...adminComputed,
            ...profileComputed,
            storeEmployeesData() {
                return this.levelEmployeesData.employees;
            },
            variable_watchers(){
                var el = this;
                return {
                    leave_type(newVal, oldVal, id, is_new_row){
                        let names = []
                        window.webix.$$(el.table_leave_uid).data.each(function(obj){ 
                            console.log(obj);
                            if(!obj.is_empty && !obj.is_new) {
                                names.push(obj.leave_type);
                            }
                        });
                        if(is_new_row){
                            if(names.includes(newVal)) {
                                let item = window.webix.$$(el.table_leave_uid).data.getItem(id);
                                item.leave_type = ""
                                window.webix.$$(el.table_leave_uid).data.updateItem(item.id, item)
                                //window.webix.$$(el.table_leave_uid).data.remove(id);
                                
                                el.$swal.fire('The leave type already exists');
                                window.webix.$$(el.table_leave_uid).refresh()
                            }
                        } else {
                            let duplicates = _.uniq(names).length !== names.length; 
                            if(duplicates) {
                                let item = window.webix.$$(el.table_leave_uid).data.getItem(id);
                                item.leave_type = ""
                                window.webix.$$(el.table_leave_uid).data.updateItem(item.id, item)
                                //window.webix.$$(el.table_leave_uid).data.remove(id);
                                
                                el.$swal.fire('The leave type already exists');
                                window.webix.$$(el.table_leave_uid).refresh()
                            }
                        }
                    },
                }
            },
            activeEmployee() {
                var el = this;
                var findIndex = _.findIndex(this.storeEmployeesData, function(o) { return o.id == el.activeEmployeeId; });
                if (findIndex > -1) {
                    return this.storeEmployeesData[findIndex];
                }
                return {};
            },
            active_employee_data() {
                let data = null;
                if(this.activeEmployeeId != -1){
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                    if(index != -1){
                        data = this.levelEmployeesData[index];
                    }
                }
                return data;
            },
            isSuperUser(){
                let allowed = this.$hasUserRole('Power User');
                if(allowed == false){
                    return this.canEditProfile;
                }
                else{
                    return '';
                }
            },
            canEditProfile(){
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return '';
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return '';
                }
                //else
                return 'block-click'
            },
            columns() {
                let el = this;
                return [
                    { id: 'title', header: 'Job Title', fillspace: 1,
                        template(object){
                            console.log(object)
                            if(object == undefined){
                                let name = el.getJobByID(object.id);
                                return name;
                            }
                            else {
                                return object.title;
                            }
                            
                        }
                    },
                    { id: 'start_date', header: 'Start Date', fillspace: 1,
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                        /*template(obj) {
                            //* format the date
                            if(obj.start_date == undefined){
                                //* add a date to new rows without a date to fix undefined date error
                                let date = new Date();
                                return format(date, 'dd-MMM-yyyy');
                            }
                            let date = obj.start_date;
                            //* only parse date if it is in string form
                            if(typeof obj.start_date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }*/
                    },
                ]
            },
            leave_columns() {
                let el = this
                return [
                    { id: 'leave_type', header: 'Leave', fillspace: 1, template:function(obj) {
                        console.log(obj);
    
                        if(el.$refs[el.table_leave_uid].table_mode == 'edit'){
                            if(!obj.is_empty) {
                                let value = el.leave_dropdown_options.find(o => o.id === obj.leave_type);
                                return value.value;
                            } else {
                                return ''
                            }
                        }
                        else{
                            //let object = obj.leave_type
                            //let value = el.leave_dropdown_options[obj.leave_type]
                            let value = el.leave_dropdown_options.find(o => o.id === obj.leave_type);
                            if(obj.used != 0 && !obj.is_empty) {
                                return value.value;
                            } else {
                                return ``
                            }
                            
                        }
                    }},
                    { id: 'available', header: 'Available', fillspace: 1, editor: 'text', template:function(obj){
                        if(obj.available != 0) {
                            return obj.available
                        } else {
                            if(el.$refs[el.table_leave_uid].table_mode == 'edit') {
                                return obj.available
                            } else {
                                return ``
                            }
                        }
                    }},
                    { id: 'used', header: 'Used', fillspace: 1, editor: 'text', template:function(obj){
                        if(obj.used != 0) {
                            return obj.used
                        } else {
                            if(el.$refs[el.table_leave_uid].table_mode == 'edit') {
                                return obj.used
                            } else {
                                return ``
                            }
                            
                        }
                    }},
                ]
            },
            getJobTitle(){
                let title = '';
                let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                if(index != -1){
                    title = this.companyJobs[index].title;
                }
                else{   
                    title = 'Job not found'
                }
                return title;
            },
            hasValidJob(){
                if(this.user != undefined){
                    let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                    console.log(this.companyJobs);
                    if(index == -1){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return false;
                }
            },
        },
        methods:{
            ...adminMethods,
            ...peopleMethods,
            ...levelMethods,
            ...profileMethods,
            showReloadingPrompt(user_name, archived = false){
                let msg = archived ? 'Archived user' : 'Restored'
                Swal.fire({
                    title: `${msg}: ${user_name}`,
                    text: 'Reloading data.',
                    timer: 5000,
                    timerProgressBar: true,
                    icon: "success",showConfirmButton: false,
                }).then(()=>{
                    this.$router.go();
                })
            },
            formatStartDate(date){
                console.log('DATE', date)
                if(date == null){
                    return ''
                }
                if(date !== ''){
                    if(typeof date === 'string'){
                        date = parseISO(date);
                    }
                    return format(date, 'dd-MMM-yyyy');
                }
                else{
                    return '';
                }
            },
            updateUser() {
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                this.updateEmployee(this.user)
                .then(()=>{
                    if(Number(this.core.id) == Number(this.selected_user_id)){
                        this.loadCoreData();
                    }
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.loadLevels();
                        //refresh owner users for datatable columns
                        this.loadAllUsers();
                        //refrrsh users for current level
                        this.loadMenuUsers(this.selected_level.id);
                        if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            updateJobDetails(){
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                let updatedUser = _.cloneDeep(this.user);
                updatedUser.current_job = _.cloneDeep(this.edit_current_job);
                this.updateEmployee(updatedUser)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        /*if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }*/
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                        this.editJobDetails = false;
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            returnOnClickMethods(){
                let onClick = {
                    //eslint-disable-next-line
                    'unlink_button':function(e, id){
                        console.log('On button click', id);
                    }
                };
                return onClick;
            },
            setActiveUser(){
                this.form_data_changed = false;
                //* moved this block of code from the 
                //* activeEmployeeId watcher (method used in activeEmployeeId watcher and mounted hook)
                if (this.active_employee_data != null) {
                    this.block_form_watcher = true;
                    this.user = _.cloneDeep(this.active_employee_data);
                    this.user_display_name = this.user.name + ' ' + this.user.last_name
                    this.firstName = this.user.name
                    this.lastName = this.user.last_name
                    this.employmentDate = this.user.employment_date
                    this.progress_status = 70
                    let user = this.all_users.find( person => { return person.id == this.reports_to_user_id; })
                    console.log("!!!!!")
                    console.log(JSON.parse(JSON.stringify(this.user)));
    
                    if(user) {
                        this.report_to = user.name
                    }
                    console.log(JSON.parse(JSON.stringify(this.user)))
                        if(this.user.current_job == undefined){
                            this.user['current_job'] = {}
                        }
                        this.user.level_id = this.user.pivot.level_id;
                        this.$nextTick(()=>{
                            this.block_form_watcher = false;
                            this.hasMounted = true;
                        })
                       
                    //* fix issue with older user where user.current_job == null
                    
                }
            },
            saveFromWizardEvent(){
                this.updateUser();
            },
            handleUpdatedUserLevels(){
                let promises = [
                    this.loadEmployeesData(this.selected_level.id),
                    this.loadMenuUsers(this.selected_level.id)
                ]
                Promise.all(promises)
                .then(()=>{
                    let index = _.findIndex(this.levelEmployeesData, { id: this.selected_user_id})
                    if(index != -1){
                        this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                        this.setActiveUser();
                    }
                    else{
                        this.setSelectedUser(null);
                    }
                })
            },
            editUserJobDetails(){
                this.edit_current_job = _.clone(this.user.current_job)
                this.editJobDetails = true;
            },
    
            isUserManager(){
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id) ;
                if(is_editing_as_manager){
                    return '';
                }
                return 'block-click';
            },
            hasValidPerms(){
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return true;
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return true;
                }
                return false;
            },
            debugInput(){
    
            },
        
            changedJob(){
                this.is_loading_job = true;
                this.loadMenuUsers(this.selected_level.id)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                        .then(()=>{
                            this.setActiveUser();
                            this.is_loading_job = false;
                    })
                })
            },
            showDiscardPrompt(tableName,newMode){
                Swal.fire({
                    title: `Clear unsaved ${tableName} changes?`,
                    text: "Warning, changes will be deleted.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.table_view_mode = newMode;
                    }
                });
            },
            getJobByID(id){
                let job_name = 'Invalid Job'
                let index = _.findIndex(this.companyJobs, {id: id});
                if(index != -1){
                    job_name = this.companyJobs[index].title;
                }
                return job_name;
            }
        },
        
        mounted(){
            if(this.user == null){
                //* used v-if to show this component
                //* user will be null on load so we need to set the active user data
                //! could also use the activeEmployeeId watcher with the 'immediate' flag set to true 
                this.setActiveUser();
            }
    
            this.$eventHub.$on('saveUserFromWizard', this.saveFromWizardEvent);
            this.$eventHub.$on('updatedUserLevels', this.handleUpdatedUserLevels)
        },
        beforeDestroy(){
            this.$eventHub.$off('saveUserFromWizard');
            this.$eventHub.$off('updatedUserLevels');
        }
    }
    </script>
    
    <style>
    .userForm > .row {
        margin-bottom: 1rem;
    }
    .userForm > .row > .col-2 {
        padding-top: 0.55rem;
    }
    .header-profile-user-view{
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #f6f6f6;
        padding: 3px;
    }
    
    
    .hoverEditIcon{
        cursor: pointer;
        position: absolute;
            bottom: 5px;
            right: 5px;
    }
    </style>