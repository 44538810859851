<template>
    <div>
        <Table
        uid="combinedDatasetTable"
        :prevent_first_row_select="true"
        :data="available_graphs"
        :columns="columns"
        :is_loading="is_loading"
        :pager_config="10"
        :variable_watchers="variable_watchers"
        />

        <b-alert v-if="false" :show="areSomeGraphsUnlinkable">
            One or more graphs are unavailable for linking.
            <ul class="mb-0">
                <li>
                    Unit type mismatch
                </li>
                <li>
                    Graph start/end date range doesn't match the parent graph's start/end range
                </li>
            </ul>
        </b-alert>
        <b-overlay :show="is_loading" no-wrap ></b-overlay>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/widgets/table/table.vue'
import { activityComputed, activityMethods, levelComputed, performanceComputed, performanceMethods } from '@/state/helpers'
import Swal from 'sweetalert2';
export default {
    components:{
        Table,
    },
    props:{
        is_kpi:{
            required: false,
            default: false,
        },
        is_metric: {
            required: false,
            default: false,
        },
        share_type:{
            default: null,
        }
    },
    data:() => ({
        calculation_type: '', // sum, product, average,
        is_loading: false,
    }),
    watch:{
        selected_target(new_val){
            if(new_val != -1){
                this.initTarget();  
            }
            
        }
    },
    computed:{
        ...performanceComputed,
        ...activityComputed, ...levelComputed,
        activeUserId() {
            if (this.selected_user_id != null && this.selected_user_id != undefined) {
                //* return user id that was selected on the left menu
                return this.selected_user_id;
            } else {
                return -1;
                //return this.core.id;// return logged in user
            }
        },
        available_graphs(){
            if(!this.avaliable_shared_graphs){
                return [];
            }
            let data = this.avaliable_shared_graphs.map((row) => {
                    return {
                        //...row,//merge exisiting fields
                        id: row.id,
                        name: row.name,
                        owner_name: row.owner_name,
                        unit: this.findUnitByID(row.unit_type_id),
                        selected: row.already_linked || row.already_linked_multi_line,
                        linkable: row.linkable,
                        share_type: row.share_type,
                        multi_line: row.multi_line,
                    }
                });
            data = _.orderBy(data, ['selected', 'linkable'], ['desc', 'desc']);
            return data;
        },
        variable_watchers(){
            //let el = this;
            return {
                //eslint-disable-next-line
                selected(newVal, oldVal, id){
                    console.log(id, newVal);
                }
            }
        },
        columns(){
            return [
                { id: 'selected', checkValue:true, uncheckValue: false, template:function(obj, common){
                    if(obj.is_empty == undefined && obj.linkable == true){
                        return common.checkbox(obj,common, obj.selected, {checkValue:true, uncheckValue: false})
                    }
                    else{
                        return '';
                    }
                }, width: 40, header: '' },
                { id: 'name', header: 'Name', fillspace: 1 },
                { id: 'owner_name', header: 'Owner', fillspace: 1 },
                { id: 'unit', header: 'Unit Type', fillspace: 1 },
            ]
        },
        areSomeGraphsUnlinkable(){
            //find any graph that has linkable = false, if a graph is found then there are unlinkable graphs in the array
            let test_object = this.available_graphs.find( graph => { graph.linkable == false });
            return test_object ? false : true
        }
    },
    methods:{
        ...performanceMethods,
        ...activityMethods,
        //eslint-disable-next-line no-unused-vars
        //checkboxCheckEvent({ rowID, colID, state}){
        //    alert(`${rowID} ${colID} ${state}`)
        //},
        updateCalculationMode(type){
            this.calculation_type = type;
        },
        saveSharedTargetDataset(){
            if(this.calculation_type == null || this.calculation_type == ''){
                Swal.fire('Please select a calculation type');
                return;
            }
            this.is_loading = true;
            //* Get all the rows that are checked via the checkbox ('selected' property)
            let table = window.webix.$$('combinedDatasetTable')
            let data = table.serialize(true);
            data = data.filter( item => item.is_empty == undefined && item.selected)

            //* multiselect get's its own endpoint - sum, average product use the same
            if(this.calculation_type == 'multi-line'){
                let params = {
                    linked_ids:[],
                    parent_id: this.selected_target
                }
                data.forEach( item => {
                    params.linked_ids.push(item.id);
                })
                this.is_loading = true;
                this.saveMultilineTargetGraph(params)
                .then(()=>{
                    this.$emit('updated');
                    this.$nextTick(()=>{
                        this.is_loading = false;
                        this.$emit('savedChanges');
                    })
                    //this.refreshKeyResults();
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                //* Check if old share type is multi-line, then just save an empty multiline share type
                //* this.share_type is a prop from the parect component containing the original type
                if(this.share_type == 'multi-line'){
                    let params = {
                        linked_ids:[],
                        parent_id: this.selected_key_result.id
                    }
                    this.saveMultilineTargetGraph(params);
                }
                let params = {
                    "parent_target_id": this.selected_target,
                    "share_type": this.calculation_type,
                    "graphs" : []
                };
                //* if no graphs are selected, save share type as null - abort mission!!
                if(data.length == 0){
                    params.share_type = null;
                }
                else{
                    data.forEach( item => {
                        params.graphs.push({ 'linked_target_id': item.id });
                    })
                }
                this.is_loading = true;
                this.saveSharedTargetGraph(params)
                .then(()=>{
                    this.$emit('updated');
                    this.$nextTick(()=>{
                        this.is_loading = false;
                        this.$emit('savedChanges');
                    })
                    //this.refreshKeyResults();
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        saveSharedKeyResultDataset(){
            if(this.calculation_type == null || this.calculation_type == ''){
                Swal.fire('Please select a calculation type');
                return;
            }
            this.is_loading = true;
            //* Get all the rows that are checked via the checkbox ('selected' property)
            let table = window.webix.$$('combinedDatasetTable')
            let data = table.serialize(true);
            data = data.filter( item => item.is_empty == undefined && item.selected)

            //* multiselect get's its own endpoint - sum, average product use the same
            if(this.calculation_type == 'multi-line'){
                let params = {
                    linked_ids:[],
                    parent_id: this.selected_key_result.id
                }
                data.forEach( item => {
                    params.linked_ids.push(item.id);
                })
                this.is_loading = true;
                this.saveMultilineKeyResultGraph(params)
                .then(()=>{
                    this.refreshKeyResults();
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                //* Check if old share type is multi-line, then just save an empty multiline share type
                //* this.share_type is a prop from the parect component containing the original type
                if(this.share_type == 'multi-line'){
                    let params = {
                        linked_ids:[],
                        parent_id: this.selected_key_result.id
                    }
                    this.saveMultilineKeyResultGraph(params);
                }
                let params = {
                    "objective_main_key_result_id": this.selected_key_result.id,
                    "share_type": this.calculation_type,
                    "graphs" : []
                };
                //* if no graphs are selected, save share type as null - abort mission!!
                if(data.length == 0){
                    params.share_type = null;
                }
                else{
                    data.forEach( item => {
                        params.graphs.push({ 'objective_linked_key_result_id': item.id });
                    })
                }
                this.is_loading = true;
                this.saveSharedKeyResultGraph(params)
                .then(()=>{
                    this.refreshKeyResults();
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        saveSharedKpiDataset(){
            if(this.calculation_type == null || this.calculation_type == ''){
                Swal.fire('Please select a calculation type');
                return;
            }
            this.is_loading = true;
            //* Get all the rows that are checked via the checkbox ('selected' property)
            let table = window.webix.$$('combinedDatasetTable')
            let data = table.serialize(true);
            data = data.filter( item => item.is_empty == undefined && item.selected)
            
            //* check if saving a multi-line graph or sum type graphs
            if(this.calculation_type == 'multi-line'){
                let params = {
                    linked_ids:[],
                    parent_id: this.selected_key_performance_indicator.id
                }
                data.forEach( item => {
                    params.linked_ids.push(item.id);
                })
                this.is_loading = true;
                this.saveMultilineKPIGraph(params)
                .then(()=>{
                    this.refreshKpis().then(()=>{
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                // * other summing modes
                let params = {
                    "parent_kpi_id": this.selected_key_performance_indicator.id,
                    "share_type": this.calculation_type,
                    "graphs" : [
                        /*{"objective_linked_key_result_id": 122},*/
                    ]
                };
                //* if no graphs are selected, save share type as null - abort mission!!
                if(data.length == 0){
                    params.share_type = null;
                }
                else{
                    data.forEach( item => {
                        params.graphs.push({ 'linked_kpi_id': item.id });
                    })
                }
                this.is_loading = true;
                this.saveSharedKpiGraph(params)
                .then(()=>{
                    this.refreshKpis().then(()=>{
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        saveSharedMetricDataset(){
            if(this.calculation_type == null || this.calculation_type == ''){
                Swal.fire('Please select a calculation type');
                return;
            }
            this.is_loading = true;
            //* Get all the rows that are checked via the checkbox ('selected' property)
            let table = window.webix.$$('combinedDatasetTable')
            let data = table.serialize(true);
            data = data.filter( item => item.is_empty == undefined && item.selected)
            //* check if saving a multi-line graph or sum type graphs
            if(this.calculation_type == 'multi-line'){
                let params = {
                    linked_ids:[],
                    parent_id: this.selected_metric.id
                }
                data.forEach( item => {
                    params.linked_ids.push(item.id);
                })
                this.is_loading = true;
                this.saveMultilineMetricGraph(params)
                .then(()=>{
                    this.refreshMetrics()
                    .then(()=> {
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                let params = {
                    "parent_metric_id": this.selected_metric.id,
                    "share_type": this.calculation_type,
                    "graphs" : [
                        /*{"objective_linked_key_result_id": 122},*/
                    ]
                };
                //* if no graphs are selected, save share type as null - abort mission!!
                if(data.length == 0){
                    params.share_type = null;
                }
                else{
                    data.forEach( item => {
                        params.graphs.push({ 'linked_metric_id': item.id });
                    })
                }
                this.is_loading = true;
                this.saveSharedMetricGraph(params)
                .then(()=>{
                    this.refreshMetrics()
                    .then(()=> {
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        removeSharedDataset(){
            if(this.is_kpi){
                let params = {
                "parent_kpi_id": this.selected_key_performance_indicator.id,
                "share_type": null,
                "graphs" : []
                };
                this.is_loading = true;
                this.saveSharedKpiGraph(params)
                .then(()=>{
                    this.refreshKpis().then(()=>{
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else if(this.is_metric){
                let params = {
                "parent_metric_id": this.selected_metric.id,
                "share_type": null,
                "graphs" : []
                };
                this.is_loading = true;
                this.saveSharedMetricGraph(params)
                .then(()=>{
                    this.refreshMetrics().then(()=>{
                        this.$emit('closeComponent');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                let params = {
                    "parent_target_id": this.selected_target,
                    "share_type": null,
                    "graphs" : []
                };

                this.is_loading = true;
                this.saveSharedTargetGraph(params)
                .then(()=>{
                    this.is_loading = false;
                    this.$emit('updated');
                    this.$nextTick(()=>{
                        this.is_loading = false;
                        this.$emit('savedChanges');
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
        },
        findUnitByID(id){
            let unit = this.keyResultUnit.find( unit => {
                return unit.id == id;
            });
            if(unit){
                return unit.name;
            }
            else{
                'Type not found'
            }
        },
        setDisabledRowCss(){
            //setTimeout(()=>{
                let table = window.webix.$$('combinedDatasetTable');
                if(table){
                    this.available_graphs.forEach( item =>{
                        console.log(item)
                        if(item.linkable == false){
                            table.addCss(item.id, "webix-disabled-row", true);
                        }
                    })
                    table.refresh();
                }
            //}, 500)
        },
        handleSaveEvent(){
            this.saveSharedTargetDataset();
        },
        initTarget(){
            this.is_loading = true
            let payload = {
                target_id: this.getSelectedTarget.id,
                frequency: this.getSelectedTarget.current_time_period,
            }
            this.loadAvailableTargetDataset(payload)
            .then(()=>{
                this.is_loading = false;
                this.$nextTick(()=>{
                    this.setDisabledRowCss();
                })
            })
            // if(this.share_type != null){
            //     this.calculation_type = this.share_type;
            // }

            this.calculation_type = this.getSelectedTarget.share_type;
        },
        initKeyResult(){
            let payload = {
                key_result_id: this.selected_key_result.id,
                frequency: this.selected_key_result.current_time_period,
            }
            this.loadAvailableKeyResultDataset(payload)
            .then(()=>{
                this.is_loading = false;
                this.$nextTick(()=>{
                    this.setDisabledRowCss();
                })
            })

            this.calculation_type = this.selected_key_result.share_type;
        },
        initKPI(){
            let payload = {
                kpi_id: this.selected_key_performance_indicator.id,
                frequency: this.selected_key_performance_indicator.current_time_period,
            }
            this.loadAvailableKPIDataset(payload)
            .then(()=>{
                this.is_loading = false;
                this.$nextTick(()=>{
                    this.setDisabledRowCss();
                })
            })
        },
        initMetric(){
            let payload = {
                metric_id: this.selected_metric.id,
                frequency: this.selected_metric.current_time_period,
            }
            this.loadAvailableMetricDataset(payload)
            .then(()=>{
                this.is_loading = false;
                this.$nextTick(()=>{
                    this.setDisabledRowCss();
                })
            })
        },
        refreshKeyResults(){
            //this.loadActivityData(this.selected_level.id).then(()=>{
            //    let params = {
            //        id: this.selected_objective.id,
            //        user_id: this.selected_user_id == null ? -1 : this.selected_user_id, //* if no user is selected return -1
            //    }
            //    this.loadObjectiveDetailAPI(params)
            //    .then(()=>{
            //        //* emit that the data was updated
                    this.$emit('updated');
                    this.$nextTick(()=>{
                        this.$emit('savedChanges');
                    })
            //    })
            //})
            //.catch(e => { console.log(e)})
        },
        refreshKpis(){
            return new Promise((resolve, reject) => {
                let params = {
                    id:this.selected_objective.id,
                    user_id: this.selected_user_id == null ? -1 : this.selected_user_id,
                }
                this.loadObjectiveDetailAPI(params)
                .then(()=>{
                    window.webix.$$('keyResultsTable').select(this.selected_key_result.id)
                    this.is_loading = false;
                    resolve();
                })
                .catch(()=>{
                    this.is_loading = false;
                    reject();
                })
            })
            
        },
        
        refreshMetrics(){
            let params = {id:this.selected_objective.id, user_id: this.activeUserId}
            this.loadObjectiveDetailAPI(params)
            .then(()=>{
                this.is_loading = false;
                if(this.$refs[this.table_uid]){
                    this.$refs[this.table_uid].table_mode = 'view';
                }
            })
        }
    },
    mounted(){
        //this.is_loading = true;
        this.initTarget();
        

        
        
        

        this.$eventHub.$on('saveSharedDataset', this.handleSaveEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('saveSharedDataset');
        if(window.webix.$$('combinedDatasetTable')){
            window.webix.$$('combinedDatasetTable').destructor();
        }
    }
}
</script>

<style>
.webix-disabled-row{
    background: lightgray;
    cursor: not-allowed;
}
</style>