<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-15T10:18:04+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-15T12:23:21+02:00
@Copyright: Diversitude 2021
-->

<template>
    <b-overlay :show="is_loading">
        <b-card class="card-hover border border-grey" style="">
            <div class="d-flex flex-wrap mb-3" style="width: 100%;">
                <div class="me-2">
                  <h5 class="card-title mr-2 d-flex align-items-center">
                    Job Description
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                    <span class="text-info">{{ jobName }}</span>
                  </h5>
                </div>

                <!-- -- dropdown -- -->
                <b-dropdown
                  class="ms-auto"
                  text="Button text via Prop"
                  variant="white"
                  toggle-tag="a"
                  menu-class="dropdown-menu-end"
                  toggle-class="font-size-16 p-0"
                  right
                >
                  <template #button-content>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </template>

                  <b-dropdown-item
                    v-if="isEditing == true"
                    variant="danger"
                    href="#"
                    @click="cancelEdit()"
                    >Cancel</b-dropdown-item
                  >

                  <b-dropdown-item
                    v-if="isEditing == false"
                    variant="primary"
                    href="#"
                    @click="editDesc()"
                    >Edit</b-dropdown-item
                  >

                  <b-dropdown-item
                    v-if="isEditing == true"
                    variant="success"
                    href="#"
                    @click="saveDesc()"
                    >Save</b-dropdown-item
                  >
                </b-dropdown>
            </div>
            <!-- Flip -->
            <div class="">
              <div
                class=""
              >
                <!-- flip front -->
                <div v-show="flip" class="" style="text-align: left;">
                    <b-alert v-show="jobDesc.length == 0 && !isEditing" show>No Job Description Added</b-alert>
                    <transition name="fade">
                      <b-row>
                        <b-col cols="8">
                          
                            <b-form-textarea
                              v-show="jobDesc.length > 0 || isEditing"
                              :readonly="(isEditing ? false : true)"
                              id="field"
                              ref="field"
                              v-model="jobDesc"
                              no-resize
                              rows="7"
                              placeholder="Type text here"
                            ></b-form-textarea>
                        </b-col>
                        <b-col cols="4">
                          <b-row class="centro">
                            <b-col cols="4">
                              <div><h4>Basic From</h4></div>
                              <div><h5>R{{job_summary.from}}</h5></div>
                            </b-col>
                            <b-col cols="4">
                              <div><h4>Basic To</h4></div>
                              <div><h5>R{{job_summary.to}}</h5></div>
                            </b-col>
                            <b-col cols="4">
                              <div><h4>Bonus</h4></div>
                              <div><h5>R{{job_summary.bonus}}</h5></div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </transition>
                </div>
                <!-- flip back -->
                <div class="" v-if="!flip">
                  <!-- Guideline -->
                  <Guideline
                    v-if="showGuideline"
                    ref="guideline"
                    :cardName="'PlanOverview'"
                    :isEditGuideline="false"
                  />
                </div>
              </div>
            </div>
        </b-card>
    </b-overlay>
</template>
<script>
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  //adminComputed,
} from "@/state/helpers";
//import FilterComponent from "@/components/objectives/components/filterComponent.vue";
import Guideline from "@/components/objectives/components/guideline.vue";
import _ from 'lodash';
export default {
    name: "Admin",
    components: {
        //FilterComponent,
        Guideline
    },
    data:() => ({
        is_loading: false,
        searchText: "",
        searchDate: "",
        resetFilter: "",
        isEditing: false,
        flip: true,
        showGuideline: false,
        jobDesc: ""
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        //...adminComputed,
        levelName() {
          return this.selected_level
            ? this.selected_level.name
            : "No Level Selected";
        },

        active_job_data(){
            let data = null;
            if(this.activeHiringId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },

        job_summary(){
          let data = {
            from: '',
            to: '',
            bonus: ''
          }
          if(this.active_job_data != null){
            data.from = this.active_job_data.basic_from;
            data.to = this.active_job_data.basic_to;
            data.bonus = this.active_job_data.bonus;
          }

          return data;
        },

        
        storeHiringData() {
            return this.levelHiringData.hiring;
        },
        activeJob() {
            var el = this;
            var findIndex = _.findIndex(this.storeHiringData, function(o) { return o.id == el.activeHiringId; });
            if (findIndex > -1) {
                return this.storeHiringData[findIndex];
            }
            return {};
        },
        jobName() {
            if(this.active_job_data != null){
                return this.active_job_data.job_title;
            }
            else{
                return ''
            }
        }
    },
    props: {
        activeHiringId: {
            required: true
        }
    },
    watch: {
        activeHiringId() {
            this.$nextTick(() => {
              if(this.active_job_data != null){
                this.jobDesc = _.cloneDeep(this.active_job_data.job_description);
              }
              else{
                this.jobDesc = '';
              }
                //this.mapJobDesc();
            });
        }
    },
    methods:{
        ...peopleMethods,
        mapJobDesc() {
            var jobDescription = (this.activeJob.job_description == null ? "" : this.activeJob.job_description);
            this.jobDesc = jobDescription;
        },
        cancelEdit() {
            this.isEditing = false;
        },
        editDesc() {
            this.isEditing = true;
        },
        saveDesc() {
          this.is_loading = true;
            var params = {job_id: this.activeHiringId, job_description: this.jobDesc };
            this.saveJobDescription(params).then(() => {
                this.loadHiringData(this.selected_level.id);
                this.is_loading = false;
                this.cancelEdit();
            })
            .catch(()=>{
              this.is_loading = false;
            })
        }
    },
    mounted(){
        this.mapJobDesc();
    }
}
</script>

<style>
.centro{
  text-align: center;
}
</style>
