<template>
  <div>
    <!-- <h2 @click="tmp">hellp</h2> -->
    <b-form-textarea
      v-if="!isEditGuideline"
      id="field"
      ref="field"
      v-model="text"
      no-resize
      rows="7"
      readonly
      placeholder="No guildines available."
    ></b-form-textarea>
    <transition name="fade">
      <b-form-textarea
        v-if="isEditGuideline"
        id="field"
        ref="field"
        v-model="text"
        no-resize
        rows="7"
        placeholder="Type text here"
      ></b-form-textarea
    ></transition>
  </div>
</template>
<script>
import _ from "lodash";
import {
  activityMethods,
  levelComputed,
  adminComputed,
  adminMethods,
} from "@/state/helpers";
export default {
  props: {
    content: {
      type: String,
    },
    requestId: {
      type: Number,
    },
    cardName: {
      type: String,
    },
    isEditGuideline: { type: Boolean },
  },
  data() {
    return {
      text: "",
      type: {
        Vision: "vision_guideline",
        Mission: "mission_guideline",
        Objective: "objective_guideline",
        ObjectiveRisk: "objective_risk_guideline,",
        ObjectivePlan: "objective_plan_guideline",
        PlanOverview: "plan_overvieW_guideline",
        KeyResult: "key_result_guideline",
      },
    };
  },
  watch: {
    targetGuideline() {
      this.text = this.targetGuideline;
    },
    // content: function() {
    //   this.text = this.content;
    // },
  },
  methods: {
    ...adminMethods,
    ...activityMethods,
    tmp() {
      console.log(this.text);
    },
    saveGuideline() {
      const payload = {
        type: this.type[this.cardName],
        body: this.text,
      };

      // let apiName = `save${this.cardName}GuidelineAPI`;
      let apiName = `saveGuidelineAPI`;
      this[apiName](payload).then(() => {
        this.loadCoreData();
        // .then(() => {
        //   setTimeout(() => {
        //     let targetGuideline = _.find(this.core.guidelines, {
        //       type: this.type[this.cardName],
        //     });

        //     this.text = targetGuideline.guideline;
        //   }, 500);
        // });
      });
      this.$emit("refresh");
    },
    resetContent() {
      let targetGuideline = _.find(this.core.guidelines, {
        type: this.type[this.cardName],
      });

      this.text = targetGuideline ? targetGuideline.guideline : "";
      // this.text = this.content;
    },
  },
  computed: {
    ...levelComputed,
    ...adminComputed,
    targetGuideline() {
      let targetGuideline = _.find(this.core.guidelines, {
        type: this.type[this.cardName],
      });
      return targetGuideline ? targetGuideline.guideline : "";
    },
  },
  mounted() {
    console.log("this.core");
    console.log(this.core);
    let targetGuideline = _.find(this.core.guidelines, {
      type: this.type[this.cardName],
    });
    //alert(targetGuideline)
    this.text = targetGuideline ? targetGuideline.guideline : "";
    //TODO:
    // this.text = this.content;
  },
};
</script>
<style scoped>
.container {
  margin-top: 40px;
  padding: 0 40px;
}

.item {
  margin-bottom: 25px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

.reply {
  color: #34c38f;
}

.icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  background: #556ee640;
  color: #556ee6;
  font-size: 16px;
}

.comment-content {
  text-align: left;
}
</style>
