<template>
    <div>
        <b-overlay :show="is_loading">
        <b-form class="jobForm mt-3 p-3">
            <b-row>
                <b-col cols="2">Job Title</b-col>
                    <b-col cols="4">
                        <vue-typeahead-bootstrap
                                ref="testFocusInput1"
                                :data="jobs"
                                v-model="query1"
                                @hit="selectJob($event)"
                                placeholder="Search for a job title"
                                :minMatchingChars="Number(-1)"
                                :autoClose="true"
                                style="width:100%"
                        ></vue-typeahead-bootstrap>
                    </b-col>
                    <b-col cols="2">Reports to</b-col>
                    <b-col cols="4">
                        <vue-typeahead-bootstrap
                            ref="testFocusInput2"
                            :data="employees"
                            v-model="query2"
                            @hit="selectEmployee($event)"
                            placeholder="Search for a Employee Name"
                            :minMatchingChars="Number(-1)"
                            :autoClose="true"
                            style="width:100%"
                        ></vue-typeahead-bootstrap>
                    </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">Job Description</b-col>
                <b-col cols="4">
                    <b-form-input v-model="job_data.description" v-on:input="form_data_changed = true"></b-form-input>
                </b-col>
                <b-col cols="2">Start Date</b-col>
                <b-col cols="4">
                    <b-form-input v-on:input="form_data_changed = true" type="date" v-model="job_data.form_start_date"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">Country</b-col>
                <b-col cols="4">
                    <b-form-input id="country" v-on:input="form_data_changed = true" v-model="job_data.country"></b-form-input>
                </b-col>
                <b-col cols="2">City</b-col>
                <b-col cols="4">
                    <b-form-input id="city" v-on:input="form_data_changed = true" v-model="job_data.city"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">Region</b-col>
                <b-col cols="4">
                    <b-form-input v-on:input="form_data_changed = true" v-model="job_data.region"></b-form-input>
                </b-col>
            </b-row>
            <b-row v-if="form_data_changed">
                <b-col>
                    <b-button @click="clearChanges" variant="danger" class="" style="float: right;">
                        Clear Changes
                    </b-button>
                    <b-button @click="saveJobDetails" variant="primary" class="me-2 ms-auto" style="float: right;">
                        Update Job
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        </b-overlay>
        <b-overlay no-wrap :show="is_saving"></b-overlay>
    </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import _ from 'lodash'
import { peopleMethods, peopleComputed, levelComputed, adminComputed, levelMethods, } from '@/state/helpers'
export default {
    components: {
        VueTypeaheadBootstrap
    },
    data:() => ({
        job_data:{},
        is_saving: false,
        is_loading: false,
        form_data_changed: false,
        had_mounted: false,
        edit_text:'',
        query1: "",
        query2: "",
        jobs: [],
        selected_title: "",
        selected_reports_to_title: "",
        create_new: false,
        employees: [],
        selectedJobID: null,
        selectedID: null,
        job_change: false
    }),
    props:{
        is_new_job:{
            default: false
        },
        query:{
            default: ''
        },
        job_object:{
            default: function(){
                return {}
            },
        }
    },
    watch: {
        employeeDetails: {
            handler() {
                if(this.has_mounted) {
                    this.form_data_changed = false
                    this.$nextTick(() => {        
                        this.job_data = _.cloneDeep(this.employeeDetails.current_job);
                        if(this.job_data.start_date) {
                            this.job_data.form_start_date = format(parseISO(this.job_data.start_date), 'yyyy-MM-dd')
                        }
                        this.processJobsData()
                    })
                }
            }
        }
    },
    computed:{
        ...levelComputed,
        ...adminComputed,
        ...peopleComputed,
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        selectEmployee(data) {
            let email = data.split('-')[1];
            console.log(email)
            let fullName = null
            let user = null
            this.levelEmployeesData.forEach((value) => {
                console.log(JSON.parse(JSON.stringify(value)))
                if(email.trim() == value.email) {
                    user = value
                    fullName = value.name + ' ' + value.last_name
                }
            });
            if(user != null) {
                this.selectedID = user.id
            }
            this.form_data_changed = true
            this.selected_reports_to_title = fullName
        },
        contentChanged(new_content){
            this.edit_text = new_content;
        },
        setEmployeesData() {
            this.employees = [];
            this.levelEmployeesData.forEach((value) => {
                this.employees.push(value.name + ' - ' + value.email);
            });
        },
        setJobsData() {
            this.jobs = [];
            /*Object.keys(this.menu_users).forEach((key) => {
                this.jobs.push(key);
            });
            
            this.levelHiringData.forEach( job =>{
                if(this.jobs.includes(job.job_title) == false){
                    this.jobs.push(job.job_title);
                }
            })*/
            this.companyJobs.forEach((job) => {
                this.jobs.push(job.title);
            });
        },
        // saveJobChange(job_title) {
        //     this.is_loading = true

        //     let index = _.findIndex(this.companyJobs, { title: job_title });
        //     if(index == -1) {
        //         this.$swal.fire({
        //             title: "Please select a valid Job",
        //             icon: "warning",
        //             confirmButtonColor: "#34c38f",
        //             confirmButtonText: "Ok",
        //         }).then(() => {
        //             this.is_loading = false
        //         })
        //         return
        //     } 
        //     //let user_index = _.findIndex(this.levelEmployeesData, { name: reports_to });
        //     console.log(this.selectedID)
        //     if(this.selectedID == null) {
        //         this.$swal.fire({
        //             title: "Please select a valid user to report to",
        //             icon: "warning",
        //             confirmButtonColor: "#34c38f",
        //             confirmButtonText: "Ok",
        //         }).then(() => {
        //             this.is_loading = false
        //         })
        //         return
        //     } 
        //     if (index != -1) {
        //         console.log('getting in here to save')
        //         this.last_selected_id = this.companyJobs[index].id;
        //         if (this.isNewUser) {
        //             let job = {
        //                 id: this.companyJobs[index].id,
        //                 text: job_title,
        //             };
        //             this.$nextTick(() => {
        //                 this.$emit("returnJob", job);
        //             });
        //         } else {
        //             let date = new Date();
        //             date = format(date, "yyyy-MM-dd");
        //             let update_params = {
        //                 user_id: Number(this.selected_user_id),
        //                 job_id: this.companyJobs[index].id,
        //                 reports_to_user_id: this.selectedID,
        //                 start_date: date,
        //                 create_new: this.companyJobs[index].id == this.employeeDetails.current_job.id ? false : true
        //             };
        //             console.log(update_params)
        //             this.updateEmployeeJob(update_params).then(() => {
        //                 this.loadEmployeesData(this.selected_level.id).then(() => {
        //                     //this.loadEmployeeData(this.selected_user_id).then(() => {
        //                         this.$emit("changedJob");
        //                         this.$bvModal.hide('changeJobCardModal')
        //                         this.edit = false;
        //                     //})
        //                 }) 
        //             });
        //         }
        //     } else {
        //         console.log("Error finding job ID");
        //     }
           
        // },
        selectJob(data) {
            this.form_data_changed = true
            this.selected_title = data;
            this.selectedID = null
            this.job_data.reports_to_user_id = null;
            this.selected_reports_to_title = ''
            this.query2 = ''

            this.job_data.form_start_date = format(new Date(), 'yyyy-MM-dd')  
            let jobID = null 
            let index = _.findIndex(this.companyJobs, { title: data });
            if(index != -1) {
                jobID = this.companyJobs[index].id
            }
            this.selectedJobID = jobID
            this.job_change = true
            setTimeout(() => {
                //* get input field from component and then set its focus
                let input = this.$refs["testFocusInput2"].$refs["input"];
                input.focus();
            }, 200);
        },
        clearChanges() {
            this.job_data = _.cloneDeep(this.job_object);
            this.processJobsData()
            this.$nextTick(() => {
                this.form_data_changed = false
            })
        },
        saveJobDetails() {
            this.is_loading = true
            if(this.selectedJobID == null) {
                this.$swal.fire({
                    title: "Please select a valid Job",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.is_loading = false
                })
                return
            } 
            if(this.selectedID == null) {
                this.$swal.fire({
                    title: "Please select a valid user to report to",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.is_loading = false
                })
                return
            } 
            if(this.job_data.form_start_date == null) {
                this.$swal.fire({
                    title: "Please select a valid start date",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.is_loading = false
                })
                return
            } 
            let update_job_params = {
                user_id: Number(this.selected_user_id),
                job_id: this.selectedJobID,
                reports_to_user_id: this.selectedID,
                start_date: this.job_data.form_start_date ? this.job_data.form_start_date : format(new Date(), 'yyyy-MM-dd'),
                country: this.job_data.country,
                city: this.job_data.city,
                region: this.job_data.region,
                job_description: this.job_data.description,
                create_new: this.job_change ? true : false
            };
            console.log(update_job_params)
            //start_date: format(parseISO(this.employeeDetails.current_job.updated_at), 'yyyy-MM-dd'),
            // {"user_id":149,"job_id":36,"reports_to_user_id":43,"start_date":"2023-02-02","country":"South Africa","city":"Cape Town ","region":"Southern Seaboard","job_description":"Optional job description text","create_new":true}
            this.updateEmployeeJob(update_job_params).then(() => {
                this.loadEmployeesData(this.selected_level.id).then(() => {
                    this.loadEmployeeData(this.selected_user_id).then(() => {
                        this.job_data = this.employeeDetails.current_job
                        this.processJobsData()
                        this.job_data.form_start_date = format(parseISO(this.job_data.start_date), 'yyyy-MM-dd') 
                        this.is_loading = false
                        this.form_data_changed = false
                    })
                })  
            });
        },
        processJobsData() {
            if(this.job_data.reports_to_user_id != null) {
            this.selected_reports_to_title = this.job_data.reports_to_user_name
            this.selectedID = this.job_data.reports_to_user_id
            this.query2= this.job_data.reports_to_user_name
            if(this.job_data.start_date) {
                this.start_date = this.job_data.start_date
            }
            } else {
                this.selected_reports_to_title = 'No User Selected'
                this.selectedID = null
            }
            if(this.job_data.id) {
                this.selectedJobID = this.job_data.id
                this.selected_title = this.job_data.title
                this.query1 = this.job_data.title
            } else {
                this.selected_title = 'No Job Linked'
                this.selectedJobID = null
            }
            
            if(this.selected_title == 'No Job Linked') {
                this.create_new = true
            }
            this.setJobsData();
            this.setEmployeesData();
        }
    },
    beforeDestroy() {
        this.has_mounted = false
    },
    mounted(){
        this.edit = this.enableEdit;
        this.job_data = _.cloneDeep(this.job_object);
        this.processJobsData()
        if(this.job_data.start_date) {
            this.job_data.form_start_date = format(parseISO(this.job_data.start_date), 'yyyy-MM-dd') 
        }
        this.$nextTick(() => {
            this.has_mounted = true
        })
    },
}
</script>

<style>
.jobForm>.row{
    margin-bottom: 1rem;
}
.jobForm>.row>.col-2{
    padding-top: 0.5rem;
}
</style>