<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:42:11+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T12:05:39+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div>
        <b-row>
            <b-col cols="6">
                <HiresTable
                @setTableView="updateJobViewEvent"
                :job_category="job_category"
                ></HiresTable>
            </b-col>
            <b-col cols="6">
                <JobCandidates v-if="activeHiringPositionId > -1 && job_category === 'candidates'" :activeCandidateId="activeCandidateId" :activeHiringId="activeHiringPositionId" @setTableView="updateJobViewEvent"></JobCandidates>
                <JobSkills v-if="false && activeHiringPositionId > -1 && job_category === 'skills'" :activeHiringId="activeHiringPositionId" ></JobSkills>
            </b-col>
        </b-row>
        <hr v-show="activeHiringPositionId > -1">
        <b-row v-if="activeHiringPositionId > -1 && false">
            <b-col cols="12">
                job desc
                <JobDesc :activeHiringId="activeHiringPositionId"></JobDesc>
            </b-col>
        </b-row>

        <div class="collapse-parent">
            <b-card v-if="activeHiringPositionId > -1 && activeCandidateId > -1" no-body class="mb-1 shadow-none collapse-card">
                <b-card-header v-b-toggle.accordion-1 header-bg-variant="light" header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a class="text-dark" href="javascript: void(0);">
                            <i class="fas fa-tools me-1"></i>
                            Candidate Skills
                        </a>
                    </h6>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel"  @show="refreshTable('skills')">
                    <b-card-body style="padding-top: 0.2rem;">
                        <b-card-text>
                            <b-row>
                                <b-col cols="5">
                                    <CandidateSkills :activeSkillId="activeSkillId" :activeHiringId="activeHiringPositionId" :activeCandidateId="activeCandidateId"></CandidateSkills>
                                </b-col>
                                <b-col cols="7">
                                    <InterviewComments
                                    v-if="activeSkillId > -1"
                                    :activeHiringId="activeHiringPositionId"
                                    :activeCandidateId="activeCandidateId"
                                    :activeSkillId="activeSkillId"
                                    />
                                    <b-alert style="margin-top: 1.7rem;" v-else show>
                                        Select a Skill to enter interview details
                                    </b-alert>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card v-if="activeHiringPositionId > -1 && activeCandidateId > -1" no-body class="mb-1 shadow-none collapse-card">
                <b-card-header v-b-toggle.accordion-2 header-bg-variant="light" header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a class="text-dark" href="javascript: void(0);">
                            <i class="fas fa-graduation-cap me-1"></i>
                            Candidate Qualifications
                        </a>
                    </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel"  @show="refreshTable('qualifications')">
                    <b-card-body style="padding-top: 0.2rem;">
                        <b-card-text>
                            <b-row>
                                <b-col cols="12">
                                    <JobQualifications :activeHiringId="activeHiringPositionId" :activeCandidateId="activeCandidateId"></JobQualifications>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <!--<b-card v-if="activeCandidateId > -1 && activeSkillId > -1" no-body class="mb-1 shadow-none collapse-card">
                <b-card-header v-b-toggle.accordion-3 header-bg-variant="light" header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a class="text-dark" href="javascript: void(0);">
                            <i class="far fa-comment-dots me-1"></i>
                            Employee Skill Reviews
                        </a>
                    </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel"  @show="refreshTable('comments')">
                    <b-card-body style="padding-top: 0.2rem;">
                        <b-card-text>
                            <b-row>
                                <b-col cols="12">
                                    <InterviewComments
                                    :activeHiringId="activeHiringId"
                                    :activeCandidateId="activeCandidateId"
                                    :activeSkillId="activeSkillId"
                                    />
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>-->

            <b-card v-if="activeHiringPositionId > -1" no-body class="mb-1 shadow-none collapse-card">
                <b-card-header v-b-toggle.accordion-4 header-bg-variant="light" header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a class="text-dark" href="javascript: void(0);">
                            <i class="fas fa-chart-line me-1"></i>
                            Summary Graphs
                        </a>
                    </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel"  @show="refreshTable('summary')">
                    <b-card-body style="padding-top: 0.2rem;">
                        <b-card-text>
                            <b-row>
                                <b-col cols="12">
                                    <SummaryRatings/>
                                </b-col>
                                <b-col><hr></b-col>
                                <b-col cols="12">
                                    <SummarySkills/>
                                </b-col>
                                <b-col><hr></b-col>
                                <b-col cols="12">
                                    <SummaryResults/>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>
<script>
//* New version of hiring table
import HiresTable from './components/hiring/hiringTab.vue'
import JobDesc from './components/hiring/jobDesc.vue'
import JobCandidates from './components/hiring/jobCandidates.vue'
import JobSkills from './components/hiring/jobSkills.vue'
import CandidateSkills from './components/hiring/candidateSkills.vue'
import JobQualifications from './components/hiring/jobQualifications.vue'
import InterviewComments from './components/hiring/interviewerComments.vue'

import SummaryRatings from './components/hiring/summaryRatings.vue'
import SummarySkills from './components/hiring/summarySkills.vue'
import SummaryResults from './components/hiring/summaryResults.vue'
import _ from 'lodash';
import {//* import vuex store
    peopleComputed,
    levelComputed,
    peopleMethods,
} from "@/state/helpers";

export default {
    name: "Hiring",
    components: {
        HiresTable,
        JobDesc,
        JobCandidates,
        JobSkills,
        CandidateSkills,
        JobQualifications,
        InterviewComments,
        SummaryRatings,
        SummarySkills,
        SummaryResults
    },
    data:() => ({
        //* collapse index
        active_collapse_index: null,

        has_mounted: false,

        job_category: 'candidates', //* or 'skills'
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        active_job_data(){
            let data = null;
            if(this.activeHiringPositionId != -1){
                let index = _.findIndex(this.levelHiringData, {id:Number(this.activeHiringPositionId)});
                if(index != -1){
                    data = this.levelHiringData[index];
                }
            }
            return data;
        },
        active_candidate_data(){
            let data = null;
            if(this.activeCandidateId != -1 && this.active_job_data != null){
                let index = _.findIndex(this.active_job_data.candidates, {id:Number(this.activeCandidateId)});
                if(index != -1){
                    data = this.active_job_data.candidates[index];
                }
            }
            return data;
        },
    },
    watch:{
        current_level_id(){
            /*this.activeHiringId = -1;
            this.activeCandidateId = -1;
            this.activeSkillId = -1;*/
            this.setActiveHiringId(-1);
            this.setActiveCandidateId(-1);
            this.loadHiringData(this.selected_level.id)
        },
    },
    methods:{
        ...peopleMethods,
        updateJobViewEvent(type){
            this.job_category = type;
        },
        setActiveJob(id) {
            this.activeHiringPositionId = id;
            this.activeCandidateId = -1;
            this.activeSkillId = -1;
        },
        setActiveCandidate(id) {
            this.activeCandidateId = id;
            this.activeSkillId = -1;
        },
        setActiveSkill(id){
            this.activeSkillId = id;
        },
        refreshTable(type){
            switch(type){
                case 'skills':{
                    //* refresh candidate skills table
                    if(window.webix.$$('candidateSkillsTable') != undefined){
                        window.webix.$$('candidateSkillsTable').refresh();
                    }
                    break;
                }
                case 'qualifications':{
                    //* refresh qualifications table
                    if(window.webix.$$('jobQualifications') != undefined){
                        window.webix.$$('jobQualifications').refresh();
                    }
                    break;
                }
                case 'summary':{
                    //* refresh summary results table
                    if(window.webix.$$('summaryTable') != undefined){
                        window.webix.$$('summaryTable').refresh();
                    }
                    break;
                }
                case 'comments':{
                    //* refresh comments table
                    if(window.webix.$$('interviewTable') != undefined){
                        window.webix.$$('interviewTable').refresh();
                    }
                    break;
                }
            }
        }
    },
    mounted() {
        this.setActiveCandidateId(-1);
        this.setActiveHiringId(-1);
        //this.$eventHub.$on("setActiveJobHiring", this.setActiveJob);
        //this.$eventHub.$on("setActiveSkillHiring", this.setActiveSkill);
        //this.$eventHub.$on("setActiveCandidateHiring", this.setActiveCandidate);
        this.loadHiringData(this.selected_level.id)
        this.$nextTick(()=>{
            this.has_mounted = true;
        })
    },
    beforeDestroy(){
        this.setActiveCandidateId(-1);
        this.setActiveHiringId(-1);
        //this.$eventHub.$off("setActiveJobHiring");
        //this.$eventHub.$off("setActiveCandidateHiring");
        //this.$eventHub.$off("setActiveSkillHiring");
    }

}
</script>
<style>
    .border-grey{
        border-color: #a2a4ad !important;
    }
</style>
