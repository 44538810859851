<template>
    <div class="mt-1 " style="min-height: 75px;">
        <div v-if="listData.length > 0" >
            <div v-for="(item, index) in listData" :key="item.id" class=" mb-1 ">
                <!-- <b-overlay :show="refresh_id == item.id"> -->
                    <ObjectiveListViewCard :item="item" :list_index="index"/>
                    <!-- <template #overlay>
                        <div class="text-center">
                            <b-spinner label="Loading..."></b-spinner>
                            <h5 class="pt-4">Reloading OKR</h5>
                        </div>
                    </template> -->
                <!-- </b-overlay> -->
            </div>
        </div>
        <b-overlay :show="is_loading" no-wrap>
            <template #overlay>
                <div class="text-center">
                    <b-spinner label="Loading..."></b-spinner>
                    <h5 class="pt-4">Building OKR List</h5>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import ObjectiveListViewCard from './objectiveListViewCard.vue';
import { isEqual, debounce } from 'lodash';
import { levelComputed, performanceMethods, performanceComputed, } from '@/state/helpers'
export default {
    components:{
        ObjectiveListViewCard
    },
    watch:{
        selected_user_id(){
            this.loadScreenData();
        },
        current_level_id(){
            this.loadScreenData();
        },
        selected_milestone_goal(){
            this.loadScreenData();
        }

    },
    data:() => ({
        is_loading: false,
        flat_array:[],

        refresh_id: -1,
    }),
    computed:{
        ...levelComputed,
        ...performanceComputed,
        
        // debug(){
            
        //     //"objectivesTableGOAL"
        //     let table = this.table_states[this.table_uid]
        // },
        propBackground(){
            if(this.isCurrentItemSelected){
                return {    'border-color': `${this.tile_color} !important`,
                            'border-left': `4px solid ${this.tile_color}`,
                            'background-color': this.tile_color_bg,
                }
            }
            return {};
        },
        getSelectedClass(){
            if(this.isCurrentItemSelected){
                return 'border-primary selected_tile';
            }
            else{
                return '';
            }
        },
        listData() {
            return this.all_okrs_list;
        },
        // list_data(){
        //     return uniqBy(this.all_okrs_list, 'id');
        // },
    
    },
    methods:{
        ...performanceMethods,
        // listData() {
        //     if (!this.listDataCache) {
        //         this.listDataCache = uniqBy(this.all_okrs_list, 'id');
        //     }
        //     console.log()
        //     return this.listDataCache;
        // },
        handleSave(objective_id){
            this.$bvModal.hide('keyResultModal');
            this.refresh_id = objective_id;

            let params = {
                level_id: this.current_level_id,
                user_id: this.selected_user_id ? this.selected_user_id : 0,
                objective_id: objective_id,
                objective_data: 1,
            }
            this.fetchSingleObjective(params)
            .then(()=>{
                this.setKeyResultLoadingID(-1);
                this.refresh_id = -1;
            })
            .catch(()=>{
                this.refresh_id = -1;
            })
        },
        loadScreenData: debounce( function(force_reload = false){
            this.is_loading = true;
            let params = {
                //level_id: this.selected_level.id,
                user_id: this.selected_user_id != null ? this.selected_user_id : -1,
            }
            let must_load_for_goal = this.selected_milestone_goal > -1 && this.company_id == this.current_level_id
            if(must_load_for_goal){
                params['objective_id'] = this.selected_milestone_goal
            }
            else{
                params['level_id'] = this.selected_level.id;
            }  

            //* check if new params are different to the last used params
            let is_new_params = isEqual(params, this.last_loaded_okr_id);
            if(force_reload || is_new_params == false){
                this.fetchOkrData(params)
                .then(()=>{
                    this.is_loading = false;
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            }
            else{
                this.is_loading = false;
            }
        }, 100),
    },
    mounted(){
        this.$eventHub.$on('reloadListObjectives', this.handleSave)

        this.is_loading = true;
        setTimeout(()=>{
            this.loadScreenData();
        }, 200)
        
    },
    beforeDestroy(){
        this.setIsViewingObjectiveList(false);
        this.$eventHub.$off('reloadListObjectives');
    }
}
</script>

<style>

</style>