<template>
    <div>
        <b-card>
        <Table
            :uid="table_uid" 
            :is_loading="is_loading"
            :ref="table_uid"
            :data="job_skills"
            :columns="columns"
            :simple_columns="simple_columns"
            :table_mode="table_view_mode"
            :disable_action_buttons="canEdit"
            :button_methods="button_methods"
            column_autoheight_key="manager_comment"
            :table_modes="['view', 'edit']"
            :validation="table_validation_rules"
            :hide_action_buttons="viewOnly"
            no_extra_rows
            no_empty_rows
            @selectedRowEvent="selectedRowEvent"
            @onDataUpdate="onChangesMade"
            @saveTable="saveTable"
            @deleteRows="deleteRow">
            <template #title>
                Job Skills/Competence
                &nbsp;
                
                &nbsp;
                <!-- <span class="text-primary">{{selectedJobName}}</span> -->
            </template>
            <!-- <template #buttons>
                    <b-button title="Add Skill Categories" class="m-0 me-1" size="sm" variant="primary" @click="showSkillCategoriesModal()">
                           Add Skill Categories
                    </b-button>
            </template> -->
        </Table>
        <!-- <b-table responsive :items="items"></b-table> -->
        </b-card>
    </div>
</template>

<script>
//import Table from '@/components/widgets/table.vue'
import Table from '@/components/widgets/table/table.vue'

//* misc imports
import Swal from 'sweetalert2';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import {
    adminComputed,
    adminMethods,
    levelComputed,
    peopleMethods,
    peopleComputed,
    } from '@/state/helpers'
export default {
    components:{
        Table,
    },
    data:() => ({
        //* Variables for the Table Component
        table_uid: 'competencyRating',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        changes_made_flag: false, //* true if the table component detects a change in a cell value
        is_loading: false,
        //* ----
    }),
    watch: {
        // table_view_mode() {
        //     if(this.core.id != this.employeeDetails.id &&) {

        //     }
        // }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        button_methods(){
            //let el = this;
            let onClick = {
                //eslint-disable-next-line
                'restrictedEdit':function(e, id){
                    Swal.fire({
                        title: "Items are Job Specific",
                        text: "The Skills can be updated on the Model Screen under job profiles.",
                        icon: "info",
                        confirmButtonColor: "primary",
                        confirmButtonText: "Ok",
                    })
                },
            }
            return onClick;
        },
        canEdit() {
            if(this.job_skills.length != 0) {
                return false
            } else {
                return true
            }
        },
        viewOnly() {
            if(this.core.id == this.employeeDetails.id || this.$hasPerm('Power User') || this.core.id == this.employeeDetails.current_job.reports_to_user_id) {
                return false
            } else {
                return true
            }
        },
        competency_required() {
            let values = ['1','2','3','4','5','6','7','8','9','10']
            return values
        },
        job_skills(){
            if(this.employeeDetails.current_job.user_skills) {
                return this.employeeDetails.current_job.user_skills
            } else {
                return []
            }     
        },
        //* column config for table component
        columns(){
            let el=this
            return [
                { id: 'skill_name', header: 'Item', fillspace: 1, css: { "background-color": "#E0E0E0" },
                    template: function (obj) {
                    if (obj.skill_name != undefined) {
                        return `<span class="m-1 restrictedEdit"> ${obj.skill_name} <span/>`
                    }
                    else {
                        return ''
                    }
                },},
                { id: 'user_comment', header: 'Employee Comment', minWidth: 250, editor: "text", hidden: this.userEditCheck},
                { id: 'manager_comment', header: 'Manager Comment', minWidth: 250, editor: "text", hidden: this.managerEditCheck},
                { id: 'user_competency_rating', header: 'Employee Rating', minWidth: 150, editor: "combo", options: el.competency_required, hidden: this.userEditCheck,
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.competency_required,
                            template:"#value#",
                            yCount:10
                        }
                    },
                },
                { id: 'manager_competency_rating', header: 'Manager Rating', minWidth: 150, editor: "combo", options: el.competency_required, hidden: this.managerEditCheck,
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.competency_required,
                            template:"#id#",
                            yCount:10
                        }
                    },
                },
            ]
        },
        simple_columns(){
            //let el=this
            return [
                { id: 'skill_name', header: 'Item', fillspace: 1},
                { id: 'user_comment', header: 'Employee Comment', minWidth: 250, editor: "text", template: function(obj) {
                    console.log(obj)
                    if(obj.user_comment == '') {
                        return "No Employee Comment"
                    } else {
                        return obj.user_comment
                    }
                }},
                { id: 'manager_comment', header: 'Manager Comment', minWidth: 250, editor: "text", template: function(obj) {
                    if(obj.manager_comment == '') {
                        return "No Manager Comment"
                    } else {
                        return obj.manager_comment
                    }
                }},
                { id: 'user_competency_rating', header: 'Employee Rating', minWidth: 120,
                },
                { id: 'manager_competency_rating', header: 'Manager Rating', minWidth: 120,
                }
            ]
        },
        table_validation_rules(){
            return {
                "skill_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },
        userEditCheck(){
            if(this.core.id == this.employeeDetails.id) {
                return false
            }
            else {
                return true
            }
        },
        managerEditCheck(){
            if(this.core.id == this.employeeDetails.current_job.reports_to_user_id || (this.$hasPerm('Power User') && this.core.id != this.employeeDetails.id)) {
                return false
            }
            else {
                return true
            }
        },
    },
    methods:{
        ...peopleMethods,
        ...adminMethods,
        showSkillCategoriesModal() {

        },
        userCheck(val){
            console.log(val)
            if(this.core.id != this.employeeDetails.id) {
                return 'blackOut push-right'
            }
            else {
                return ''
            }
        },
        managerCheck(val){
            console.log(val)
            // if() {
            //     return 'blackOut push-right'
            // }
            // else {
            //     return ''
            // }
        },
        
        getCategoryOptions(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({category_id: item.id, value: item.name});
                })
            }   
            return names;
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleDragMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('drag')
            }
            else{
                this.table_view_mode = 'drag';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }

        },
        showDiscardPrompt(type){
            //* ask a user if they would like to cancel unsaved changes
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        //* table events
        selectedRowEvent(row){
            console.log('Use me -> ', row)
            //* Event returns the selected row
            //* here we save the seelcted row object or id in the people vuex store
            /*let index = _.findIndex(this.formatedObjectiveData, { id: row.id });
            if(index != -1){
                let objective = _.cloneDeep(this.formatedObjectiveData[index]);
                this.$eventHub.$emit('selectedObjective', objective);
                this.setSelectedObjective(objective);
            }*/
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        saveTable(){
            //* handle validation/serialization
            let table = window.webix.$$(this.table_uid);//* get main table for component
            this.$nextTick(()=>{
                let params = {
                    user_id: this.employeeDetails.id,
                    user_skills: []
                }
                let rows = table.serialize(true); //* pass true to parse all data;
                rows.forEach( row => {
                    if(!row.is_empty){
                        params.user_skills.push({
                            skill_id: row.is_new ? -1 : row.id,
                            user_competency_rating: row.user_competency_rating ? Number(row.user_competency_rating) : 0,
                            user_comment: row.user_comment ? row.user_comment : '',
                            manager_competency_rating: row.manager_competency_rating ? Number(row.manager_competency_rating) : 0,
                            manager_comment: row.manager_comment ? row.manager_comment : '',
                        })
                    }
                });
                this.is_loading = true;
                this.saveUserSkill(params)
                .then(()=>{
                    this.loadCompanyJobs(this.company_id)
                    .then(()=>{
                        this.loadEmployeeData(this.selected_user_id)
                        this.is_loading = false;
                        this.changes_made_flag = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.toggleViewMode();
                        //this.updateViewMode()
                    })
                })
                .catch(()=>{
                    console.log('Saving skills error');
                    this.is_loading = false;
                })
            })
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected Skills?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            console.log(JSON.parse(JSON.stringify(data)))
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteJobSkill({'skill_id': item.id}));
            })
            this.is_loading = false;
            Promise.all(promises)
            .then(()=>{
                this.delete_array = [];
                this.changes_made_flag = false;
                //console.log('Action Plan table ref',this.$refs[this.table_uid])
                this.$refs[this.table_uid].table_mode = 'view';
                this.is_loading = false;
			})
        },
    },
    mounted(){
    },
}
</script>

<style>
.blackOut {
    background-color: #f8f8fb !important;
}
</style>