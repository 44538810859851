<template>
    <!-- //TODO create child components from both b-tabs -->
        <div>
            <div v-if="user != null">
                <!-- <TeamsTable v-if="hasMounted"/> -->
                <workGroups noEdit />
                <teamMembers noEdit />
            </div>
        </div>
    </template>
    
    <script>
    import _ from 'lodash';
    import {
        levelComputed,
        levelMethods,
        peopleMethods,
        peopleComputed,
        adminComputed,
        adminMethods,
        profileComputed,
        profileMethods
    } from "@/state/helpers";
    //eslint-disable-next-line
    import { format, parseISO } from "date-fns";
    import Vue from 'vue'
    import 'vue-advanced-cropper/dist/style.css';
    import Swal from 'sweetalert2';
    import workGroups from '@/components/profile/profile-tables/workGroups.vue';
    import teamMembers from "@/components/profile/profile-tables/workGroupMembers.vue";
    //import userDetails from './userDetails.vue'
    export default {
        components: {
            teamMembers,
            workGroups,
            //userDetails
        },
        props: {
            activeEmployeeId: {
                required: true
            },
            existing_user:{
                default: false,
            },
            hideUpdateButton:{
                default: false,
            },
            hideEdit:{
                default: false
            }
        },
        data:() => ({
            show_modal: false,
            user: null, //* old -- user: {}
            user_display_name: '', //copy of name and surname (fixes display name changing when editing first/last name)
            firstName: '',
            lastName: '',
            employmentDate: '',
            report_to: '',
            is_loading_job: false,
            table_uid: 'jobHistoryTable',
            table_leave_uid: 'leaveTable',
            table_remuneration_uid: 'remunerationTable',
            table_assets_uid: 'assetTable',
            leave_table_view_mode: 'view',
            remuneration_table_view_mode: 'view',
            processedData: [],
            processedRemunerationData: [],
            linkableAssetsData: [],
            savedLeaveTypes: [],
            popup_date: null,
            assets_table_view_mode: '',
            is_loading: false,
            is_saving_avatar: false,
            is_saving_user: false,
            leave_changes_made_flag: false,
            remuneration_changes_made_flag: false,
            assets_changes_made_flag: false,
    
            hasMounted: false,
            active_collapse_index: 1,
    
            selectedImage: "",
            hoverOverImg: false,
    
            editJobDetails: false,
            edit_current_job:null,
    
            block_form_watcher: false, //* block watcher from firing when the user object is updated with new user data
            form_data_changed: false,//* true when form input fields change
    
            typeahead_query: '',
    
            leave_dropdown_options:[
                { id: 'annual', value: 'Annual'},
                { id: 'maternity', value: 'Maternity'},
                { id: 'sick', value: 'Sick'},
                { id: 'family_responsibility', value: 'Family Responsibility'},
                { id: 'study', value: 'Study'},
                { id: 'religious', value: 'Religious'},
                { id: 'personal', value: 'Personal'},
            ],
    
            full_remuneration_dropdown_options:[
                    { id: 'salary', value: 'Salary'},
                    { id: 'overtime', value: 'Overtime'},
                    { id: 'sick', value: 'Sick'},
                    { id: 'bonus_payment', value: 'Bonus Payment'},
                    { id: 'commission', value: 'Commission'},
            ]
    
        }),
        watch: {
            activeEmployeeId() {
                //* update the active user data when the activeEmployeeId changes
                this.$nextTick(() => {
                    this.setActiveUser()
                });
            },
            active_collapse_index(newValue){
                if(newValue == 2){
                    //* refresh job history table
                    window.webix.$$(this.table_uid).refresh();
                }
                else if(newValue == 3){
                    //* regresh leave/assets.. tables
                    window.webix.$$(this.table_leave_uid).refresh();
                    window.webix.$$(this.table_assets_uid).refresh();
                    window.webix.$$(this.table_remuneration_uid).refresh();
                }
            },
            user:{
                deep: true,
                handler(){
                    if(this.block_form_watcher == false){
                        this.form_data_changed = true;
                    }
                }
            },
            assets_table_view_mode: {
                handler() {
                    this.loadData()     
                }
            },
            leave_table_view_mode: {
                handler() {
                    if(this.leave_table_view_mode == 'edit'){
                        this.processLeaveData(this.user.leave, false)
                    } else {
                        this.processLeaveData(this.user.leave, true)
                    }    
                }
            },
            remuneration_table_view_mode: {
                handler() {
                    if(this.remuneration_table_view_mode == 'edit'){
                        this.processRemunerationData(this.user.remuneration, false)
                    } else {
                        this.processRemunerationData(this.user.remuneration, true)
                    }    
                }
            }
        },
        computed:{
            ...levelComputed,
            ...peopleComputed,
            ...adminComputed,
            ...profileComputed,
            storeEmployeesData() {
                return this.levelEmployeesData.employees;
            },
            variable_watchers(){
                var el = this;
                return {
                    leave_type(newVal, oldVal, id, is_new_row){
                        let names = []
                        window.webix.$$(el.table_leave_uid).data.each(function(obj){ 
                            console.log(obj);
                            if(!obj.is_empty && !obj.is_new) {
                                names.push(obj.leave_type);
                            }
                        });
                        if(is_new_row){
                            if(names.includes(newVal)) {
                                let item = window.webix.$$(el.table_leave_uid).data.getItem(id);
                                item.leave_type = ""
                                window.webix.$$(el.table_leave_uid).data.updateItem(item.id, item)
                                //window.webix.$$(el.table_leave_uid).data.remove(id);
                                
                                el.$swal.fire('The leave type already exists');
                                window.webix.$$(el.table_leave_uid).refresh()
                            }
                        } else {
                            let duplicates = _.uniq(names).length !== names.length; 
                            if(duplicates) {
                                let item = window.webix.$$(el.table_leave_uid).data.getItem(id);
                                item.leave_type = ""
                                window.webix.$$(el.table_leave_uid).data.updateItem(item.id, item)
                                //window.webix.$$(el.table_leave_uid).data.remove(id);
                                
                                el.$swal.fire('The leave type already exists');
                                window.webix.$$(el.table_leave_uid).refresh()
                            }
                        }
                    },
                }
            },
            activeEmployee() {
                var el = this;
                var findIndex = _.findIndex(this.storeEmployeesData, function(o) { return o.id == el.activeEmployeeId; });
                if (findIndex > -1) {
                    return this.storeEmployeesData[findIndex];
                }
                return {};
            },
            active_employee_data() {
                let data = null;
                if(this.activeEmployeeId != -1){
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                    if(index != -1){
                        data = this.levelEmployeesData[index];
                    }
                }
                return data;
            },
            isSuperUser(){
                let allowed = this.$hasUserRole('Power User');
                if(allowed == false){
                    return this.canEditProfile;
                }
                else{
                    return '';
                }
            },
            canEditProfile(){
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return '';
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return '';
                }
                //else
                return 'block-click'
            },
            columns() {
                let el = this;
                return [
                    { id: 'title', header: 'Job Title', fillspace: 1,
                        template(object){
                            console.log(object)
                            if(object == undefined){
                                let name = el.getJobByID(object.id);
                                return name;
                            }
                            else {
                                return object.title;
                            }
                            
                        }
                    },
                    { id: 'start_date', header: 'Start Date', fillspace: 1,
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        },
                        /*template(obj) {
                            //* format the date
                            if(obj.start_date == undefined){
                                //* add a date to new rows without a date to fix undefined date error
                                let date = new Date();
                                return format(date, 'dd-MMM-yyyy');
                            }
                            let date = obj.start_date;
                            //* only parse date if it is in string form
                            if(typeof obj.start_date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }*/
                    },
                ]
            },
            leave_columns() {
                let el = this
                return [
                    { id: 'leave_type', header: 'Leave', fillspace: 1, template:function(obj) {
                        console.log(obj);
    
                        if(el.$refs[el.table_leave_uid].table_mode == 'edit'){
                            if(!obj.is_empty) {
                                let value = el.leave_dropdown_options.find(o => o.id === obj.leave_type);
                                return value.value;
                            } else {
                                return ''
                            }
                        }
                        else{
                            //let object = obj.leave_type
                            //let value = el.leave_dropdown_options[obj.leave_type]
                            let value = el.leave_dropdown_options.find(o => o.id === obj.leave_type);
                            if(obj.used != 0 && !obj.is_empty) {
                                return value.value;
                            } else {
                                return ``
                            }
                            
                        }
                    }},
                    { id: 'available', header: 'Available', fillspace: 1, editor: 'text', template:function(obj){
                        if(obj.available != 0) {
                            return obj.available
                        } else {
                            if(el.$refs[el.table_leave_uid].table_mode == 'edit') {
                                return obj.available
                            } else {
                                return ``
                            }
                        }
                    }},
                    { id: 'used', header: 'Used', fillspace: 1, editor: 'text', template:function(obj){
                        if(obj.used != 0) {
                            return obj.used
                        } else {
                            if(el.$refs[el.table_leave_uid].table_mode == 'edit') {
                                return obj.used
                            } else {
                                return ``
                            }
                            
                        }
                    }},
                ]
            },
            remuneration_columns() {
                let el = this;
                return [
                    { id: 'type', header: 'Type', fillspace: 1, template(obj) {
                        if(el.$refs[el.table_remuneration_uid].table_mode == 'edit'){
                            console.log(obj)
                            if(!obj.is_empty) {
                                console.log(obj.type)
                                let value = el.full_remuneration_dropdown_options.find(o => o.id === obj.type);
                                return value.value;
                            } else {
                                return ''
                            }
                        }
                        else{
                            //let object = obj.leave_type
                            //let value = el.leave_dropdown_options[obj.leave_type]
                            let value = el.full_remuneration_dropdown_options.find(o => o.id === obj.type);
                            if(!obj.is_empty) {
                                return value.value;
                            } else {
                                return ``
                            }
                            
                        }
                    }},
                    { id: 'amount', header: 'Amount', fillspace: 1, editor: 'text', template:function(obj){
                        if(obj.amount != 0) {
                            return obj.amount
                        } else {
                            if(el.$refs[el.table_remuneration_uid].table_mode == 'edit') {
                                return obj.amount
                            } else {
                                return ``
                            }
                        }
                    }},
                    { id: 'effective_date', header: 'Effective Date', fillspace: 1,  editor: "date",
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return 'Select Date';
                            }
                        }
                    },
                ]
            },
            assets_columns() {
                let el = this
                return [
                    { id: 'selected', checkValue:true, uncheckValue: null, editor: 'checkbox', template:function(obj, common){
                        if(el.$refs[el.table_assets_uid].table_mode == 'edit' && !obj.is_empty){
                            return common.checkbox(obj,common, obj.selected, {checkValue:true, uncheckValue: false})
                        }
                        else{
                            return ``;
                        }
                    }, width: 30, header: '' },
                    { id: 'asset_name', header: 'Name', fillspace: 1},
                    { id: 'received_date', header: 'Received Date', fillspace: 1, visibility: ['view'],
                        //* example on how to format date without mutating the origional data
                        format:function(value){
                            console.log(value);
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        }
                    },
                    { id: 'returned_date', header: 'Returned Date', fillspace: 1, visibility: ['view'],
                        //* example on how to format date without mutating the origional data
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return 'To be returned';
                            }
                        }
                    },
                    { id: 'serial_no', header: 'Serial/Asset Number', fillspace: 1},
                    { id: 'return', header: 'Return Asset', fillspace: 1, tooltip: false,
                        template(obj){
                            // console.log(obj);
                            if(obj.is_empty || obj.is_new){
                                return '';
                            }
                            else{
                                let inner_html = '';
                                inner_html += `<span class="return_text view_return" >Return</span>`
    
                                return `<div class="d-flex action-buttons-flex">${inner_html}</div>`
                                
                            }
                        }
                    }
                ]
            },
            isLeaveViewMode(){
                if(this.leave_table_view_mode != 'view'){
                    return false;
                }
                else{
                    return true
                }
            },
            isRemunerationViewMode(){
                if(this.remuneration_table_view_mode != 'view'){
                    return false;
                }
                else{
                    return true
                }
            },
            isAssetsViewMode(){
                if(this.assets_table_view_mode != 'view'){
                    return false;
                }
                else{
                    return true
                }
            },
            isLeaveEditMode(){
                if(this.leave_table_view_mode != 'edit'){
                    return false;
                }
                else{
                    return true
                }
            },
            isRemunerationEditMode(){
                if(this.remuneration_table_view_mode != 'edit'){
                    return false;
                }
                else{
                    return true
                }
            },
            leaveDropdownClassFix() {
                if(this.leave_changes_made_flag == true){
                    return '';
                }
                else{
                    return 'ms-auto'
                }
            },
            remunerationDropdownClassFix() {
                if(this.remuneration_changes_made_flag == true){
                    return '';
                }
                else{
                    return 'ms-auto'
                }
            },
            assetsDropdownClassFix() {
                if(this.assets_changes_made_flag == true){
                    return '';
                }
                else{
                    return 'ms-auto';
                }
            },
            getJobTitle(){
                let title = '';
                let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                if(index != -1){
                    title = this.companyJobs[index].title;
                }
                else{   
                    title = 'Job not found'
                }
                return title;
            },
            hasValidJob(){
                if(this.user != undefined){
                    let index = _.findIndex(this.companyJobs, {id:  this.user.current_job.id});
                    console.log(this.companyJobs);
                    if(index == -1){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                else{
                    return false;
                }
            },
            asset_table_validation_rules(){
                //let el = this;
                return {
                    "asset_name": function(item, obj){
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if(item === '' || item == undefined){
                                return false;
                            }
                            else{
                                return true;
                            }
                        }
                    },
                    "serial_no": function(item, obj){
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if(item === '' || item == undefined){
                                return false;
                            }
                            else{
                                return true;
                            }
                        }
                    },
                };
            },
            button_methods(){
                let el = this;
                let onClick = {
                    //eslint-disable-next-line
                    'view_return':function(e, id){
                        console.log(id);
                        let item = this.getItem(id.row);
                        console.log(item);
                        if(!item.returned_date) {
                            el.$swal.fire({
                            title: 'Select a Date',
                            html: '<b-form-input v-model="selectedDate" type="date"></b-form-input>',
                            showCancelButton: true,
                            confirmButtonText: 'Select',
                            cancelButtonText: 'Cancel',
                            onOpen: () => {
                                new Vue({
                                    el: '#swal2-content',
                                    watch:{
                                        selectedDate(newVal){
                                            el.popup_date = newVal;
                                        }
                                    },
                                    data() {
                                        return {
                                            selectedDate: '',
                                        };
                                    },
                                });
                            },
                            }).then((result) => {
                                if (result.isConfirmed && el.popup_date != undefined) {
                                    console.log(el.popup_date)
                                    // Handle selected date
                                    let data = {asset_id: item.asset_id, user_id: el.activeEmployeeId, returned_date: el.popup_date}
                                    el.updateAssetLink(data);
                                    console.log(result);
                                }
                            });
                        } else {
                            el.$swal.fire({
                            title: 'Asset Already Returned',
                            html: '',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel'
                            })
                        }
                       
                           //el.$router.push(`/process/${item.id}`);
                    },
                }
                return onClick;
            },
            leave_table_validation_rules(){
                return {
                    // "leave_type": function(item, obj){
                    //     if(obj.is_empty){
                    //         return true;
                    //     }
                    //     else{
                    //         if(item == ''){
                    //             return false;
                    //         }
                    //         else{
                    //             return true;
                    //         }
                    //     }
                    // },
                    "available": function(item, obj){
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if(item === '' || item == undefined || isNaN(item)){
                                return false;
                            } 
                            else {
                                if(Number(obj.available) < Number(obj.used)){
                                    return false
                                }
                                return true;
                            }
                        }
                    },
                    "used": function(item, obj){                    
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if(item === '' || item == undefined || isNaN(item)){
                                return false;
                            }
                            else {
                                if(Number(obj.used) > Number(obj.available)){
                                    return false
                                }
                                return true;
                            }
                        }
                    },
                };
            },
            remuneration_table_validation_rules(){
                return {
                    "type": function(item, obj){
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if(item === ''){
                                return false;
                            }
                            else{
                                return true;
                            }
                        }
                    },
                    "amount": function(item, obj){
                        if(obj.is_empty){
                            return true;
                        }
                        else{
                            if((item === '' || item == undefined || isNaN(item)) ){
                                return false;
                            }
                            else {
                                if(obj.effective_date != null && item == 0) {
                                    return false;
                                } else {
                                    return true;
                                }
                                
                            }
                        }
                    },
                    "effective_date": function(item, obj){
                        if(obj.is_empty || (obj.effective_date == null && obj.amount == 0)){
                            return true;
                        }
                        else{
                            if((item === '' || item == undefined)){
                                return false;
                            }
                            else{
                                
                                return true;
                            }
                        }
                    },
                };
            },
        },
        methods:{
            ...adminMethods,
            ...peopleMethods,
            ...levelMethods,
            ...profileMethods,
            deleteRemunerationRow(data) {
                let promises = [];
                data.forEach( item => {
                    promises.push(this.deleteEmployeeRemuneration({id: item.id}));
                })
                Promise.all(promises)
                .then(() => {
                    this.is_loading = true;
                        this.loadEmployeesData(this.selected_level.id)
                            .then(()=>{
                                this.toggleRemunerationViewMode();
                                this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                                setTimeout(()=>{
                                    this.setActiveUser();
                                    this.$refs[this.table_remuneration_uid].table_mode = 'view'
                                    this.is_loading = false;
                                    
                                }, 500)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.is_loading = false;
                    })
            },
            loadData() {
                console.clear();
                if(this.levelLinkableAssets == undefined) {
                    return []
                } else {
                    let employee = null;
                    let index = _.findIndex(this.levelEmployeesData, {id:Number(this.activeEmployeeId)});
                    if(index != -1){
                        employee = this.levelEmployeesData[index];
                    }
                    console.log(JSON.parse(JSON.stringify(employee)));
                    if(this.assets_table_view_mode == 'edit') {
    
                        //* get active assets
                        let active_user_assets = employee.assets.filter( item => {
                            return item.pivot.returned_date == null
                        })
                        this.linkableAssetsData = _.cloneDeep(this.levelLinkableAssets);
                        active_user_assets.forEach(( linked_asset) => {
                            //* find active asset and set selected falg to true
                            let index = _.findIndex(this.linkableAssetsData, {id: linked_asset.id});
                            if(index != -1){
                                this.linkableAssetsData[index].selected = true;
                            }
                        })
                        this.processAssetsData(this.linkableAssetsData);
                    } else {
                        this.processAssetsData(JSON.parse(JSON.stringify(employee.assets)));
                    }
                }
            },
            processAssetsData(data) {
                const tableData = [];
                if (data == null) {
                    console.log('failing here');
                    data = [];
                }
                else {
                    // const id = 0;
                    data.forEach((element) => {
                        console.log(JSON.parse(JSON.stringify(element)));
                        const asset = {};
                        asset['id'] = element.id
                        asset['asset_id'] = element.id
                        asset['asset_name'] = element.asset_name;
                        asset['serial_no'] = element.serial_no;
                        if(element.pivot){
                            let pivot = element.pivot
                            asset['received_date'] = pivot.received_date
                            asset['returned_date'] = pivot.returned_date
                        } else {
                            asset['received_date'] = ''
                            asset['returned_date'] = ''
                        }
                        
                        
                        asset['selected'] = element.selected != undefined ? element.selected : false;
                        tableData.push(asset)
                    });
                }
                //data = _.orderBy(tableData, ['selected'], ['desc',]);
                console.log(tableData);
                this.linkableAssetsData = tableData;
                this.is_loading = false;
            },
            updateAssetLink(data) {
                this.is_loading = true;
                this.updateAssetsLink(data)
                .then(() => {
                        this.loadLinkableAssets(this.selected_level.id)
                        this.loadEmployeesData(this.selected_level.id)
                        .then(()=>{
                            //this.$refs[this.table_assets_uid].table_mode = 'view'
                            this.loadData();
                            this.is_loading = false;
                            //this.toggleAssetsViewMode();
                            this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                            this.setActiveUser();
                        })
                    })
                .catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                })
            },
            handleAssetTableModeUpdate(new_mode){ 
                this.assets_table_view_mode = new_mode
            },
            handleLeaveTableModeUpdate(new_mode){ 
                this.leave_table_view_mode = new_mode
            },
            handleRemunerationTableModeUpdate(new_mode){ 
                this.remuneration_table_view_mode = new_mode
            },
            restoreSelectedUser(){
                Swal.fire({
                    title: "Are you sure you want to restore this employee?",
                    //text: "You won't be able to revert this!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        this.user.active = true;
                        //let payload = _.find(this.levelEmployeesData, {id:Number(this.selected_user_id)});
                        this.setEmployeeArchiveState(this.user)
                        .then(()=>{
                            //let user_name = `${this.user.name} ${this.user.last_name}`
                            //this.showReloadingPrompt(user_name, false);
                            this.loadEmployeesData(this.selected_level.id)
                            this.loadAllUsers();
                            this.loadMenuUsers(this.selected_level.id);
                        })
                    }
                });
            },
            deleteSelectedUser(){
                if(Number(this.selected_user_id) === Number(this.core.id)){
                    Swal.fire("Warning, can't archive logged in user.");
                    return;
                }   
                Swal.fire({
                    title: "Are you sure you want to archive this employee?",
                    //text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "YES",
                }).then((result) => {
                    if (result.value) {
                        this.user.active = false;
                        //let payload = _.find(this.levelEmployeesData, {id:Number(this.selected_user_id)});
                        this.setEmployeeArchiveState(this.user)
                        .then(()=>{
                            //let user_name = `${this.user.name} ${this.user.last_name}`
                            //this.showReloadingPrompt(user_name, true)
                            this.loadEmployeesData(this.selected_level.id)
                            this.loadAllUsers();
                            this.loadMenuUsers(this.selected_level.id);
                        })
                    }
                });
            },
            showReloadingPrompt(user_name, archived = false){
                let msg = archived ? 'Archived user' : 'Restored'
                Swal.fire({
                    title: `${msg}: ${user_name}`,
                    text: 'Reloading data.',
                    timer: 5000,
                    timerProgressBar: true,
                    icon: "success",showConfirmButton: false,
                }).then(()=>{
                    this.$router.go();
                })
            },
            formatStartDate(date){
                console.log('DATE', date)
                if(date == null){
                    return ''
                }
                if(date !== ''){
                    if(typeof date === 'string'){
                        date = parseISO(date);
                    }
                    return format(date, 'dd-MMM-yyyy');
                }
                else{
                    return '';
                }
            },
            updateUser() {
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                this.updateEmployee(this.user)
                .then(()=>{
                    if(Number(this.core.id) == Number(this.selected_user_id)){
                        this.loadCoreData();
                    }
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.loadLevels();
                        //refresh owner users for datatable columns
                        this.loadAllUsers();
                        //refrrsh users for current level
                        this.loadMenuUsers(this.selected_level.id);
                        if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            updateJobDetails(){
                this.is_saving_user = true;
                if(this.user.active == undefined){
                    //* include new 'active' flag
                    this.user['active'] = true;
                }
                let updatedUser = _.cloneDeep(this.user);
                updatedUser.current_job = _.cloneDeep(this.edit_current_job);
                this.updateEmployee(updatedUser)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        /*if(this.existing_user == false){
                            //this.$emit('back');
                            this.$router.push('/people');
                        }*/
                        this.is_saving_user = false;
                        this.form_data_changed = false;
                        this.setActiveUser();
                        this.editJobDetails = false;
                    })
                })
                .catch(()=>{
                    this.is_saving_user = false;
                })
            },
            returnOnClickMethods(){
                let onClick = {
                    //eslint-disable-next-line
                    'unlink_button':function(e, id){
                        console.log('On button click', id);
                    }
                };
                return onClick;
            },
            selectedLeaveRowEvent() {
                console.log('selectedLeaveRowEvent');
            },
            selectedRemunerationRowEvent() {
                console.log('selectedRemunerationRowEvent');
            },
            selectedAssetsRowEvent() {
                console.log('selectedAssetsRowEvent');
            },
            // onLeaveChangesMade(value){
            //     this.leave_changes_made_flag = value;
            // },
            // onRemunerationChangesMade(value){
            //     this.remuneration_changes_made_flag = value;
            // },
            onAssetsChangesMade(value){
                this.assets_changes_made_flag = value;
            },
            setActiveUser(){
                this.form_data_changed = false;
                //* moved this block of code from the 
                //* activeEmployeeId watcher (method used in activeEmployeeId watcher and mounted hook)
                if (this.active_employee_data != null) {
                    this.block_form_watcher = true;
                    this.user = _.cloneDeep(this.active_employee_data);
                    this.user_display_name = this.user.name + ' ' + this.user.last_name
                    this.firstName = this.user.name
                    this.lastName = this.user.last_name
                    this.employmentDate = this.user.employment_date
                    this.progress_status = 70
                    let user = this.all_users.find( person => { return person.id == this.reports_to_user_id; })
                    console.log("!!!!!")
                    console.log(JSON.parse(JSON.stringify(this.user)));
    
                    if(user) {
                        this.report_to = user.name
                    }
                    console.log(JSON.parse(JSON.stringify(this.user)))
                    this.processRemunerationData(this.user.remuneration, true);
                    this.processLeaveData(this.user.leave, true)
                        if(this.user.current_job == undefined){
                            this.user['current_job'] = {}
                        }
                        this.user.level_id = this.user.pivot.level_id;
                        this.$nextTick(()=>{
                            this.block_form_watcher = false;
                            this.hasMounted = true;
                        })
                       
                    //* fix issue with older user where user.current_job == null
                    
                }
            },
            processRemunerationData(data, table_mode) {
                let remuneration_dropdown_options = [
                    { id: 'salary', value: 'Salary'},
                    { id: 'overtime', value: 'Overtime'},
                    { id: 'sick', value: 'Sick'},
                    { id: 'bonus_payment', value: 'Bonus Payment'},
                    { id: 'commission', value: 'Commission'},
                ]
                if(data.length > 0) {
                    data.forEach(item => {
                        remuneration_dropdown_options.forEach(option => {
                            if(option.id == item.type) {
                                remuneration_dropdown_options.splice(remuneration_dropdown_options.findIndex(a => a.id === item.type) , 1)
                            }
                        })
                    })   
                }
                let processedEditDataArray = []
                remuneration_dropdown_options.forEach((item) => {
                    let newItem = {}
                    newItem['type'] = item.id,
                    newItem['amount'] = 0,
                    newItem['effective_date'] = null
                    processedEditDataArray.push(newItem);
                })
                let final = data.concat(processedEditDataArray);
                if(table_mode) {
                    this.processedRemunerationData = data
                } else {
                    this.processedRemunerationData = final;
                }
            },
            processLeaveData(data, table_mode) {
                let edit_leave_dropdown_options = [
                    { id: 'annual', value: 'Annual'},
                    { id: 'maternity', value: 'Maternity'},
                    { id: 'sick', value: 'Sick'},
                    { id: 'family_responsibility', value: 'Family Responsibility'},
                    { id: 'study', value: 'Study'},
                    { id: 'religious', value: 'Religious'},
                    { id: 'personal', value: 'Personal'},
                ]
                let editDataArray = edit_leave_dropdown_options;
                data.forEach((item) => {
                    editDataArray.forEach(dataItem => {
                        console.log(dataItem.leave_type);
                        if(item.leave_type == dataItem.id){
                            // console.log(editDataArray.findIndex(a => a.id === dataItem.leave_type));
                            editDataArray.splice(editDataArray.findIndex(a => a.id === dataItem.id) , 1)
                        }
                        
                    })
                });
    
                let processedEditDataArray = []
    
                editDataArray.forEach((item) => {
                    let newItem = {}
                    newItem['leave_type'] = item.id,
                    newItem['available'] = 0,
                    newItem['used'] = 0
                    processedEditDataArray.push(newItem);
                })
                let final = data.concat(processedEditDataArray);
                console.log(final);
                if(table_mode) {
                    this.processedData = data
                } else {
                    this.processedData = final;
                }
            },
            setLeaveTableMode(mode){
                switch(mode){
                    case 'edit':{
                        if(this.leave_changes_made_flag){
                            this.showDiscardPrompt('leave', mode);
                        }
                        this.toggleLeaveEditMode();
                        break;
                    }
                    case 'view':{
                        this.toggleLeaveViewMode();
                        break;
                    }
                }
            },
            setAssetsTableMode(mode){
                switch(mode){
                    case 'edit':{
                        this.toggleAssetsEditMode();
                        break;
                    }
                    case 'view':{
                        this.toggleAssetsViewMode();
                        break;
                    }
                }
            },
            setRemunerationTableMode(mode){
                switch(mode){
                    case 'edit':{
                        this.toggleRemunerationEditMode();
                        break;
                    }
                    case 'view':{
                        this.toggleRemunerationViewMode();
                        break;
                    }
                }
            },
            toggleLeaveEditMode(){
                this.leave_table_view_mode = 'edit';
            },
            toggleRemunerationEditMode(){
                this.remuneration_table_view_mode = 'edit';
            },
            toggleLeaveViewMode(){
                this.leave_table_view_mode = 'view';
            },
            toggleRemunerationViewMode(){
                this.remuneration_table_view_mode = 'view';
            },
            saveLeaveTable() {
                //eslint-disable-next-line
                let table = $$(this.table_leave_uid);
                const all_data = table.serialize(true);
                console.log(all_data);
                var params = {
                    user_id: this.activeEmployeeId,
                    leave: [],               
                };
                all_data.forEach( row => {
                    console.log(row);
                    if(!row.is_empty && row.used != 0 && row.available != 0) {
                        this.is_loading = true;
                        params.leave.push({
                            leave_type: row.leave_type,
                            available: Number(row.available),
                            used: Number(row.used),
                        });
                    }
                })
                console.log(JSON.parse(JSON.stringify(params)));
                this.saveEmployeeLeave(params)
                    .then(() => {
                        this.loadEmployeesData(this.selected_level.id)
                            .then(()=>{
                                this.$refs[this.table_leave_uid].table_mode = 'view'
                                this.is_loading = false;
                                this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                                this.toggleLeaveViewMode();
                                this.setActiveUser();
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.is_loading = false;
                    })
            },
            saveRemunerationTable() {
                //eslint-disable-next-line
                let table = $$(this.table_remuneration_uid);
                const all_data = table.serialize(true);
                var params = {
                    user_id: this.activeEmployeeId,
                    remuneration: []
                };
                all_data.forEach( row => {
                    this.is_loading = true;
                    console.log(row);
                    if(!row.is_empty && row.amount != 0) {
                        
                        params.remuneration.push({
                            type: row.type,
                            amount: row.amount,
                            effective_date: row.effective_date,
                        });
                    }
                })
                console.log(JSON.parse(JSON.stringify(params)))
                this.saveEmployeeRemuneration(params)
                    .then(() => {
                        this.loadEmployeesData(this.selected_level.id)
                            .then(()=>{
                                this.is_loading = true
                                this.toggleRemunerationViewMode();
                                this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                                setTimeout(()=>{
                                    this.setActiveUser();
                                    this.$refs[this.table_remuneration_uid].table_mode = 'view'
                                    this.is_loading = false;
                                    
                                }, 500)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.is_loading = false;
                    })
            },
            updateLinkAssets() {
                //eslint-disable-next-line
                const all_data = window.webix.$$(this.table_assets_uid).serialize(true);
                var linkingAssets = [];
                var unlinkingAssets = [];
                all_data.forEach( row => {
                    if(!row.is_empty) {
                        this.is_loading = true;
                        let newRow = row.is_new;
                        var params = {
                            asset_id:  newRow ? -1 : row.asset_id,
                            user_id: this.activeEmployeeId,
                            received_date: format(new Date(), 'yyyy-MM-dd'),
                        };
                        if(row.selected == true) {
                            this.linkableAssetsData.forEach( asset => {
                                if(asset.selected == false && asset.id == row.asset_id) {
                                    linkingAssets.push(params);
                                }
                            })
                        } else if(row.selected == null){
                            unlinkingAssets.push(params)
                        }
                    }
                })
                let promises = [];
                this.is_loading = true;
                linkingAssets.forEach( item => {
                    promises.push(this.linkEmployeesAssets(item));
                })
                unlinkingAssets.forEach( item => {
                    promises.push(this.unlinkEmployeesAssets({asset_id: String(item.asset_id), user_id: this.activeEmployeeId}));
                })
                Promise.all(promises)
                .then(() => {
                    this.loadLinkableAssets(this.selected_level.id)
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.$refs[this.table_assets_uid].table_mode = 'view'
                        this.is_loading = false;
                        //this.toggleAssetsViewMode();
                        this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                        this.setActiveUser();
                        
                    })
                })
                .catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                })
            },
            saveFromWizardEvent(){
                this.updateUser();
            },
            handleUpdatedUserLevels(){
                let promises = [
                    this.loadEmployeesData(this.selected_level.id),
                    this.loadMenuUsers(this.selected_level.id)
                ]
                Promise.all(promises)
                .then(()=>{
                    let index = _.findIndex(this.levelEmployeesData, { id: this.selected_user_id})
                    if(index != -1){
                        this.$eventHub.$emit('setActiveEmployee', this.activeEmployeeId);
                        this.setActiveUser();
                    }
                    else{
                        this.setSelectedUser(null);
                    }
                })
            },
            editUserJobDetails(){
                this.edit_current_job = _.clone(this.user.current_job)
                this.editJobDetails = true;
            },

            isUserManager(){
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id) ;
                if(is_editing_as_manager){
                    return '';
                }
                return 'block-click';
            },
            hasValidPerms(){
                //let is_editing_self = this.$hasPerm('people') && this.core.id ==  this.selected_user_id
                //let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                //console.log(is_editing_self, is_editing_as_manager)
                //if(is_editing_self){
                //    return true;
                //}
                //else if(is_editing_as_manager){
                //    return true;
                //}
                //return false;
                let is_editing_self = this.core.id ==  this.selected_user_id;
                let is_editing_as_manager = this.$isManagerCheck(this.core.id, this.selected_user_id)
                if(is_editing_self){
                    return true;
                }
                else if(this.$hasPerm('people-manager') && (is_editing_as_manager || is_editing_self)){
                    return true;
                }
                return false;
            },
            changedJob(){
                this.is_loading_job = true;
                this.loadMenuUsers(this.selected_level.id)
                .then(()=>{
                    this.loadEmployeesData(this.selected_level.id)
                        .then(()=>{
                            this.setActiveUser();
                            this.is_loading_job = false;
                    })
                })
            },
            showDiscardPrompt(tableName,newMode){
                Swal.fire({
                    title: `Clear unsaved ${tableName} changes?`,
                    text: "Warning, changes will be deleted.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        this.table_view_mode = newMode;
                    }
                });
            },
            getJobByID(id){
                let job_name = 'Invalid Job'
                let index = _.findIndex(this.companyJobs, {id: id});
                if(index != -1){
                    job_name = this.companyJobs[index].title;
                }
                return job_name;
            }
        },
        
        mounted(){
            //this.loadLinkableAssets(this.selected_level.id);
            if(this.user == null){
                //* used v-if to show this component
                //* user will be null on load so we need to set the active user data
                //! could also use the activeEmployeeId watcher with the 'immediate' flag set to true 
                this.setActiveUser();
            }
    
            this.$eventHub.$on('saveUserFromWizard', this.saveFromWizardEvent);
            this.$eventHub.$on('updatedUserLevels', this.handleUpdatedUserLevels)
        },
        beforeDestroy(){
            this.$eventHub.$off('saveUserFromWizard');
            this.$eventHub.$off('updatedUserLevels');
        }
    }
    </script>
    
    <style>
    .userForm > .row {
        margin-bottom: 1rem;
    }
    .userForm > .row > .col-2 {
        padding-top: 0.55rem;
    }
    .header-profile-user-view{
        cursor: pointer;
        height: 100px;
        width: 100px;
        background-color: #f6f6f6;
        padding: 3px;
    }
    
    
    .hoverEditIcon{
        cursor: pointer;
        position: absolute;
            bottom: 5px;
            right: 5px;
    }
    </style>